import { useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { Breadcrumbs } from "../../../components/common";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllBlogInner } from "../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { BlogInner } from "./BlogInner/BlogInner";

const STATUS_DRAFT = "DRAFT";

const BlogInnerPage = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { alias } = useParams();
  const navigate = useNavigate();
  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);

  // **Local state
  const [, setTokenCaptcha] = useState(null);

  // **Ref
  const captchaRef = useRef(null);
  // **Redux state
  const { content, sidebar } = useSelector(getAllBlogInner);
  const { t } = useTranslation();
  //blogInnerPage, notifications
  // **Local state
  const [isLoaded, setIsLoaded] = useState(alias === content.data?.alias);
  const [saveLang, setSaveLang] = useState(queryLang);

  // Dispatch
  const {
    getBlogContentByAlias,
    setErrorBlogInnerContent,
    getBlogSidebarContent
  } = useDispatchedActions();

  useEffect(() => {
    if (alias === content.data?.alias && saveLang === queryLang) {
      return;
    }
    setSaveLang(queryLang);
    getBlogContentByAlias({ alias, languageCode: queryLang });
    // setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, alias]);

  useEffect(() => {
    if (sidebar.data?.languageCode === queryLang) {
      return;
    }

    const promise = getBlogSidebarContent({ languageCode: queryLang });
    return () => promise?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  // Handling error
  useEffect(() => {
    if (content.isError || content?.data?.status === STATUS_DRAFT) {
      toast.error(t("notifications.blogInner.notFoundBlogPost"));
      navigate(`${hrefLang}/blog/?tab=articles`);
      setErrorBlogInnerContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isError, content?.data?.status]);

  useEffect(() => {
    if (content?.data?.alias === alias && !isLoaded) {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.data?.alias]);
  return (
    <>
      <Breadcrumbs
        items={[
          ...t("blogInnerPage.breadcrumbs", { returnObjects: true }),
          { title: content.data?.title }
        ]}
      />
      {isLoaded && (
        <BlogInner captchaRef={captchaRef} setTokenCaptcha={setTokenCaptcha} />
      )}
      {captchaConfig.data.enable && captchaConfig.data.clientKey && (
        <HCaptcha
          ref={captchaRef}
          sitekey={captchaConfig.data.clientKey}
          // onLoad={() => captchaRef.current?.resetCaptcha()}
          onVerify={setTokenCaptcha}
          onExpire={() => setTokenCaptcha(null)}
          onError={(err) => toast.error(err?.message)}
          size="invisible"
        />
      )}
    </>
  );
};

export default BlogInnerPage;

BlogInnerPage.getServerSideState = async (store, params, api, req) => {
  const [, identifier] = req._parsedUrl.pathname.split("/").reverse();
  const { data: sidebar } = await axios.get(
    `${api}/api/front/site/blog/sidebar`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: blogContentByAlias } = await axios.get(
    `${api}/api/front/site/blog/view-article`,
    { params: { alias: identifier, languageCode: params.languageCode } }
  );
  const { data: comments } = await axios.get(
    `${api}/api/front/site/comments/page`,
    { params: { targetId: blogContentByAlias.id, page: 0, size: 10 } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.languageCode
    })
  );
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setBlogContentByAlias(blogContentByAlias));
  store.dispatch(AllActions.setBlogComments(comments));
};

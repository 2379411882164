import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Dropdown from "components/ui/New/Dropdown/Dropdown";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Select from "components/ui/New/Select/Select";

import { useDispatchedActions } from "hooks";

import { getParamsUrlForFilter } from "utils/helpers";

import styles from "./ProxyFilter.module.scss";

export const ProxyFilter = memo(() => {
  // **Props
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // **Redux state
  const { t } = useTranslation();

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const routeHandler = (filter) => {
    setActiveFilters({ filter });

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const fpts = searchParams.getAll("fpt");
    const s = searchParams.get("s");
    navigate(getParamsUrlForFilter(fc, fg, fpts, s, filter));
  };
  const options = [
    { value: "rating", label: t("proxyFilters.rating") },
    { value: "reviews", label: t("proxyFilters.review") },
    { value: "costs", label: t("proxyFilters.costs") }
  ];

  const filter = searchParams.get("filter");
  const activeFilter =
    options.find((item) => item.value === filter)?.value || "rating";

  return (
    <>
      <Dropdown
        className={styles.filter__dropdown}
        label={<IconsSVG name="filter" />}
        align={"end"}
        direction={"bottom"}
      >
        {options.map((item) => (
          <Dropdown.Item
            className={styles.filter__dropdown__item}
            key={item.value}
            onClick={() => routeHandler(item.value)}
            active={activeFilter === item.value}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown>
      <Select
        wrapperClassName={styles.filter__select}
        options={options}
        defaultValue={activeFilter}
        onChange={routeHandler}
      />
    </>
  );
});

import { useTranslation } from "react-i18next";

import {
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle,
  StyledOKIcon
} from "../../ExpandebleComponent.styled";

export const Benefits = ({ data }) => {
  const { t } = useTranslation();

  const { benefits } = data;
  const benefitsKeys = Object.keys(benefits);

  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.benefits.title")}
      </StyledContentTitle>
      {benefitsKeys.length > 0 ? (
        <StyledContentList>
          {benefitsKeys
            .map((key, i) =>
              benefits[key] ? (
                <StyledContentListItem key={`benefit-${key}-${i}`}>
                  <StyledOKIcon />
                  {t(`proxyPage.content.table.benefits.${key}`)}
                </StyledContentListItem>
              ) : null
            )
            .filter(Boolean)}
        </StyledContentList>
      ) : (
        <span>{t("proxyPage.content.table.benefits.noBenefits")}</span>
      )}
    </div>
  );
};

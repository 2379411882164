export const reviewsMockData = {
  data: {
    totalElements: 4,
    totalPages: 1,
    content: [
      {
        id: "65b8c3d567ed2014b0bb9fd2",
        creationDate: "2024-01-30T09:39:33.288+00:00",
        siteId: "63ac38df3e71d938a7032ce0",
        responses: [
          {
            id: "65b8c49f67ed2014b0bb9fd6",
            creationDate: "2024-01-30T09:42:55.154+00:00",
            targetId: "65b8c3d567ed2014b0bb9fd2",
            userId: "60d4897ccf044751a3912e67",
            userName: "Admin",
            commentBody: "привіт",
            locale: "ua",
            translation: {
              en: {
                locale: "en",
                commentBody: "hello"
              },
              ru: {
                locale: "ru",
                commentBody: "привет"
              },
              ua: {
                locale: "ua",
                commentBody: "привіт"
              }
            },
            responses: [
              {
                id: "6634c2e09585ab7627da0503",
                creationDate: "2024-05-03T10:56:32.539+00:00",
                targetId: "65b8c49f67ed2014b0bb9fd6",
                userId: "6634c2df9585ab7627da0502",
                userName: "asdasd",
                commentBody: "asdasdasdasdasd",
                locale: "en",
                translation: {
                  en: {
                    locale: "en",
                    commentBody: "asdasdasdasdas"
                  },
                  ru: {
                    locale: "ru",
                    commentBody: "асдасдасдасдас"
                  },
                  ua: {
                    locale: "ua",
                    commentBody: "asdasdasdasdasdas"
                  }
                },
                responses: [],
                karma: 0
              },
              {
                id: "6634d0eb9585ab7627da0507",
                creationDate: "2024-05-03T11:56:27.237+00:00",
                targetId: "65b8c49f67ed2014b0bb9fd6",
                userId: "6634d0ea9585ab7627da0506",
                userName: "asdasd",
                commentBody: "asdasdasdasdas",
                locale: "en",
                translation: {
                  en: {
                    locale: "en",
                    commentBody: "asdasdasdasdas"
                  },
                  ru: {
                    locale: "ru",
                    commentBody: "асдасдасдасдас"
                  },
                  ua: {
                    locale: "ua",
                    commentBody: "asdasdasdasdasdas"
                  }
                },
                responses: [],
                karma: 0
              },
              {
                id: "663b494403891d1bdf3c9253",
                creationDate: "2024-05-08T09:43:32.448+00:00",
                targetId: "65b8c49f67ed2014b0bb9fd6",
                userId: "663b494403891d1bdf3c9252",
                userName: "test",
                commentBody: "asdasdasdasda test",
                locale: "en",
                translation: {
                  en: {
                    locale: "en",
                    commentBody: "asdasdasdasda test"
                  },
                  ru: {
                    locale: "ru",
                    commentBody: "тест асдасдасдасда"
                  },
                  ua: {
                    locale: "ua",
                    commentBody: "тест asdasdasdasdasda"
                  }
                },
                responses: [],
                karma: 0
              }
            ],
            karma: 1
          },
          {
            id: "65eae10d7850cc6b5dd03cea",
            creationDate: "2024-03-08T09:57:32.956+00:00",
            targetId: "65b8c3d567ed2014b0bb9fd2",
            userId: "65eae10c7850cc6b5dd03ce9",
            userName: "dadasdas",
            commentBody: "asdasdasd",
            locale: "en",
            translation: {
              en: {
                locale: "en",
                commentBody: "asdasdasd"
              },
              ru: {
                locale: "ru",
                commentBody: "asdasdasd"
              },
              ua: {
                locale: "ua",
                commentBody: "asdasdasd"
              }
            },
            responses: [],
            karma: 0
          },
          {
            id: "663b488503891d1bdf3c9250",
            creationDate: "2024-05-08T09:40:21.015+00:00",
            targetId: "65b8c3d567ed2014b0bb9fd2",
            userId: "663b488403891d1bdf3c924f",
            userName: "STANDART",
            commentBody: "asdasdasdasd",
            locale: "en",
            translation: {
              en: {
                locale: "en",
                commentBody: "asdasdasdasd"
              },
              ru: {
                locale: "ru",
                commentBody: "асдасдасдасд"
              },
              ua: {
                locale: "ua",
                commentBody: "asdasdasdasd"
              }
            },
            responses: [],
            karma: 0
          }
        ],
        userId: "60d4897ccf044751a3912e67",
        userName: "Admin",
        userEmail: "admin@admin.com",
        attributes: {
          advantages: "test1",
          disadvantages: "test",
          usage: "test",
          wishes: "test"
        },
        reviewBody: "test",
        locale: "en",
        translation: {
          en: {
            locale: "en",
            reviewBody: "test",
            attributes: {
              advantages: "test1",
              disadvantages: "test",
              usage: "test",
              wishes: "test"
            }
          },
          ru: {
            locale: "ru",
            reviewBody: "тест",
            attributes: {
              advantages: "тест1",
              disadvantages: "тест",
              usage: "тест",
              wishes: "тест"
            }
          },
          ua: {
            locale: "ua",
            reviewBody: "тест",
            attributes: {
              advantages: "test1",
              disadvantages: "тест",
              usage: "тест",
              wishes: "тест"
            }
          }
        },
        useful: 0,
        unuseful: 0,
        rating: 5,
        isOwner: false,
        isApproved: true
      },
      {
        id: "649e9d8b7b6d4255f843cd93",
        creationDate: "2023-06-30T09:16:59.560+00:00",
        siteId: "63ac38df3e71d938a7032ce0",
        responses: [],
        userId: "649e9d8b7b6d4255f843cd92",
        userName: "test",
        userEmail: "testestest@gmail.com",
        attributes: {
          advantages: "testtesttesttesttesttesttesttesttesttesttesttesttest",
          disadvantages: "testtesttesttesttesttesttesttesttesttesttesttest",
          usage: "testtesttesttesttesttesttesttesttesttesttesttest",
          wishes: "testtesttesttesttesttesttesttesttesttesttesttesttest"
        },
        reviewBody: "testtesttesttesttesttesttesttesttesttesttesttesttesttest",
        locale: "en",
        translation: {
          en: {
            locale: "en",
            reviewBody: "test test test test test test test test test test",
            attributes: {
              advantages: "test test test test test test test test",
              disadvantages: "test test test test test test test test test",
              usage: "test test test test test test test test test",
              wishes: "test test test test test test test test"
            }
          },
          ru: {
            locale: "ru",
            reviewBody:
              "испытание испытание испытание испытание испытание испытание испытание испытание испытание испытание испытание испытание испытание",
            attributes: {
              advantages: "тест тест тест тест тест тест тест тест тест тест",
              disadvantages:
                "испытание испытание испытание испытание испытание испытание испытание испытание испытание",
              usage:
                "испытание испытание испытание испытание испытание испытание испытание испытание испытание",
              wishes: "тест тест тест тест тест тест тест тест тест тест"
            }
          },
          ua: {
            locale: "ua",
            reviewBody:
              "тест тест тест тест тест тест тест тест тест тест тест тест тест",
            attributes: {
              advantages:
                "тест тест тест тест тест тест тест тест тест тест тест",
              disadvantages:
                "тест тест тест тест тест тест тест тест тест тест тест",
              usage:
                "тест тест тест тест тест тест тест тест тест тест тест тест",
              wishes: "тест тест тест тест тест тест тест тест тест тест тест"
            }
          }
        },
        useful: 1,
        unuseful: 0,
        rating: 5,
        isOwner: false,
        isApproved: true
      },
      {
        id: "63ac38e73e71d938a7033574",
        creationDate: "2021-12-06T03:16:00.000+00:00",
        siteId: "63ac38df3e71d938a7032ce0",
        responses: [],
        userId: "63ac38dc3e71d938a70328f6",
        userName: "Мишель",
        userEmail: "ivanov@card-oil.ru",
        attributes: {
          advantages: "много стран и полезных для прокси инструментов на сайте",
          disadvantages:
            "раньше был недостаток - долгое время выдачи, после обновления сайта его устранили",
          usage: "Кей Коллектор",
          wishes: ""
        },
        reviewBody: "Обновили сайт, после этого наконец все стало нормально",
        locale: "ru",
        translation: {
          en: {
            locale: "en",
            reviewBody:
              "We updated the site, and then finally everything is fine.",
            attributes: {
              advantages: "Many countries and useful proxy tools on the site",
              disadvantages:
                "There used to be a drawback - a long issuance time, after updating the site it has been eliminated",
              usage: "Kay Collector",
              wishes: null
            }
          },
          ru: {
            locale: "ru",
            reviewBody:
              "Обновили сайт, после этого наконец все стало нормально",
            attributes: {
              advantages:
                "много стран и полезных для прокси инструментов на сайте",
              disadvantages:
                "раньше был недостаток - долгое время выдачи, после обновления сайта его устранили",
              usage: "Кей Коллектор",
              wishes: null
            }
          },
          ua: {
            locale: "ua",
            reviewBody: "Оновили сайт, після цього нарешті все стало нормально",
            attributes: {
              advantages:
                "багато країн і корисних для проксі інструментів на сайті",
              disadvantages:
                "раніше був недолік - довгий час видачі, після оновлення сайту його усунули",
              usage: "Кей Колектор",
              wishes: null
            }
          }
        },
        useful: 10,
        unuseful: 5,
        rating: 4,
        isOwner: false,
        isApproved: true
      },
      {
        id: "63ac38e73e71d938a7033589",
        creationDate: "2021-11-04T21:34:00.000+00:00",
        siteId: "63ac38df3e71d938a7032ce0",
        responses: [],
        userId: "63ac38dc3e71d938a70328fa",
        userName: "Денис",
        userEmail: "raixen@yandex.kz",
        attributes: {
          advantages: "скорость, цена, обилие геолокаций",
          disadvantages: "иногда очень долгое время выдачи",
          usage: "ФБ",
          wishes: "Автоматизируйте выдачу проксей и цены вам не будет"
        },
        reviewBody:
          "Если закрыть глаза на время выдачи - отличный сервис. Брал несколько раз пачки проксей под арбитраж, хорошо себя показали",
        locale: "ru",
        translation: {
          en: {
            locale: "en",
            reviewBody:
              "If you turn a blind eye to the issuance time, it's an excellent service. I took several packs of proxies for arbitrage, and they were good.",
            attributes: {
              advantages: "Speed, price, abundance of geolocations",
              disadvantages: "Sometimes a very long issuance time",
              usage: "ФБ",
              wishes: "Automate the proxies and you will be priceless"
            }
          },
          ru: {
            locale: "ru",
            reviewBody:
              "Если закрыть глаза на время выдачи - отличный сервис. Брал несколько раз пачки проксей под арбитраж, хорошо себя показали",
            attributes: {
              advantages: "скорость, цена, обилие геолокаций",
              disadvantages: "иногда очень долгое время выдачи",
              usage: "ФБ",
              wishes: "Автоматизируйте выдачу проксей и цены вам не будет"
            }
          },
          ua: {
            locale: "ua",
            reviewBody:
              "Якщо закрити очі на час видачі - чудовий сервіс. Брав кілька разів пачки проксей під арбітраж, добре себе показали",
            attributes: {
              advantages: "швидкість, ціна, велика кількість геолокацій",
              disadvantages: "іноді дуже довгий час видачі",
              usage: "ФБ",
              wishes: "Автоматизуйте видачу проксей і ціни вам не буде"
            }
          }
        },
        useful: 4,
        unuseful: 2,
        rating: 4,
        isOwner: false,
        isApproved: true
      }
    ]
  }
};

import { createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getComparisonWidgetInfo = createAsyncThunk(
  "comparison/getComparisonWidgetInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getComparisonWidgetInfo();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const updateComparsionSites = createAsyncThunk(
  "comparison/updateComparsionSites",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.updateComparsionSites(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

import PT from "prop-types";

import styles from "./FormBlock.module.scss";

export const FormBlock = ({
  title,
  subtitle,
  className,
  bodyClassName,
  children
}) => (
  <div className={`${styles.wrapper} ${className}`}>
    <div className={styles.label}>
      {title && <h3 className={styles.label_title}>{title}</h3>}
      {subtitle && <p className={styles.label_subtitle}>{subtitle}</p>}
    </div>
    <div className={`${styles.body} ${bodyClassName}`}>{children}</div>
  </div>
);

FormBlock.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  className: PT.string,
  bodyClassName: PT.string,
  children: PT.node
};

FormBlock.defaultProps = {
  title: "",
  subtitle: "",
  className: "",
  bodyClassName: "",
  children: null
};

export default FormBlock;

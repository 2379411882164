import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../hooks";

import "./Logo.scss";

const Logo = ({
  imgPath,
  imgWidth = 198,
  imgHeight = 41,
  imgAlt = "Rating Proxy",
  linkTitle,
  position,
  ...rest
}) => {
  const [, hrefLang] = useLangUrlDefault();

  const defineClassname = () => {
    let className = "logo";

    switch (position) {
      case "center": {
        className += " logo__position--center";
        break;
      }
      default: {
        break;
      }
    }

    return className;
  };

  return (
    <div className={defineClassname()} {...rest}>
      <Link to={`${hrefLang}/`} title={linkTitle}>
        <img
          src={imgPath}
          width={imgWidth}
          height={imgHeight}
          loading="lazy"
          alt={imgAlt}
        />
      </Link>
    </div>
  );
};

export default Logo;

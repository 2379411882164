import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "components/ui/New/Button/Button";
import { Subtitle } from "components/ui/New/Subtitle/Subtitle";

import { Input } from "../../../../components/forms/New/Input/Input";
import { Alert } from "../../../../components/ui/New/Alert/Alert";
import { Title } from "../../../../components/ui/New/Title/Title";
import { useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { AllActions } from "../../../../store/reducers/AllActions";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { RecoverySchema } from "../../../../utils/validation";
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate";

import styles from "./RecoverySendPage.module.scss";

const RecoverySendPage = () => {
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const navigate = useNavigate();

  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);

  const { enable, clientKey, headerName } = captchaConfig.data;

  const methods = useForm({
    resolver: yupResolver(RecoverySchema(t("forms", { returnObjects: true })))
  });

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [, setTokenCaptcha] = useState(null);

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
      // const { response: token } = await captchaRef.current.execute({
      //   async: true
      // });
      // header = { [headerName]: token };
    }

    try {
      setIsLoading(true);

      const response = await ApiService.sendPasswordLink(data?.email, header);

      if (response && response.status !== 200) {
        throw response;
      }

      methods.reset();
      setSuccess(true);
      //   toast.success(t("notifications.emailSent"));
    } catch (err) {
      setError(true);
      //   toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  const onReturn = () => {
    navigate(`${hrefLang}/new/login/`);
  };

  return (
    <>
      <Title className={styles.title}>
        {t("authPages.resetPassword.title")}
      </Title>
      <Subtitle className={styles.subtitle}>
        {t("authPages.resetPassword.subtitle")}
      </Subtitle>
      {success && (
        <Alert className={styles.alert} type="success" onClose={onReturn}>
          {" "}
          {t("authPages.resetPassword.success")}
        </Alert>
      )}
      {!success && (
        <FormProvider {...methods}>
          <form
            className={styles.form}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            {error && (
              <Alert type="error" className={styles.alert_error}>
                {t("authPages.resetPassword.error")}
              </Alert>
            )}
            <Input
              name="email"
              type={"email"}
              placeholder={t("forms.email.label")}
              label={t("forms.email.label")}
              showError
            />
            {enable && clientKey && (
              <HCaptcha
                ref={captchaRef}
                sitekey={clientKey}
                onVerify={setTokenCaptcha}
                onExpire={() => setTokenCaptcha(null)}
                onError={(err) => toast.error(err?.message)}
                size="invisible"
              />
            )}
            <Button
              className={styles.submit}
              formSubmit
              fullWidth
              disabled={isLoading}
              loading={isLoading}
            >
              {t("authPages.resetPassword.btn")}
            </Button>
          </form>
        </FormProvider>
      )}{" "}
      <AuthNavigate to="login" isStatic />
    </>
  );
};

export default RecoverySendPage;

RecoverySendPage.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

// Libs
import axios from "axios";
import { isServer } from "../utils/helpers";

export const $api = isServer ? axios.create({ baseURL: process.env.REACT_APP_URL }) : axios.create();

$api.interceptors.response.use(
  (response) => {
    try {
      if (response.data.slice(0, 15) === "<!doctype html>") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();

        return null;
      } else {
        return response;
      }
    } catch (e) {
      return response;
    }
  },
  (error) => {
    try {
      if (error.config.url === "/api/login") {
        return error;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Login error", e);
    }
    // eslint-disable-next-line no-undefined
    if (typeof error.response === undefined) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();

      return null;
    } else {
      return error;
    }
  }
);

import { forwardRef, useRef, useState } from "react";

import { Option } from "@mui/base/Option";
import { Select as BaseSelect } from "@mui/base/Select";
import PT from "prop-types";

import Icon from "../Icon/Icon";

import styles from "./Select.module.scss";

const Button = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { ownerState, children, ...other } = props;
  return (
    <button {...other} ref={ref}>
      {children}
      <Icon name="arrowDown" className="indicator" size="xlarge" />
    </button>
  );
});

export const Select = ({
  options,
  id,
  name,
  value,
  defaultValue,
  onChange,
  disabled,
  className,
  wrapperClassName,
  menuClassName,
  placeholder,
  multiple,
  renderValue,
  label,
  color,
  labelSize
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    if (isOpen) {
      const size = ref.current.getBoundingClientRect();
      if (size.width !== width) {
        setWidth(size.width);
      }
    }
    setIsMenuOpen(isOpen);
  };

  const currentValueProps = (value) => {
    if (!value) return multiple ? [] : value;
    const type = typeof value;
    if (!multiple) {
      return type === "object" ? value?.[0] : value;
    }
    return type === "object" ? value : [value];
  };

  const defineWrapperClassName = () => {
    const classes = [styles.wrapper];

    if (styles[color]) {
      classes.push(styles[color]);
    }

    if (wrapperClassName) {
      classes.push(wrapperClassName);
    }

    return classes.join(" ");
  };

  return (
    <div className={defineWrapperClassName()}>
      {label && (
        <label className={`${styles.label} ${styles[`label__${labelSize}`]}`}>
          {label}
        </label>
      )}
      <BaseSelect
        ref={ref}
        id={id}
        name={name}
        value={currentValueProps(value)}
        defaultValue={currentValueProps(defaultValue)}
        onChange={(event, value) => {
          if (!event) return;
          onChange(value);
        }}
        className={`${styles.select} ${className}`}
        slots={{ root: Button }}
        slotProps={{
          popup: {
            className: `${styles.popup} ${
              isMenuOpen ? styles.popup__open : styles.popup__close
            }`,
            disablePortal: true
          },
          listbox: {
            className: `${styles.listbox} ${
              styles[`listbox__${color}`]
            } ${menuClassName} ${color === "dark" ? "scrollbar__dark" : ""}`,
            style: {
              minWidth: `${width}px`
            }
          }
        }}
        renderValue={renderValue}
        onListboxOpenChange={handleMenuOpen}
        listboxOpen={isMenuOpen}
        disabled={disabled}
        placeholder={
          <div
            className={`${styles.placeholder} ${
              styles[`placeholder__${color}`]
            }`}
          >
            {placeholder}
          </div>
        }
        multiple={multiple}
      >
        {options.map((option) => (
          <Option
            className={`${styles.option} ${styles[`option__${color}`]}`}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
      </BaseSelect>
    </div>
  );
};

Select.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.oneOfType([PT.string, PT.number]).isRequired,
      label: PT.node.isRequired
    })
  ).isRequired,
  onChange: PT.func.isRequired,
  className: PT.string,
  disabled: PT.bool,
  id: PT.string,
  name: PT.string,
  value: PT.oneOfType([PT.string, PT.array, PT.number]),
  defaultValue: PT.oneOfType([PT.string, PT.array, PT.number]),
  placeholder: PT.string,
  multiple: PT.bool,
  menuClassName: PT.string,
  wrapperClassName: PT.string,
  label: PT.string,
  renderValue: PT.func,
  color: PT.oneOf(["light", "dark"]),
  labelSize: PT.oneOf(["small", "large"])
};

Select.defaultProps = {
  options: [],
  onChange: () => null,
  className: "",
  wrapperClassName: "",
  disabled: false,
  id: "",
  name: "",
  defaultValue: "",
  placeholder: "",
  multiple: false,
  menuClassName: "",
  label: null,
  renderValue: null,
  color: "light",
  labelSize: "small"
};

export default Select;

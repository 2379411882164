// import { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";
import { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Input } from "components/forms/New";
import Button from "components/ui/New/Button/Button";
import Modal from "components/ui/New/Modal/Modal";

import styles from "./ModalAppeal.module.scss";

export const ModalAppeal = ({ open, close, idComment }) => {
  const { t } = useTranslation();
  const methods = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const cancelHandler = (value) => {
    close?.(value);
  };

  const submitHandler = (data) => {
    setIsSubmitting(true);
    const params = {
      id: idComment,
      text: data.text
    };
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log(params);
      setIsSubmitting(false);
      close?.(false);
      toast.success("Your appeal has been sent");
    }, 2000);
  };

  return (
    <Modal
      size={"sm"}
      open={open}
      setOpen={close}
      title={t("dashboard.reviews.appeal.modal.title")}
      className={styles.modal}
      classNameBody={styles.modal_body}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(submitHandler)}
          noValidate
          className={styles.modal_form}
        >
          <Input
            name="text"
            type="textarea"
            placeholder={t("forms.comment.placeholder")}
            label={t("forms.comment.label")}
            rows={6}
            className={styles.modal_input}
          />
          <div className={styles.modal_buttons}>
            <Button fullWidth loading={isSubmitting} formSubmit>
              {t("forms.buttons.send")}
            </Button>
            <Button
              variant={"secondary"}
              onClick={() => cancelHandler(false)}
              fullWidth
              loading={isSubmitting}
            >
              {t("forms.buttons.cancel")}
            </Button>
          </div>{" "}
        </form>
      </FormProvider>
    </Modal>
  );
};

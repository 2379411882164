import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// import { PromocodeItem } from "pages/CabinetPages/pages/Promocodes/PromocodesListPage/PromocodesList/PromocodeItem/PromocodeItem";
import { NoData } from "pages/New/Dashboard/components/NoData/NoData";

import { StyledDataTable } from "components/common/New/Table/Table.styled";
import {
  HeaderNameCell,
  SiteNameCell,
  TextCell
} from "components/common/New/TableColumns";
import { Loader } from "components/ui";
import Card from "components/ui/New/Card/Card";
import Dropdown from "components/ui/New/Dropdown/Dropdown";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import PaginationTable from "components/ui/New/PaginationTable/PaginationTable";
// import Status from "components/ui/New/Status/Status";
import Switch from "components/ui/New/Switch/Switch";
import { TableCheckbox } from "components/ui/New/TableCheckbox/TableCheckbox";

// import Tooltip from "components/ui/New/Tooltip/Tooltip";
import { AllActions } from "store/reducers/AllActions";
import { getAllCabinet } from "store/reducers/CabinetReducer/Cabinet.selectors";

import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import { ModalConfirm } from "../components/ModalConfirm/ModalConfirm";
import { SortBlock } from "../components/SortBlock/SortBlock";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { NameFilter } from "./components/NameFilter";

import styles from "./Promocodes.module.scss";

// const COLORS = {
//   active: "success",
//   inactive: "error",
//   added: "success",
//   moderation: "warning"
// };

const Promocodes = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const { promocodes } = useSelector(getAllCabinet);

  // **Local state
  const [deletedPromocodeId, setDeletedPromocodeId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [filter, setFilter] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [params, setParams] = useState(
    promocodes.prevParams || {
      page: 0,
      size: 10
    }
  );

  // **Dispatch
  const { getCabinetPromocodes, setCabinetPromocodesPrevParams } =
    useDispatchedActions();

  // **Ref
  // const scrollRef = useRef();

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const fetchCabinetPromocodes = useCallback(() => {
    getCabinetPromocodes(params);
    setCabinetPromocodesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const pageSizeChangeHandler = (size) =>
    setParams({ ...params, page: 0, size });

  const changeSortType = (type) => {
    setParams({ ...params, sort: type });
  };

  const openModalHandler = (id) => {
    setIsModalOpened(true);
    setDeletedPromocodeId(id);
  };

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((r) => r.id === row.id),
      style: {
        backgroundColor: "rgba(246, 247, 249, 0.80)"
      }
    }
  ];
  // console.log("promocodes", promocodes?.data?.content);
  const columns = [
    {
      name: (
        <div
          className={`${styles.column} ${styles.column_name} ${styles.column_name_head}`}
        >
          {t("dashboard.promocodes.table.columns.name").toUpperCase()}
          <NameFilter
            data={promocodes?.data?.content}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      ),
      minWidth: "230px",
      cell: (row) => (
        <SiteNameCell
          imgUrl={row?.imageUrl}
          name={row?.siteName}
          showRating={false}
        />
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.promocode")}
        </HeaderNameCell>
      ),
      minWidth: "200px",
      cell: (row) => <TextCell>{row?.promocode}</TextCell>
    },
    // {
    //   name: t("dashboard.promocodes.table.columns.status").toUpperCase(),
    //   minWidth: "200px",

    //   cell: (row) => (
    //     <div>
    //       <Status
    //         size={"sm"}
    //         bordered={false}
    //         label={t(`statuses.${row?.statusType}`)}
    //         color={COLORS[row?.statusType]}
    //       />
    //       {row?.statusType === "inactive" && (
    //         <Tooltip
    //           place={"bottom"}
    //           classNameTooltip={styles.column_status_tooltip}
    //           body={
    //             "A hint is a graphical interface element that serves as an additional user training tool."
    //           }
    //         >
    //           <IconsSVG
    //             name="infoChat"
    //             role="button"
    //             className={styles.column_status_info}
    //           />
    //         </Tooltip>
    //       )}
    //     </div>
    //   ),
    //   sortable: true,
    //   selector: (row) => row?.statusType
    // },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.date")}
        </HeaderNameCell>
      ),
      minWidth: "175px",
      cell: (row) => (
        <div className={`${styles.column} ${styles.column_date}`}>
          <div className={styles.column_date_value}>
            {row?.eternal ? (
              <div className={styles.column_date_eternal}>{"∞ Is Eternal"}</div>
            ) : (
              <>
                <Moment format="DD.MM.YYYY">{new Date(row?.startDate)}</Moment>
                {" - "}
                <Moment format="DD.MM.YYYY">{new Date(row?.endDate)}</Moment>
              </>
            )}
          </div>
        </div>
      ),
      sortable: true,
      selector: (row) => row?.startDate
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.active")}
        </HeaderNameCell>
      ),
      minWidth: "160px",
      cell: (row) => (
        <div className={`${styles.column} ${styles.column_active}`}>
          <Switch checked={row?.isActive} />
        </div>
      )
    },
    {
      minWidth: "24px",
      cell: (row) => (
        <div className={`${styles.column} ${styles.column_actions_box}`}>
          <Dropdown
            label={
              <IconsSVG name="dots" className={styles.column_actions_icon} />
            }
            className={styles.column_actions}
            menuClassName={styles.column_actions_menu}
            align={"start"}
            direction={"left"}
            position={"anchor"}
            portal
          >
            <Dropdown.Item
              className={styles.column_actions_item}
              linkTo={`${hrefLang}/new/dashboard/promocodes/edit/${row.id}/`}
            >
              <IconsSVG
                name="edit"
                className={styles.column_actions_item_icon}
              />{" "}
              {t("cabinetPages.proxy.list.edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => openModalHandler(row.id)}
              className={`${styles.column_actions_item} ${styles.column_actions_item_delete}`}
            >
              <IconsSVG
                name="deleteV2"
                className={styles.column_actions_item_icon}
              />
              {t("cabinetPages.proxy.list.delete")}
            </Dropdown.Item>
          </Dropdown>
        </div>
      )
    }
  ];

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await ApiService.deletePromocode(deletedPromocodeId);

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      setIsModalOpened(false);

      // Show success message
      toast.success(t("notifications.promocode.removed"));

      setDeletedPromocodeId(null);
      fetchCabinetPromocodes();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(promocodes.prevParams)) {
      fetchCabinetPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (promocodes.data.totalPages !== params.page) {
      return;
    }

    if (params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, promocodes]);

  const customStyles = {
    table: {
      style: {
        marginBottom: "18px"
      }
    }
  };

  return (
    <>
      <TitleBlock
        title={t("dashboard.promocodes.table.title")}
        linkTo={"/new/dashboard/promocodes/create"}
        linkName={t("dashboard.promocodes.table.add")}
      />

      {promocodes.data?.content?.length !== 0 && (
        <Card className={styles.card} size={"small"} radius={"small"}>
          <SortBlock
            rowCount={promocodes.data?.totalElements}
            params={params}
            label={t("proxySitePage.content.reviews.top.title")}
            options={[
              { value: "value1", label: "Value 1" },
              { value: "value2", label: "Value 2" }
            ]}
            changeSortType={changeSortType}
          />
          <StyledDataTable
            styleRules={{
              showedAll: true
            }}
            data={promocodes.data?.content}
            columns={columns}
            selectableRows
            selectableRowsComponent={TableCheckbox}
            selectableRowsHighlight
            onSelectedRowsChange={handleRowSelected}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
          />
          <PaginationTable
            rowCount={promocodes.data.totalElements}
            rowsPerPage={params.size}
            onChangePage={pageChangeHandler}
            onChangeRowsPerPage={pageSizeChangeHandler}
            currentPage={params.page + 1}
            className={styles.pagination}
          />
        </Card>
      )}

      {!promocodes.isLoading && promocodes.data?.content?.length === 0 && (
        <NoData
          title={t("dashboard.promocodes.noData.title")}
          subtitle={t("dashboard.promocodes.noData.subtitle")}
          srcX1={"/img/dashboard/no-promocodes-x1.png"}
          srcX2={"/img/dashboard/no-promocodes-x2.png"}
        />
      )}
      {promocodes.isLoading && <Loader type="blur" />}
      <ModalConfirm
        open={isModalOpened}
        close={setIsModalOpened}
        isDeleting={isDeleting}
        srcX1={"/img/dashboard/delete-promo-x1.png"}
        srcX2={"/img/dashboard/delete-promo-x2.png"}
        subtitle={t("dashboard.promocodes.modal.delete.subtitle")}
        onConfirm={confirmHandler}
        classNamePicture={styles.modal_picture}
      />
    </>
  );
};

export default Promocodes;

Promocodes.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

import { memo } from "react";

import { useSelector } from "react-redux";

import { Loader } from "components/ui/Loader/Loader";
import { Title } from "components/ui/New/Title/Title";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { About } from "./About/About";
import { Alternative } from "./Alternative/Alternative";
import { Info } from "./Info/Info";

import styles from "./Content.module.scss";

export const Content = memo(({ stepRating, clickStepRatingHandle }) => {
  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  const { site } = useSelector(getAllProxySite);

  return !site.isLoading ? (
    <>
      <Title tag={"h1"} className={styles.title}>
        {current?.data?.h1 || site.data?.name}
      </Title>
      <div className={styles.content}>
        <Info />
        <About
          stepRating={stepRating}
          clickStepRatingHandle={clickStepRatingHandle}
        />
        <Alternative />
      </div>
    </>
  ) : (
    <Loader />
  );
});

import { useState } from "react";

import "moment/locale/ru";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Stars from "components/ui/New/Stars/Stars";
import TextButton from "components/ui/New/TextButton/TextButton";

import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { useLangUrlDefault } from "hooks";

import { ModalAppeal } from "./ModalaAppeal";

import styles from "./UserPartV2.module.scss";

export const UserPartV2 = ({ review, isRootReview }) => {
  const [lang] = useLangUrlDefault();
  const { t } = useTranslation();
  const { user, isUserAuthenticated } = useSelector(getAllUser);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={`${styles.user} ${isRootReview ? "" : styles.nested}`}>
      <div
        className={`${styles.user_data} ${
          isRootReview ? "" : styles.nested_data
        }`}
      >
        <div className={styles.user_name}>
          {review?.userName
            ? `${
                review?.userName.includes("@")
                  ? review?.userName.split("@")[0]
                  : review?.userName
              } `
            : `${t("reviews.anonymous")} `}
          {isUserAuthenticated &&
            review?.userId === user?.uid &&
            t("reviews.you")}
        </div>
        {isRootReview && (
          <Stars
            size={20}
            value={review?.rating}
            className={styles.review_stars}
          />
        )}
        {!isRootReview && review?.userName === "Admin" && (
          <div className={styles.user_official}>
            <IconsSVG name="checkCircle" />
            {review?.userName}
          </div>
        )}
      </div>

      <div className={styles.date}>
        {isRootReview && (
          <div
            className={`${styles.date_status} ${
              review?.isApproved
                ? styles.date_status_approved
                : styles.date_status_rejected
            }`}
          >
            <IconsSVG name={review?.isApproved ? "checkCircle" : "clock"} />
            <span className={styles.date_status_text}>
              {review?.isApproved ? t("approved") : t("rejected")}
            </span>
          </div>
        )}
        <Moment
          format="MMM DD, YYYY"
          locale={lang}
          className={styles.date_value}
        >
          {new Date(review.creationDate)}
        </Moment>
      </div>
      {isRootReview && (
        <>
          <TextButton
            iconLeft={"info"}
            className={styles.user_btn}
            color={"secondary"}
            onClick={handleOpenModal}
            size={"sm"}
          >
            {t("dashboard.reviews.appeal.btn")}
          </TextButton>
          <ModalAppeal
            open={openModal}
            close={handleCloseModal}
            idComment={review.id}
          />
        </>
      )}
    </div>
  );
};

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import Switch from "components/ui/New/Switch/Switch";

import IconsSVG from "../../../ui/New/Icon/IconsSVG";

import styles from "./FormSwitch.module.scss";

export const FormSwitch = ({
  name,
  rules,
  label,
  className,
  defaultValue,
  showError,
  disabled
}) => {
  // Form
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={`${styles.wrapper} ${className}`}>
          <Switch
            checked={field.value}
            onChange={field.onChange}
            label={label}
            disabled={disabled}
          />

          {showError && error?.message && (
            <div className={styles.error}>
              <IconsSVG name={"inputError"} />
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  );
};

FormSwitch.PT = {
  name: PT.string.isRequired,
  rules: PT.object,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  defaultValue: PT.bool,
  showError: PT.bool,
  disabled: PT.bool
};

FormSwitch.defaultProps = {
  className: "",
  defaultValue: false,
  showError: false,
  disabled: false
};

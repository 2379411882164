import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Rating } from "../../../../../../../components/common";
import { FeedbackCount } from "../../../../../../../components/ui/FeedbackCount/FeedbackCount";
import { useLangUrlDefault } from "../../../../../../../hooks";

import { Tooltip } from "./Tooltip/Tooltip";

export const NameCell = ({ row }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const filterPromocodesLang = row.promocodes.filter(
    (it) => Object.keys(it.showIn).length === 0 || it.showIn[queryLang]
  );

  return (
    <div className="proxy-table__name">
      <div className="proxy-table__name-content">
        <div
          className={
            row.imageFile
              ? "proxy-table__name-image"
              : "proxy-table__name-image proxy-table__name-image--empty"
          }
        >
          <Link to={`${hrefLang}/proxy/${row.alias}/`}>
            <img
              src={row.imageFile ? row.imageFile : "/img/ui/placeholder.svg"}
              width={115}
              height={35}
              loading="lazy"
              alt=""
              aria-hidden="true"
            />
          </Link>
        </div>
        {row?.name && (
          <Link to={`${hrefLang}/proxy/${row.alias}/`}>
            <div className="proxy-table__name-title">{row?.name}</div>
          </Link>
        )}
        {typeof row?.rating === "number" && (
          <Rating rating={row?.rating?.toFixed(1)} />
        )}
        <Link to={`${hrefLang}/proxy/${row.alias}/#reviews`}>
          <FeedbackCount
            showIcon
            positive={row.ratingPositive}
            negative={row.ratingNegative}
            centered
          />
        </Link>
      </div>
      <div className="proxy-table__name-tooltip">
        <Tooltip
          data={[
            {
              key: t("proxyPage.content.table.name.domainYear"),
              value: `${row?.domainYear} ${t(
                "proxyPage.content.table.name.domainYearEnding"
              )}`
            },
            {
              key: t("proxyPage.content.table.name.countriesQuantity"),
              value: row.countries.length
            }
          ]}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M10 0C4.47254 0 0 4.45222 0 9.9537C0 15.4556 4.47293 19.9074 10 19.9074C15.5275 19.9074 20 15.4552 20 9.9537C20 4.45183 15.5271 0 10 0ZM10 18.3521C5.33621 18.3521 1.5625 14.5956 1.5625 9.9537C1.5625 5.31151 5.33652 1.55527 10 1.55527C14.6638 1.55527 18.4375 5.31182 18.4375 9.9537C18.4375 14.5959 14.6635 18.3521 10 18.3521Z"></path>
              <path d="M10 5.01099C9.56852 5.01099 9.21875 5.35913 9.21875 5.78862V10.7963C9.21875 11.2258 9.56852 11.5739 10 11.5739C10.4315 11.5739 10.7812 11.2258 10.7812 10.7963V5.78862C10.7812 5.35913 10.4315 5.01099 10 5.01099Z"></path>
              <path d="M10 14.6259C10.5825 14.6259 11.0547 14.1558 11.0547 13.576C11.0547 12.9963 10.5825 12.5262 10 12.5262C9.41751 12.5262 8.94531 12.9963 8.94531 13.576C8.94531 14.1558 9.41751 14.6259 10 14.6259Z"></path>
            </g>
          </svg>
        </Tooltip>
        {filterPromocodesLang.length > 0 && (
          <Tooltip
            promocode
            data={filterPromocodesLang.map((promocode) => ({
              key: t("proxyPage.content.table.name.promocode"),
              value: promocode
            }))}
          >
            {queryLang === "en" ? (
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M10 0.856445C4.47254 0.856445 0 5.30867 0 10.8101C0 16.312 4.47293 20.7639 10 20.7639C15.5275 20.7639 20 16.3116 20 10.8101C20 5.30828 15.5271 0.856445 10 0.856445ZM10 19.2086C5.33621 19.2086 1.5625 15.452 1.5625 10.8101C1.5625 6.16795 5.33652 2.41171 10 2.41171C14.6638 2.41171 18.4375 6.16826 18.4375 10.8101C18.4375 15.4523 14.6635 19.2086 10 19.2086Z" />
                  <path d="M8.912 16V12.682H11.292C13.308 12.682 14.428 11.296 14.428 9.672C14.428 8.034 13.322 6.662 11.292 6.662H6.924V16H8.912ZM11.026 10.932H8.912V8.412H11.026C11.81 8.412 12.398 8.888 12.398 9.672C12.398 10.442 11.81 10.932 11.026 10.932Z" />
                </g>
              </svg>
            ) : (
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M10 0.856445C4.47254 0.856445 0 5.30867 0 10.8101C0 16.312 4.47293 20.7639 10 20.7639C15.5275 20.7639 20 16.3116 20 10.8101C20 5.30828 15.5271 0.856445 10 0.856445ZM10 19.2086C5.33621 19.2086 1.5625 15.452 1.5625 10.8101C1.5625 6.16795 5.33652 2.41171 10 2.41171C14.6638 2.41171 18.4375 6.16826 18.4375 10.8101C18.4375 15.4523 14.6635 19.2086 10 19.2086Z"></path>
                  <path d="M13 13.8564H11.53V8.38244H8.422V13.8564H6.952V7.09445H13V13.8564Z"></path>
                </g>
              </svg>
            )}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

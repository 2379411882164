import { useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import camelCase from "lodash.camelcase";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Container from "components/common/New/Container/Container";
import { Progress } from "components/common/New/Progress/Progress";
import { Input } from "components/forms/New/Input/Input";
import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";

import { useUserIp } from "hooks/useUserIp";

import { IpBlacklistCheckSchema } from "utils/validation/IpBlacklistCheck.validation";

import { IpCheckItem } from "./IpCheckItem";

import styles from "./IpCheck.module.scss";

const mockData = [
  {
    address: "xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "zen.spamhaus.org",
    result: "Listed"
  },
  {
    address: "pbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl-xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "cbl.abuseat.org",
    result: "Listed"
  },
  {
    address: "xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "zen.spamhaus.org",
    result: "Listed"
  },
  {
    address: "pbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl-xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "cbl.abuseat.org",
    result: "Listed"
  }
];

export const IpCheck = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      IpBlacklistCheckSchema(t("ipBlacklist", { returnObjects: true }))
    )
  });

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      methods.reset();

      setResult(mockData);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  const checkList = useMemo(
    () => [
      {
        title: t("ipBlacklist.checks"),
        image: "img/ui/cheks.svg",
        number: 0
      },
      {
        title: t("ipBlacklist.checkedOut"),
        image: "img/ui/checkedOut.svg",
        number: 0
      },
      {
        title: t("ipBlacklist.blacklisted"),
        image: "img/ui/blacklisted.svg",
        number: 0
      }
    ],
    [t]
  );

  const memoResultList = useMemo(
    () =>
      result?.map((el, index) => (
        <div key={index} className={styles.results__item}>
          {index + 1}. {el.address} -{" "}
          <span className={styles[camelCase(el.result)]}>{el.result}</span>
        </div>
      )),
    [result]
  );

  const memoCheckList = useMemo(
    () => checkList.map((item) => <IpCheckItem key={item.title} {...item} />),
    [checkList]
  );

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.form}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Title tag="h2" className={styles.title}>
                {t("ipBlacklist.ipCheckserver")}
              </Title>
              <div className={styles.input}>
                <Input
                  name="ipAddress"
                  type="text"
                  placeholder={t("ipBlacklist.form.enterIp")}
                  label={t("ipBlacklist.form.label")}
                  showError
                />
                <div
                  className={styles.additional_text}
                  onClick={() => {
                    methods.setValue("ipAddress", userIp?.ipAddress);
                    methods.clearErrors();
                  }}
                >
                  {t("portScanner.form.pasteIP")}
                </div>
              </div>
              <Button
                className={styles.button}
                formSubmit
                loading={isLoading}
                {...(isLoading && { iconLeft: "loading" })}
                fullWidth
              >
                {isLoading
                  ? t("ipBlacklist.form.loading")
                  : t("ipBlacklist.form.examination")}
              </Button>
            </form>
          </FormProvider>
          <div className={styles.info_message}>
            <img src="img/icons/information.svg" alt="Info icon" />
            <span>{t("ipBlacklist.form.infoMessage")}</span>
          </div>
        </div>
        <div className={styles.list__wrapper}>
          <ul className={styles.list}>{memoCheckList}</ul>
          <div className={isLoading ? styles.results__loading : styles.results}>
            {isLoading ? (
              <Progress isLoading={isLoading} />
            ) : (
              <div className={styles.results__content}>
                {result ? memoResultList : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

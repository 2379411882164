import { useTranslation } from "react-i18next";

import styles from "./ResultTable.module.scss";

export const ResultsTable = ({ data }) => {
  const { t } = useTranslation();

  const renderBoolValue = (key) => {
    if (data[key]) {
      return (
        <>
          <img src="img/icons/check-green.svg" className={styles.icon} alt="" />
          <span>{t("ui.yesNo.yes")}</span>
        </>
      );
    } else return (
        <>
          <img src="img/icons/no.svg" className={styles.icon} alt="" />
          <span>{t("ui.yesNo.no")}</span>
        </>
      );
  };

  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/provider.svg"
            alt={t("myAnonymity.table.provider")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.provider")}</span>
        </div>
        <span className={styles.value}>{data.provider || "Triolan"}</span>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/hostname.svg"
            alt={t("myAnonymity.table.hostname")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.hostname")}</span>
        </div>
        <span className={styles.value}>
          {data.hostname || "155.15.6.225.triolan.net"}
        </span>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/os.svg"
            alt={t("myAnonymity.table.os")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.os")}</span>
        </div>
        <span className={styles.value}>{data.platform}</span>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/browser.svg"
            alt={t("myAnonymity.table.browser")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.browser")}</span>
        </div>
        <span className={styles.value}>{data.app}</span>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/dns.svg"
            alt={t("myAnonymity.table.dns")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.dns")}</span>
        </div>
        <span className={styles.value}>{data.ipAddress}</span>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/proxy.svg"
            alt={t("myAnonymity.table.proxy")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.proxy")}</span>
        </div>
        <div className={styles.value}>{renderBoolValue("proxy")}</div>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/anonymizer.svg"
            alt={t("myAnonymity.table.anonymizer")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.anonymizer")}</span>
        </div>
        <div className={styles.value}>{renderBoolValue("anonymizer")}</div>
      </li>
      <li className={styles.item}>
        <div className={styles.key}>
          <img
            src="img/icons/blacklist.svg"
            alt={t("myAnonymity.table.blacklist")}
            className={styles.icon}
            width="24px"
            height="24px"
          />
          <span>{t("myAnonymity.table.blacklist")}</span>
        </div>
        <div className={styles.value}>{renderBoolValue("blacklist")}</div>
      </li>
    </ul>
  );
};

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import BlogInnerReducer from "./reducers/BlogInnerReducer/BlogInner.reducer";
import BlogReducer from "./reducers/BlogReducer/Blog.reducer";
import CabinetReducer from "./reducers/CabinetReducer/Cabinet.reducer";
import ComparisonReducer from "./reducers/ComparisonReducer/Comparison.reducer";
// Reducers
import ContentReducer from "./reducers/ContentReducer/Content.reducer";
import PromocodesReducer from "./reducers/PromocodesReducer/Promocodes.reducer";
import ProxySiteReducer from "./reducers/ProxySiteReducer/ProxySite.reducer";
import SiteReducer from "./reducers/SiteReducer/Site.reducer";
import TranslationReducer from "./reducers/TranslationReducer/Translation.reducer";
import UserReducer from "./reducers/UserReducer/User.reducer";
import FreeProxyReducer from "./reducers/FreeProxyReducer/FreeProxy.reducer";

const rootReducer = combineReducers({
  content: ContentReducer,
  translation: TranslationReducer,
  site: SiteReducer,
  blog: BlogReducer,
  bloginner: BlogInnerReducer,
  proxysite: ProxySiteReducer,
  user: UserReducer,
  cabinet: CabinetReducer,
  comparison: ComparisonReducer,
  promocodes: PromocodesReducer,
  freeproxy: FreeProxyReducer
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ["translation"]
        }
      }),
    preloadedState
  });

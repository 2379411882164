import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import IconsSVG from "../../../ui/New/Icon/IconsSVG";

import styles from "./Checkbox.module.scss";

export const Checkbox = ({
  name,
  rules,
  type,
  label,
  value,
  className,
  defaultValue,
  showError,
  noActionLabel,
  ...rest
}) => {
  // Form
  const { control } = useFormContext();

  // const isNode = typeof label !== "string";
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={`${styles.wrapper} ${className}`}>
          <div className={styles.box}>
            <label className={field.value ? "label-checked" : ""}>
              <input
                type={type}
                {...field}
                {...rest}
                {...(type === "radio"
                  ? {
                      checked: field.value === value,
                      value
                    }
                  : {})}
              />
              {((field.value && type !== "radio") ||
                (field.value === value && type === "radio")) && (
                <IconsSVG name={"checkboxOn"} className={styles.checkbox} />
              )}
              {((!field.value && type !== "redio") ||
                (field.value !== value && type === "radio")) && (
                <IconsSVG name={"checkboxOff"} className={styles.checkbox} />
              )}
              {/* {!isNode && <div className={styles.label}>{label}</div>} */}
              {label && (
                <div
                  className={`${styles.label} ${
                    noActionLabel ? styles.label__no_action : ""
                  }`}
                >
                  {label}
                </div>
              )}
            </label>
            {noActionLabel && (
              <div className={`${styles.label} ${styles.label__no_action}`}>
                {noActionLabel}
              </div>
            )}
            {/* {isNode && <div className={styles.label}>{label}</div>} */}
          </div>
          {showError && error?.message && (
            <div className={styles.error}>
              <IconsSVG name={"inputError"} />
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  );
};

Checkbox.PT = {
  name: PT.string.isRequired,
  rules: PT.object,
  type: PT.string,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  defaultValue: PT.bool
};

Checkbox.defaultProps = {
  type: "checkbox",
  className: ""
  // defaultValue: false
};

import { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import Container from "components/common/New/Container/Container";
import { Export } from "components/common/New/Export/Export";
import InfoMessage from "components/ui/New/InfoMessage/InfoMessage";
import PaginationTable from "components/ui/New/PaginationTable/PaginationTable";
import { Title } from "components/ui/New/Title/Title";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllFreeProxy } from "store/reducers/FreeProxyReducer/FreeProxy.selectors";

import { ApiService } from "services";

import { getCountryId, isEqual } from "utils/helpers";

import { FreeProxyFilter } from "./FreeProxyFilter/FreeProxyFilter";
import { FreeProxyTable } from "./FreeProxyTable/FreeProxyTable";

import styles from "./FreeProxyList.module.scss";

export const FreeProxyList = () => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  // **Redux state
  const { countries } = useSelector(getAllContent);
  const { freeProxy, allFreeProxy } = useSelector(getAllFreeProxy);
  const [searchParams, setSearchParams] = useSearchParams();

  // **Local state
  const [isLoading, setIsLoading] = useState(true);
  const countryId = getCountryId(
    countries.data,
    searchParams.get("country")
  )?.id;

  const [allData, setAllData] = useState(allFreeProxy?.data);
  const [data, setData] = useState({
    totalElements: freeProxy?.data?.totalElements || 0,
    totalPages: freeProxy?.data?.totalPages || 0,
    content: freeProxy?.data?.content || [],
    skip: 0
  });
  const [filterParams, setFilterParams] = useState({
    countryIds: countryId ? [countryId] : [],
    city: searchParams.get("city") || "",
    google:
      searchParams.get("google") === "yes" || !searchParams.get("google")
        ? "true"
        : "false",
    type: searchParams.get("type") || "",
    page: 0,
    pageSize: 10
  });
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [values, setValues] = useState({
    country: "",
    city: "",
    type: ""
  });

  const pageChangeHandler = (page) => {
    setFilterParams({ ...filterParams, page: page - 1 });
  };

  const handleSetRowsPerPage = (value) => {
    setFilterParams({ ...filterParams, page: 0, pageSize: value });
  };

  useEffect(() => {
    setIsLoading(true);
    ApiService.getFreeProxy(filterParams)
      .then((response) => {
        if (response && response?.status !== 200) {
          throw response;
        }
        setData({
          ...response.data,
          skip: filterParams.page * filterParams.pageSize
        });
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    ApiService.getAllFreeProxy({
      countryIds: filterParams.countryIds,
      city: filterParams.city,
      google: filterParams.google,
      type: filterParams.type
    })
      .then((response) => {
        if (response && response.status !== 200) {
          throw response;
        }
        setAllData(response.data);
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterParams.countryIds,
    filterParams.city,
    filterParams.google,
    filterParams.type
  ]);

  useEffect(() => {
    const prevFilterParams = filterParams;
    const currentFilterParams = {
      page: 0,
      pageSize: filterParams.pageSize,
      countryIds: countryId ? [countryId] : countriesFilter,
      city: searchParams.get("city") || "",
      type: searchParams.get("type") || "",
      google:
        searchParams.get("google") === "yes" || !searchParams.get("google")
          ? "true"
          : "false"
    };
    const isEqualFilter = isEqual(prevFilterParams, currentFilterParams);

    if (!isEqualFilter) {
      setFilterParams({
        ...filterParams,
        ...currentFilterParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, countriesFilter, countryId]);

  const defaultValues = {
    country: "",
    city: "",
    type: "",
    google: searchParams.get("google") || "yes"
  };

  const methods = useForm({ defaultValues });

  useEffect(() => {
    const onSubmit = (data) => {
      const { country, city, type, google } = data;
      const newSearchparams = {
        ...(country?.value && { country: country.value }),
        ...(city?.value && { city: city.value }),
        ...(type?.value && { type: type.value }),
        ...(google && { google })
      };
      setSearchParams(newSearchparams);
    };
    const subscription = methods.watch(methods.handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, searchParams, setSearchParams]);

  const exportData = useMemo(
    () =>
      allData
        .map(
          // eslint-disable-next-line no-useless-escape
          (item) => `<div style=\"padding: 5px;\">${item.ip}:${item.port}</div>`
        )
        .join(" "),
    [allData]
  );

  const txtData = useMemo(
    () => allData?.map((item) => `${item.ip}:${item.port}`),
    [allData]
  );

  const renderTable = (className) => (
    <div className={className}>
      <div className={styles.export__wrapper}>
        <div className={styles.items}>
          {t("freeProxy.list.itemsFound")} {data?.totalElements}
        </div>
        <Export
          exportData={exportData}
          id="freeProxyTable"
          tooltip={t("freeProxy.list.tooltip")}
          txtData={txtData}
        />
      </div>
      <FreeProxyTable
        tableData={data.content}
        skip={data.skip}
        isLoading={isLoading}
        countries={countries.data}
        countriesFilter={countriesFilter}
        setCountriesFilter={setCountriesFilter}
        filterParams={filterParams}
      />
      {data.totalElements > 0 && (
        <PaginationTable
          currentPage={filterParams?.page + 1}
          rowsPerPage={filterParams?.pageSize}
          rowCount={data?.totalElements}
          onChangeRowsPerPage={handleSetRowsPerPage}
          onChangePage={pageChangeHandler}
          border={false}
          className={styles.pagination}
          rowsInfoClassName={styles.pagination__rows}
          itemsOnPageClassName={styles.pagination__items}
        />
      )}
    </div>
  );

  return (
    <>
      <Container className={styles.container}>
        <div className={styles.inner}>
          <Title tag="h2" className={styles.title}>
            {t("freeProxy.list.title")}
          </Title>
          <FreeProxyFilter
            defaultValues={defaultValues}
            methods={methods}
            countries={countries.data}
            values={values}
            setValues={setValues}
          />
          {renderTable(styles.table__desktop)}
        </div>
      </Container>
      <div className={styles.table__container}>
        {renderTable(styles.table__mobile)}
      </div>
      <div className={styles.info_message__container}>
        <div className={styles.info_message__inner}>
          <InfoMessage text={t("freeProxy.list.infoMessage")} />
        </div>
      </div>
    </>
  );
};

import { useState } from "react";
import PT from "prop-types";

import NavigationItem from "./NavigationItem";

import styles from "./Navigation.module.scss";

export const Navigation = ({ data, className, mobile }) => {

  const [openItems, setOpenItems] = useState([]);

  const handleOpen = (el) => {
    setOpenItems([...openItems, el]);
  };
  const handleClose = (el) => {
    setOpenItems(openItems?.filter((item) => item !== el));
  };

  return (
    <nav className={`${styles.navigation} ${className}`}>
      {data.map((item, index) => (
        <NavigationItem
          title={item.title}
          list={item.list}
          onClick={
            openItems.includes(item.title)
              ? () => handleClose(item.title)
              : () => handleOpen(item.title)
          }
          key={index}
          isOpened={openItems.includes(item.title)}
          mobile={mobile}
        />
      ))}
    </nav>
  );
};

Navigation.propTypes = {
  data: PT.arrayOf(PT.shape({})).isRequired,
  className: PT.string,
  mobile: PT.bool
};

Navigation.defaultProps = {
  className: ""
};

import { useRef } from "react";

import { Input as BaseInput } from "@mui/base/Input";
import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import IconsSVG from "../../../ui/New/Icon/IconsSVG";

import styles from "./InputCode.module.scss";

export const InputCode = ({
  label,
  name,
  rules,
  defaultValue,
  type,
  onChange,
  readOnly,
  disabled,
  showError,
  className,
  length
}) => {
  // ** Hooks
  const { control } = useFormContext();

  const inputRefs = useRef(new Array(5).fill(null));

  const handleOnChange = (value) => onChange?.(value);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        const focusInput = (targetIndex) => {
          const targetInput = inputRefs.current[targetIndex];
          targetInput.focus();
        };

        const selectInput = (targetIndex) => {
          const targetInput = inputRefs.current[targetIndex];
          targetInput.select();
        };

        const handleKeyDown = (event, currentIndex) => {
          switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
            case " ":
              event.preventDefault();
              break;
            case "ArrowLeft":
              event.preventDefault();
              if (currentIndex > 0) {
                focusInput(currentIndex - 1);
                selectInput(currentIndex - 1);
              }
              break;
            case "ArrowRight":
              event.preventDefault();
              if (currentIndex < length - 1) {
                focusInput(currentIndex + 1);
                selectInput(currentIndex + 1);
              }
              break;
            case "Delete":
              event.preventDefault();
              onChange(
                value.slice(0, currentIndex) + value.slice(currentIndex + 1)
              );
              handleOnChange(
                value.slice(0, currentIndex) + value.slice(currentIndex + 1)
              );

              break;
            case "Backspace":
              event.preventDefault();
              if (currentIndex > 0) {
                focusInput(currentIndex - 1);
                selectInput(currentIndex - 1);
              }

              onChange(
                value.slice(0, currentIndex - 1) + value.slice(currentIndex + 1)
              );
              handleOnChange(
                value.slice(0, currentIndex - 1) + value.slice(currentIndex + 1)
              );
              break;

            default:
              break;
          }
        };

        const handleChange = (event, currentIndex) => {
          const currentValue = event.target.value;
          let indexToEnter = 0;

          while (indexToEnter <= currentIndex) {
            if (
              inputRefs.current[indexToEnter].value &&
              indexToEnter < currentIndex
            ) {
              indexToEnter += 1;
            } else {
              break;
            }
          }
          const otpArray = value.split("");
          const lastValue = currentValue[currentValue.length - 1];
          otpArray[indexToEnter] = lastValue;
          onChange(otpArray.join(""));
          handleOnChange(otpArray.join(""));

          if (currentValue !== "") {
            if (currentIndex < length - 1) {
              focusInput(currentIndex + 1);
            }
          }
        };

        const handleClick = (event, currentIndex) => {
          selectInput(currentIndex);
        };

        const handlePaste = (event, currentIndex) => {
          event.preventDefault();
          const { clipboardData } = event;

          // Check if there is text data in the clipboard
          if (clipboardData.types.includes("text/plain")) {
            let pastedText = clipboardData.getData("text/plain");
            pastedText = pastedText.substring(0, length).trim();
            let indexToEnter = 0;

            while (indexToEnter <= currentIndex) {
              if (
                inputRefs.current[indexToEnter].value &&
                indexToEnter < currentIndex
              ) {
                indexToEnter += 1;
              } else {
                break;
              }
            }

            const otpArray = value.split("");

            for (let i = indexToEnter; i < length; i += 1) {
              const lastValue = pastedText[i - indexToEnter] ?? " ";
              otpArray[i] = lastValue;
            }

            onChange(otpArray.join(""));
            handleOnChange(otpArray.join(""));
          }
        };

        return (
          <div className={`${styles.wrapper} ${className}`}>
            <label>
              {label && <div className={styles.label}>{label}</div>}
              <div className={styles.input_box}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <BaseInput
                    key={index}
                    className={styles.input}
                    id={name}
                    error={!!error}
                    readOnly={readOnly}
                    type={type}
                    disabled={disabled}
                    slotProps={{
                      input: {
                        ref: (ele) => {
                          inputRefs.current[index] = ele;
                        },
                        onKeyDown: (event) => handleKeyDown(event, index),
                        onChange: (event) => handleChange(event, index),
                        onClick: (event) => handleClick(event, index),
                        onPaste: (event) => handlePaste(event, index),
                        value: value[index] ?? ""
                      }
                    }}
                  />
                ))}
              </div>
            </label>
            {showError && !!error?.message && (
              <div className={styles.error}>
                <IconsSVG name={"inputError"} />
                {error.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

InputCode.propTypes = {
  label: PT.string,
  name: PT.string.isRequired,
  rules: PT.object,
  defaultValue: PT.string,
  type: PT.oneOf(["text", "password"]),
  onChange: PT.func,
  readOnly: PT.bool,
  disabled: PT.bool,
  showError: PT.bool,
  className: PT.string,
  length: PT.number
};

InputCode.defaultProps = {
  label: "",
  rules: {},
  defaultValue: "",
  placeholder: "",
  type: "text",
  readOnly: false,
  disabled: false,
  showError: false,
  className: "",
  length: 5
};

export default InputCode;

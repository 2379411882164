import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";
import Rive from "@rive-app/react-canvas";
import { animationTestPort } from "assets/animations";

import {
  // portScannerCTAImage,
  // portScannerCTAImage2x,
  portScannerCTAImageMobile,
  portScannerCTAImageMobile2x
} from "assets/img";

import styles from "./CTA.module.scss";

export const CTA = () => {
  const { t } = useTranslation();

  return (
    <Container className={styles.container}>
      <div className={styles.animation__wrapper}>
        <Rive
          src={animationTestPort}
          autoPlay
          className={styles.animation}
          stateMachines="State Machine 1"
        />
      </div>
      <picture>
        {/* <source
          srcSet={`${portScannerCTAImage} 1x, ${portScannerCTAImage2x} 2x`}
          media="(min-width: 993px)"
        /> */}
        <source
          srcSet={`${portScannerCTAImageMobile} 1x, ${portScannerCTAImageMobile2x} 2x`}
          // media="(max-width: 992px)"
        />
        <img src={portScannerCTAImageMobile} alt={t("portScanner.cta.title")} className={styles.image} />
      </picture>
      <div className={styles.content}>
        <Title tag="h2" className={styles.title}>
          {t("portScanner.cta.title")}
        </Title>
        <div className={styles.description}>
          {t("portScanner.cta.description")}
        </div>
        <Button>{t("portScanner.cta.buttonLabel")}</Button>
      </div>
    </Container>
  );
};

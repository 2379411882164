import { ExportPage } from "pages/New/ExportPage/ExportPage.jsx";

export const ExportRoutes = [
  {
    path: "/:lang?/export/",
    tag: "export",
    type: "export",
    component: () => import("../../pages/New/ExportPage/ExportPage.jsx"),
    element: ExportPage,
    isAuthRequired: false
  }
];

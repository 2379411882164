/* eslint-disable no-nested-ternary */
import { useId } from "react";

import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import { SkeletonToolTable } from "components/ui/New/Skeleton/SkeletonToolTable/SkeletonToolTable";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import styles from "../WebRTCLeakTest.module.scss";

export const ResultsTable = ({ isLoading, data, status }) => {
  const { t } = useTranslation();
  const customTooltipId = useId();

  const columns = [
    {
      name: (
        <p className={styles.table_title}>{t("webRTCLeakTest.table.ip")}</p>
      ),
      cell: (row) => <span className={styles.table_text}>{row.ipAddress}</span>,
      width: "33%"
    },
    {
      name: (
        <p className={styles.table_title}>{t("webRTCLeakTest.table.type")}</p>
      ),
      cell: (row) => (
        <span className={styles.table_text}>{row.type || "Public IPv4"}</span>
      ),
      selector: (row) => row.type,
      width: "33%"
    },
    {
      name: (
        <p className={styles.table_title}>{t("webRTCLeakTest.table.status")}</p>
      ),
      cell: () => (
        <div className={styles.status}>
          <p className={status ? styles.status__error : styles.status__success}>
            {status
              ? t("webRTCLeakTest.table.leak")
              : t("webRTCLeakTest.table.noLeak")}
          </p>
          <Tooltip
            body={
              status
                ? t("webRTCLeakTest.table.leakTooltip")
                : t("webRTCLeakTest.table.noLeakTooltip")
            }
            className={styles.tooltip}
            id={customTooltipId}
            place="top"
          >
            <img
              src="img/icons/information.svg"
              alt=""
              className={styles.icon}
            />
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.status,
      width: "33%"
    }
  ];

  return (
    <DataTable
      className={"DnsLeakTest WebRTCLeakTest"}
      columns={columns}
      data={data}
      noDataComponent={
        !isLoading && (
          <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
            {t("proxyPage.content.table.noDataComponent")}
          </div>
        )
      }
      progressPending={isLoading}
      progressComponent={<SkeletonToolTable cells={8} />}
    />
  );
};

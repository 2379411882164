import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "components/ui/New/Icon/Icon";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import { useLangUrlDefault } from "hooks";

import styles from "./Export.module.scss";

export const Export = ({ tooltip, txtData, exportData }) => {
  const { t } = useTranslation();

  const [, hrefLang] = useLangUrlDefault();

  const exportToExcel = () => {
    const link = document.createElement("a");

    link.setAttribute(
      "href",
      `data:text/json;charset=utf-8,${encodeURIComponent(
        txtData.map((item) => item).join("\n")
      )}`
    );

    link.setAttribute("download", "Data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToTxt = () => {
    // eslint-disable-next-line no-undef
    const blob = new Blob([txtData?.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "Data.txt";
    link.href = url;
    link.click();
  };

  const exportToUrl = () => {
    localStorage.setItem("exportData", JSON.stringify(exportData));
    const newWin = window.open(`${hrefLang}/export/`, "_blank");
    if (newWin === null) {
      setTimeout(() => {
        window.open(`${hrefLang}/export/`, "_blank");
      });
    }
  };

  return (
    <div className={styles.export__wrapper}>
      <span className={styles.export}>{t("ui.export")}</span>
      <Tooltip body={tooltip} place="top-start">
        <Icon
          name="infoOutline"
          className={styles.info}
          width="24px"
          height="24px"
        />
      </Tooltip>
      <ul className={styles.list}>
        <li className={styles.list_item} onClick={exportToTxt}>
          txt
        </li>
        <li className={styles.list_item} onClick={exportToExcel}>
          excel
        </li>
        <li className={styles.list_item} onClick={exportToUrl}>
          url
        </li>
      </ul>
    </div>
  );
};

Export.propTypes = {
  txtData: PT.arrayOf(PT.string).isRequired
};

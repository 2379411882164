import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { Loader } from "../../../../ui/Loader/Loader";

import { SellerItem } from "./SellerItem/SellerItem";

export const Sellers = ({ closeSidebar }) => {
  const { t } = useTranslation();

  // **Redux state
  const { sellers } = useSelector(getAllContent);

  return (
    <div className="proxy-sidebar__item">
      {!sellers.isLoading ? (
        <>
          <div className="proxy-sidebar__title">
            {t("proxyPage.sidebar.right.sellers.title")}
          </div>
          <ul className="proxy-sidebar__sellers">
            {sellers.data.map((seller) => (
              <SellerItem
                key={seller.id}
                seller={seller}
                closeSidebar={closeSidebar}
              />
            ))}
          </ul>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

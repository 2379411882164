import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import Button from "components/ui/New/Button/Button";
import Logo from "components/ui/New/Logo/Logo";

import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

import {
  useDispatchedActions,
  useLangUrlDefault,
  useLockBackdrop
} from "hooks";

import profileIcon from "assets/img/dashboard/nav/profile.svg";
import promocodesIcon from "assets/img/dashboard/nav/promocodes.svg";
import reviewsIcon from "assets/img/dashboard/nav/reviews.svg";
import servicesIcon from "assets/img/dashboard/nav/services.svg";

import { LangSelect } from "./Lang/LangSelect";

import styles from "./Menu.module.scss";

const CHANGE_TYPE = {
  default: "addSite",
  addSite: "default",
  logout: "default"
};

export const DashboardMenu = ({ type, setType }) => {
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const prefix = `${hrefLang}/new/dashboard`;
  const { user } = useSelector(getAllUser);
  // **Dispatch
  const { setLogout, setMobileMenu, resetCabinet } = useDispatchedActions();
  // const [scrollY, setScrollY] = useState(0);
  const [classes, setClasses] = useState(`${styles.container}`);

  const handleOpenAddSite = () => {
    if (window.innerWidth < 992) {
      setType(CHANGE_TYPE[type]);
    }
  };

  const logout = async () => {
    setType("default");
    try {
      const response = await ApiService.logout();

      if (response && response.status !== 200) {
        throw response;
      }

      navigate(`${hrefLang}/new/`, { replace: true });
      setMobileMenu(false);
      setLogout();
      resetCabinet();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response || err?.response || err);
    }
  };

  const render = useMemo(
    () => (
      <>
        <div
          className={`${styles.menu_backdrop} ${
            type !== "default" ? styles.menu_backdrop_open : ""
          }`}
          onClick={() => setType("default")}
        />
        <div className={classes}>
          <Logo className={styles.logo} />
          {type === "default" && (
            <>
              <div className={`${styles.menu} scrollbar__dark`}>
                <NavLink to={`${prefix}/profile/`} className={styles.link}>
                  <img src={profileIcon} alt={t("dashboard.navBar.profile")} />
                  <span className={styles.link_text}>
                    {t("dashboard.navBar.profile")}
                  </span>
                </NavLink>
                <NavLink to={`${prefix}/services/`} className={styles.link}>
                  <img
                    src={servicesIcon}
                    alt={t("dashboard.navBar.services")}
                  />
                  <span className={styles.link_text}>
                    {" "}
                    {t("dashboard.navBar.services")}
                  </span>
                </NavLink>
                <div className={styles.menu_center} />
                <NavLink to={`${prefix}/reviews/`} className={styles.link}>
                  <img src={reviewsIcon} alt={t("dashboard.navBar.reviews")} />
                  <span className={styles.link_text}>
                    {" "}
                    {t("dashboard.navBar.reviews")}
                  </span>
                </NavLink>
                <NavLink to={`${prefix}/promocodes/`} className={styles.link}>
                  <img
                    src={promocodesIcon}
                    alt={t("dashboard.navBar.promocodes")}
                  />
                  <span className={styles.link_text}>
                    {t("dashboard.navBar.promocodes")}
                  </span>
                </NavLink>
              </div>
            </>
          )}
          {type === "logout" && (
            <div className={styles.submenu}>
              <p className={styles.submenu_title}>
                {t("dashboard.navBar.hello")}
              </p>
              <p className={styles.submenu_email}>{user.email}</p>
              <LangSelect />
              <Button
                className={styles.button_logout}
                fullWidth
                iconRight={"logout"}
                onClick={logout}
              >
                <span className={styles.button_text}>
                  {t("dashboard.navBar.logout")}
                </span>
              </Button>
            </div>
          )}

          <Button
            className={`${styles.button_create} ${
              type === "addSite" ? styles.button_visible : ""
            }`}
            variant={"primary"}
            size={"md"}
            iconLeft={"plus2"}
            linkTo={`${prefix}/services/create/`}
            onClick={handleOpenAddSite}
          >
            <span className={styles.button_text}>
              {t("dashboard.navBar.addSite")}
            </span>
          </Button>

          {type !== "logout" ? (
            <Button
              className={styles.button_mobile}
              variant={"primary"}
              size={"md"}
              iconLeft={type === "addSite" ? "x" : "plus2"}
              onClick={handleOpenAddSite}
            />
          ) : (
            <Button
              className={`${styles.button_mobile} ${styles.button_mobile_logout}`}
              variant={"primary"}
              size={"md"}
              onClick={handleOpenAddSite}
            >
              {user?.email.charAt(0).toUpperCase()}
            </Button>
          )}
        </div>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, classes, prefix, user]
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992 && type !== "default") {
        setType("default");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [type, setType]);

  // useEffect(() => {
  //   const handleOnScroll = () => {
  //     if (window.innerWidth > 992 || type !== "default") {
  //       if (!classes.includes(styles.container_visible)) {
  //         setClasses(`${styles.container} ${styles.container_visible}`);
  //       }
  //       return;
  //     }
  //     const posY = window.scrollY;
  //     if (
  //       posY > 0 &&
  //       posY > scrollY &&
  //       classes.includes(styles.container_hidden)
  //     ) {
  //       setClasses(`${styles.container} ${styles.container_visible}`);
  //     }
  //     if (posY < scrollY && classes.includes(styles.container_visible)) {
  //       setClasses(`${styles.container} ${styles.container_hidden}`);
  //     }
  //     setScrollY(posY);
  //   };

  //   window.addEventListener("scroll", handleOnScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleOnScroll);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [type, scrollY]);

  useEffect(() => {
    if (type !== "default" && !classes.includes(styles.container_visible)) {
      setClasses(`${styles.container} ${styles.container_visible}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useLockBackdrop(type !== "default");
  return render;
};

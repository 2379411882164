import { useMemo } from "react";

import PT from "prop-types";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import { Export } from "components/common/New/Export/Export";
import { SkeletonToolTable } from "components/ui/New/Skeleton/SkeletonToolTable/SkeletonToolTable";
import { Title } from "components/ui/New/Title/Title";

import styles from "./PortChecked.module.scss";

export const PortChecked = ({ data, isLoading }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: "№",
      selector: (_, index) => {
        if (index < 9) {
          return <span className={styles.text}>{`0${index + 1}.`}</span>;
        } else return <span className={styles.text}>{`${index + 1}.`}</span>;
      },
      maxWidth: "64px",
      minWidth: "64px",
      center: true
    },
    {
      name: t("portScanner.portChecked.port").toUpperCase(),
      cell: (row) => (
        <div>
          <span className={styles.text}>{row.port}</span>
        </div>
      ),
      width: "265px"
    },
    {
      name: t("portScanner.portChecked.protocol").toUpperCase(),
      cell: (row) => (
        <div>
          <span className={styles.text}>{row.protocol}</span>
        </div>
      ),
      selector: (row) => row.protocol,
      width: "180px"
    },
    {
      name: t("portScanner.portChecked.description").toUpperCase(),
      cell: (row) => <span className={styles.text}>{row.description}</span>,
      selector: (row) => row?.description,
      minWidth: "600px"
    }
  ];

  const exportData = useMemo(
    () => data.map((item) => `<div>${item.port}: ${item.protocol}</div>`).join(" "),
    [data]
  );

  const txtData = useMemo(
    () => data.map((item) => `${item.port}:${item.protocol}`),
    [data]
  );

  return (
    <div className={styles.container}>
      <div className={styles.title__wrapper}>
        <Title tag="h2" className={styles.title}>
          {t("portScanner.portChecked.title")}
        </Title>
        <Export
          tooltip="Choose in which format you want to export the check results"
          id="portChecked"
          txtData={txtData}
          exportData={exportData}
        />
      </div>
      <DataTable
        className={"ToolTable PortChecked"}
        columns={columns}
        data={data}
        noDataComponent={
          !isLoading && (
            <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
              {t("proxyPage.content.table.noDataComponent")}
            </div>
          )
        }
        progressPending={isLoading}
        progressComponent={<SkeletonToolTable cells={10} />}
      />
    </div>
  );
};

PortChecked.propTypes = {
  data: PT.arrayOf(PT.shape({})).isRequired,
  isLoading: PT.bool
};

PortChecked.defaultProps = {
  isLoading: false
};

import PT from "prop-types";
import Card from "components/ui/New/Card/Card";

import styles from "./PortScannerSlider.module.scss";

export const PortScannerSliderItem = ({ title, image, description }) => (
  <Card className={styles.card} size={"small"}>
    <div>
      {image ? (
        <img
          className={styles.image}
          src={image}
          loading="lazy"
          alt={title}
          aria-hidden="true"
        />
      ) : null}
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
  </Card>
);

PortScannerSliderItem.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};

PortScannerSliderItem.defaultProps = {
  title: "",
  description: "",
  image: null
};

import PromocodesCreatePage from "pages/CabinetPages/pages/Promocodes/PromocodesCreatePage/PromocodesCreatePage";
import PromocodesEditPage from "pages/CabinetPages/pages/Promocodes/PromocodesEditPage/PromocodesEditPage";
import PromocodesListPage from "pages/CabinetPages/pages/Promocodes/PromocodesListPage/PromocodesListPage";
import ProxyCreatePage from "pages/CabinetPages/pages/Proxy/ProxyCreatePage/ProxyCreatePage";
import ProxyEditPage from "pages/CabinetPages/pages/Proxy/ProxyEditPage/ProxyEditPage";
import ProxyListPage from "pages/CabinetPages/pages/Proxy/ProxyListPage/ProxyListPage";

export const CabinetRoutesOLD = [
  {
    path: "/:lang?/cabinet/proxy/",
    tag: "cabinet-proxy",
    type: "cabinet",
    component: () =>
      import("pages/CabinetPages/pages/Proxy/ProxyListPage/ProxyListPage"),
    element: ProxyListPage, // TODO: find solution for chanck loading
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/proxy/create/",
    tag: "cabinet-proxy-create",
    type: "cabinet",
    component: () =>
      import("pages/CabinetPages/pages/Proxy/ProxyCreatePage/ProxyCreatePage"),
    element: ProxyCreatePage, // TODO: find solution for chanck loading
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/proxy/edit/:id",
    tag: "cabinet-proxy-edit",
    type: "cabinet",
    component: () =>
      import("pages/CabinetPages/pages/Proxy/ProxyEditPage/ProxyEditPage"),
    element: ProxyEditPage, // TODO: find solution for chanck loading
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/",
    tag: "cabinet-promocodes",
    type: "cabinet",
    component: () =>
      import(
        "pages/CabinetPages/pages/Promocodes/PromocodesListPage/PromocodesListPage"
      ),
    element: PromocodesListPage, // TODO: find solution for chanck loading
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/create",
    tag: "cabinet-promocodes-create",
    type: "cabinet",
    component: () =>
      import(
        "pages/CabinetPages/pages/Promocodes/PromocodesCreatePage/PromocodesCreatePage"
      ),
    element: PromocodesCreatePage, // TODO: find solution for chanck loading
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/edit/:id",
    tag: "cabinet-promocodes-edit",
    type: "cabinet",
    component: () =>
      import(
        "pages/CabinetPages/pages/Promocodes/PromocodesEditPage/PromocodesEditPage"
      ),
    element: PromocodesEditPage, // TODO: find solution for chanck loading
    isHeaderFull: true,
    isAuthRequired: true
  }
];

/* eslint-disable no-nested-ternary */
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import { SkeletonResultsTable } from "components/ui/New/Skeleton/SkeletonResultTable/SkeletonResultsTable";

import styles from "../HttpHeaderCheck.module.scss";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("httpHeadersChecker.table.header").toUpperCase(),
      cell: (row) => <span className={styles.table_text}>{row.header}</span>,
      width: "235px"
    },
    {
      name: t("httpHeadersChecker.table.value").toUpperCase(),
      cell: (row) => <span className={styles.table_text}>{row.value}</span>,
      selector: (row) => row.value,
      width: "446px"
    }
  ];

  return (
    <DataTable
      className={"ToolTableAbsolute HttpHeaderCheck"}
      columns={columns}
      data={data}
      noDataComponent={
        !isLoading && (
          <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
            {t("proxyPage.content.table.noDataComponent")}
          </div>
        )
      }
      progressPending={isLoading}
      progressComponent={<SkeletonResultsTable cells={5} />}
    />
  );
};

import { useEffect, useRef, useState } from "react";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import styles from "../../../BlockType.module.scss";

const RendetItem = ({ list, index, total, onClerClick }) => {
  const ref = useRef();
  const [end, setEnd] = useState(0);
  const [endParent, setEndParent] = useState(0);

  const handlerRemove = (e) => {
    const item = list[index];
    onClerClick?.(item);
    e.stopPropagation();
  };

  useEffect(() => {
    if (ref?.current) {
      setEnd(ref.current.getBoundingClientRect().right);
      setEndParent(ref.current.parentElement.getBoundingClientRect().right);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.offsetWidth]);

  if (index >= total) {
    return null;
  }
  if (end !== 0 && endParent !== 0 && end > endParent - 80) {
    return (
      <>
        <span className={styles.info_multiple_dots}>{"..."}</span>
        <div className={styles.info_multiple_end}>{total - index}</div>
      </>
    );
  }

  return (
    <>
      <button
        ref={ref}
        className={styles.info_multiple_item}
        onClick={handlerRemove}
        type="button"
      >
        {list[index]?.label}
        <IconsSVG name="closeModal" />
      </button>
      <RendetItem
        list={list}
        index={index + 1}
        total={total}
        onClerClick={onClerClick}
      />
    </>
  );
};

export const RenderValue = ({ selected, onClerClick }) => (
  <div className={styles.info_multiple}>
    {selected?.length > 0 && (
      <RendetItem
        list={selected}
        index={0}
        total={selected.length}
        onClerClick={onClerClick}
      />
    )}
  </div>
);

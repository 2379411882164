import PT from "prop-types";
import camelCase from "lodash.camelcase";

import Icon from "components/ui/New/Icon/Icon";

import styles from "./IpDetails.module.scss";

const IpDetailsItem = ({
  id,
  title,
  image,
  isLoading,
  isOpened,
  handleOpen,
  listData
}) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <div className={styles.value__item}>
          {value.map((item, i) => (
            <div key={i} className={styles.value}>
              {item}
            </div>
          ))}
        </div>
      );
    } else return <div className={styles.value}>{value}</div>;
  };

  return (
    <div
      className={styles.item}
      id={camelCase(id)}
      onClick={() => handleOpen(id)}
    >
      <div className={styles.header}>
        <div className={styles.header__wrapper}>
          <img src={image} alt={title} className={styles.image} />
          <div className={styles.item__title}>{title}</div>
        </div>
        {isLoading ? (
          <Icon name="loading" loading={isLoading} className={styles.icon} />
        ) : (
          <Icon
            name="arrowDown"
            className={isOpened ? styles.icon__open : styles.icon}
          />
        )}
      </div>
      <div className={isOpened ? styles.list__open : styles.list}>
        {listData.map((el, index) => (
          <div key={index} className={styles.list__item}>
            {el.keyTitle && (
              <div className={styles.key__item}>
                <div className={styles.key__title}>{el.keyTitle}</div>
                <div className={styles.key__value}>
                  {el.keyValue?.map((value) => value)}
                </div>
              </div>
            )}
            {el.key && <div className={styles.key}>{el.key}</div>}
            {el.value && renderValue(el.value)}
          </div>
        ))}
      </div>
    </div>
  );
};

IpDetailsItem.propTypes = {
  id: PT.string.isRequired,
  title: PT.string,
  image: PT.node,
  isLoading: PT.bool,
  isOpened: PT.bool,
  handleOpen: PT.func,
  listData: PT.arrayOf(PT.shape({}))
};

IpDetailsItem.defaultProps = {
  title: "",
  image: null,
  isLoading: false,
  isOpened: false,
  handleOpen: () => null,
  listData: []
};

export default IpDetailsItem;

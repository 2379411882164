import { useTranslation } from "react-i18next";

import Pagination from "components/ui/New/Pagination/Pagination";
import TextButton from "components/ui/New/TextButton/TextButton";

import { Reviews } from "./Reviews/Reviews";

import styles from "./RenderReviews.module.scss";

export const RenderReviews = ({
  reviews,
  totalElements,
  pageSize,
  currentPage,
  changeHandler,
  onChangePage,
  handlerShowMore,
  paginationDisabled,
  captchaRef,
  setTokenCaptcha,
  isDashboard
}) => {
  const { t } = useTranslation();

  const onShowMore = () => handlerShowMore?.();

  return (
    <>
      <Reviews
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        reviews={reviews}
        changeHandler={changeHandler}
        isDashboard={isDashboard}
      />
      {!isDashboard && totalElements > pageSize * currentPage && (
        <TextButton
          className={styles.show_more}
          onClick={onShowMore}
          iconRight={"refresh"}
        >
          {t("reviews.showMore")}
        </TextButton>
      )}
      {totalElements !== 0 && totalElements > pageSize && (
        <Pagination
          className={styles.pagination}
          rowCount={totalElements}
          rowsPerPage={pageSize}
          page={currentPage}
          pageRangeDisplayed={paginationDisabled}
          marginPagesDisplayed={paginationDisabled}
          onChangePage={onChangePage}
        />
      )}
    </>
  );
};

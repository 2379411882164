import { forwardRef, useRef, useState } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Option } from "@mui/base/Option";
import { Select as BaseSelect } from "@mui/base/Select";
import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Icon from "components/ui/New/Icon/Icon";

import FormError from "../FormError/FormError";

import styles from "./Select.module.scss";

const Button = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { ownerState, children, ...other } = props;
  return (
    <button {...other} ref={ref}>
      {children}
      <Icon name="arrowDown" className="indicator" size="xlarge" />
    </button>
  );
});

export const FormSelect = ({
  name,
  options,
  defaultValue,
  rules,
  onChange,
  disabled,
  className,
  wrapperClassName,
  menuClassName,
  placeholder,
  multiple,
  renderValue,
  label,
  color,
  labelSize,
  noData,
  showError
}) => {
  // ** Hooks
  const { control } = useFormContext();
  const { t } = useTranslation();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    if (isOpen) {
      const size = ref.current.getBoundingClientRect();
      if (size.width !== width) {
        setWidth(size.width);
      }
    }
    setIsMenuOpen(isOpen);
  };

  const currentValueProps = (value) => {
    if (!value) return multiple ? [] : value;
    const type = typeof value;
    if (!multiple) {
      return type === "object" ? value?.[0] : value;
    }
    return type === "object" ? value : [value];
  };

  const defineWrapperClassName = () => {
    const classes = [styles.wrapper];

    if (styles[color]) {
      classes.push(styles[color]);
    }

    if (wrapperClassName) {
      classes.push(wrapperClassName);
    }

    return classes.join(" ");
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={defineWrapperClassName()}>
          {label && (
            <label
              className={`${styles.label} ${styles[`label__${labelSize}`]}`}
            >
              {label}
            </label>
          )}
          <ClickAwayListener onClickAway={() => handleMenuOpen(false)}>
            <BaseSelect
              ref={ref}
              id={name}
              name={name}
              value={currentValueProps(field.value)}
              onChange={(event, value) => {
                if (!event) return;
                field.onChange(value);
                onChange(value);
              }}
              className={`${styles.select} ${className}  ${
                error?.message ? styles.select__error : ""
              }`}
              slots={{ root: Button }}
              slotProps={{
                popup: { className: styles.popup },
                listbox: {
                  className: `${styles.listbox} ${
                    styles[`listbox__${color}`]
                  } {${menuClassName}`,
                  style: {
                    minWidth: `${width}px`
                  }
                }
              }}
              renderValue={renderValue}
              onListboxOpenChange={handleMenuOpen}
              listboxOpen={isMenuOpen}
              disabled={disabled}
              placeholder={
                <div className={styles.placeholder}>{placeholder}</div>
              }
              multiple={multiple}
            >
              {options?.length > 0 ? (
                options.map((option) => (
                  <Option
                    className={`${styles.option} ${styles[`option__${color}`]}`}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </Option>
                ))
              ) : (
                <div className={styles.noData}>
                  {noData ? noData : t("noData.select.default")}
                </div>
              )}
            </BaseSelect>
          </ClickAwayListener>
          {showError && !!error?.message && (
            <FormError message={error.message} />
          )}
        </div>
      )}
    />
  );
};

FormSelect.propTypes = {
  name: PT.string.isRequired,
  options: PT.array.isRequired,
  defaultValue: PT.oneOfType([PT.string, PT.array]),
  rules: PT.object,
  onChange: PT.func,
  disabled: PT.bool,
  className: PT.string,
  wrapperClassName: PT.string,
  menuClassName: PT.string,
  placeholder: PT.string,
  multiple: PT.bool,
  renderValue: PT.func,
  label: PT.string,
  color: PT.string,
  labelSize: PT.string,
  showError: PT.bool,
  noData: PT.node
};

FormSelect.defaultProps = {
  defaultValue: "",
  rules: {},
  onChange: () => null,
  disabled: false,
  className: "",
  wrapperClassName: "",
  menuClassName: "",
  placeholder: "",
  multiple: false,
  renderValue: null,
  label: "",
  color: "light",
  labelSize: "small",
  showError: false,
  noData: null
};

import { createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getProxySiteInfo = createAsyncThunk(
  "proxysite/getProxySiteInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteInfo(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setSiteViewStatistic = createAsyncThunk(
  "proxysite/setSiteViewStatistic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.setSiteViewStatistic(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return id;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteReviewsAmount = createAsyncThunk(
  "proxysite/getProxySiteReviewsAmount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteReviewsAmount(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteRating = createAsyncThunk(
  "proxysite/getProxySiteRating",
  async ({ siteId, step }, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteRating(siteId, step);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteReviews = createAsyncThunk(
  "proxysite/getProxySiteReviews",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteReviews(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteCompaints = createAsyncThunk(
  "proxysite/getProxySiteCompaints",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteCompaints(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllPromocodesBySite = createAsyncThunk(
  "proxysite/getAllPromocodesBySite",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllPromocodesBySite(siteId);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySites = createAsyncThunk(
  "proxysite/getProxySites",
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getProxySites(params, signal);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        ...response.data,
        skip: params.page * params.size,
        params
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

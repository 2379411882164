import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { Title } from "components/ui/New/Title/Title";

import { useLangUrlDefault } from "hooks";

import styles from "./Related.module.scss";

export const RelatedItem = ({ data }) => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  return (
    <li className={styles.related__item}>
      <div className={styles.image__wrapper}>
      <Link to={`${hrefLang}/new/blog/${data.alias}/`}>
        <picture>
          <source
            srcSet={data.coverImage || "/img/ui/blog-placeholder.svg"}
          />
          <img
            src={data.coverImage}
            loading="lazy"
            alt={data.title}
            className={styles.image}
          />
        </picture>
      </Link>
      </div>
      <div className={styles.item_content}>
        <div>
          <p className={styles.category}>
            {data.category
              ? t("blogPage.item.categories.article")
              : t("blogPage.item.categories.news")}
          </p>
          <Title tag="h3" className={styles.item_title}>
            <Link to={`${hrefLang}/new/blog/${data.alias}/`}>{data.title}</Link>
          </Title>
          {data.description && <div className={styles.description}>{data.description}</div>}
          </div>
          <span className={styles.date}>
            <Moment format="MMMM DD, YYYY">
              {data.creationDate && new Date(data.creationDate)}
            </Moment>
          </span>
      </div>
    </li>
  );
};

RelatedItem.propTypes = {
  data: PT.shape({})
};

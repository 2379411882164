import { useId } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useLangUrlDefault } from "../../../../../../hooks";
import { Button } from "../../../../../ui/Button/Button";

export const SellerItem = ({ seller, closeSidebar }) => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();
  const customToastId = useId();

  const onCopyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  const routeHandler = () => {
    closeSidebar(false);
  };

  return (
    <li className="proxy-sidebar__seller">
      <div
        className={
          seller.image
            ? "proxy-sidebar__seller-image"
            : "proxy-sidebar__seller-image proxy-sidebar__seller-image--empty"
        }
      >
        <Link to={`${hrefLang}/proxy/${seller.alias}/`} onClick={routeHandler}>
          <img
            src={seller.image ? seller.image : "/img/ui/placeholder.svg"}
            width={115}
            height={65}
            loading="lazy"
            alt={seller.name}
          />
        </Link>
      </div>
      <div className="proxy-sidebar__seller-content">
        <div className="proxy-sidebar__seller-title">
          <Link
            to={`${hrefLang}/proxy/${seller.alias}/`}
            onClick={routeHandler}
          >
            {seller.name}
          </Link>
        </div>
        {seller.promoCode && (
          <div className="proxy-sidebar__seller-promocode">
            <div className="proxy-sidebar__seller-promocode-title">
              {t("proxyPage.sidebar.right.sellers.promocode")}
            </div>
            <CopyToClipboard text={seller.promoCode} onCopy={onCopyHandler}>
              <div className="proxy-sidebar__seller-promocode-body">
                {seller.promoCode}
              </div>
            </CopyToClipboard>
          </div>
        )}
        <Button
          size="small"
          type="solid"
          link
          href={`${hrefLang}/proxy/${seller.alias}/`}
          onClick={routeHandler}
        >
          {t("proxyPage.sidebar.right.sellers.goBtn")}
        </Button>
      </div>
    </li>
  );
};

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "hooks";

import { RenderText } from "./RenderText/RenderText";

// import { Translator } from "./Translator/Translator";
import styles from "./InfoPart.module.scss";

export const InfoPart = (props) => {
  // **Props
  const [queryLang] = useLangUrlDefault();
  const { review, toggleReview } = props;
  const { locale = null, translation = {} } = review;

  // **Redux state
  const { t } = useTranslation();

  const isTranslations = Object.keys(translation).length > 0;
  // const isShowTranslator = locale && isTranslations && queryLang !== locale;

  // **Local state
  const [isShowOriginal] = useState(false);

  const getReviewData = (data, isAttribute, nameField) => {
    if (isAttribute) {
      return data?.["attributes"]?.[nameField];
    } else {
      return data?.[nameField];
    }
  };

  const getTranslateReviewData = (isAttribute, nameField) => {
    if (
      locale !== queryLang &&
      isTranslations &&
      translation?.[queryLang] &&
      !isShowOriginal
    ) {
      return getReviewData(translation[queryLang], isAttribute, nameField);
    } else {
      return getReviewData(review, isAttribute, nameField);
    }
  };

  const isReviewData = (isAttribute, nameField) =>
    Boolean(getTranslateReviewData(isAttribute, nameField));

  // const showOriginalHandle = () => {
  //   setIsShowOriginal(!isShowOriginal);
  // };

  // console.log("review", ref.current?.clientHeight, ref.current?.scrollHeight);

  return (
    <div className={styles.review}>
      {/* {isShowTranslator && (
        <Translator
          isShowOriginal={isShowOriginal}
          showOriginalHandle={showOriginalHandle}
        />
      )} */}
      {isReviewData(false, "reviewBody") && (
        // <div className={styles.review_text} ref={ref}>
        <RenderText toggleReview={toggleReview}>
          {getTranslateReviewData(false, "reviewBody")}
        </RenderText>
        // </div>
      )}
      {isReviewData(false, "commentBody") && (
        <div className={styles.review_details_item}>
          <p className={styles.review_details_name}>
            {t("reviews.content.review.title")}:
          </p>
          {/* <div className={styles.review_details_text}>
           {getTranslateReviewData(false, "commentBody")}
          </div> */}
          <RenderText sub={true} toggleReview={toggleReview}>
            {getTranslateReviewData(false, "commentBody")}
          </RenderText>
        </div>
      )}
      <ul className={styles.review_details}>
        {isReviewData(true, "advantages") && (
          <li className={styles.review_details_item}>
            <p className={styles.review_details_name}>
              {t("reviews.content.advantages.title")}:
            </p>
            {/* <div className={styles.review_details_text}>
              {getTranslateReviewData(true, "advantages")}
            </div> */}
            <RenderText sub={true} toggleReview={toggleReview}>
              {getTranslateReviewData(true, "advantages")}
            </RenderText>
          </li>
        )}
        {isReviewData(true, "disadvantages") && (
          <li className={styles.review_details_item}>
            <p className={styles.review_details_name}>
              {t("reviews.content.disadvantages.title")}:
            </p>
            {/* <div className={styles.review_details_text}>
              {getTranslateReviewData(true, "disadvantages")}
            </div> */}
            <RenderText sub={true} toggleReview={toggleReview}>
              {getTranslateReviewData(true, "disadvantages")}
            </RenderText>
          </li>
        )}

        {/* {isReviewData(true, "usage") && (
          <li className={styles.review_details_item}>
            <div className={styles.review_details_name}>
              {t("reviews.content.usage.title")}:
            </div>
            <div className={styles.review_details_text}>
              {getTranslateReviewData(true, "usage")}
            </div>
          </li>
        )} */}
        {/* {isReviewData(true, "wishes") && (
          <li className={styles.review_details_item}>
            <div className={styles.review_details_name}>
              {t("reviews.content.wishes.title")}:
            </div>
            <div className={styles.review_details_text}>
              {getTranslateReviewData(true, "wishes")}
            </div>
          </li>
        )} */}
        {/* {isReviewData(false, "reason") && (
          <li className={styles.review_details_item}>
            <div className={styles.review_details_name}>
              {t("reviews.content.reason.title")}:
            </div>
            <div className={styles.review_details_text}>
              {getTranslateReviewData(false, "reason")}
            </div>
          </li>
        )}
        {isReviewData(false, "complaintBody") && (
          <li className={styles.review_details_item}>
            <div className={styles.review_details_name}>
              {t("reviews.content.review.title")}:
            </div>
            <div className={styles.review_details_text}>
              {getTranslateReviewData(false, "complaintBody")}
            </div>
          </li>
        )} */}
      </ul>
    </div>
  );
};

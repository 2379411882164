import styled from "@emotion/styled";

export const ErrorCounter = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors["red-500"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114%;

  margin-top: 5px;
  & svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex: none;
    color: ${(props) => props.theme.colors["red-500"]};
  }
`;

import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
// import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Checkbox } from "../../../../components/forms/New/Checkbox/Checkbox";
import Input from "../../../../components/forms/New/Input/Input";
import { Button } from "../../../../components/ui/New/Button/Button";
import TextButton from "../../../../components/ui/New/TextButton/TextButton";
import { Title } from "../../../../components/ui/New/Title/Title";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
// import { AllActions } from "../../../../store/reducers/AllActions";
// import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { LoginSchema } from "../../../../utils/validation";
// import { Authorization } from "../../components/Authorization/Authorization";
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate";

import { ModalEmailCofigmed } from "./ModalEmailCofigmed/ModalEmailCofigmed";

// eslint-disable-next-line import/order
import styles from "../AuthPages.module.scss";

const LoginPage = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // **Redux state
  const { t } = useTranslation();
  // const { pageContent } = useSelector(getAllContent);

  // **Local state
  // const metaData = useMetaData();

  // **Local state
  const [adminUser, setAdminUser] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInProcess, setIsInProcess] = useState(true);

  // Dispatch
  const { setUser, setAuthentication, setIsRedirectToCabinet } =
    useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(LoginSchema(t("forms", { returnObjects: true })))
  });

  const getAdminUser = async () => {
    try {
      const response = await ApiService.getAdminUser();

      if (response && response.status !== 200) {
        throw response;
      }

      if (!response?.data) {
        setAdminUser(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const createDefaultUser = async (data) => {
    try {
      const response = await ApiService.createDefaultUser(
        data.email,
        data.password
      );

      if (response && response.status !== 200) {
        throw response;
      }

      window.location.reload();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const modalHandler = () => {
    searchParams.delete("token");
    setIsModalOpened(false);

    if (!isConfirmed) {
      navigate(`${hrefLang}/registration/`);
    }
  };

  const checkToken = async () => {
    try {
      const response = await ApiService.confirmEmail(searchParams.get("token"));

      if (response && response.status !== 200) {
        throw response;
      }

      if (response.data) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsInProcess(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const res = await ApiService.login(
        data.email,
        data.password,
        data.remember
      );
      if (res && res.status !== 200) {
        if (res.response.status === 401) {
          const error = {
            type: "manual",
            message: t("notifications.authError.401")
          };
          methods.setError("email", error);
          methods.setError("password", error);
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 561) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 560) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 543) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        throw res;
      }

      const userInfo = await ApiService.getUserAuth();

      if (userInfo && userInfo.status !== 200) {
        throw res;
      }

      setUser(userInfo?.data);
      setAuthentication(true);

      setIsRedirectToCabinet(true);

      navigate(`${hrefLang}/new/dashboard/profile/`, { replace: true });
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      setIsModalOpened(true);

      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AuthNavigate to="registration" />
      <Title className={styles.title}>{t("authPages.signIn.title")}</Title>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(
            adminUser ? onSubmit : createDefaultUser
          )}
          noValidate
        >
          <Input
            name="email"
            type={"email"}
            placeholder={t("forms.email.label")}
            label={t("forms.email.label")}
            showError
          />
          <Input
            name="password"
            type={"password"}
            placeholder={t("forms.password.placeholder")}
            label={t("forms.password.placeholder")}
            showError
          />
          <div className={styles.remember}>
            <Checkbox name="remember" label={t("authPages.signIn.remember")} />
            <TextButton
              className={styles.recovery}
              color={"secondary"}
              size={"md"}
              linkTo={`${hrefLang}/new/recovery/`}
            >
              {t("authPages.signIn.forgot")}
            </TextButton>
          </div>

          <Button
            className={styles.submit}
            formSubmit
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          >
            {t("authPages.signIn.btn")}
          </Button>
        </form>
      </FormProvider>
      <ModalEmailCofigmed
        open={isModalOpened}
        onClose={modalHandler}
        isConfirmed={isConfirmed}
        isInProcess={isInProcess}
      />
    </>
  );
};

export default LoginPage;
//
// LoginPage.getServerSideState = async (store, params, api) => {
//   const { data: pageContent } = await axios.post(
//     `${api}/api/page/front/content`,
//     params
//   );
//
//   store.dispatch(AllActions.setActivePageParams(params));
//   store.dispatch(AllActions.setPageContent(pageContent));
// };

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { CardInfo } from "pages/New/Dashboard/components/CardInfo/CardInfo";

import { FormList } from "components/forms/New/FormList/FormList";
import LabelCountry from "components/ui/New/LabelContry/LabelCountry";
import Select from "components/ui/New/Select/Select";

import { useLangUrlDefault } from "hooks";

import styles from "../BlockType.module.scss";

export const Advantages = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const [lang, setLang] = useState(queryLang);

  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];

  return (
    <CardInfo>
      <div className={styles.advantages_head}>
        <div className={styles.advantages_title}>
          {t("dashboard.services.addEdit.blocks.type.advantages.title")}
        </div>
        <Select
          className={styles.advantages_select}
          name={"lang"}
          defaultValue={queryLang}
          renderValue={(selected) =>
            options.find((option) => option.value === selected?.value)?.label
          }
          onChange={setLang}
          options={options}
        />
      </div>

      {options.map((option) => (
        <FormList
          key={option.value}
          className={`${option.value === lang ? "" : styles.hidden}`}
          name={`advantages.${option.value}`}
          placeholder={t(
            "dashboard.services.addEdit.blocks.type.advantages.placeholder"
          )}
          addButtonText={t(
            "dashboard.services.addEdit.blocks.type.advantages.add"
          )}
        />
      ))}
    </CardInfo>
  );
};

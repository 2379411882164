import { Suspense, useEffect } from "react";

import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import { Loader } from "../../components/ui/Loader/Loader";
import {
  useLangUrlDefault, // useMatchedRoute,
  useMetaData,
  useRedirect
} from "../../hooks";
import { MetaPage } from "../../pages/MetaPage/MetaPage";

import styles from "./toast.module.scss";

export const IndexLayout = () => {
  useRedirect();
  useMetaData();

  // **Props
  // const route = useMatchedRoute();

  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { content, blog, translation } = useSelector((state) => state);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth"
  //   });
  // }, [route?.path]);

  // Handling error
  useEffect(() => {
    if (
      blog.content.isError ||
      content.pageContent.isError ||
      content.countries.isError ||
      content.goalsByGroups.isError ||
      content.sellers.isError ||
      content.reviews.isError ||
      content.goals.isError ||
      content.topSites.isError
    ) {
      toast.error(translation.translations[queryLang].notifications.apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blog.content.isError,
    content.pageContent.isError,
    content.countries.isError,
    content.goalsByGroups.isError,
    content.sellers.isError,
    content.reviews.isError,
    content.goals.isError,
    content.topSites.isError
  ]);

  useEffect(() => {
    const html = document.querySelector("html");
    html.setAttribute("new-layout", "true");
    return () => {
      html.removeAttribute("new-layout");
    };
  }, []);
  return (
    <>
      <MetaPage />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <ToastContainer
        closeOnClick={false}
        hideProgressBar
        autoClose={2000}
        toastClassName={styles.toast}
        bodyClassName={styles.body}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable
        closeButton={false}
        icon={(data) => <IconsSVG name={data.type} />}
        limit={3}
      />
    </>
  );
};

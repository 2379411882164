import styled from "@emotion/styled";

export const StyledRenderTextWrapper = styled.div`
  margin-bottom: ${(props) => (props.sub ? "0" : " 16px")};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const StyledRenderText = styled.p`
  width: 100%;
  text-align: left;
  color: ${(props) =>
    props.sub ? props.theme.colors["neutral-500"] : "inherit"};
  ${(props) =>
    props.full
      ? ""
      : `max-height: 200px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;`}
`;

// import { useSelector } from "react-redux";
import { Suspense } from "react";

// import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { useMatchedRoute } from "../../../hooks";
import { Loader } from "../../ui/Loader/Loader";

import { DetermineIp } from "./DetermineIp/DetermineIp";
import { HeaderBottom } from "./HeaderBottom/HeaderBottom";
import { HeaderTop } from "./HeaderTop/HeaderTop";

import "./Header.scss";

export const Header = () => {
  // **Redux state
  // const { routeContent } = useSelector(getAllContent);
  const route = useMatchedRoute();

  return (
    <>
      {route?.tag === "home" && <DetermineIp />}
      <header className="header">
        <Suspense fallback={<Loader />}>
          <HeaderTop />
        </Suspense>
        {route?.isHeaderFull && (
          <div className="mdMin">
            <HeaderBottom />
          </div>
        )}
      </header>
    </>
  );
};

import "moment/locale/ru";
import "moment/locale/uk";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import Icon from "components/ui/New/Icon/Icon";

import { useLangUrlDefault } from "hooks";

import styles from "../Article.module.scss";

export const Info = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  return (
    <ul className={styles.info}>
      <li className={styles.wrapper}>
        <Icon name="calendar" className={styles.icon} />
        <Moment
          format="MMMM DD, YYYY"
          locale={queryLang}
          suppressHydrationWarning
        >
          {new Date(data.creationDate)}
        </Moment>
      </li>
      <li className={styles.wrapper}>
        <Icon name="eye" className={styles.icon} />
        {`${data?.viewCount} ${t("blogInnerPage.articleBody.viewsTitle")}`}
      </li>
    </ul>
  );
};

Info.propTypes = {
  data: PT.shape({
    viewCount: PT.number
  })
};

import AuthButton from "../../ui/AuthButton";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";

import { Countries } from "./Countries/Countries";
// import { Goals } from "./Goals/Goals";
import { Navigation } from "./Navigation/Navigation";

import "./MobileNav.scss";

export const MobileNav = () => (
  <BurgerMenu>
    <AuthButton type="transparent" />
    <Navigation />
    <Countries />
    {/*<Goals />*/}
  </BurgerMenu>
);

import PT from "prop-types";

import styles from "./Subtitle.module.scss";

export const Subtitle = ({ className, size, color, children }) => {
  const classes = [styles.subtitle];
  if (size) classes.push(styles[size]);
  if (color) classes.push(styles[color]);
  if (className) classes.push(className);

  return <p className={classes.join(" ")}>{children}</p>;
};

Subtitle.propTypes = {
  children: PT.node,
  className: PT.string,
  size: PT.oneOf(["lg"]),
  color: PT.oneOf(["white"])
};

Subtitle.defaultProps = {
  children: null,
  className: "",
  size: null,
  color: null
};

export default Subtitle;

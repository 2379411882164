import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";
import { navigation } from "../../../../utils/constants";

export const Navigation = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  return (
    <nav className="footer__nav">
      <ul className="footer__nav-list">
        {navigation.map(({ route, translation }) => (
          <li key={route}>
            <Link to={`${hrefLang}/${route}/`}>
              <span>{t(`navigation.${translation}`)}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

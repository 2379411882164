import {
  StyledExpandebleComponentContainer,
  StyledExpandebleComponentContent
} from "./ExpandebleComponent.styled";
import { Benefits } from "./components/Benefits/Benefits";
import { Characteristics } from "./components/Characteristics/Characteristics";
import { Locations } from "./components/Locations/Locations";
import { PromocodesTable } from "./components/PromocodesTable/PromocodesTable";
import { ProxyTypes } from "./components/ProxyTypes/ProxyTypes";

export const ExpandebleComponent = ({ data }) => (
  <StyledExpandebleComponentContainer>
    <StyledExpandebleComponentContent>
      <ProxyTypes data={data} />
      <Benefits data={data} />
      <Locations data={data} />
      <Characteristics data={data} />
    </StyledExpandebleComponentContent>
    <PromocodesTable data={data} />
  </StyledExpandebleComponentContainer>
);

import { Promocode } from "components/common/New/Promocodes/Promocode";

import {
  StyledOfferItem,
  StyledOfferItemBox,
  StyledOfferItemSiteImage,
  StyledOfferItemTitle
} from "./OfferItem.styled";

export const OfferItem = ({ title, image, discription, promocode }) => (
  <StyledOfferItem>
    <StyledOfferItemTitle>
      {image ? (
        <StyledOfferItemSiteImage
          src={image || "/img/ui/placeholder.svg"}
          loading="lazy"
          alt=""
          aria-hidden="true"
        />
      ) : null}
      {title}
    </StyledOfferItemTitle>
    <StyledOfferItemBox>
      <Promocode
        variant={"dark"}
        description={discription}
        promocode={promocode}
        title={title}
        time={new Date().toISOString()}
        eternal={false}
        image={null}
      />
    </StyledOfferItemBox>
  </StyledOfferItem>
);

import PT from "prop-types";

import styles from "./Container.module.scss";

export const Container = ({ className, children }) => (
  <div className={`${styles.container} ${className}`}>{children}</div>
);

Container.propTypes = {
  className: PT.string,
  children: PT.node
};

Container.defaultProps = {
  className: "",
  children: null
};

export default Container;

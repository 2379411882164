import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import TextButton from "components/ui/New/TextButton/TextButton";

import { ReviewItem } from "./ReviewItem/ReviewItem";

import styles from "./Reviews.module.scss";

const PREV_SHOW_COUNT = 3;

export const Reviews = ({
  captchaRef,
  setTokenCaptcha,
  reviews = [],
  changeHandler,
  complaint = false,
  nestedResponseValue = 1,
  toggleReview,
  isDashboard
}) => {
  const { t } = useTranslation();
  const reviewsLength = reviews?.length;
  const [reviewsToShow, setReviewsToShow] = useState([]);

  const showAllHandler = () => {
    setReviewsToShow(reviews);
    toggleReview?.(true);
  };

  useEffect(() => {
    setReviewsToShow(
      nestedResponseValue !== 1
        ? reviews.slice(
            0,
            reviewsLength > PREV_SHOW_COUNT ? PREV_SHOW_COUNT : reviewsLength
          )
        : reviews
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);
  return (
    <>
      {reviewsToShow?.map((review) => (
        <ReviewItem
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
          key={review.id}
          review={review}
          changeHandler={changeHandler}
          complaint={complaint}
          isRootReview={nestedResponseValue === 1}
          nestedResponseValue={nestedResponseValue}
          isDashboard={isDashboard}
        />
      ))}
      {nestedResponseValue !== 1 &&
        reviewsToShow?.length < reviewsLength &&
        reviewsLength > PREV_SHOW_COUNT && (
          <TextButton
            color={"secondary"}
            className={styles.show_all}
            onClick={showAllHandler}
            iconRight={"refresh"}
          >
            {t("reviews.showAll")}
          </TextButton>
        )}
    </>
  );
};

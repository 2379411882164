import { useState } from "react";

import { useSelector } from "react-redux";

import Search from "components/ui/New/Search/Search";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { servicesList } from "utils/constants";

import { Countries } from "../Countries/Countries";
import { Locations } from "../Locations/Locations";
import NavigationItem from "../Navigation/NavigationItem";

import styles from "./ProxiesNavigation.module.scss";

export const ProxiesNavigation = () => {
  const [search, setSearch] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries } = useSelector(getAllContent);

  return (
    <div className={styles.proxies_navigation}>
      <NavigationItem
        title="proxies"
        list={servicesList}
        className={styles.proxies_navigation_item}
      />
      <NavigationItem
        title="locations"
        className={styles.proxies_navigation_item}
      >
        <Search value={search} onChange={setSearch} variant="outlined-dark" />
        <Locations
          selectedLocation={selectedLocation}
          onClick={setSelectedLocation}
          className={styles.locations__list}
        />
      </NavigationItem>
      <NavigationItem
        title={selectedLocation}
        className={styles.proxies_navigation_item}
      >
         <Countries countries={countries} search={search} selectedLocation={selectedLocation} />
      </NavigationItem>
    </div>
  );
};

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { RenderReviews } from "components/common/New/RenderReviews/RenderReviews";
import Card from "components/ui/New/Card/Card";
// import Pagination from "components/ui/New/Pagination/Pagination";
import TextButton from "components/ui/New/TextButton/TextButton";

import { NoData } from "../../components/NoData/NoData";
import { SortBlock } from "../../components/SortBlock/SortBlock";
import { reviewsMockData } from "../mock.reviews.data";

// import { Reviews } from "./Reviews/Reviews";
import styles from "./ListReview.module.scss";

export const ListReview = () => {
  const { t } = useTranslation();
  // **Props
  const options = t("proxySitePage.content.reviews.top.sortList", {
    returnObjects: true
  }).map((sortType) => ({
    label: sortType.label,
    value: sortType.param
  }));

  // **Local state
  const [paginationDisabled, setPaginationDisabled] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [reviews, setReviews] = useState({
    data: { content: [] },
    reviewPage: 0,
    isError: false
  });
  const [pageParams, setPageParams] = useState({
    sort: "creationDate",
    page: reviews.reviewPage,
    size: 1
  });

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      page
    });
  };

  // const incrementPageSizes = () => {
  //   setPageParams({ ...pageParams, size: pageParams.size + 4 });
  // };

  const fetchProxyRevies = () => null;

  // useEffect(() => {
  //   if (reviews.reviewPage?.toString()) {
  //     setPageParams({
  //       ...pageParams,
  //       page: reviews.reviewPage + 1
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reviews.reviewPage]);

  // Handling error
  useEffect(() => {
    if (reviews.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.isError]);

  useEffect(() => {
    const width = document.body.clientWidth;
    if (width > 992) {
      setPageParams({ ...pageParams, size: 4 });
      setPaginationDisabled(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextButton onClick={() => setReviews(reviewsMockData)}>
        Show mock data
      </TextButton>
      {reviews.data?.content?.length !== 0 && (
        <Card className={styles.card} size={"small"} radius={"small"}>
          <SortBlock
            rowCount={reviews.data?.totalElements}
            params={pageParams}
            label={t("proxySitePage.content.reviews.top.title")}
            options={options}
            changeSortType={changeSortType}
          />
          <RenderReviews
            reviews={reviews.data?.content}
            totalElements={reviews.data?.totalElements}
            pageSize={pageParams.size}
            currentPage={pageParams.page}
            changeHandler={fetchProxyRevies}
            onChangePage={pageChangeHandler}
            paginationDisabled={paginationDisabled}
            isDashboard
          />
        </Card>
      )}
      {reviews.data?.content?.length === 0 && (
        <NoData
          title={t("dashboard.reviews.noData.title")}
          subtitle={t("dashboard.reviews.noData.subtitle")}
          srcX1={"/img/dashboard/no-reviews-x1.png"}
          srcX2={"/img/dashboard/no-reviews-x2.png"}
          className={styles.noData}
        />
      )}
    </>
  );
};

import { useFieldArray, useFormContext } from "react-hook-form";

import "./InputField.scss";

export const InputField = ({
  name,
  type,
  margin,
  rules,
  label,
  layout,
  showError,
  errorPath,
  ...rest
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const defineClassname = () => {
    let className = "form-input-field__item";

    switch (layout) {
      case "vertical": {
        className += " form-input-field__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-input-field__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-input-field__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (errors[name]) {
      className += " form-input-field__item--error";
    }
    return className;
  };

  return fields.map(
    (item, index, arr) =>
      index < 3 && (
        <div key={item.id} className={defineClassname()}>
          <label>
            {label && <span className="form-input-field__label">{label}</span>}
            <div className="form-input-field__input-row">
              <div className="form-input-field__input">
                <input
                  type={type}
                  {...register(`${name}.${index}`, rules)}
                  {...rest}
                />
              </div>
              {index === 0 && (
                <button
                  type="button"
                  aria-label="Add new field"
                  onClick={() => {
                    append(" ");
                  }}
                  className="form-input-field__add"
                  disabled={arr.length > 2}
                ></button>
              )}
              {index !== 0 && (
                <button
                  type="button"
                  aria-label="Add new field"
                  onClick={() => {
                    remove(index);
                  }}
                  className="form-input-field__remove"
                ></button>
              )}
            </div>
          </label>
          {showError &&
            errors[errorPath[0]]?.[errorPath[1]]?.[index]?.message && (
              <div className="form-input-field__error">
                {errors[errorPath[0]]?.[errorPath[1]]?.[index]?.message}
              </div>
            )}
        </div>
      )
  );
};

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { Loader } from "../../../../ui/Loader/Loader";

import { ReviewItem } from "./ReviewItem/ReviewItem";

export const Reviews = ({ closeSidebar }) => {
  const { t } = useTranslation();

  // **Redux state
  const { reviews } = useSelector(getAllContent);

  return (
    <div className="proxy-sidebar__item">
      {!reviews.isLoading ? (
        <>
          <div className="proxy-sidebar__title">
            {t("proxyPage.sidebar.right.reviews.title")}
          </div>
          <ul className="proxy-sidebar__reviews">
            {reviews.data.map((review) => (
              <ReviewItem
                key={review.siteId + review.date.toString()}
                review={review}
                closeSidebar={closeSidebar}
              />
            ))}
          </ul>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import styles from "./RadioGroup.module.scss";

const RadioGroup = ({ data, name, label, defaultValue, width, className }) => {
  const { control } = useFormContext();

  const renderInputGroup = (field) => (
    <div className={styles.input__wrapper}>
      {data.map((item) => (
        <label
          key={item.label}
          className={
            field.value === item.value ? styles.label__checked : styles.label
          }
        >
          <input
            className={styles.input}
            {...field}
            onChange={() => {
              field.onChange(item.value);
            }}
            type="radio"
            checked={field.value === item.value}
            value={item.value}
          />
          {item.label}
        </label>
      ))}
    </div>
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div className={`${styles.wrapper}  ${className}`} style={{ width }}>
          <div className={styles.group_label}>{label}</div>
          {renderInputGroup(field)}
        </div>
      )}
    />
  );
};

RadioGroup.propTypes = {
  data: PT.array.isRequired,
  label: PT.string,
  name: PT.string
};

export default RadioGroup;

import styled from "@emotion/styled";

const slyles = (p) => `
 width: 100%;
    color: ${
      p.color === "white" ? p.theme.colors.white : p.theme.colors["neutral-800"]
    };
    font-family: ${p.theme.fontFamily.title};
    font-size: ${p.theme.fontSizes.xll};
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
    word-wrap: break-word;

    @media (min-width: ${p.theme.screenSizes.lgMin}) {
        font-size: ${p.theme.fontSizes.xxxl};
        line-height: 108%;
    }
`;

export const StyletTitle = {
  h1: styled.h1`
    ${(p) => slyles(p)}
  `,
  h2: styled.h2`
    ${(p) => slyles(p)}
  `,
  h3: styled.h3`
    ${(p) => slyles(p)}
  `,
  h4: styled.h4`
    ${(p) => slyles(p)}
  `,
  h5: styled.h5`
    ${(p) => slyles(p)}
  `,
  h6: styled.h6`
    ${(p) => slyles(p)}
  `,
  div: styled.div`
    ${(p) => slyles(p)}
  `
};

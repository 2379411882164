import { useTranslation } from "react-i18next";

import YesNo from "components/common/New/YesNo/YesNo";

// import { useLangUrlDefault } from "hooks";
// import { time } from "utils/helpers";
import styles from "./ShortInfo.module.scss";

export const ShortInfo = ({ data }) => {
  const { t } = useTranslation();
  // const [queryLang] = useLangUrlDefault();

  return (
    <ul className={styles.list}>
      <li className={styles.list_item}>
        <span>{t("proxySitePage.info.informations.freeTest")}</span>
        <YesNo
          dot={false}
          value={data?.freeTest}
          className={styles.list_value}
        />
      </li>
      <li className={styles.list_item}>
        <span>{t("proxySitePage.info.informations.refund")}</span>
        {/* <span className={styles.list_value}> */}
        <YesNo dot={false} value={data?.refund} className={styles.list_value} />
        {/* {time(
            "day",
            data?.refund,
            queryLang,
            t("times", { returnObjects: true })
          )} */}
        {/* </span> */}
      </li>
      <li className={styles.list_item}>
        <span>{t("proxySitePage.info.informations.minPrice")}</span>
        <span className={styles.list_value}>${data?.minPrice}</span>
      </li>
    </ul>
  );
};

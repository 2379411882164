import { convertCountryCodeAlpha3ToAlpha2 } from "./convertCountryCodeAlpha3ToAlpha2.helper";

export const getSelectCountryOptions = (countries, queryLang, styles) => countries.data?.map((country) => {
    const alpha2 = convertCountryCodeAlpha3ToAlpha2(
      country?.code
    ).toLowerCase();
    const name = queryLang ? country?.localization[queryLang] : "";
    return {
      value: country?.code,
      label: (
        <>
          {alpha2 !== "default" ? (
            <span className={`fl fl-${alpha2} ${styles.flag}`}></span>
          ) : (
            <img
              className={styles.flag}
              src="/img/flags/default.svg"
              width={22}
              height={16}
              loading="lazy"
              alt={name}
            />
          )}
          <span>{name}</span>
        </>
      )
    };
  });

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "components/ui/New/Icon/Icon";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";

import { useLangUrlDefault } from "hooks";

import styles from "../Article.module.scss";

export const ArticleNavigation = () => {

  const { t } = useTranslation();
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <ul className={styles.navigation}>
      {content.data.previousArticleAlias && (
        <li className={styles.navigation__item}>
          <Link
            to={`${hrefLang}/new/blog/${content.data.previousArticleAlias}`}
          >
            <div className={styles.icon__wrapper}>
              <div className={styles.icon}>
              <Icon
                name="arrowDown"
                className={styles.icon_prev}
              />
              </div>
              <span className={styles.prev}>{t("blogInnerPage.articleBody.prev")}</span>
            </div>
            <span className={`${styles.title} ${styles.title_prev}`}>{content.data.previousArticleTitle}</span>
          </Link>
        </li>
      )}
      {content.data.nextArticleAlias && (
        <li className={`${styles.navigation__item} ${styles.navigation__item_next}`}>
          <Link to={`${hrefLang}/new/blog/${content.data.nextArticleAlias}`}>
          <div className={styles.icon__wrapper}>
              <span className={styles.next}>{t("blogInnerPage.articleBody.next")}</span>
              <div className={styles.icon}>
              <Icon
                name="arrowDown"
                className={styles.icon_next}
              />
              </div>
            </div>
            <span className={`${styles.title} ${styles.title_next}`}>{content.data.nextArticleTitle}</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

import { useTranslation } from "react-i18next";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Title } from "components/ui/New/Title/Title";

import Container from "components/common/New/Container/Container";
import { PortScannerSliderItem } from "./PortScannerSliderItem";

import styles from "./PortScannerSlider.module.scss";

export const PortScannerSlider = () => {
  const { t } = useTranslation();

  const mockData = [
    {
      title: "Identify the OS on the device",
      image: "img/ui/os.svg",
      description:
        "Scanners are often used by hackers to detect the operating system of the user and to hack its security in own purposes."
    },
    {
      title: "Monitor the working services",
      image: "img/ui/eye.svg",
      description:
        "It’s possible to see which services are working at the moment in a system through the host port and it’s available to collect the user’s data"
    },
    {
      title: "Check the system vulnerabilities",
      image: "img/ui/shield.svg",
      description:
        "Windows scanner port allows you to check the security level and terminate any possible attacks"
    },
    {
        title: "Detect open ports",
        image: "img/ui/search.svg",
        description:
          "The software detects active communication points through which the device receives and transmits data"
      }
  ];

  return (
    <div className={styles.section}>
      <Container>
        <Title tag="h2" className={styles.heading}>
          {t("portScanner.sliderTitle")}
        </Title>
        </Container>
        <Swiper
          className="port-scanner-slider"
          direction="horizontal"
          modules={[Navigation]}
          navigation={true}
          wrapperTag="ul"
          breakpoints={{
            360: {
              slidesPerView: 1.1,
              spaceBetween: 12
            },
            480: {
              slidesPerView: 1.5,
              spaceBetween: 12
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 24
            },
            993: {
                slidesPerView: 2.5,
                spaceBetween: 24
              },
            1300: {
              slidesPerView: 3,
              spaceBetween: 24
            },
            1500: {
                slidesPerView: 3.5,
                spaceBetween: 24
              },
            1700: {
                slidesPerView: 4,
                spaceBetween: 24
            }
          }}
        >
          {mockData.map((item) => (
            <SwiperSlide key={item.title} tag="li">
              <PortScannerSliderItem {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
    </div>
  );
};

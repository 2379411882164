import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { ApiService } from "../../../../../services";
import { getAllComparison } from "../../../../../store/reducers/ComparisonReducer/Comparison.selectors";

export const Comparison = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { uuid, sites } = useSelector(getAllComparison);

  // **Dispatch
  const { updateComparsionSites, clearComparisonState } =
    useDispatchedActions();

  const deleteSite = (id) => () => {
    updateComparsionSites({
      uuid,
      siteIds: sites
        .filter((site) => site.siteId !== id)
        .map((site) => site.siteId)
    });
  };

  const clearComparison = async () => {
    try {
      if (!uuid) {
        return;
      }

      const response = await ApiService.deleteComparisonSites(uuid);

      if (response && response.status !== 200) {
        throw response;
      }

      clearComparisonState();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    }
  };

  return (
    <div className="proxy-sidebar__item">
      <div className="proxy-sidebar__title">
        {t("proxyPage.sidebar.right.comparison.title")}
      </div>
      <div className="proxy-sidebar__comparison">
        <ul className="proxy-sidebar__comparison-list">
          {sites.map((site) => (
            <li key={site?.siteId}>
              <span>{site?.siteName}</span>
              <button
                onClick={deleteSite(site?.siteId)}
                aria-label={t("proxyPage.sidebar.right.comparison.clearAction")}
              ></button>
            </li>
          ))}
        </ul>
        <ul className="proxy-sidebar__comparison-actions">
          <li className="proxy-sidebar__comparison-action">
            <Link to={`${hrefLang}/proxy/comparison/${uuid}/`}>
              {t("proxyPage.sidebar.right.comparison.compareAction")}
            </Link>
          </li>
          <li className="proxy-sidebar__comparison-action">
            <button onClick={clearComparison}>
              {t("proxyPage.sidebar.right.comparison.clearAction")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

import styled from "@emotion/styled";

export const StyledResultTable = styled.div`
  padding-bottom: 16px;
  width: 100%;
`;

export const TableBody = styled.div`
  max-height: 382px;
  overflow-y: auto;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: #f6f7f9;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 64px;
  }
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 8px 0px 8px 24px;
  align-items: center;
  color: var(--neutral-400);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;
  border-bottom: 1px solid rgba(216, 218, 224, 0.55);

  @media (max-width: ${(p) => p.theme.screenSizes.lgMin}) {
    border-bottom: none !important;

    &:nth-of-type(even) {
      background-color: rgba(246, 247, 249, 0.6);
    }
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 64px;
  }
`;

export const TableCol = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 8px 0px 8px 24px;
  align-items: center;
  color: var(--neutral-800);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const TableText = styled.span`
  color: var(--neutral-800);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const FeedbackSchema = (trns) =>
  yup.object().shape({
    name: yup.string().required(trns.name.rules.required),
    email: yup
      .string()
      .trim()
      .required(trns.email.rules.required)
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        trns.email.rules.email
      ),
    message: yup.string().required(trns.message.rules.required)
  });

import axios from "axios";
import { useTranslation } from "react-i18next";

import { AllActions } from "store/reducers/AllActions";

import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { ListReview } from "./ListReview/ListReview";

const Reviews = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleBlock
        title={t("dashboard.reviews.title")}
        linkTo={"/new/dashboard/reviews/"}
        linkName={t("dashboard.reviews.add")}
      />
      <ListReview />
    </>
  );
};

export default Reviews;

Reviews.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

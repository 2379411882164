import { createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getFreeProxyContent = createAsyncThunk(
  "freeproxy/getFreeProxyContent",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getFreeProxy(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { ...response.data, params };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllFreeProxyContent = createAsyncThunk(
  "freeproxy/getAllFreeProxyContent",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllFreeProxy(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { ...response.data, params };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

import { useState } from "react";

import { useTranslation } from "react-i18next";

import FormBlock from "pages/New/Dashboard/components/FormBlock/FormBlock";

import { Input } from "components/forms/New";
import LabelCountry from "components/ui/New/LabelContry/LabelCountry";
import Select from "components/ui/New/Select/Select";
import TextButton from "components/ui/New/TextButton/TextButton";

import { useLangUrlDefault } from "hooks";

import styles from "./BlockPromocode.module.scss";

export const BlockPromocode = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const [lang, setLang] = useState(queryLang);
  const [open, setOpen] = useState(false);

  const handlerOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];
  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.promocode.title")}
      subtitle={t("dashboard.services.addEdit.blocks.promocode.subtitle")}
      classNameBody={`${styles.body} ${open ? styles.body_open : ""}`}
    >
      <Input
        name="promocode"
        label={t("forms.promocode.label")}
        placeholder={t("forms.promocode.placeholder")}
      />
      <Select
        className={styles.select}
        name={"lang"}
        defaultValue={queryLang}
        renderValue={(selected) =>
          options.find((option) => option.value === selected?.value)?.label
        }
        onChange={setLang}
        options={options}
      />
      {options.map((option) => (
        <div
          key={option.value}
          className={`${option.value === lang ? "" : styles.hidden}`}
        >
          <Input
            name={`promocodeDescription.${option.value}`}
            label={t("forms.promocodeDescription.label")}
            placeholder={t("forms.promocodeDescription.placeholder")}
            type={"textarea"}
            showMaxLenght={100}
            showError
            showParentError
            parentErrorPath={"promocodeDescription"}
          />
        </div>
      ))}
      <TextButton
        color={"secondary"}
        className={`${styles.btn} ${open ? styles.btn_open : ""}`}
        iconRight={"arrowDown"}
        onClick={handlerOpen}
      >
        {t("dashboard.services.addEdit.blocks.promocode.show")}
      </TextButton>
      <picture
        className={`${styles.picture} ${open ? styles.picture_open : ""}`}
      >
        <source
          srcSet={
            "/img/dashboard/promo-example-x1.png 1x, /img/dashboard/promo-example-x2.png 2x"
          }
          type="image/png"
        />
        <img src={"/img/dashboard/promo-example-x1.png"} alt={"no data"} />
      </picture>
    </FormBlock>
  );
};

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import IconsSVG from "../Icon/IconsSVG";

const COLORS_BG = {
  primary: {
    default: "main-500",
    hover: "main-gradient",
    active: "main-600"
  },
  secondary: {
    default: "neutral-25",
    hover: "neutral-25",
    active: "secondary-active"
  },
  tertiary: {
    default: "transparent",
    hover: "neutral-25",
    active: "neutral-25"
  },
  outlined: {
    default: "transparent",
    hover: "transparent",
    active: "secondary-active"
  },
  danger: {
    default: "red-500",
    hover: "red-gradient",
    active: "red-600"
  }
};

const SIZES = {
  sm: {
    height: "32px",
    svg: "16px",
    padding: "4px 24px",
    iconPadding: "8px"
  },
  md: {
    height: "40px",
    svg: "20px",
    padding: "8px 24px",
    iconPadding: "10px"
  },
  nm: {
    height: "48px",
    svg: "20px",
    padding: "12px 32px",
    iconPadding: "12px"
  },
  lg: {
    height: "52px",
    svg: "24px",
    padding: "14px 32px",
    iconPadding: "14px"
  }
};

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => (p.size === "sm" ? "6px" : "8px;")};
  font-family: ${(p) => p.theme.fontFamily.lato};
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: ${(p) =>
    p.size === "sm" ? p.theme.fontSizes.s : p.theme.fontSizes.m};
  line-height: ${(p) => (p.size === "sm" ? 170 : 150)}%;
  padding: ${(p) =>
    p.type === "icon" ? SIZES[p.size].iconPadding : SIZES[p.size].padding};
  ${(p) => (p.fullWidth ? "width: 100%;" : "")}
  width: ${(p) => (p.full === "true" ? "100%" : "unset")};
  height: ${(p) => SIZES[p.size].height};

  color: ${(p) =>
    p.variant === "danger"
      ? p.theme.colors.white
      : p.theme.colors["neutral-800"]};
  background-image: ${(p) =>
    p.theme.linearGradients[COLORS_BG[p.variant].default]};
  outline: ${(p) =>
    p.variant === "outlined"
      ? `1px solid ${p.theme.colors["outline-color"]}`
      : "none"};
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-image: ${(p) =>
      p.theme.linearGradients[COLORS_BG[p.variant].hover]};
    ${(p) =>
      ["secondary", "outlined"].includes(p.variant)
        ? `color: ${p.theme.colors["main-600"]};`
        : ""}
    outline: ${(p) =>
      p.variant === "outlined"
        ? `1px solid ${p.theme.colors["main-600"]}`
        : "none"};
    transition: all 0.3s ease;
  }

  &:active {
    background-image: ${(p) =>
      p.theme.linearGradients[COLORS_BG[p.variant].active]};
    outline: ${(p) => {
      if (p.variant === "secondary" || p.variant === "outlined") {
        return `1px solid ${p.theme.colors["main-600"]}`;
      }
      if (p.variant === "tertiary") {
        return `1px solid ${p.theme.colors["outline-color"]}`;
      }
      return "none";
    }};
    transition: all 0.3s ease;
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors["neutral-25"]};
    color: ${(p) => (!p.loading ? p.theme.colors["neutral-300"] : "inherit")};
    pointer-events: none;
  }

  & > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    flex: none;
    width: ${(p) => SIZES[p.size].svg};
    height: ${(p) => SIZES[p.size].svg};
  }
`;

export const StyledLoading = styled(IconsSVG)`
  animation: ${loading} 1s linear infinite;
`;

export const StyledButtonLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => (p.size === "sm" ? "6px" : "8px;")};
  font-family: ${(p) => p.theme.fontFamily.lato};
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: ${(p) =>
    p.size === "sm" ? p.theme.fontSizes.s : p.theme.fontSizes.m};
  line-height: ${(p) => (p.size === "sm" ? 170 : 150)}%;
  padding: ${(p) =>
    p.type === "icon" ? SIZES[p.size].iconPadding : SIZES[p.size].padding};
  width: ${(p) => (p.full === "true" ? "100%" : "unset")};
  height: ${(p) => SIZES[p.size].height};

  color: ${(p) =>
    p.variant === "danger"
      ? p.theme.colors.white
      : p.theme.colors["neutral-800"]};
  background-image: ${(p) =>
    p.theme.linearGradients[COLORS_BG[p.variant].default]};
  outline: ${(p) =>
    p.variant === "outlined"
      ? `1px solid ${p.theme.colors["outline-color"]}`
      : "none"};
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-image: ${(p) =>
      p.theme.linearGradients[COLORS_BG[p.variant].hover]};
    ${(p) =>
      ["secondary", "outlined"].includes(p.variant)
        ? `color: ${p.theme.colors["main-600"]};`
        : ""}
    outline: ${(p) =>
      p.variant === "outlined"
        ? `1px solid ${p.theme.colors["main-600"]}`
        : "none"};
    transition: all 0.3s ease;
  }

  &:active {
    background-image: ${(p) =>
      p.theme.linearGradients[COLORS_BG[p.variant].active]};
    outline: ${(p) => {
      if (p.variant === "secondary" || p.variant === "outlined") {
        return `1px solid ${p.theme.colors["main-600"]}`;
      }
      if (p.variant === "tertiary") {
        return `1px solid ${p.theme.colors["outline-color"]}`;
      }
      return "none";
    }};
    transition: all 0.3s ease;
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors["neutral-25"]};
    color: ${(p) => (!p.loading ? p.theme.colors["neutral-300"] : "inherit")};
    pointer-events: none;
  }

  & svg {
    flex: none;
    width: ${(p) => SIZES[p.size].svg};
    height: ${(p) => SIZES[p.size].svg};
    &:first-of-type {
      ${(p) => (p.loading ? `animation: ${loading} 1s linear infinite;` : "")}
    }
  }
`;

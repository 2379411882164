import { useRef, useState } from "react";

import { useSelector } from "react-redux";

import { useOnClickOutside } from "../../../../hooks";
import { getAllComparison } from "../../../../store/reducers/ComparisonReducer/Comparison.selectors";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { SidebarButton } from "../../../ui/SidebarButton/SidebarButton";

import { Comparison } from "./Comparison/Comparison";
import { Reviews } from "./Reviews/Reviews";
import { Sellers } from "./Sellers/Sellers";

import "../ProxySidebars.scss";

export const ProxySidebarRight = () => {
  // **Redux state
  const { sellers, reviews } = useSelector(getAllContent);
  const { sites } = useSelector(getAllComparison);

  // **Local state
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  // **Ref
  const asideRef = useRef();

  useOnClickOutside(asideRef, () => setIsSidebarOpened(false));

  const clickHandler = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  return (
    <>
      <aside
        ref={asideRef}
        className={
          isSidebarOpened
            ? "proxy-sidebar proxy-sidebar--right proxy-sidebar--active"
            : "proxy-sidebar proxy-sidebar--right"
        }
      >
        {sites?.length !== 0 && <Comparison />}
        {sellers?.data?.length !== 0 && (
          <Sellers closeSidebar={setIsSidebarOpened} />
        )}
        {reviews?.data?.length !== 0 && (
          <Reviews closeSidebar={setIsSidebarOpened} />
        )}
      </aside>
      <div className="xlMin">
        <SidebarButton
          position="right"
          arrowPosition={29}
          isActive={isSidebarOpened}
          onClick={clickHandler}
        />
      </div>
    </>
  );
};

import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Rive } from "components/ui/New/Rive/Rive";
import { Title } from "components/ui/New/Title/Title";

import { animationWebRTCLeak, animationWebRTCLeakIp } from "assets/animations";

import styles from "./WebRTCLeakTestInfo.module.scss";

export const WebRTCLeakTestInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.section__dark}>
        <Container className={styles.container}>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("webRTCLeakTest.whatIsWebRTC.title")}
            </Title>
            <div className={styles.description}>
              {t("webRTCLeakTest.whatIsWebRTC.description")}
            </div>
          </div>
          <div className={styles.animation}>
            <Rive
              src={animationWebRTCLeak}
              autoPlay
              top={-74}
              right={-80}
              left={-80}
              bottom={-74}
            />
          </div>
          <picture>
            {/* <source srcSet={webRTCLeakInfoImage} media="(min-width: 993px)" /> */}
            <source
              srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <img
              src="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
              alt={t("webRTCLeakTest.whatIsWebRTC.title")}
              className={styles.image}
            />
          </picture>
        </Container>
      </div>
      <div className={styles.section__light}>
        <Container className={styles.container__light}>
          <div className={styles.animation}>
            <Rive
              src={animationWebRTCLeakIp}
              autoPlay
              top={-85}
              right={-71}
              bottom={-58}
              left={-70}
            />
          </div>
          <picture>
            <source srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg" />
            <img
              src="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg"
              alt={t("webRTCLeakTest.whatIsWebRTC.title")}
              className={styles.image}
            />
          </picture>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("webRTCLeakTest.whatDoesWebRTC.title")}
            </Title>
            <div className={styles.description}>
              {t("webRTCLeakTest.whatDoesWebRTC.description")}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

/* eslint-disable no-nested-ternary */
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import { SkeletonResultsTable } from "components/ui/New/Skeleton/SkeletonResultTable/SkeletonResultsTable";

import styles from "../DnsLeakTest.module.scss";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("dnsLeak.table.ip").toUpperCase(),
      cell: (row) => <span className={styles.table_text}>{row.ip}</span>,
      width: "170px"
    },
    {
      name: t("dnsLeak.table.provider").toUpperCase(),
      cell: (row) => <span className={styles.table_text}>{row.provider}</span>,
      selector: (row) => row.provider,
      width: "260px"
    },
    {
      name: t("dnsLeak.table.country").toUpperCase(),
      cell: (row) => (
        <div className={styles.country}>
          <img src={row.flag} alt={row.country} className={styles.table_flag} />
          <p className={styles.country__text}>{row.country}</p>
        </div>
      ),
      selector: (row) => row?.country,
      minWidth: "260px"
    }
  ];

  return (
    <DataTable
      className={"ToolTableAbsolute"}
      columns={columns}
      data={data}
      noDataComponent={
        !isLoading && (
          <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
            {t("proxyPage.content.table.noDataComponent")}
          </div>
        )
      }
      progressPending={isLoading}
      progressComponent={<SkeletonResultsTable cells={5} />}
    />
  );
};

import i18n from "i18next";

export const getSiteDescription = (
  descriptions,
  currentLang,
  noDescription
) => {
  if (Object.keys(descriptions).length === 0) {
    return noDescription || "";
  }

  if (descriptions.hasOwnProperty(currentLang) && descriptions[currentLang]) {
    return descriptions[currentLang];
  }

  const currentLanguage = i18n.language;

  return (
    Object.entries(descriptions).find(
      ([lang, value]) => Object.keys(currentLanguage).includes(lang) && value
    )?.[1] || noDescription
  );
};

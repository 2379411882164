import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Input } from "components/forms/New/Input/Input";
import Button from "components/ui/New/Button/Button";
import Subtitle from "components/ui/New/Subtitle/Subtitle";
import { Title } from "components/ui/New/Title/Title";

import { AllActions } from "store/reducers/AllActions";

import { ApiService } from "services";

import { useLangUrlDefault } from "hooks";

import { FeedbackSchema } from "utils/validation";

import styles from "../AuthPages.module.scss";

const FeedbackPage = () => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const navigate = useNavigate();

  // Form
  const methods = useForm({
    resolver: yupResolver(FeedbackSchema(t("forms", { returnObjects: true })))
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const params = {
      name: data.name.trim(),
      email: data.email.trim(),
      message: data.message.trim()
    };
    try {
      setIsLoading(true);
      const response = await ApiService.createFeedback(params);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form
      methods.reset();

      // Show success message
      navigate(`${hrefLang}/new/feedback/success`);
      // toast.success(t("notifications.feedback"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title className={styles.title}>Feedback</Title>
      <Subtitle className={styles.subtitle}>
        Use this form if you want to ask us a question or report a bug. Please
        make your message as detailed as possible, then we can solve the problem
        much faster
      </Subtitle>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <Input
            name="name"
            type={"text"}
            placeholder={t("forms.name.label")}
            label={t("forms.name.label")}
            showError
          />
          <Input
            name="email"
            type={"email"}
            placeholder={t("forms.email.label")}
            label={t("forms.email.label")}
            showError
          />
          <Input
            name="message"
            type={"textarea"}
            placeholder={t("forms.message.placeholder")}
            label={t("forms.message.label")}
            showError
            className={styles.textarea}
          />
          <Button
            className={styles.submit}
            formSubmit
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          >
            {t("feedbackPage.form.btn")}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default FeedbackPage;

FeedbackPage.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

import PT from "prop-types";

import Card from "components/ui/New/Card/Card";

import styles from "./NoData.module.scss";

export const NoData = ({ title, subtitle, className, srcX1, srcX2 }) => (
  <Card
    size={"small"}
    radius={"small"}
    className={`${styles.card} ${className}`}
  >
    {title && <div className={styles.title}>{title}</div>}
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    <picture className={styles.picture}>
      {srcX1 && srcX2 && (
        <source srcSet={`${srcX1} 1x, ${srcX2} 2x`} type="image/png" />
      )}
      <img src={srcX1 || srcX2} alt={"no data"} />
    </picture>
  </Card>
);

NoData.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  className: PT.string,
  srcX1: PT.string,
  srcX2: PT.string
};

NoData.defaultProps = {
  title: "",
  subtitle: "",
  className: "",
  srcX1: "",
  srcX2: ""
};

import { useTranslation } from "react-i18next";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { animationPortScannerStep } from "assets/animations";

import Container from "components/common/New/Container/Container";

import { PortScannerStep } from "./PortScannerStep";

import styles from "./PortScannerSteps.module.scss";

export const PortScannerSteps = () => {
  const { t } = useTranslation();

  const mockData = [
    {
      image: "img/steps/port-scanner-step-1.svg",
      animation: animationPortScannerStep,
      description:
        "If you need to check the ports of a proxy, switch on the proxy and insert its IP address to the “Enter the IP address or domain” field. If it is necessary, scan your personal IP, using the “Insert my IP address” function;"
    },
    {
      image: "img/steps/port-scanner-step-2.svg",
      description:
        "If this is a website, then insert a link to the site. Next, select the required type of ports: hidden ports, popular ports."
    },
    {
      image: "img/steps/port-scanner-step-3.svg",
      description:
        "Finish the job by clicking the Scan button and after a few seconds the result will be displayed at the bottom of the form"
    }
  ];

  return (
    <div className={styles.section}>
      <Container className={styles.container}>
        <Swiper
          className="port-scanner-steps"
          direction="horizontal"
          modules={[Scrollbar]}
          spaceBetween={30}
          grabCursor={true}
          rewind={true}
          scrollbar={{
            hide: false
          }}
          wrapperTag="ul"
        >
          {mockData.map((item, index) => (
            <SwiperSlide key={index} tag="li">
              <PortScannerStep
                title={t("portScanner.stepGuide")}
                index={index + 1}
                {...item}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Checkbox } from "components/ui/New/Checkbox/Checkbox";
import Dropdown from "components/ui/New/Dropdown/Dropdown";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Search from "components/ui/New/Search/Search";

import { useLangUrlDefault } from "hooks";

import { convertCountryCodeAlpha3ToAlpha2, getCountryId } from "utils/helpers";

import styles from "./SearchCountryDropdown.module.scss";

export const SearchCountryDropdown = ({
  data,
  countriesFilter,
  setCountriesFilter
}) => {
  // **Dispatch
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [searchParams] = useSearchParams();

  const handleSearch = (value) => {
    setSearch(value);
  };

  const countryId = getCountryId(data, searchParams.get("country"))?.id;

  useEffect(() => {
    if (countryId && search) {
      setFilteredData(
        data?.filter((country) => {
          const name = queryLang ? country?.localization[queryLang] : "";
          return (
            name.toLowerCase().includes(search.toLowerCase()) &&
            countryId === country.id
          );
        })
      );
    } else if (countryId) {
      setFilteredData(data?.filter((country) => country.id === countryId));
    } else setFilteredData(
        data?.filter((country) => {
          const name = queryLang ? country?.localization[queryLang] : "";
          return name.toLowerCase().includes(search.toLowerCase());
        })
      );
  }, [search, data, queryLang, countryId]);

  useEffect(() => {
    if (countryId) {
      setCountriesFilter([countryId]);
    } else setCountriesFilter([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const handleCheckboxClick = (id) => {
    if (countriesFilter.includes(id)) {
      setCountriesFilter(countriesFilter?.filter((item) => item !== id));
    } else {
      setCountriesFilter([...countriesFilter, id]);
    }
  };

  return (
    <Dropdown
      className={styles.dropdown}
      label={
        <div className={styles.label}>
          {t("freeProxy.filter.country").toUpperCase()}
          <IconsSVG className={styles.icon} name="filterAlt" />
        </div>
      }
      menuClassName={styles.dropdown__menu}
      align="start"
      direction="bottom"
      portal={true}
    >
      <Search value={search} onChange={handleSearch} variant={"outlined"} />
      <div className={styles.container}>
        {filteredData?.length ? (
          <ul className={styles.list}>
            {filteredData.map((country) => {
              const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                country?.code
              ).toLowerCase();
              const name = queryLang ? country?.localization[queryLang] : "";
              const checked = countriesFilter.includes(country.id);
              return (
                <li
                  key={country?.id}
                  className={checked ? styles.item__checked : styles.item}
                  onClick={() => handleCheckboxClick(country.id)}
                >
                  <Checkbox
                    checked={checked}
                    name={name}
                    label={
                      <>
                        {alpha2 !== "default" ? (
                          <span
                            className={`fl fl-${alpha2} ${styles.flag}`}
                          ></span>
                        ) : (
                          <img
                            className={styles.flag}
                            src="/img/flags/default.svg"
                            width={20}
                            height={20}
                            loading="lazy"
                            alt={name}
                          />
                        )}
                        <span className={styles.name}>{name}</span>
                      </>
                    }
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </Dropdown>
  );
};

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "components/ui/New/Icon/Icon";

import styles from "./LocationsItem.module.scss";

export const LocationItem = ({ title, isSelected, onClick, className }) => {
  const { t } = useTranslation();
  return (
    <li
      className={
        isSelected
          ? `${styles.item__active} ${className}`
          : `${styles.item} ${className}`
      }
      onClick={onClick}
    >
      {t(`navigation.${title}`)}
      <Icon name="arrowDown" className={styles.icon} />
    </li>
  );
};

LocationItem.propTypes = {
  title: PT.string.isRequired,
  isSelected: PT.bool,
  onClick: PT.func,
  className: PT.string
};

LocationItem.defaultProps = {
  className: ""
};

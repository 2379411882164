export const mockListData = {
  en: [
    "Viewing blocked resources via Firefox, Opera and other popular browsers or Internet surfing;",
    "Announcements and feedback posting, where a bind to a region or IP is needed",
    "For active social network using (for example, for Instagram group and account promoting Instagram, Odnoklassniki, VK);",
    "If you are eager to check out the key working principles of proxy and find out how it works;",
    "To view video content banned in your region;",
    "Parsing and key query gathering."
  ],
  ru: [
    "Просмотр заблокированных ресурсов через Firefox, Opera и другие популярные браузеры или интернет-серфинг;",
    "Размещение объявлений и отзывов, где необходима привязка к региону или IP",
    "За активное использование социальных сетей (например, для продвижения группы и аккаунта Инстаграм, Одноклассники, ВК);",
    "Если вы хотите ознакомиться с ключевыми принципами работы прокси и узнать, как он работает;",
    "Для просмотра видеоконтента, запрещенного в вашем регионе;",
    "Разбор и сбор ключевых запросов"
  ],
  ua: [
    "Перегляд заблокованих ресурсів через Firefox, Opera та інші популярні браузери або інтернет-серфінг;",
    "Оголошення та розміщення відгуків, де потрібна прив’язка до регіону чи IP",
    "Для активного користування соціальною мережею (наприклад, для групи в Instagram і для просування акаунта Instagram, Одноклассники, ВК);",
    "Якщо ви хочете ознайомитися з ключовими принципами роботи проксі та дізнатися, як він працює;",
    "Для перегляду відеоконтенту, забороненого у вашому регіоні;",
    "Аналіз і збір ключових запитів"
  ]
};

export const mockTableData = {
  en: [
    {
      freeProxy: "Low speed",
      personalProxy: "High speed, quality as well"
    },
    {
      freeProxy: "High ping, in some cases reaching a space shuttle speed",
      personalProxy: "Low stable ping"
    },
    {
      freeProxy: "Large numbers of people use it",
      personalProxy: "IP address is given only for one user"
    },
    {
      freeProxy:
        "Free proxy may be banned on the necessary resource due to unlawful acts of other persons",
      personalProxy:
        "As the address leased by you is being used only by you, thus the proxy’s “destiny\" depends only on your activity"
    },
    {
      freeProxy: "Low probability to remain anonymous",
      personalProxy:
        "By buying elite proxy, you will be granted with complete anonymity"
    },
    {
      freeProxy:
        "The assurance of security cannot ever get to 100 per cent. Such a connection fails to be secure enough, thus, while choosing a proxy, you should track all of your data and not to be confident in unverified resources",
      personalProxy:
        "Your personal data as logins, passwords, card number etc. are totally safe.(information on proxy usage and the real IP for providers and other network users is hidden)"
    },
    {
      freeProxy:
        "If a proxy happens to be banned, there is no one whom you may file claims. You will have to search for a new working IP",
      personalProxy:
        "In case of force majeure, the proxy will be restored or replaced by a new one"
    },
    {
      freeProxy:
        "No 24/7 support is available, thus you will have to find a solution by yourself (possibly, during a long period of time)",
      personalProxy:
        "Buying proxy form us, you can be confident that you will be provided with 24/7 support regarding any issue you may face"
    }
  ],
  ru: [
    {
      freeProxy: "Низкая скорость",
      personalProxy: "Высокая скорость, качество тоже"
    },
    {
      freeProxy:
        "Высокий пинг, в некоторых случаях достигающий скорости космического корабля",
      personalProxy: "Низкий стабильный пинг"
    },
    {
      freeProxy: "Его использует большое количество людей",
      personalProxy: "IP-адрес предоставляется только одному пользователю"
    },
    {
      freeProxy:
        "Бесплатные прокси могут быть забанены на нужном ресурсе из-за противоправных действий других лиц",
      personalProxy:
        "Поскольку арендованный вами адрес используется только вами, то и «судьба» прокси зависит только от вашей активности"
    },
    {
      freeProxy: "Низкая вероятность остаться анонимным",
      personalProxy: "Покупая элитные прокси, вы получаете полную анонимность"
    },
    {
      freeProxy:
        "Гарантия безопасности никогда не может достигать 100 процентов. Такое соединение не может быть достаточно безопасным, поэтому при выборе прокси следует отслеживать все свои данные и не быть уверенным в непроверенных ресурсах",
      personalProxy:
        "Ваши персональные данные, такие как логины, пароли, номера карт и т.д., в полной безопасности. (информация об использовании прокси и реальном IP для провайдеров и других пользователей сети скрыта)"
    },
    {
      freeProxy:
        "Если прокси забанят, то претензии предъявлять не к кому. Придется искать новый рабочий IP",
      personalProxy:
        "В случае форс-мажорных обстоятельств прокси будет восстановлено или заменено на новый"
    },
    {
      freeProxy:
        "Круглосуточная поддержка отсутствует, поэтому вам придется искать решение самостоятельно (возможно, в течение длительного периода времени)",
      personalProxy:
        "Покупая прокси у нас, вы можете быть уверены, что вам будет оказана круглосуточная поддержка по любому вопросу, с которым вы можете столкнуться"
    }
  ],
  ua: [
    {
      freeProxy: "Низька швидкість",
      personalProxy: "Висока швидкість, якість також"
    },
    {
      freeProxy:
        "Високий пінг, у деяких випадках досягає швидкості космічного човника",
      personalProxy: "Низький стабільний пінг"
    },
    {
      freeProxy: "Цим користується велика кількість людей",
      personalProxy: "IP-адреса надається лише для одного користувача"
    },
    {
      freeProxy:
        "Безкоштовний проксі може бути забанений на потрібному ресурсі через неправомірні дії інших осіб",
      personalProxy:
        "Оскільки орендована вами адреса використовується тільки вами, то «доля» проксі залежить тільки від вашої активності"
    },
    {
      freeProxy: "Низька ймовірність залишитися анонімним",
      personalProxy: "Купуючи елітний проксі, ви отримуєте повну анонімність"
    },
    {
      freeProxy:
        "Гарантія безпеки ніколи не досягає 100 відсотків. Таке з’єднання недостатньо безпечне, тому, вибираючи проксі, слід відстежувати всі свої дані, а не покладатися на неперевірені ресурси",
      personalProxy:
        "Ваші персональні дані, такі як логіни, паролі, номери карток і т.д., знаходяться в цілковитій безпеці. (інформація про використання проксі та реальний IP для провайдерів та інших користувачів мережі прихована)"
    },
    {
      freeProxy:
        "Якщо проксі трапиться забанений, претензії нема на кого подавати. Доведеться шукати новий робочий IP",
      personalProxy:
        "У разі форс-мажорних обставин проксі буде відновлено або замінено на новий"
    },
    {
      freeProxy:
        "Цілодобової підтримки немає, тому вам доведеться самостійно шукати рішення (можливо, протягом тривалого періоду часу)",
      personalProxy:
        "Купуючи проксі у нас, ви можете бути впевнені, що вам буде надано підтримку 24/7 щодо будь-якої проблеми, з якою ви можете зіткнутися"
    }
  ]
};

export const mockStepsData = {
  en: [
    "Select the necessary country and the type of proxy using the filter on the top",
    "After this, use any convenient option that is proposed in the “export” tab."
  ],
  ru: [
    "Выберите необходимую страну и тип прокси с помощью фильтра вверху",
    "После этого воспользуйтесь любой удобной опцией, которая предложена во вкладке «экспорт»"
  ],
  ua: [
    "Виберіть необхідну країну та тип проксі за допомогою фільтра вгорі",
    "Після цього скористайтеся будь-яким зручним варіантом, запропонованим у вкладці «Експорт»"
  ]
};

import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { convertCountryCodeAlpha3ToAlpha2 } from "../../../../utils/helpers";

export const Countries = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { countries } = useSelector(getAllContent);

  // **Local state
  const [isOpened, setIsOpened] = useState(false);

  // **Dispatch
  const { setMobileMenu } = useDispatchedActions();

  // **Ref
  const listRef = useRef(null);

  const mobileMenu = () => {
    setMobileMenu(false);
  };

  const toggleHandler = () => {
    if (!listRef.current) return;

    const height = listRef.current.scrollHeight;

    if (isOpened) {
      requestAnimationFrame(() => {
        listRef.current.style.height = `${height}px`;

        requestAnimationFrame(() => {
          listRef.current.style.height = "0px";
        });
      });

      setIsOpened(false);
    } else {
      listRef.current.style.height = `${height}px`;

      setIsOpened(true);
    }
  };

  const transitionEndHandler = () => {
    if (!listRef.current) return;

    if (isOpened) {
      listRef.current.style.height = "auto";
    } else {
      listRef.current.style.height = "0px";
    }
  };

  useEffect(() => {
    if (!listRef.current) return;

    if (!isOpened) {
      listRef.current.style.height = "0px";
    } else {
      listRef.current.style.height = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        isOpened
          ? "mobile-nav__dropdown mobile-nav__dropdown--active"
          : "mobile-nav__dropdown"
      }
    >
      <button className="mobile-nav__dropdown-title" onClick={toggleHandler}>
        {t("proxyPage.sidebar.left.countries.title")}
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="M9.93685 7.14357L4.34704 12.7332C3.99146 13.0889 3.41495 13.0889 3.05955 12.7332C2.70411 12.3778 2.70411 11.8013 3.05955 11.4459L8.00566 6.49991L3.05969 1.55412C2.70425 1.19854 2.70425 0.622118 3.05969 0.266684C3.41512 -0.0888946 3.99161 -0.0888946 4.34719 0.266684L9.93699 5.85639C10.1147 6.03419 10.2035 6.26698 10.2035 6.49988C10.2035 6.73289 10.1145 6.96585 9.93685 7.14357Z"></path>
          </g>
        </svg>
      </button>
      <ul
        ref={listRef}
        className="mobile-nav__dropdown-list"
        onTransitionEnd={transitionEndHandler}
      >
        {countries.data?.map((country) => {
          const alpha2 = convertCountryCodeAlpha3ToAlpha2(
            country?.code
          ).toLowerCase();
          const name = queryLang ? country?.localization[queryLang] : "";

          return (
            <li key={country.code}>
              <Link
                to={`${hrefLang}/proxy/?fc=${country.urlParam}`}
                onClick={mobileMenu}
              >
                {alpha2 !== "default" ? (
                  <span className={`fi fi-${alpha2}`}></span>
                ) : (
                  <img
                    src="/img/flags/default.svg"
                    width={20}
                    height={20}
                    loading="lazy"
                    alt={name}
                  />
                )}
                &nbsp;
                {country.localization?.[queryLang]}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

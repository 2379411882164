import PT from "prop-types";

import styles from "./IpCheck.module.scss";

export const IpCheckItem = ({ title, image, number }) => (
  <div className={styles.item}>
    <div className={styles.image}>
      <img src={image} loading="lazy" alt={title} aria-hidden="true" />
    </div>
    <div className={styles.content}>
      <p className={styles.item__title}>
        {title}
      </p>
      <div className={styles.number}>{number}</div>
    </div>
  </div>
);

IpCheckItem.propTypes = {
  title: PT.string,
  number: PT.oneOfType([PT.string, PT.number]),
  image: PT.node
};

IpCheckItem.defaultProps = {
  title: "",
  number: 0,
  image: null
};

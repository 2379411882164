export const questionsAndAnswers = [
    {
        question: "Is there a free trial available?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can other info be added to an invoice?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "How do I change my account email?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can other info be added to an invoice?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can other info be added to an invoice?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can other info be added to an invoice?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqToolFingerprint = [
    {
        question: "What is browser fingerprint?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What are attributes?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What is browser fingerprint?",
        answer: "Browser fingerprinting stands for collecting information about you through your device fingerprint, which conveys the following data: your OS, keyboard preferences, hardware, geolocation, timezone, display size, browser, device memory, vendor, and many other attributes. Some advanced websites even know what apps you installed on your PC. All this data helps identify you out of a crowd with up to 99% accuracy."
    },
    {
        question: "What are attributes?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What are attributes?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What is browser fingerprint?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqToolPortScanner = [
    {
        question: "Are open ports a security risk?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "How can I secure open ports on my network?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can I close the ports on my computer?",
        answer: "Yes. You can close ports on your computer to limit external access. This is usually done through firewall settings or network configuration. It is important to know that closed ports help to reduce the potential attack surface for malicious actors."
    },
    {
        question: "Does our Port Scanner  tool help you identify if a port is misconfigured?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Are open ports a security risk?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqIpBlacklistCheck = [
    {
        question: "Why should I do IP spam checking?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Can I only check the IP address or IP of the site too?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "My IP is in one of the blacklists. What to do?",
        answer: "It is necessary to replace the used proxy server with a clean one. Blacklisted addresses are not recommended for work."
    },
    {
        question: "What IPs can I check for blacklisting?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqMyAnonymity = [
    {
        question: "What to do if proxy anonymity is displayed low?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What to do if IP in the blacklist?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "What settings affect IP anonymity?",
        answer: "IP anonymity is influenced by a large number of parameters. The main ones are: IP data, location, OS, browser, system language, user agent availability, blacklist definition, number of open ports."
    },
    {
        question: "What should I do if the anonymization check service sees that a proxy is being used?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqWebRTCLeakTest = [
    {
        question: "How do I fix WebRTC leaks?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "How do I stop WebRTC from leaking in Chrome?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Should I turn off WebRTC?",
        answer: "That depends. You shouldn’t turn off WebRTC on your browser if you use websites or services that rely on WebRTC, like Google Hangouts, Google Meets, or Facebook Messenger. However, if the services you use don’t use WebRTC, turning off the feature prevents WebRTC leaks entirely."
    },
    {
        question: "How do I know if WebRTC is leaking?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqHttpHeadersChecker = [
    {
        question: "What is HTTP Header Checker?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "Features of HTTP Header Check Tool",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
        question: "How Can I Utilize HTTP Header Checker Tool?",
        answer: "Our HTTP header check tool is very easy to use. All you need to do is type in the target URL you wish to check, and then press Enter key or click the submit button. Our server will check it for you in the background, usually the job will be completed in a few seconds. Once response fetched, the result headers will be listed for you in details, the left column is the header name, and the right column is the header value. When there are too many results listed, you can click the \"Clear Results\" button to empty the list."
    },
    {
        question: "How do I know if WebRTC is leaking?",
        answer: "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
];

export const faqEvercookieTest = [
  {
    question: "What are cookies on a website?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
  },
  {
    question: "How do cookies track?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
  },
  {
    question: "Why check cookies on a website?",
    answer: `Privacy laws like the EU cookie laws mandate that the websites must disclose what cookies are being used on the website and why, who installs them (or who the data is shared with), and how long they are stored. For that, you need to know the cookies set by your website. Checking cookies on your website will give you the list of cookies, and then you can compile their details.

        The websites should inform the users of the cookies using a cookie banner and more details about the cookies in the cookie or privacy policy of the website.
        `
  },
  {
    question: "What is cookie scanning?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
  }
];

export const faqBrowserInformation = [
    {
      question: "What is my browser?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
      question: "Why is my browser info important?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    },
    {
      question: "What is an IP address?",
      answer: "An IP address is a set of numbers that identifies your device, and which can be tied to your online activity. Some users may not want their browsing history associated with their IP address, and might therefore opt to use a Virtual Private Network (VPN) for an extra layer of security and privacy. We recommend looking into one of these VPN services to secure your online activity."
    },
    {
      question: "What is cookie scanning?",
      answer:
        "Lorem ipsum dolor sit amet consectetur. Tempor amet dui nunc felis dolor elementum mauris sit. Urna aliquet lobortis in at dui. Purus morbi tortor enim ac vestibulum sit leo. Diam magnis felis laoreet feugiat duis. Sit pretium nulla diam morbi et. Hendrerit sodales feugiat viverra vitae natoque nunc urna sagittis. Vitae magna magna enim mi ligula adipiscing diam. Morbi suspendisse justo dui interdum massa enim. Nibh netus est in ut rhoncus natoque. In vulputate orci est pellentesque tellus risus sit nisl. Leo nisi arcu ornare sem ante mauris ipsum purus."
    }
  ];

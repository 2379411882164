import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const RegistrationSchema = (trns) =>
  yup.object().shape({
    email: yup
      .string()
      .required(trns.email.rules.required)
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        trns.email.rules.email
      ),
    password: yup
      .string()
      .required(trns.password.rules.required)
      .test({
        name: "noSpaces",
        message: trns.password.rules.noSpace,
        test: (value) => !/\s/.test(value)
      })
      .min(6, `${trns.password.rules.min} 6`),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref("password"), null], trns.password.rules.mismatch),
    agree: yup.boolean().oneOf([true], trns.agree.rules.required)
  });

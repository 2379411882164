import { useState, useId } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useLangUrlDefault } from "../../../../../../../hooks";

export const PromocodeItem = ({ promocode }) => {
  const customToastId = useId();
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Local state
  const [isVisible, setIsVisible] = useState(false);

  const visibilityHandler = () => {
    setIsVisible(true);
  };

  const showNotification = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <li className="proxy-site__promocodes-item">
      <div className="mdMin">
        <div className="proxy-site__promocodes-image">
          <div className="proxy-site__promocodes-image-inner">
            <img
              src={
                promocode.imageUrl
                  ? promocode.imageUrl
                  : "/img/ui/placeholder.svg"
              }
              width={115}
              height={35}
              loading="lazy"
              alt=""
              aria-hidden="true"
            />
          </div>
        </div>
      </div>

      <div className="proxy-site__promocodes-description">
        {Object.keys(promocode?.description).length > 0 &&
        promocode?.description.hasOwnProperty(queryLang)
          ? promocode.description[queryLang]
          : t("proxySitePage.content.promocodes.noDescription")}
      </div>
      <div className="proxy-site__promocodes-body">
        <div className="proxy-site__promocodes-body-title">
          {t("proxySitePage.content.promocodes.title")}
        </div>
        {isVisible ? (
          <CopyToClipboard text={promocode.promocode} onCopy={showNotification}>
            <button
              className="proxy-site__promocodes-btn proxy-site__promocodes-btn-code"
              onClick={visibilityHandler}
            >
              <span>{promocode.promocode}</span>
            </button>
          </CopyToClipboard>
        ) : (
          <button
            className="proxy-site__promocodes-btn proxy-site__promocodes-btn-show"
            onClick={visibilityHandler}
          >
            <span>{t("proxySitePage.content.promocodes.btn")}</span>
          </button>
        )}
      </div>
    </li>
  );
};

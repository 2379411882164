import { useEffect, useState } from "react";

import { compareIpHelpers } from "../utils/helpers";

export const useWebRtcStatus = (ips, storedIp, setIps) => {
  const [webRtcStatus, setWebRtcStatus] = useState(true);

  useEffect(() => {
      if (ips && ips?.length > 0) {
        setWebRtcStatus(true);

        const compareIps = compareIpHelpers(ips[0].ip, storedIp);

        if (!compareIps && !ips.find((item) => item.ip === storedIp)) {
          setIps((prevState) => [
            ...prevState,
            { ip: storedIp, type: "Public IPv4", state: true }
          ]);
        }
      } else if (ips) {
        setWebRtcStatus(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ips, storedIp]);

  return webRtcStatus;
};

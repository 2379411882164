import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";

import { useLangUrlDefault } from "hooks";

import styles from "./TitleBlock.module.scss";

export const TitleBlock = ({ title, linkTo = "", linkName }) => {
  const [, hrefLang] = useLangUrlDefault();
  let link = linkTo;

  if (linkTo && linkTo?.slice(0, 1) !== "/") {
    link = `/${linkTo}`;
  }

  if (hrefLang && linkTo && linkTo?.slice(0, 3) !== hrefLang) {
    link = `${hrefLang}${linkTo}`;
  }

  return (
    <div className={styles.head_block}>
      <Title size={"sm"} className={styles.head_title}>
        {title}
      </Title>
      {link ? (
        <Button
          fullWidth
          linkTo={link}
          iconLeft={"plus"}
          className={styles.head_create}
        >
          {linkName}
        </Button>
      ) : null}
    </div>
  );
};

import { Suspense } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { Breadcrumbs } from "../../../components/common";
import { Container } from "../../../components/ui";
import { Loader } from "../../../components/ui/Loader/Loader";
import { Typography } from "../../../components/ui/Typography/Typography";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { CabinetTabs } from "../CabinetTabs/CabinetTabs";

import "./Content.scss";

export const Content = () => {
  const { t } = useTranslation();
  const { routeContent } = useSelector(getAllContent);
  const cabinetPages = t("cabinetPages.breadcrumbs", { returnObjects: true });

  return (
    <section className="cabinet">
      <Container isFluid={routeContent.isContainerFluid}>
        <Breadcrumbs items={cabinetPages[routeContent.tag]} />
        <Typography
          Tag="h1"
          weight="bold"
          size="very-large"
          padding="bottom-large"
        >
          {t("cabinetPages.title")}
        </Typography>
        <div className="cabinet__content">
          <CabinetTabs />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </Container>
    </section>
  );
};

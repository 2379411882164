import { useSelector } from "react-redux";

import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { Container } from "../../../ui";

import { ProxyGeo } from "./ProxyGeo/ProxyGeo";
import { ProxyType } from "./ProxyType/ProxyType";

export const HeaderBottom = () => {
  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <div className="header__bottom">
      <Container isFluid={routeContent.isContainerFluid}>
        <ul className="list-reset header__bottom-list">
          <ProxyGeo />
          <ProxyType />
        </ul>
      </Container>
    </div>
  );
};

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Title } from "components/ui/New/Title/Title";

import { RelatedItem } from "./RelatedItem";

import styles from "./Related.module.scss";

export const Related = ({ data }) => {
  // **Props
  const { t } = useTranslation();

  return (
    <div className={styles.related}>
      <Container className={styles.container}>
        <div>
          <Title tag="h2" className={styles.title}>
            {t("blogInnerPage.articleBody.relatedTitle")}
          </Title>
          <ul className={styles.list}>
            {data?.slice(0, 3)?.map((post) => (
              <RelatedItem key={post?.id} data={post} />
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

Related.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      viewCount: PT.number
    })
  )
};

import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { StyledDataTable } from "components/common/New/Table/Table.styled";
import {
  HeaderNameCell,
  SiteNameCell
} from "components/common/New/TableColumns";
import { Loader } from "components/ui/Loader/Loader";
import Annotation from "components/ui/New/Annotation/Annotation";
import Card from "components/ui/New/Card/Card";
import Dropdown from "components/ui/New/Dropdown/Dropdown";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import PaginationTable from "components/ui/New/PaginationTable/PaginationTable";
import Status from "components/ui/New/Status/Status";
import { TableCheckbox } from "components/ui/New/TableCheckbox/TableCheckbox";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import { AllActions } from "store/reducers/AllActions";
import { getAllCabinet } from "store/reducers/CabinetReducer/Cabinet.selectors";

import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import { ModalConfirm } from "../components/ModalConfirm/ModalConfirm";
import { NoData } from "../components/NoData/NoData";
import { SortBlock } from "../components/SortBlock/SortBlock";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import styles from "./Services.module.scss";

const Services = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  const { proxies, siteStatuses } = useSelector(getAllCabinet);

  // **Local state
  const [deletedSiteId, setDeletedSiteId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [params, setParams] = useState(
    proxies.prevParams || {
      proxyType: [],
      country: "",
      statusId: "",
      page: 0,
      size: 10
    }
  );
  const [isDeleting, setIsDeleting] = useState(false);

  // **Dispatch
  const { getCabinetSites, setCabinetProxiesPrevParams } =
    useDispatchedActions();

  const fetchCabinetSites = useCallback(() => {
    getCabinetSites(params);
    setCabinetProxiesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const pageSizeChangeHandler = (size) =>
    setParams({ ...params, page: 0, size });

  const changeSortType = (data) => {
    setParams({ ...params, statusId: data });
  };

  const openModalHandler = (id) => {
    setIsModalOpened(true);
    setDeletedSiteId(id);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await ApiService.deleteProxySite(deletedSiteId);

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      setIsModalOpened(false);

      // Show success message
      toast.success(t("notifications.site.removed"));

      setDeletedSiteId(null);
      fetchCabinetSites();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  const columns = [
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.name")}
        </HeaderNameCell>
      ),
      minWidth: "230px",
      selector: (row) => (
        <SiteNameCell
          imgUrl={row?.imageURL}
          name={row?.name}
          rating={row?.rating}
        />
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.date")}
        </HeaderNameCell>
      ),
      sortable: true,
      selector: (row) => row?.createDate,
      minWidth: "140px",
      cell: (row) => (
        <div className={`${styles.column} ${styles.column_date}`}>
          <Moment format="DD.MM.YYYY">{row?.createDate}</Moment>
          <span className={styles.column_date_delimiter}>|</span>
          <Moment format="HH:mm">{row?.createDate}</Moment>
        </div>
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.status")}
        </HeaderNameCell>
      ),
      minWidth: "160px",
      sortable: true,
      selector: (row) => row?.statusType,
      cell: (row) => {
        const currentStatus = siteStatuses.data?.length
          ? siteStatuses.data.find((item) => item.type === row?.statusType)
          : null;
        const name =
          currentStatus?.translations?.[queryLang] || currentStatus?.name;

        return (
          <div className={`${styles.column} ${styles.column_status}`}>
            <Status
              size={"sm"}
              bordered={false}
              label={name}
              color={currentStatus?.color || "default"}
            />
            {row?.statusType === "inactive" && (
              <Tooltip
                place={"bottom"}
                body={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet."
                }
              >
                <IconsSVG
                  name="infoChat"
                  role="button"
                  className={styles.column_status_info}
                />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.views")}
        </HeaderNameCell>
      ),
      minWidth: "100px",

      selector: (row) => (
        <div className={styles.column}>{row?.statistic?.views || 0}</div>
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.reviews")}
        </HeaderNameCell>
      ),
      minWidth: "100px",

      selector: (row) => (
        <div className={styles.column}>
          <Annotation
            className={styles.column_reviews_annotation}
            showIcon={false}
            positive={row.ratingPositive}
            negative={row.ratingNegative}
          />
        </div>
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.services.table.columns.conversions")}
        </HeaderNameCell>
      ),
      minWidth: "120px",

      selector: (row) => (
        <div className={styles.column}>{row?.statistic?.conversions || 0}</div>
      )
    },
    {
      minWidth: "24px",
      selector: (row) => (
        <div className={`${styles.column} ${styles.column_actions_box}`}>
          <Dropdown
            label={
              <IconsSVG name="dots" className={styles.column_actions_icon} />
            }
            className={styles.column_actions}
            menuClassName={styles.column_actions_menu}
            align={"start"}
            direction={"left"}
            position={"anchor"}
            portal
          >
            <Dropdown.Item
              className={styles.column_actions_item}
              linkTo={`${hrefLang}/new/dashboard/services/edit/${row.id}/`}
            >
              <IconsSVG
                name="edit"
                className={styles.column_actions_item_icon}
              />
              {t("dashboard.services.table.columns.actions.edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => openModalHandler(row.id)}
              className={`${styles.column_actions_item} ${styles.column_actions_item_delete}`}
            >
              <IconsSVG
                name="delete"
                className={styles.column_actions_item_icon}
              />
              {t("dashboard.services.table.columns.actions.delete")}
            </Dropdown.Item>
          </Dropdown>
        </div>
      )
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((r) => r.id === row.id),
      style: {
        backgroundColor: "rgba(246, 247, 249, 0.80)"
      }
    }
  ];

  const customStyles = {
    table: {
      style: {
        marginBottom: "18px"
      }
    }
  };

  useEffect(() => {
    if (siteStatuses.data?.length) {
      setStatusOptions([
        ...siteStatuses.data.map((item) => ({
          label: item.translations?.[queryLang] || item.name,
          value: item.id
        })),
        { label: t("dashboard.services.table.all"), value: "" }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteStatuses.data, queryLang]);

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(proxies.prevParams)) {
      fetchCabinetSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (proxies.data.totalPages !== params.page) {
      return;
    }

    if (params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, proxies]);

  return (
    <>
      <TitleBlock
        title={t("dashboard.services.table.title")}
        linkTo={"/new/dashboard/services/create/"}
        linkName={t("dashboard.services.table.add")}
      />
      {proxies.data?.anyExists && (
        <Card className={styles.card} radius={"small"} size={"small"}>
          <SortBlock
            // rowCount={proxies.data?.totalElements}
            params={params}
            label={t("dashboard.services.table.filter")}
            options={statusOptions}
            changeSortType={changeSortType}
          />
          <StyledDataTable
            styleRules={{
              showedAll: true
            }}
            columns={columns}
            data={proxies.data?.content}
            selectableRows
            selectableRowsComponent={TableCheckbox}
            onSelectedRowsChange={handleRowSelected}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
            noDataComponent={
              !proxies.isLoading && (
                <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
                  {t("proxyPage.content.table.noDataComponent")}
                </div>
              )
            }
          />
          {proxies.data?.totalElements > 0 && (
            <PaginationTable
              rowCount={proxies.data.totalElements}
              rowsPerPage={params.size}
              onChangePage={pageChangeHandler}
              onChangeRowsPerPage={pageSizeChangeHandler}
              currentPage={params.page + 1}
              className={styles.pagination}
            />
          )}
        </Card>
      )}
      {!proxies.isLoading && !proxies.data?.anyExists && (
        <NoData
          title={t("dashboard.services.noData.title")}
          subtitle={t("dashboard.services.noData.subtitle")}
          srcX1={"/img/dashboard/no-secvise-x1.png"}
          srcX2={"/img/dashboard/no-secvise-x2.png"}
        />
      )}
      {proxies.isLoading && <Loader type="blur" />}
      <ModalConfirm
        open={isModalOpened}
        close={setIsModalOpened}
        isDeleting={isDeleting}
        srcX1={"/img/dashboard/delete-service-x1.png"}
        srcX2={"/img/dashboard/delete-service-x2.png"}
        subtitle={t("dashboard.services.modal.delete.subtitle")}
        onConfirm={confirmHandler}
      />
    </>
  );
};

export default Services;

Services.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

import AddsitePage from "pages/AppPages/AddsitePage/AddsitePage";
import BlogInnerPage from "pages/AppPages/BlogInnerPage/BlogInnerPage";
import BlogPage from "pages/AppPages/BlogPage/BlogPage";
import ComparisonPage from "pages/AppPages/ComparisonPage/ComparisonPage";
import MainPage from "pages/AppPages/MainPage/MainPage";
import TestErrorBoundary from "pages/AppPages/Test/ErrorBoundary/ErrorBoundary";
import TestPage from "pages/AppPages/Test/Test";

export const AppRoutesOLD = [
  {
    path: "/:lang?/",
    tag: "home",
    type: "app",
    component: () => import("pages/AppPages/MainPage/MainPage"),
    element: MainPage,
    isHeaderFull: false,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/blog/",
    tag: "blog",
    type: "app",
    component: () => import("pages/AppPages/BlogPage/BlogPage"),
    element: BlogPage,
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/blog/:alias/",
    tag: "bloginner",
    type: "app",
    component: () => import("pages/AppPages/BlogInnerPage/BlogInnerPage"),
    element: BlogInnerPage,
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/addsite/",
    tag: "addsite",
    type: "app",
    component: () => import("pages/AppPages/AddsitePage/AddsitePage"),
    element: AddsitePage,
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/proxy/comparison/:uuid/",
    tag: "comparison",
    type: "app",
    component: () => import("pages/AppPages/ComparisonPage/ComparisonPage"),
    element: ComparisonPage,
    isHeaderFull: true,
    isAuthRequired: false
  },
  {
    path: "/:lang?/u3e83e8yr47233e0ur47y883/test",
    tag: "home",
    type: "app",
    component: () => import("pages/AppPages/Test/Test"),
    element: TestPage,
    isHeaderFull: false,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/u3e83e8yr47233e0ur47y883/test/errorBoundary",
    tag: "home",
    type: "app",
    component: () => import("pages/AppPages/Test/ErrorBoundary/ErrorBoundary"),
    element: TestErrorBoundary,
    isHeaderFull: false,
    isAuthRequired: false,
    isSsr: false
  }
];

import PT from "prop-types";

import { StyledChip } from "./Chip.styled";

const Chip = ({ label, className, color, style }) => (
  <StyledChip style={style} color={color} className={className}>
    <div>{label}</div>
  </StyledChip>
);

Chip.propTypes = {
  className: PT.string,
  color: PT.string,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  style: PT.shape({})
};

Chip.defaultProps = {
  className: "",
  color: "success",
  style: {}
};

export default Chip;

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SubscribePromoModal } from "components/common/New/Modals/SubscribePromoModal/SubscribePromoModal";
import { Promocode } from "components/common/New/Promocodes/Promocode";
import { Loader } from "components/ui/Loader/Loader";
import Pagination from "components/ui/New/Pagination/Pagination";
import TextButton from "components/ui/New/TextButton/TextButton";

import { getAllPromocodes } from "store/reducers/PromocodesReducer/Promocodes.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import icon from "assets/img/proxyPage/promocode-icon.svg";

import { TabTitle } from "../TabTitle/TabTitle";

import styles from "./Promocodes.module.scss";

export const Promocodes = ({ active }) => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { promocodes } = useSelector(getAllProxySite);
  const { alternatePromocodes } = useSelector(getAllPromocodes);
  const [showedPromocodes, setShowedPromocodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 10
  });

  const triggerClose = () => setOpen((prev) => !prev);

  const currentPromocodes = () => {
    if (promocodes.data?.length > 0) {
      return promocodes.data;
    }
    if (
      !promocodes.isLoading &&
      promocodes.data?.length === 0 &&
      alternatePromocodes.data?.length > 0
    ) {
      return alternatePromocodes.data;
    }
    return [];
  };

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const handleSizeChange = () => {
    setParams({ ...params, size: params.size + 10 });
  };
  const promocodesList = currentPromocodes();
  useEffect(() => {
    if (promocodesList.length > 0) {
      const form = params.size * (params.page - 1);
      const to = form + params.size;
      const promo = promocodesList.slice(form, to);
      setShowedPromocodes(promo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page, params?.size, promocodes.data, alternatePromocodes.data]);

  return (
    <div className={`${styles.wrapper} ${!active ? styles.wrapper_hide : ""}`}>
      <div className={styles.head}>
        <TabTitle
          className={styles.head_title}
          icon={icon}
          title={t("proxySitePage.about.promocodes.title")}
        />
        <TextButton
          color={"secondary"}
          iconLeft={"mail"}
          className={styles.head_btn}
          onClick={triggerClose}
        >
          {t("proxySitePage.about.promocodes.subscribe")}
        </TextButton>
        <SubscribePromoModal open={open} onClose={triggerClose} />
      </div>
      {showedPromocodes?.length > 0 ? (
        <>
          <ul className={styles.list}>
            {showedPromocodes?.map((promocode) => {
              const { showIn } = promocode;
              const showInKeys = Object.keys(showIn);

              if (showInKeys.length > 0) {
                if (showIn[queryLang]) {
                  return (
                    <li key={promocode.id} className={styles.list_item}>
                      <Promocode
                        variant={"light"}
                        promocode={promocode?.promocode}
                        description={promocode?.description?.[queryLang]}
                        title={promocode?.siteName}
                        image={promocode?.imageUrl}
                        time={promocode?.endTime}
                        eternal={promocode?.eternal}
                      />
                    </li>
                  );
                } else {
                  return;
                }
              }

              return (
                <li key={promocode.id} className={styles.list_item}>
                  <Promocode
                    variant={"light"}
                    promocode={promocode?.promocode}
                    description={promocode?.description?.[queryLang]}
                    title={promocode?.siteName}
                    image={promocode?.imageUrl}
                    time={promocode?.endTime}
                    eternal={promocode?.eternal}
                  />
                </li>
              );
            })}
          </ul>
          {promocodes.data?.length > params.size * params.page && (
            <TextButton
              className={styles.show_more}
              onClick={handleSizeChange}
              iconRight={"refresh"}
            >
              {t("reviews.showMore")}
            </TextButton>
          )}
          {promocodesList.length > 0 && promocodesList.length > params.size && (
            <Pagination
              className={styles.pagination}
              rowCount={promocodes.data?.length}
              rowsPerPage={params.size}
              page={params.page}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              onChangePage={handlePageChange}
            />
          )}
        </>
      ) : (
        <p>{t("proxySitePage.content.promocodes.noData")}</p>
      )}
      {promocodes.isLoading && <Loader type="blur" />}
    </div>
  );
};

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Card from "components/ui/New/Card/Card";
import Tabs from "components/ui/New/Tabs/Tabs";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import { BaseInfo } from "./BaseInfo/BaseInfo";
import { Contacts } from "./Contacts/Contacts";
import { Informations } from "./Informations/Informations";
import { SocialMedia } from "./SocialMedia/SocialMedia";

import styles from "./Info.module.scss";

export const Info = () => {
  const { t } = useTranslation();
  const [lang] = useLangUrlDefault();
  const [show, setShow] = useState("info");
  const { site } = useSelector(getAllProxySite);
  const { socials } = useSelector(getAllContent);

  const [media, setMedia] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [tabs, setTabs] = useState([
    {
      value: "info",
      label: t("proxySitePage.info.tabs.info")
    }
  ]);

  useEffect(() => {
    const media = site.data?.newSocialNetworks?.filter(
      (it) => it?.code !== "email"
    );
    const contacts = site.data?.newSocialNetworks?.filter(
      (it) => it?.code === "email"
    );
    if (contacts) {
      setContacts(contacts);
    }
    if (media?.length) {
      setMedia(media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site.data?.socialNetworks, socials.data]);

  useEffect(() => {
    const newTabs = [
      {
        value: "info",
        label: t("proxySitePage.info.tabs.info")
      }
    ];
    if (media?.length) {
      newTabs.push({
        value: "social",
        label: t("proxySitePage.info.tabs.social")
      });
    }
    if (contacts?.length) {
      newTabs.push({
        value: "contacts",
        label: t("proxySitePage.info.tabs.contacts")
      });
    }
    setTabs(newTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, media, contacts]);

  return (
    <Card shadow className={styles.card} radius={"small"} size={"small"}>
      <BaseInfo />
      <Tabs
        content={tabs}
        value={show}
        onChange={setShow}
        withoutPanel
        className={styles.tabs}
      />

      <Informations active={show === "info"} />
      {media?.length ? (
        <SocialMedia active={show === "social"} data={media} />
      ) : null}
      {contacts?.length ? (
        <Contacts active={show === "contacts"} data={contacts} />
      ) : null}
    </Card>
  );
};

import { Suspense, useEffect } from "react";

import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { Footer, Header, MobileNav, SeoSection } from "../components/common";
import { Loader } from "../components/ui/Loader/Loader";
import {
  useLangUrlDefault,
  useMatchedRoute,
  useMetaData,
  useRedirect
} from "../hooks";
import { MetaPage } from "../pages/MetaPage/MetaPage";
import { getAllUser } from "../store/reducers/UserReducer/User.selectors";
import { getPathname } from "../utils/helpers";

const BLOG_INNER_TAG = "bloginner";

export const IndexLayout = () => {
  useRedirect();
  useMetaData();
  const { pathname } = useLocation();

  // **Props
  const route = useMatchedRoute();
  const navigate = useNavigate();
  const [queryLang, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { isUserAuthenticated, isAuthfetched } = useSelector(getAllUser);
  const { content, blog, translation } = useSelector((state) => state);

  // Check auth
  useEffect(() => {
    if (!isUserAuthenticated && route?.isAuthRequired && isAuthfetched) {
      navigate(`${hrefLang}/login/?pathname=${getPathname(pathname)}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, route?.isAuthRequired, isAuthfetched]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [route?.path]);

  // Handling error
  useEffect(() => {
    if (
      blog.content.isError ||
      content.pageContent.isError ||
      content.countries.isError ||
      content.goalsByGroups.isError ||
      content.sellers.isError ||
      content.reviews.isError ||
      content.goals.isError ||
      content.topSites.isError
    ) {
      toast.error(translation.translations[queryLang].notifications.apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blog.content.isError,
    content.pageContent.isError,
    content.countries.isError,
    content.goalsByGroups.isError,
    content.sellers.isError,
    content.reviews.isError,
    content.goals.isError,
    content.topSites.isError
  ]);

  return (
    <>
      <MetaPage />
      <div className={"wrapper"}>
        {!isAuthfetched && route?.type === "cabinet" ? (
          <Loader />
        ) : (
          <>
            {route?.type !== "auth" && (
              <Suspense fallback={<Loader />}>
                <Header />
              </Suspense>
            )}
            <main>
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
              {route?.tag !== BLOG_INNER_TAG && (
                <Suspense fallback={<Loader />}>
                  <SeoSection />
                </Suspense>
              )}
            </main>
            {route?.type !== "auth" && (
              <Suspense fallback={<Loader />}>
                <Footer />
              </Suspense>
            )}
          </>
        )}
      </div>
      <div className="lgMax">
        <MobileNav />
      </div>
      <ToastContainer
        closeOnClick={false}
        hideProgressBar
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable
        limit={3}
      />
    </>
  );
};

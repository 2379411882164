import { Navigate } from "react-router-dom";

import {
  AppLayout,
  CabinetLayout,
  AuthLayout as OldAuthLayout,
  SidebarLayout
} from "layout";
import { ExportLayout } from "layout/export.layout";
import { IndexLayout as OldIndexLayout } from "layout/index.layout";
import { AuthLayout } from "layout/new/AuthLayout";
import { DashboardLayout } from "layout/new/DashbordLayout/DashboardLayout";
import { IndexLayout } from "layout/new/index.layout";
import { NewAppLayout } from "layout/new/new.app.layout";

import { AppRoutes } from "./new/app.routes";
import { AuthRoutes } from "./new/auth.routes";
import { DashboardRoutes } from "./new/dashboard.routes";
import { ExportRoutes } from "./new/export.routes";
import { ToolsRoutes } from "./new/tools.routes";
import { AppRoutesOLD } from "./old/app.routes";
import { AuthRoutesOLD } from "./old/auth.routes";
import { CabinetRoutesOLD } from "./old/cabinet.routes";
import { SidebarRoutesOLD } from "./old/sidebar.routes";

const convertRoutes = (routes) =>
  routes.map((route) => {
    // eslint-disable-next-line no-unused-vars
    const { component, element, ...rest } = route;
    return {
      ...rest,
      async lazy() {
        const module = await component();
        return { Component: module.default };
      }
    };
  });

export const NEW_ROUTES = [
  {
    element: <OldIndexLayout />,
    children: [
      {
        element: <AppLayout />,
        children: convertRoutes(AppRoutesOLD)
      },
      {
        element: <SidebarLayout />,
        children: convertRoutes(SidebarRoutesOLD)
      },
      {
        element: <OldAuthLayout />,
        children: convertRoutes(AuthRoutesOLD)
      },
      {
        element: <CabinetLayout />,
        children: convertRoutes(CabinetRoutesOLD)
      }
    ]
  },
  {
    element: <IndexLayout />,
    children: [
      { element: <NewAppLayout />, children: convertRoutes(AppRoutes) },
      { element: <NewAppLayout />, children: convertRoutes(ToolsRoutes) },
      {
        loader: () => ({ isAuthRequired: true }),
        element: <DashboardLayout />,
        children: convertRoutes(DashboardRoutes)
      },
      { element: <AuthLayout />, children: convertRoutes(AuthRoutes) }
    ]
  },
  {
    element: <ExportLayout />,
    children: convertRoutes(ExportRoutes)
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />
  }
];

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

export const ProxyType = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);

  const proxyTypesForLang = proxyTypes.data?.[queryLang];
  const isProxyTypesForLangNotEmpty =
    proxyTypesForLang && proxyTypesForLang.length > 0;

  return (
    <li className="header__bottom-item">
      <Link to={`${hrefLang}/proxy/?fpt=IPv4`}>
        <img
          src="/img/proxyItems/proxy-type.svg"
          loading="lazy"
          width="14"
          height="14"
          alt={t("header.bottom.type.title")}
        />
        {t("header.bottom.type.title")}
      </Link>
      <div className="header__dropdown">
        <div className="header__dropdown-column">
          <ul className="header__dropdown-list header__dropdown-list--type-columns">
            {isProxyTypesForLangNotEmpty &&
              proxyTypesForLang?.map(({ type, name }) => (
                <li key={type} className="header__dropdown-list-item">
                  <Link to={`${hrefLang}/proxy/?fpt=${type}`}>
                    <span>{name}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </li>
  );
};

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const COLORS = {
  default: {
    default: "neutral-500",
    hover: "neutral-600",
    active: "neutral-600"
  },
  primary: {
    default: "main-700",
    hover: "main-700",
    active: "text-button-main-active"
  },
  secondary: {
    default: "neutral-500",
    hover: "neutral-800",
    active: "neutral-800"
  },
  error: {
    default: "red-500",
    hover: "red-500",
    active: "red-500"
  },
  table: {
    default: "neutral-500",
    hover: "main-700",
    active: "main-700"
  }
};

export const StyledTextButton = styled.button`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  cursor: pointer;
  font-weight: 400;
  font-size: ${(p) =>
    p.size === "sm" ? p.theme.fontSizes.s : p.theme.fontSizes.m};
  line-height: ${(p) => (p.size === "sm" ? "142%" : "150%")};
  color: ${(p) => p.theme.colors[COLORS[p.color].default]};
  transition: all 0.3s ease;

  & > span {
    &::after {
      content: "";
      position: relative;
      top: -1px;
      width: 0px ${(p) => (p.disabled ? "!important" : "")};
      height: 1px;
      display: block;
      background: ${(p) => p.theme.colors[COLORS[p.color].default]};
      transition: all 0.3s ease;
    }
  }

  &:hover,
  &:focus {
    font-weight: 600;
    color: ${(p) => p.theme.colors[COLORS[p.color].hover]};
    transition: all 0.3s ease;

    & > span {
      &::after {
        width: 100%;
        background: ${(p) => p.theme.colors[COLORS[p.color].hover]};
        transition: all 0.3s ease;
      }
    }
  }

  &:active {
    color: ${(p) => p.theme.colors[COLORS[p.color].active]};
    transition: all 0.3s ease;

    & > span {
      &::after {
        width: 100%;
        background: ${(p) => p.theme.colors[COLORS[p.color].active]};
        transition: all 0.3s ease;
      }
    }
  }

  &:disabled {
    color: ${(p) =>
      p.color === "error"
        ? "#f6a7a8"
        : p.theme.colors["neutral-300"]} !important;
    font-weight: 400 !important;
    cursor: not-allowed;
  }

  & > svg {
    width: ${(p) => (p.size === "sm" ? "20px" : "24px")};
    height: ${(p) => (p.size === "sm" ? "20px" : "24px")};
  }
`;

export const StyledTextLink = styled(Link)`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  cursor: pointer;
  font-weight: 400;
  font-size: ${(p) =>
    p.size === "sm" ? p.theme.fontSizes.s : p.theme.fontSizes.m};
  line-height: ${(p) => (p.size === "sm" ? "142%" : "150%")};
  color: ${(p) => p.theme.colors[COLORS[p.color].default]};
  transition: all 0.3s ease;

  & > span {
    &::after {
      content: "";
      position: relative;
      top: -1px;
      width: 0px ${(p) => (p.disabled ? "!important" : "")};
      height: 1px;
      display: block;
      background: ${(p) => p.theme.colors[COLORS[p.color].default]};
      transition: all 0.3s ease;
    }
  }

  &:hover,
  &:focus {
    font-weight: 600;
    color: ${(p) => p.theme.colors[COLORS[p.color].hover]};
    transition: all 0.3s ease;

    & > span {
      &::after {
        width: 100%;
        background: ${(p) => p.theme.colors[COLORS[p.color].hover]};
        transition: all 0.3s ease;
      }
    }
  }

  &:active {
    color: ${(p) => p.theme.colors[COLORS[p.color].active]};
    transition: all 0.3s ease;
    & > span {
      &::after {
        width: 100%;
        background: ${(p) => p.theme.colors[COLORS[p.color].active]};
        transition: all 0.3s ease;
      }
    }
  }

  ${(p) =>
    p.disabled
      ? `
    color: ${(p) =>
      p.color === "error"
        ? "#f6a7a8"
        : p.theme.colors["neutral-300"]} !important;
    font-weight: 400 !important;
    cursor: not-allowed;
    `
      : ""}

  & > svg {
    width: ${(p) => (p.size === "sm" ? "20px" : "24px")};
    height: ${(p) => (p.size === "sm" ? "20px" : "24px")};
  }
`;

import { useCallback, useEffect, useRef } from "react";

import PT from "prop-types";
import { CSSTransition } from "react-transition-group";

import styles from "./Overlay.module.scss";

export const Overlay = ({ isOpened, setIsOpened, children }) => {
  // **Ref
  const menuRef = useRef(null);

  const handleOverlayClick = useCallback(
    (event) => {
      if (event.target.className === styles.overlay__active) {
        setIsOpened(false);
      }
    },
    [setIsOpened]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (isOpened && event.key === "Escape") {
        setIsOpened(false);
        event.preventDefault();
      }
    },
    [isOpened, setIsOpened]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  // Lock scroll
  useEffect(() => {
    if (isOpened) {
      // eslint-disable-next-line no-undef
      document.body.classList.add("lock-new");
    } else {
      // eslint-disable-next-line no-undef
      document.body.classList.remove("lock-new");
    }

    return () => document.body.classList.remove("lock-new");
  }, [isOpened]);

  return (
    <CSSTransition
      in={isOpened}
      timeout={500}
      classNames={{
        enter: styles.overlay,
        enterActive: styles.overlay__active,
        enterDone: styles.overlay__active,
        exit: styles.overlay__exit,
        exitActive: styles.overlay__exit__active,
        exitDone: styles.overlay
      }}
      unmountOnExit
    >
      <div onClick={handleOverlayClick}>
        <div ref={menuRef} className={styles.content}>
          <div className={styles.children}>{children}</div>
        </div>
      </div>
    </CSSTransition>
  );
};

Overlay.propTypes = {
  children: PT.node.isRequired
};

// import { useState } from "react";
import { useTranslation } from "react-i18next";

// import { toast } from "react-toastify";
import Button from "components/ui/New/Button/Button";
import Modal from "components/ui/New/Modal/Modal";

import styles from "./ModalConfirm.module.scss";

export const ModalConfirm = ({
  open,
  close,
  isDeleting,
  srcX1,
  srcX2,
  subtitle,
  onConfirm,
  classNamePicture
}) => {
  const { t } = useTranslation();

  const cancelHandler = (value) => {
    close?.(value);
  };

  const confirmHandler = () => onConfirm?.();

  return (
    <Modal
      size={"sm"}
      open={open}
      setOpen={close}
      className={styles.modal}
      classNameBody={styles.modal_body}
    >
      <picture className={`${styles.modal_picture} ${classNamePicture}`}>
        <source srcSet={`${srcX1} 1x, ${srcX2} 2x`} type="image/png" />
        <img src={srcX1} alt={"image delete"} />
      </picture>
      <p className={styles.modal_subtitle}>{subtitle}</p>
      <div className={styles.modal_buttons}>
        <Button
          variant={"danger"}
          onClick={confirmHandler}
          fullWidth
          loading={isDeleting}
        >
          {t("forms.buttons.delete")}
        </Button>
        <Button
          variant={"secondary"}
          onClick={() => cancelHandler(false)}
          fullWidth
          loading={isDeleting}
        >
          {t("forms.buttons.cancel")}
        </Button>
      </div>
    </Modal>
  );
};

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CardInfo } from "pages/New/Dashboard/components/CardInfo/CardInfo";

import { Input } from "components/forms/New";
import { FormSelect } from "components/forms/New/FormSelect/FormSelect";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import { transformMinRent } from "utils/helpers";

import { Countries } from "./components/Countries/Countries";

// eslint-disable-next-line import/order
import styles from "../BlockType.module.scss";

export const ProxyTypeInfo = ({ data, showIcon }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { rentPeriods } = useSelector(getAllContent);

  const { setValue } = useFormContext();
  const prefix = `proxy.${data.type}.`;

  const rents = transformMinRent(
    rentPeriods?.data,
    queryLang,
    t("times", { returnObjects: true })
  );

  const handleCloseCardClick = () => {
    setValue(`proxyTypes.${data.type}`, false);
  };

  return (
    <CardInfo
      title={data?.name}
      showIcon={showIcon}
      onClose={handleCloseCardClick}
    >
      <Input name={`${prefix}link`} label={"Link"} showError />
      <div className={styles.info_row}>
        <FormSelect
          name={`${prefix}minRent`}
          label={t("forms.minRent.label")}
          options={rents}
          showError
        />
        <Input
          name={`${prefix}minPrice`}
          type="number"
          label={t("forms.minPrice.label")}
          placeholder={"$"}
          showError
        />
      </div>
      {data?.type === "ResidentialProxy" ? (
        <div className={styles.info_row}>
          <Input
            name={`${prefix}pullIP`}
            label={t("forms.pullIP.label")}
            showError
          />
          <Input
            name={`${prefix}minGb`}
            type={"number"}
            label={t("forms.minGB.label")}
            showError
          />
        </div>
      ) : (
        <Countries prefix={prefix} type={data.type} />
      )}
    </CardInfo>
  );
};

// import { useEffect, startTransition } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute
} from "../../../hooks";
import { getAllBlogInner } from "../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { getAllTranslation } from "../../../store/reducers/TranslationReducer/Translation.selectors";
import { setCookie } from "../../../utils/helpers/cookie.helpers";

import "./Languages.scss";

const BLOG_INNER_TAG = "bloginner";

export const Languages = ({ margin, title }) => {
  // **Dispatch
  const { setCurrentLang } = useDispatchedActions();

  const [queryLang] = useLangUrlDefault();
  const location = useLocation();
  const navigate = useNavigate();
  const route = useMatchedRoute();

  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { t } = useTranslation();
  const { routeContent } = useSelector(getAllContent);
  const { content: BlogInner } = useSelector(getAllBlogInner);

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    setCookie("lang", newLang);
    const pathnameSplit = location.pathname.split("/");
    const isNotLangUrl = pathnameSplit[1].length > 2;
    const rootEn = newLang === "en" ? "" : `/${newLang}`;

    setCurrentLang(newLang);

    if (
      routeContent.innerTag === BLOG_INNER_TAG ||
      route?.tag === BLOG_INNER_TAG
    ) {
      const { langLinks } = BlogInner.data;
      const isLangForBlog = langLinks.hasOwnProperty(newLang);

      if (isLangForBlog) {
        navigate(`${newLang}/blog/${langLinks[newLang]}/`, { replace: true });
      } else {
        toast.error(t("notifications.blogInner.notFoundBlogPost"));
        navigate(`${rootEn}/blog/?tab=articles`, { replace: true });
      }

      return;
    }

    navigate(
      {
        pathname: `${rootEn}/${location.pathname.slice(isNotLangUrl ? 1 : 4)}`,
        hash: location.hash,
        search: location.search,
        state: location.state
      },
      { replace: true }
    );
  };

  // useEffect(() => {
  //   startTransition(() => {
  //     changeLanguage(queryLang);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const defineClassname = () => {
    let className = "languages";

    switch (margin) {
      case "left": {
        className += " languages__margin--left";
        break;
      }
      default: {
        break;
      }
    }

    return className;
  };

  const normalizeLanguageCode = () => {
    const languageCode = i18n.language;
    const parts = languageCode.split("-");
    return parts[0];
  };

  return (
    <div className={defineClassname()}>
      {title && <div className="languages__title">{title}</div>}
      <div className="languages__inner">
        <div className="languages__current">{normalizeLanguageCode()}</div>
        <ul className="languages__list">
          {languages.map((language) => (
            <li
              key={language.value}
              className={
                queryLang === language.value
                  ? "languages__item languages__item--active"
                  : "languages__item"
              }
            >
              <button onClick={() => changeLanguage(language.value)}>
                {language.value}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

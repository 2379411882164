// import { useSelector } from "react-redux";
import { SectionHead } from "components/common/New/SectionHead/SectionHead";

// import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

// import { useMetaData } from "hooks";

export const ProxyContentTop = () => (
  // **Redux state
  // const { pageContent } = useSelector(getAllContent);

  // **Local state
  // const metaData = useMetaData();

  <SectionHead
    // title={metaData?.h1 || pageContent.data?.h1 || ""}
    // description={metaData?.description || pageContent.data?.description}
    title={"Rating of mobile proxy providers"}
    description={
      "More than 1368 Proxy servers in our rating. Find companies you can trust"
    }
  />
);

import { useState } from "react";

import { useSelector } from "react-redux";

import Icon from "components/ui/New/Icon/Icon";
import Search from "components/ui/New/Search/Search";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { servicesList } from "utils/constants";

import { Countries } from "../Countries/Countries";
import { Locations } from "../Locations/Locations";
import NavigationItem from "../Navigation/NavigationItem";

import styles from "./ServicesNavigation.module.scss";

export const ServicesNavigation = () => {
  const [search, setSearch] = useState("");
  const [currentStep, setCurrentStep] = useState("services");
  const [isOpened, setIsOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries } = useSelector(getAllContent);

  const handleClick = (step) => {
    setCurrentStep(step);
    setIsOpened(true);
  };

  return (
    <div className={styles.services_navigation}>
      {currentStep === "services" && (
        <NavigationItem
          title="services"
          mobile
          list={servicesList}
          handleServiceClick={() => handleClick("locations")}
          onClick={() => setIsOpened(!isOpened)}
          isOpened={isOpened}
        />
      )}
      {currentStep === "locations" && (
        <>
          {isOpened && (
            <div
              className={styles.button}
              onClick={() => handleClick("services")}
            >
              <Icon name="arrowAltRight" className={styles.icon} />
              <span>Select service</span>
            </div>
          )}

          <NavigationItem
            title="locations"
            isOpened={isOpened}
            onClick={() => setIsOpened(!isOpened)}
            mobile
          >
            <Search
              value={search}
              onChange={setSearch}
              variant="outlined-dark"
              className={styles.search}
            />
            {search ? (
              <Countries countries={countries} search={search} selectedLocation={selectedLocation} />
            ) : (
              <Locations
                selectedLocation={selectedLocation}
                onClick={setSelectedLocation}
                className={styles.locations__list}
                setCurrentStep={handleClick}
              />
            )}
          </NavigationItem>
        </>
      )}
      {currentStep === "countries" && (
        <>
          {isOpened && (
            <div
              className={styles.button}
              onClick={() => setCurrentStep("locations")}
            >
              <Icon name="arrowAltRight" className={styles.icon} />
              <span>Locations</span>
            </div>
          )}
          <NavigationItem
            title={selectedLocation}
            isOpened={isOpened}
            onClick={() => setIsOpened(!isOpened)}
            mobile
          >
            <Countries countries={countries} search={search} selectedLocation={selectedLocation} />
          </NavigationItem>
        </>
      )}
    </div>
  );
};

import { useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "components/forms/New";
import FormError from "components/forms/New/FormError/FormError";
import Button from "components/ui/New/Button/Button";

import styles from "./BlockContacts.module.scss";

export const Contact = ({ name, label }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  // const value = watch(name);
  const value = watch(`socialNetworks.${name}`);
  const { errors } = useFormState();

  const [edit, setEdit] = useState(false);
  const [oldValue, setOldValue] = useState(value || "");

  const handleSave = () => {
    setEdit(false);
  };

  const handleCancel = () => {
    setValue(`socialNetworks.${name}`, oldValue);
    setEdit(false);
  };

  const handleEdit = () => {
    setOldValue(value);
    setEdit(true);
  };

  return (
    <>
      <div className={styles.contact}>
        <div className={styles.contact_label}>{label}</div>
        {!edit && (
          <>
            {value && <div className={styles.contact_data}>{value}</div>}
            <Button
              variant={"secondary"}
              iconLeft={value ? "editV2" : "plus"}
              className={styles.contact_edit}
              onClick={handleEdit}
            >
              {value ? t("forms.buttons.edit") : t("forms.buttons.add")}
            </Button>
          </>
        )}
        <div
          className={`${styles.contact_editor} ${
            !edit ? styles.contact_editor_hidden : ""
          }`}
        >
          <Input
            name={`socialNetworks.${name}`}
            className={styles.contact_editor_input}
            showError
          />
          <div className={styles.contact_editor_actions}>
            <Button className={styles.contact_editor_save} onClick={handleSave}>
              {t("forms.buttons.save")}
            </Button>
            <Button
              variant={"secondary"}
              className={styles.contact_editor_cancel}
              onClick={handleCancel}
            >
              {t("forms.buttons.cancel")}
            </Button>
          </div>
        </div>
      </div>
      {!edit && errors?.socialNetworks?.[name]?.message && (
        <FormError message={errors?.socialNetworks?.[name]?.message} />
      )}
    </>
  );
};

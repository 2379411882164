import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Chip from "components/ui/New/Chip/Chip";
import Icon from "components/ui/New/Icon/Icon";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import styles from "./Navigation.module.scss";

const NavigationItem = ({
  title,
  list,
  children,
  isOpened,
  onClick,
  className,
  mobile,
  handleServiceClick
}) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const location = useLocation();

  // **Dispatch
  const { setBurgerMenu, setServiceMenu } = useDispatchedActions();

  const closeMenu = () => {
    setBurgerMenu(false);
    setServiceMenu(false);
  };

  const listMemo = (
    <ul className={isOpened ? styles.list__open : styles.list} id={title}>
      {list?.map((el) => (
        <li key={el.translation} className={styles.list__item}>
          <Link
            onClick={handleServiceClick ? handleServiceClick : closeMenu}
            to={
              el.route.includes("?")
                ? `${hrefLang}/new/${el.route}`
                : `${hrefLang}/new/${el.route}/`
            }
            className={
              location?.pathname === `${hrefLang}/new/${el.route}/`
                ? `${styles.list__link} ${styles.list__active}`
                : styles.list__link
            }
          >
            <span className={styles.list__title}>
              {title === "resources"
                ? t(`navigation.${el.translation}`)
                : t(`navigation.${el.translation}.title`)}
              {el.chip && <Chip label={el.chip} className={styles.chip} />}
            </span>
            {el.description && (
              <span className={styles.list__description}>
                {t(`navigation.${el.translation}.description`)}
              </span>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={`${isOpened ? styles.navigation__item__open : styles.navigation__item} ${className}`}>
      <div
        className={
          isOpened ? styles.title__wrapper_open : styles.title__wrapper
        }
        {...(mobile && { onClick })}
      >
        <div className={isOpened ? styles.title__open : styles.title}>
          {t(`navigation.${title}`)}
        </div>
        <Icon
          name={isOpened ? "arrowUp" : "arrowDown"}
          className={isOpened ? styles.icon__open : styles.icon}
        />
      </div>
      {children ? (
        <div className={isOpened ? styles.children__wrapper__open : styles.children__wrapper}>{children}</div>
      ) : (
        listMemo
      )}
    </div>
  );
};

NavigationItem.propTypes = {
  title: PT.string.isRequired,
  list: PT.arrayOf(PT.shape({})),
  children: PT.node,
  isOpened: PT.bool,
  onClick: PT.func,
  className: PT.string
};

NavigationItem.defaultProps = {
  list: [],
  className: ""
};

export default NavigationItem;

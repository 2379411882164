import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import { useLangUrlDefault } from "hooks";

import { Tooltip } from "../../../../ui/New/Tooltip/Tooltip";
import { SubscribePromoModal } from "../../Modals/SubscribePromoModal/SubscribePromoModal";

import {
  StyledPromoButton,
  StyledPromoButtonsBox,
  StyledPromoCode,
  StyledPromoCodeBox,
  StyledPromoCopy
} from "./PromoButtons.styled";

export const PromoButtons = ({ promocode, variant, className }) => {
  const { t } = useTranslation();
  const customToastId = useId();
  const promoTextId = useId();
  const promoCopyId = useId();
  const ref = useRef(null);
  const refBox = useRef(null);
  const [queryLang] = useLangUrlDefault();

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth - 64);
    }
  }, [ref, show]);

  const showCode = () => {
    // event.stopPropagation();
    setShow(!show);
  };

  const triggerClose = () => setOpen((prev) => !prev);

  const onCopyHandler = () => {
    // event.stopPropagation();
    setShow(false);
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <StyledPromoButtonsBox className={className} variant={variant}>
      {!show && (
        <StyledPromoButton
          variant={variant}
          lang={queryLang}
          onClick={showCode}
        >
          {t("promocodes.showCode")}
          <IconsSVG name={"ticket"} />
        </StyledPromoButton>
      )}
      {show && (
        <StyledPromoCodeBox variant={variant} ref={refBox}>
          <StyledPromoCode data-tooltip-id={promoTextId} ref={ref}>
            {promocode}
          </StyledPromoCode>
          {needTooltip ? (
            <StyledTooltip id={promoTextId} place={"top"}>
              {promocode}
            </StyledTooltip>
          ) : null}
          <CopyToClipboard text={promocode} onCopy={onCopyHandler}>
            <StyledPromoCopy data-tooltip-id={promoCopyId}>
              <IconsSVG name="copy" />
            </StyledPromoCopy>
          </CopyToClipboard>
          <StyledTooltip id={promoCopyId} place={"top"}>
            {t("promocodes.copy")}
          </StyledTooltip>
        </StyledPromoCodeBox>
      )}
      {variant !== "light" && (
        <Tooltip body={t("promocodes.subscribe")} place={"top-start"}>
          <StyledPromoButton icon variant={variant} onClick={triggerClose}>
            <IconsSVG name={"mail"} />
          </StyledPromoButton>
        </Tooltip>
      )}
      <SubscribePromoModal open={open} onClose={triggerClose} />
    </StyledPromoButtonsBox>
  );
};

PromoButtons.propTypes = {
  promocode: PT.string.isRequired,
  className: PT.string,
  variant: PT.oneOf(["dark", "gray", "light", "table"])
};

PromoButtons.defaultProps = {
  promocode: "",
  className: "",
  variant: "dark"
};

export default PromoButtons;

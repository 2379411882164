import { useTranslation } from "react-i18next";

import { Uploader } from "components/common/New/Uploader/Uploader";
import { Input } from "components/forms/New";
import { FormSelect } from "components/forms/New/FormSelect/FormSelect";
import Button from "components/ui/New/Button/Button";

import { serviceSelectOptions } from "../../../../../../utils/constants";
import { CardInfo } from "../../../components/CardInfo/CardInfo";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import styles from "./BlockLogo.module.scss";

export const BlockLogo = ({
  setFiles,
  croppedFileObjects,
  setCroppedFileObjects,
  defaultImage
}) => {
  const { t } = useTranslation();

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.logo.title")}
      subtitle={t("dashboard.services.addEdit.blocks.logo.subtitle")}
      bodyClassName={styles.formBlock_body}
    >
      <Uploader
        onUpload={setFiles}
        defaultImage={defaultImage}
        croppedFileObjects={croppedFileObjects}
        setCroppedFileObjects={setCroppedFileObjects}
        className={styles.uploader}
      />

      <div className={styles.formBlock_row}>
        <Input
          name={"link"}
          type={"text"}
          label={t("forms.link.label")}
          placeholder={t("forms.link.placeholder")}
          rules={{
            required: t("forms.link.rules.required")
          }}
          showError
        />

        <FormSelect
          name={"category"}
          options={serviceSelectOptions}
          label={t("forms.linkCategory.label")}
          placeholder={t("forms.linkCategory.placeholder")}
          defaultValue={"1"}
        />
      </div>

      <CardInfo title={"Instruction"}>
        <p className={styles.instruction_text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>

        <div className={styles.instruction_buttons}>
          <Button size={"md"} className={styles.instruction_check}>
            {t("dashboard.services.addEdit.blocks.logo.check")}
          </Button>

          <Button
            size={"md"}
            variant={"outlined"}
            className={styles.instruction_download}
          >
            {t("dashboard.services.addEdit.blocks.logo.download")}
          </Button>
        </div>
      </CardInfo>
    </FormBlock>
  );
};

import { Link } from "react-router-dom";

import { useLangUrlDefault } from "hooks";

export const BreadcrumbsItem = ({ item }) => {
  const [, hrefLang] = useLangUrlDefault();

  return (
    <li>
      {item.route ? (
        <>
        <Link to={`${hrefLang}/new/${item.route}`}>{item.title}</Link>
        <span>/</span>
        </>
      ) : (
        item.title
      )}
    </li>
  );
};

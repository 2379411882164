import { Switch as BaseSwitch } from "@mui/base/Switch";
import PT from "prop-types";

import styles from "./Switch.module.scss";

export const Switch = ({
  checked,
  onChange,
  disabled,
  defaultChecked,
  label,
  className,
  style
}) => (
  <label className={`${styles.wrapper} ${className}`} style={style}>
    <BaseSwitch
      className={styles.root}
      onChange={(event) => onChange(event.target.checked)}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      slotProps={{
        input: {
          "aria-label": "switch"
        }
      }}
    />
    {label && <span className={styles.label}> {label} </span>}
  </label>
);

Switch.propTypes = {
  checked: PT.bool,
  onChange: PT.func,
  disabled: PT.bool,
  defaultChecked: PT.bool,
  label: PT.oneOfType([PT.string, PT.node]),
  className: PT.string,
  style: PT.shape({})
};

Switch.defaultProps = {
  // checked: null,
  onChange: () => null,
  disabled: false,
  // defaultChecked: null,
  label: null,
  className: "",
  style: {}
};

export default Switch;

import { useTranslation } from "react-i18next";

import { Loader } from "components/ui";
import Modal from "components/ui/New/Modal/Modal";

export const ModalEmailCofigmed = ({
  open,
  onClose,
  isConfirmed,
  isInProcess
}) => {
  // **Redux state
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      setOpen={onClose}
      title={t("modals.email.title")}
      maxWidth={500}
    >
      {isInProcess ? (
        <Loader />
      ) : (
        <p style={{ textAlign: "center" }}>
          {isConfirmed
            ? t("modals.email.confirmed")
            : t("modals.email.notConfirmed")}
        </p>
      )}
    </Modal>
  );
};

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { getAllUser } from "../../../store/reducers/UserReducer/User.selectors";

import "./CabinetTabs.scss";

export const CabinetTabs = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // **Redux state
  const { t } = useTranslation();
  const { isUserAuthenticated } = useSelector(getAllUser);
  // **Dispatch
  const { setDefaultUser, setAuthentication, setMobileMenu, resetCabinet } =
    useDispatchedActions();

  const isCabinetRoute = /cabinet/.test(pathname) && isUserAuthenticated;

  const closeMenu = () => {
    setMobileMenu(false);
  };

  const logout = async () => {
    try {
      const response = await ApiService.logout();

      if (response && response.status !== 200) {
        throw response;
      }

      setDefaultUser();
      resetCabinet();
      setAuthentication(false);

      navigate(`${hrefLang}/`);
      closeMenu();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response || err?.response || err);
    }
  };

  return (
    <ul className="cabinet-tabs__list">
      <li className="cabinet-tabs__item">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "cabinet-tabs__link cabinet-tabs__link--active"
              : "cabinet-tabs__link"
          }
          to={`${hrefLang}/cabinet/proxy/`}
        >
          {t("cabinetPages.tabs.proxy")}
        </NavLink>
      </li>

      <li className="cabinet-tabs__item">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "cabinet-tabs__link cabinet-tabs__link--active"
              : "cabinet-tabs__link"
          }
          to={`${hrefLang}/cabinet/promocodes/`}
        >
          {t("cabinetPages.tabs.promocodes")}
        </NavLink>
      </li>

      {isCabinetRoute && (
        <li className="cabinet-tabs__item">
          <button className="cabinet-tabs__link" onClick={logout}>
            {t("cabinetPages.tabs.exit")}
          </button>
          {/* <NavLink
            className={({ isActive }) =>
              isActive
                ? "cabinet-tabs__link cabinet-tabs__link--active"
                : "cabinet-tabs__link"
            }
            // to={`${hrefLang}/`}
            onClick={logout}
          >
            {t("cabinetPages.tabs.exit")}
          </NavLink> */}
        </li>
      )}
    </ul>
  );
};

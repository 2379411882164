import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Progress } from "components/common/New/Progress/Progress";
import Button from "components/ui/New/Button/Button";
import Icon from "components/ui/New/Icon/Icon";
import Status from "components/ui/New/Status/Status";
import { Title } from "components/ui/New/Title/Title";

import { ua, us } from "assets/img/flags";

import { ResultsTable } from "./ResultTable/ResultTable";

import styles from "./DnsLeakTest.module.scss";

const mockResult = {
  status: "VULNERABLE",
  ipAddress: "155.15.46.225",
  flag: ua,
  securityReport: "Security report 2024-01-06 12:45:59",
  tableData: [
    {
      ip: "157.35.306.44",
      provider: "Google LLC",
      flag: us,
      country: "United States"
    },
    {
      ip: "157.35.306.44",
      provider: "Google LLC",
      flag: ua,
      country: "Ukraine"
    },
    {
      ip: "157.35.306.44",
      provider: "Google LLC",
      flag: us,
      country: "United States"
    },
    {
      ip: "157.35.306.44",
      provider: "Google LLC",
      flag: us,
      country: "United States"
    },
    {
      ip: "157.35.306.44",
      provider: "Content Delivery Network Ltd",
      flag: ua,
      country: "Ukraine"
    },
    {
      ip: "157.35.306.44",
      provider: "Content Delivery Network Ltd",
      flag: ua,
      country: "Ukraine"
    },
    {
      ip: "157.35.306.44",
      provider: "Content Delivery Network Ltd",
      flag: ua,
      country: "Ukraine"
    },
    {
      ip: "157.35.306.44",
      provider: "Content Delivery Network Ltd",
      flag: ua,
      country: "Ukraine"
    },
    {
      ip: "157.35.306.44",
      provider: "Content Delivery Network Ltd",
      flag: ua,
      country: "Ukraine"
    }
  ]
};

export const DnsLeakTest = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setResult(mockResult);
      setIsLoading(false);
    }, 1000);
  };

  const analyzingWrapper = (className) => (
    <div className={className}>
      <Title tag="h2" className={styles.title}>
        {t("dnsLeak.analyzing")}
      </Title>
      <div className={styles.additional_text}>
        <Icon name="loading" loading={isLoading} className={styles.loading} />
        {t("dnsLeak.pleaseWait")}
      </div>
      <div className={styles.progress__wrapper}>
        <Progress isLoading={isLoading} />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.info__container}>
        <div className={styles.info}>
          <div className={styles.image__wrapper}>
          <img
            src="img/dns-leak-test/dns-leak-test-image.svg"
            alt={t("dnsLeak.main.title")}
            className={styles.image}
          />
          </div>
          <div className={styles.title__wrapper}>
            <Title tag="h2" className={styles.title}>
              {result ? t("dnsLeak.result") : t("dnsLeak.main.title")}
            </Title>
            {result ? (
              <Status
                label={result.status}
                color="warning"
                className={styles.status}
              />
            ) : null}
          </div>
          <div className={styles.info_message}>
            <img src="img/icons/information.svg" alt="Info icon" />
            <span>
              {result
                ? t("dnsLeak.notDetected")
                : t("dnsLeak.verify")}
            </span>
          </div>
          <Button
            className={styles.button}
            type="button"
            onClick={handleClick}
            fullWidth
          >
            {result ? t("dnsLeak.stopDns") : t("dnsLeak.runDns")}
          </Button>
        </div>
        {isLoading && analyzingWrapper(styles.analyzing_mobile)}
      </div>
      <div className={isLoading ? styles.results__loading : styles.results}>
        {isLoading ? (
          analyzingWrapper(styles.analyzing_desktop)
        ) : (
          <div className={styles.results__container}>
            {result ? (
              <>
                <div className={styles.ip__wrapper}>
                  <div className={styles.ip}>
                    <h2 className={styles.title}>{t("dnsLeak.yourIP")}</h2>
                    <div className={styles.flag__wrapper}>
                      <img src={result.flag} className={styles.flag} />
                      <p className={styles.ip__address}>{result.ipAddress}</p>
                    </div>
                  </div>
                  <div className={styles.security_report}>
                    <Icon name="file" className={styles.icon} />
                    {result.securityReport}
                  </div>
                </div>
                <div className={styles.table__wrapper}>
                  <ResultsTable data={result.tableData} isLoading={isLoading} />
                  <div className={styles.gradient} />
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "../../../../components/common/New/Container/Container";

import { OfferItem } from "./OfferItem/OfferItem";
import {
  StyledSpesialOfferCard,
  StyledSpesialOffersDescription,
  StyledSpesialOffersHeader,
  StyledSpesialOffersListBox,
  StyledSpesialOffersTitle
} from "./SpesialOffer.styled";

export const SpecialOffer = () => {
  const { t } = useTranslation();

  const mockData = [
    {
      title: "Proxy-seller.com",
      image:
        "/storage/rating-proxy-dev/6409f8c48ba85a1ff3221e1a_proxy-seller-logo.jpg",
      discription:
        "15% discount for IPv4, IPv6 orders. Unlimited reusable coupon code.",
      promocode: "RATINGPROXY15"
    },
    {
      title: "proxywins.com",
      image:
        "/storage/rating-proxy-dev/63c9551ee71e826461324943_WsviWBaIdfNMRcvufDlbLrZJ-cRbx99GF10b_W2aSMgxRsKGNmkaWQbp1RvUK9CXuwvXNbQNJRvtf3DcY9jd3bp0XEuSjVKLxg04SIP6_hDvY-ICDirEZH-Ku4GOsQg21yZYrDYZxDiPsYp3NKwwxIHCCb8mA9xiYHQubRrClDySwfIBHMJRctc0u5UMk1XektnmOP0y6ATCb-HOg8mDS5aHF.jpg",
      discription:
        "10% discount for IPv4, IPv6 orders. Unlimited reusable coupon code.",
      promocode: "RATINGPROXY11"
    },
    {
      title: "proxy-solutions.net",
      image:
        "/storage/rating-proxy-dev/63c90862f7144a76f7ccd124_N7nvDHkJRPS4rAf2VSbtk9efybVVfKTvcDRZddZCF16KF57FdsIZdypdYNTk8-Pdtoh4YAUyf-jmzkXcyF4gFEcxU_3aAhMYmNiNssnnt6ylZ44RHdj1bRdQ7Sv3u5AoyzGcOtVTcRQXQ1FUJ3q2KYTHyuLShUdh8uFL7orfJssV-H7AunLd4R5DrKYTY1pY9DIRNrgyUKeXs53WfOjRxwn8f.jpg",
      discription:
        "10% discount for IPv4, IPv6 orders. Unlimited reusable coupon code.",
      promocode: "RATINGPROXY10"
    }
  ];

  return (
    <section>
      <Container>
        <StyledSpesialOfferCard shadow size={"large"}>
          <StyledSpesialOffersHeader>
            <StyledSpesialOffersTitle>
              {t("proxyPage.specialOffer.title")}
            </StyledSpesialOffersTitle>
            <StyledSpesialOffersDescription>
              {t("proxyPage.specialOffer.description")}
            </StyledSpesialOffersDescription>
          </StyledSpesialOffersHeader>
          <StyledSpesialOffersListBox>
            <Swiper
              direction="horizontal"
              modules={[Pagination]}
              pagination={{ clickable: true }}
              wrapperTag="ul"
              style={{ overflow: "visible", position: "static" }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                400: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 1.2,
                  spaceBetween: 20
                },
                550: {
                  slidesPerView: 1.5,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 36
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 24
                }
              }}
            >
              {mockData.map((item) => (
                <SwiperSlide key={item.title} tag="li">
                  <OfferItem {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledSpesialOffersListBox>
        </StyledSpesialOfferCard>
      </Container>
    </section>
  );
};

import { useEffect, useState } from "react";

import PT from "prop-types";

import styles from "./FAQ.module.scss";

const FAQCard = ({
  title,
  children,
  className,
  isOpened,
  onClick,
  handleOpen
}) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (handleOpen) {
      handleOpen(e);
    } else {
      setOpened(!opened);
    }
  };

  return (
    <div
      className={`${styles.card} ${className}`}
      onClick={handleClick}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div
        className={opened ? styles.top__container_open : styles.top__container}
      >
        <div itemProp="name" className={styles.question}>
          {title}
        </div>
        <div className={opened ? styles.icon__opened : styles.icon} />
      </div>
      <div
        className={opened ? styles.answer_open : styles.answer}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        {children}
      </div>
    </div>
  );
};

FAQCard.propTypes = {
  className: PT.string,
  children: PT.node,
  handleOpen: PT.func,
  isOpened: PT.bool,
  onClick: PT.func,
  title: PT.node.isRequired
};

FAQCard.defaultProps = {
  className: "",
  children: null,
  handleOpen: null,
  isOpened: false,
  onClick: null
};

export default FAQCard;

import Logo from "components/ui/New/Logo/Logo";

import Languages from "../Languages/Languages";

import {
  CopyrightDesktop,
  CopyrightMobile,
  FooterInner,
  LogoWrapper,
  StyledFooter
} from "./Footer.styled";
import { Navigation } from "./Navigation/Navigation";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const year = new Date().getFullYear();
  const COPYRIGHT_TEXT = `TrustyTech©${year} | All Rights Reserved`;

  return (
    <StyledFooter>
      <FooterInner>
        <LogoWrapper>
          <Logo className={styles.logo} />
          <Languages
            listClassName={styles.languages__mobile_list}
            className={styles.languages__mobile}
          />
          <CopyrightDesktop>{COPYRIGHT_TEXT}</CopyrightDesktop>
        </LogoWrapper>
        <Navigation />
        <Languages
          listClassName={styles.languages__desktop_list}
          className={styles.languages__desktop}
        />
        <CopyrightMobile>{COPYRIGHT_TEXT}</CopyrightMobile>
      </FooterInner>
    </StyledFooter>
  );
};

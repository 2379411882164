import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "en",
  translations: {
    en: "EN",
    ru: "RU",
    ua: "UA"
  },
  languages: [
    {
      value: "en",
      label: "English"
    },
    {
      value: "ru",
      label: "Россия"
    },
    {
      value: "ua",
      label: "Україна"
    }
  ],
  nearest: ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"],
  isDataLoaded: false
};

const TranslationReducer = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setCurrentLang(state, action) {
      state.lang = action.payload;
    },
    setLanguages(state, action) {
      state.languages = action.payload.filter(({ value }) =>
        state.translations.hasOwnProperty(value)
      );
      state.isDataLoaded = true;
    }
  }
});

export const TranslationReducerActions = {
  ...TranslationReducer.actions
};
export default TranslationReducer.reducer;

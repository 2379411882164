import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Button } from "../../../../../components/ui/Button/Button";
import { Loader } from "../../../../../components/ui/Loader/Loader";
import { ApiService } from "../../../../../services";

import "./LogoPreviews.scss";

const SRC_EMPTY = "/img/ui/placeholder.svg";

export const LogoPreviews = ({
  id = null,
  logo,
  logosCrop,
  logoTop,
  setFiles,
  setCroppedFileObjects,
  setDefaultValues
}) => {
  const { t } = useTranslation();

  // **Local state
  const [srcMain, setSrcMain] = useState("");
  const [srcTop, setSrcTop] = useState("");
  const [isDelLogoLoading, setIsDelLogoLoading] = useState(false);

  useEffect(() => {
    if (logosCrop.length) {
      setSrcMain(URL?.createObjectURL(logosCrop[0]));
      setSrcTop(URL?.createObjectURL(logosCrop[1]));
    } else {
      setSrcMain(logo ? logo : SRC_EMPTY);
      setSrcTop(logoTop ? logoTop : SRC_EMPTY);
    }
  }, [logo, logoTop, logosCrop]);

  const deleteLogoHandler = async (evt) => {
    evt.preventDefault();

    try {
      if (!id) {
        return;
      }

      setIsDelLogoLoading(true);

      const response = await ApiService.deleteImage(id);

      if (response?.response && response?.response?.status !== 200) {
        throw response;
      }

      setIsDelLogoLoading(false);
      setFiles([]);
      setCroppedFileObjects([]);
      setDefaultValues((prevState) => ({
        ...prevState,
        image: null,
        imageTop: null
      }));
      setSrcMain(SRC_EMPTY);
      setSrcTop(SRC_EMPTY);
    } catch (err) {
      toast.error(t("notifications.apiError"));
      setIsDelLogoLoading(false);
    }
  };

  return (
    <div className="logo-preview-wrapper">
      <div className="logo-previews">
        <div className="logo-preview-item">
          <img
            src={srcMain}
            className="logo-preview-main"
            width="115"
            height="35"
            loading="lazy"
            aria-hidden="true"
            alt="Main Logo Company"
          />
        </div>
        <div className="logo-preview-item">
          <img
            src={srcTop}
            className="logo-preview-top"
            width="115"
            height="65"
            loading="lazy"
            aria-hidden="true"
            alt="Top Logo Company"
          />
        </div>
      </div>
      {(logo || logoTop) && (
        <Button
          disabled={isDelLogoLoading}
          type="black-reverse"
          position="center"
          onClick={deleteLogoHandler}
        >
          {!isDelLogoLoading ? (
            t("proxy.logotype.btn.delLogo")
          ) : (
            <Loader
              type="absolute"
              scale={0.4}
              color="var(--clr-default-100)"
            />
          )}
        </Button>
      )}
    </div>
  );
};

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Container from "components/common/New/Container/Container";
import { Loader } from "components/ui/Loader/Loader";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { useDispatchedActions } from "hooks";

import { Content } from "./Content/Content";

import styles from "./ServicePage.module.scss";

const ServicePage = () => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();

  // **Redux state
  const { site, statistic } = useSelector(getAllProxySite);
  const { topSites } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Local state
  const [isLoading, setIsLoading] = useState(alias !== site.data?.alias);
  const [stepRating, setStepRating] = useState("days");

  // **Dispatch
  const {
    getProxySiteInfo,
    getProxySiteReviewsAmount,
    getProxySiteRating,
    getTopSites,
    getAllPromocodesBySite,
    getProxySiteReviews,
    getProxySiteCompaints,
    setSiteViewStatistic
  } = useDispatchedActions();

  useEffect(() => {
    if (alias !== site.data?.alias) {
      if (!statistic.isStatisticSites.includes(alias)) {
        setSiteViewStatistic(alias);
      }

      getProxySiteInfo(alias);
      getProxySiteReviewsAmount(alias);
      getAllPromocodesBySite(alias);
      getProxySiteReviews({
        sort: "creationDate",
        siteId: alias,
        page: 0,
        size: 10
      });
      if (isUserAuthenticated) {
        getProxySiteCompaints({
          sort: {
            creationDate: "ASC"
          },
          siteId: alias,
          currentPage: 0,
          size: 10
        });
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  useEffect(() => {
    if (alias !== site.data?.alias || stepRating) {
      getProxySiteRating({ siteId: alias, step: stepRating });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, stepRating]);

  useEffect(() => {
    if (!topSites.isDataLoaded) {
      getTopSites();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topSites.isDataLoaded]);

  const clickStepRatingHandle = (step) => setStepRating(step);

  return (
    <>
      <section>
        <Container className={styles.container}>
          {isLoading && !site.isError && <Loader />}
          {!isLoading && !site.isError && (
            <Content
              stepRating={stepRating}
              clickStepRatingHandle={clickStepRatingHandle}
            />
          )}
          {!isLoading && site.isError && (
            <div className="proxy-site__not-found">
              {t("proxySitePage.notFound")}
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default ServicePage;

import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const ReviewSchemaWithAuth = (trns, t) =>
  yup.object().shape({
    name: yup.string().required(t(`${trns}.name.rules.required`)),
    email: yup
      .string()
      .trim()
      .required(t(`${trns}.email.rules.required`))
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        t(`${trns}.email.rules.email`)
      ),
    advantages: yup.string().required(t(`${trns}.field.rules.required`)),
    disadvantages: yup.string().required(t(`${trns}.field.rules.required`)),
    review: yup.string().required(t(`${trns}.field.rules.required`)),
    usage: yup.string().required(t(`${trns}.field.rules.required`)),
    wishes: yup.string().required(t(`${trns}.field.rules.required`))
  });

export const ReviewSchema = (trns, t) =>
  yup.object().shape({
    advantages: yup.string().required(t(`${trns}.field.rules.required`)),
    disadvantages: yup.string().required(t(`${trns}.field.rules.required`)),
    review: yup.string().required(t(`${trns}.field.rules.required`)),
    usage: yup.string().required(t(`${trns}.field.rules.required`)),
    wishes: yup.string().required(t(`${trns}.field.rules.required`))
  });

export const ReviewSchemaWithAuthNew = (trns, t) =>
  yup.object().shape({
    name: yup.string().required(t(`${trns}.name.rules.required`)),
    email: yup
      .string()
      .trim()
      .required(t(`${trns}.email.rules.required`))
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        t(`${trns}.email.rules.email`)
      ),
    advantages: yup.string().required(t(`${trns}.field.rules.required`)),
    disadvantages: yup.string().required(t(`${trns}.field.rules.required`)),
    reviewBody: yup.string().required(t(`${trns}.field.rules.required`))
    // usage: yup.string().required(t(`${trns}.field.rules.required`)),
    // wishes: yup.string().required(t(`${trns}.field.rules.required`))
  });

export const ReviewSchemaNew = (trns, t) =>
  yup.object().shape({
    advantages: yup.string().required(t(`${trns}.field.rules.required`)),
    disadvantages: yup.string().required(t(`${trns}.field.rules.required`)),
    reviewBody: yup.string().required(t(`${trns}.field.rules.required`))
    // usage: yup.string().required(t(`${trns}.field.rules.required`)),
    // wishes: yup.string().required(t(`${trns}.field.rules.required`))
  });

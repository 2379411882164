import { useTranslation } from "react-i18next";

import { Title } from "components/ui/New/Title/Title";

import styles from "./Success.module.scss";

export const Success = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.box}>
      <Title className={styles.box_title} tag="div">
        {t("modals.review.form.success.title")}
      </Title>
      <p className={styles.box_text}>{t("modals.review.form.success.text")}</p>
      <picture className={styles.box_picture}>
        <source
          srcSet={
            "./img/review/success-1x.png 1x, ./img/review/success-2x.png 2x"
          }
          type="image/png"
        />
        <img src={"./img/review/success-1x.png"} alt="Thank you" />
      </picture>
    </div>
  );
};

import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthInput } from "../../../components/forms/AuthInput/AuthInput";
import { Button } from "../../../components/ui/Button/Button";
import { Loader } from "../../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { RegistrationSchema } from "../../../utils/validation";
import { Authorization } from "../components/Authorization/Authorization";

import "../Auth.scss";

export const RegistrationPage = () => {
  // **Ref
  const captchaRef = useRef(null);

  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  // **Redux state
  const { t } = useTranslation();
  const {
    pageContent: { current },
    captchaConfig
  } = useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const [, setTokenCaptcha] = useState(null);
  // const metaData = useMetaData();

  const { enable, clientKey, headerName } = captchaConfig.data;

  // Form
  const methods = useForm({
    resolver: yupResolver(
      RegistrationSchema(t("forms", { returnObjects: true }))
    )
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      if (data.password !== data.passwordRepeat) {
        toast.error(t("forms.password.rules.mismatch"));
        return;
      }

      setIsLoading(true);

      const response = await ApiService.registerNewUser(
        data.email,
        data.password,
        queryLang,
        header
      );

      if (response && response.status !== 200) {
        if (response.status === 540) {
          // eslint-disable-next-line no-throw-literal
          toast.clearWaitingQueue();

          throw {
            response,
            message: t(`notifications.authError.${response.status}`)
          };
        }

        throw response;
      }

      navigate(`${hrefLang}/login/`);
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  return (
    <Authorization isTabsShown title={current?.data?.h1}>
      <div className="auth__form-wrapper">
        <FormProvider {...methods}>
          <form
            className="auth__form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <AuthInput
              placeholder={t("forms.email.label")}
              type="email"
              name="email"
              showError
            />
            <AuthInput
              placeholder={t("forms.password.placeholder")}
              type="password"
              name="password"
              showError
            />
            <AuthInput
              placeholder={t("forms.password.placeholderRepeat")}
              type="password"
              name="passwordRepeat"
              showError
            />
            {enable && clientKey && (
              <HCaptcha
                ref={captchaRef}
                sitekey={clientKey}
                onVerify={setTokenCaptcha}
                onExpire={() => setTokenCaptcha(null)}
                onError={(err) => toast.error(err?.message)}
                size="invisible"
              />
            )}

            <Button
              disabled={isLoading}
              size="auth"
              position="center"
              type="solid"
            >
              {!isLoading ? (
                t("authPages.register.btn")
              ) : (
                <Loader
                  type="absolute"
                  scale={0.6}
                  color="var(--clr-default-900)"
                />
              )}
            </Button>
          </form>
        </FormProvider>
      </div>
    </Authorization>
  );
};

export default RegistrationPage;

RegistrationPage.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
};

import { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import TextButton from "components/ui/New/TextButton/TextButton";

import { CropperImg } from "./CropperImg/CropperImg";
import Img from "./components/Img";

import styles from "./Uploader.module.scss";

export const Uploader = ({
  defaultImage = null,
  onUpload,
  croppedFileObjects,
  setCroppedFileObjects,
  className,
  ...rest
}) => {
  const { t } = useTranslation();

  // **Local state
  const [fileObject, setFileObject] = useState(defaultImage);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const fileInputRef = useRef(null);

  const dragHandler = (e, state) => {
    e.preventDefault();
    setIsDragging(state);
  };

  const uploadHandler = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e?.dataTransfer?.files[0] || e?.target?.files[0];
    e.target.value = "";

    if (!file) {
      toast.error(t("notifications.file.badFile"));
      return;
    }

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      toast.error(t("notifications.file.badFileType"));
      return;
    }

    if (file.size / 1024 / 1024 >= 2) {
      toast.error(t("notifications.file.largeSize"));
      return;
    }

    setFileObject(file);
    setIsModalOpened(true);
  };

  const modalHandler = () => {
    setIsModalOpened(false);
  };

  const handleDeleteImage = (e) => {
    const { id } = e.target;

    if (id === "delete-image") {
      setCroppedFileObjects([]);
      setFileObject(null);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const fileSelectHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <label
        htmlFor="file-input"
        className={`${styles?.uploader} ${
          isDragging ? styles?.uploader__dragging : ""
        } ${className}`}
        onDragStart={(e) => dragHandler(e, true)}
        onDragOver={(e) => dragHandler(e, true)}
        onDragLeave={(e) => dragHandler(e, false)}
        onDrop={uploadHandler}
        onClick={handleDeleteImage}
        {...rest}
      >
        {croppedFileObjects.length ? (
          <Img
            croppedFileObjects={croppedFileObjects}
            defaultImage={defaultImage}
          />
        ) : (
          <div className={styles.uploader__icon}>
            <IconsSVG name="uploadCloud" />{" "}
          </div>
        )}

        <div className={styles.uploader__text}>
          {croppedFileObjects.length ? (
            <>
              <div className={styles.uploader__text__action}>
                <TextButton
                  color={"secondary"}
                  iconLeft={"uploadCloud"}
                  size="sm"
                  onClick={fileSelectHandler}
                >
                  {t("uploader.chooseOtherFile")}
                </TextButton>

                {"or"}
                <TextButton id={"delete-image"} color={"error"} size="sm">
                  Delete
                </TextButton>
              </div>
            </>
          ) : (
            <>
              <div className={styles.uploader__text__action}>
                <TextButton
                  color={"secondary"}
                  size="sm"
                  onClick={fileSelectHandler}
                >
                  {isDragging
                    ? t("uploader.draggingState")
                    : t("uploader.chooseFile")}
                </TextButton>
                <span className={styles.uploader__text__action__second}>
                  {t("uploader.chooseFileSecond")}
                </span>
              </div>
              {"SVG, PNG or JPG 256x256 px (max. size 2MB)"}
            </>
          )}
        </div>
        <input
          id="file-input"
          type="file"
          accept="image/jpeg, image/png"
          onChange={uploadHandler}
          ref={fileInputRef}
        />
      </label>
      <CropperImg
        isModalOpened={isModalOpened}
        defaultImage={defaultImage}
        fileObject={fileObject}
        setCroppedFileObjects={setCroppedFileObjects}
        onUpload={onUpload}
        cancelModalHandler={modalHandler}
      />
    </>
  );
};

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

import Card from "components/ui/New/Card/Card";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { ServiceItem } from "./ServiceItem/ServiceItem";

import styles from "./Alternative.module.scss";

export const Alternative = () => {
  const { t } = useTranslation();

  const {
    topSites: { data }
  } = useSelector(getAllContent);

  return (
    <Card shadow className={styles.card} radius={"small"} size={"small"}>
      <div className={styles.title}>
        {t("proxySitePage.content.chacracteristics.services.title")}
      </div>

      <div className={styles.mobile}>
        <Swiper
          className={styles.list}
          direction="horizontal"
          wrapperTag="ul"
          breakpoints={{
            320: {
              slidesPerView: 1.05,
              spaceBetween: 12
            },

            480: {
              slidesPerView: 1.1,
              spaceBetween: 12
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 12
            }
          }}
        >
          {data?.length
            ? data.map((item, index) => (
                <SwiperSlide
                  key={`${item.id}_item_${index}`}
                  // className={styles.item}
                  style={{ height: "auto" }}
                  tag="li"
                >
                  <ServiceItem data={item} />
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>

      <div className={styles.desctop}>
        <ul className={styles.desctop_list}>
          {data?.length
            ? data.map((item, index) => (
                <li key={`${item.id}_item__${index}`}>
                  <ServiceItem data={item} />
                </li>
              ))
            : null}
        </ul>
      </div>
    </Card>
  );
};

import { useEffect, useState } from "react";

import { countries as allCountries } from "countries-list";
import countries2to3 from "countries-list/minimal/countries.2to3.min.json";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Alert } from "components/ui/New/Alert/Alert";
import { Checkbox } from "components/ui/New/Checkbox/Checkbox";
import LabelCountry from "components/ui/New/LabelContry/LabelCountry";
import Search from "components/ui/New/Search/Search";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import styles from "./BodyCountries.module.scss";

export const BodyCountries = ({ prefix, setSelected }) => {
  const { setValue, watch } = useFormContext();

  const { locations } = watch(prefix);

  const { t } = useTranslation();
  //   // **Dispatch
  const [queryLang] = useLangUrlDefault();
  const { countries, inactive } = useSelector(getAllContent);

  const [countriesOptions, setCountriesOptions] = useState(
    countries.data?.map((country) => ({
      label: country.localization?.[queryLang],
      value: country.id,
      code: country.code,
      checked:
        locations && locations?.length ? locations.includes(country.id) : false
    }))
  );
  const [inactiveCountries, setInactiveCountries] = useState(
    Object.keys(allCountries)
      .map((country) => ({
        label: allCountries[country]?.name,
        value: countries2to3[country],
        code: country,
        checked: false
      }))
      .filter(
        (country) =>
          !countriesOptions.find((item) => item.code === country.value)
      )
  );

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(countriesOptions);
  const [filteredInactiveData, setFilteredInactiveData] =
    useState(inactiveCountries);
  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleCkick = (id) => {
    if (!locations) {
      setValue(`${prefix}locations`, [id]);
      setSelected([id]);
    } else if (!locations.includes(id)) {
      setValue(`${prefix}locations`, [...locations, id]);
      setSelected([...locations, id]);
    } else {
      setValue(
        `${prefix}locations`,
        locations.filter((item) => item !== id)
      );
      setSelected(locations.filter((item) => item !== id));
    }

    const newData = countriesOptions.map((country) => {
      if (country.value === id) {
        return {
          ...country,
          checked: !country.checked
        };
      }
      return country;
    });

    setCountriesOptions(newData);
    setFilteredData(
      newData?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const handleCkickInactive = (id) => {
    if (!inactive) {
      setValue(`${prefix}inactive`, [id]);
    } else if (!inactive.includes(id)) {
      setValue(`${prefix}inactive`, [...inactive, id]);
    } else {
      setValue(
        `${prefix}inactive`,
        inactive.filter((item) => item !== id)
      );
    }

    const newData = inactiveCountries.map((country) => {
      if (country.value === id) {
        return {
          ...country,
          checked: !country.checked
        };
      }
      return country;
    });

    setInactiveCountries(newData);
    setFilteredInactiveData(
      newData?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setFilteredData(
      countriesOptions?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredInactiveData(
      inactiveCountries?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Search
        value={search}
        onChange={handleSearch}
        variant={"outlined"}
        placeholder={t("search.placeholder")}
      />
      {filteredData?.length ? (
        <div className={styles.container}>
          <p className={styles.subtitle}>
            {t("dashboard.services.addEdit.blocks.type.countries.active")}
          </p>
          <ul className={styles.list}>
            {filteredData.map((country) => (
              <li
                key={country?.value}
                className={styles.item}
                onClick={() => handleCkick(country?.value)}
              >
                <Checkbox
                  name={country?.value}
                  label={
                    <LabelCountry code={country?.code}>
                      {country?.label}
                    </LabelCountry>
                  }
                  onClick={handleCkick}
                  checked={country?.checked}
                  className={`${styles.checkbox} ${
                    country?.checked ? styles.checkbox_checked : ""
                  }`}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {filteredInactiveData?.length ? (
        <div className={styles.container}>
          <p className={styles.subtitle}>
            {t("dashboard.services.addEdit.blocks.type.countries.inactive")}
          </p>
          <Alert type={"info"} closable={false}>
            {t("dashboard.services.addEdit.blocks.type.countries.description")}
          </Alert>
          <ul className={styles.list}>
            {filteredInactiveData.map((country) => (
              <li
                key={country?.value}
                className={styles.item}
                onClick={() => handleCkickInactive(country?.value)}
              >
                <Checkbox
                  name={country?.value}
                  label={
                    <>
                      <LabelCountry code={country?.code} />
                      <span className={styles.checkbox_country_name}>
                        {country?.label}
                      </span>
                    </>
                  }
                  checked={country?.checked}
                  className={styles.checkbox}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

import { Title } from "components/ui/New/Title/Title";

import styles from "./TabTitle.module.scss";

export const TabTitle = ({ title, icon, className = "" }) => (
  <div className={`${styles.title} ${className}`}>
    <img src={icon} alt={title} className={styles.title_img} />
    <Title className={styles.title_text} tag={"div"}>
      {title}
    </Title>
  </div>
);

import { useId } from "react";

import PT from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";

import styles from "./Tooltip.module.scss";

export const Tooltip = ({
  id,
  body,
  place,
  className,
  classNameTooltip,
  style,
  delayShow,
  children,
  isOpen
}) => {
  const idTooltip = useId();
  return (
    <div
      data-tooltip-id={id ? id : idTooltip}
      className={`${styles.wrapper} ${className}`}
      style={style}
    >
      {children}
      <ReactTooltip
        id={id ? id : idTooltip}
        className={`${styles.tooltip} ${classNameTooltip}`}
        opacity={1}
        place={place}
        classNameArrow={styles.arrow}
        delayShow={delayShow}
        isOpen={isOpen}
      >
        {body}
      </ReactTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PT.string,
  body: PT.node.isRequired,
  place: PT.oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end"
  ]),
  className: PT.string,
  classNameTooltip: PT.string,
  style: PT.shape({}),
  children: PT.node,
  delayShow: PT.number,
  isOpen: PT.bool
};

Tooltip.defaultProps = {
  place: "top",
  className: "",
  classNameTooltip: "",
  style: {},
  delayShow: null
};

export default Tooltip;

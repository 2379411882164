import styled from "@emotion/styled";

export const StyledSiteNameCell = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 12px;
`;

export const StyledSiteNameCellImageBox = styled.div`
  padding: 8px;
  width: 40px;
  height: 40px;
  flex: none;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors["background-color-image"]};
`;

export const StyledNameCellImage = styled.img`
  width: 24px;
  height: 24px;
  font-size: 0;
`;

export const StyledSiteNameCellInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 40px - 12px);
  gap: 4px;
`;

export const StyledSiteNameCellTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: 600;
  line-height: 125%;
  width: fit-content;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  color: ${(p) => p.theme.colors["dark-700"]};
`;

export const StyledSiteNameCellSubInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

import styled from "@emotion/styled";

export const Preview = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 24px;
    width: 100%;
    padding-bottom: 307px;
    background: rgba(216, 218, 224, 0.50);

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      width: 580px;
      min-width: 580px;
      margin-bottom: 32px;
    }

    a {
      font-size: 0;
    }
`;

export const LatestItemWrapper = styled.div`
    &:not(:last-child) {
      margin-bottom: 24px;
    }
`;

export const LatestItemTitle = styled.p`
      color: var(--neutral-800);
      font-family: var(--font-family-text);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 12px;
`;

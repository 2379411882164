import PT from "prop-types";
import { StyledPercent, Number } from "./Percent.styled";

const Percent = ({ number, className, color, style }) => (
    <StyledPercent color={color} className={className} style={style}>
      <Number>{`${number}%`}</Number>
    </StyledPercent>
  );

Percent.propTypes = {
  className: PT.string,
  color: PT.string,
  number: PT.oneOfType([PT.string, PT.node]).isRequired,
  style: PT.shape({})
};

Percent.defaultProps = {
  className: "",
  color: "success",
  style: {}
};

export default Percent;

import { useTranslation } from "react-i18next";

import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { faqHttpHeadersChecker } from "utils/constants/questionsAndAnswers";

import animation from "assets/animations/http_headers_check.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  httpHeadersCheckerHeroImageMobile
} from "assets/img";

import { HttpHeaderCheck } from "./HttpHeaderCheck/HttpHeaderCheck";

import styles from "./HttpHeadersCheckerPage.module.scss";

const HttpHeadersCheckerPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.section}>
      <Main
        title={t("httpHeadersChecker.main.title")}
        subtitle={t("httpHeadersChecker.main.subtitle")}
        button={false}
        images={{
          mobile1x: httpHeadersCheckerHeroImageMobile,
          mobile2x: httpHeadersCheckerHeroImageMobile
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        contentClassName={styles.hero__content}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-84}
            right={-87}
            bottom={-112}
            left={-86}
          />
        }
      />
      <HttpHeaderCheck />
      <FAQ
        list={faqHttpHeadersChecker}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        className={styles.faq}
        tools
      />
      <SeoSection />
    </div>
  );
};

export default HttpHeadersCheckerPage;

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Rive } from "components/ui/New/Rive/Rive";
import { Title } from "components/ui/New/Title/Title";

import { useLangUrlDefault } from "hooks";

import {
  mockListData,
  mockStepsData,
  mockTableData
} from "utils/constants/mockFreeProxyData";

import {
  animationFreeProxyDifference,
  animationFreeProxyDownload,
  animationFreeProxyList
} from "assets/animations";

import { ProxyDifferenceTable } from "./ProxyDifferenceTable/ProxyDifferenceTable";

import styles from "./FreeProxyInfo.module.scss";

export const FreeProxyInfo = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const dottedList = useMemo(
    () => (
      <ul className={styles.dotted__list}>
        {queryLang &&
          mockListData[queryLang].map((item, index) => (
            <li key={index} className={styles.dotted__list_item}>
              <div>
                <img src="img/icons/dot.svg" alt="Dot icon" />
              </div>
              {item}
            </li>
          ))}
      </ul>
    ),
    [queryLang]
  );

  return (
    <>
      <div className={styles.section__dark}>
        <Container className={styles.container}>
          <div className={styles.animation}>
            <Rive
              src={animationFreeProxyList}
              autoPlay
              top={-136}
              right={-110}
              bottom={-136}
              left={-110}
            />
          </div>
          <picture>
            <source
              srcSet="img/free-proxy/free-proxy-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <img
              src="img/free-proxy/free-proxy-info-image-mobile.svg"
              alt={t("freeProxy.howUseProxy.title")}
              className={styles.image}
            />
          </picture>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("freeProxy.howUseProxy.title")}
            </Title>
            <div className={styles.description}>
              {t("freeProxy.howUseProxy.description")}
            </div>
            <div className={styles.list__title}>
              {t("freeProxy.howUseProxy.listTitle")}
            </div>
            {dottedList}
          </div>
        </Container>
      </div>
      <div className={styles.section__light}>
        <Container className={styles.container__light}>
          <div className={styles.content__wrapper}>
            <div className={styles.content}>
              <Title tag="h2" className={styles.title}>
                {t("freeProxy.differenceBetween.title")}
              </Title>
              <div className={styles.description}>
                {t("freeProxy.differenceBetween.description")}
              </div>
            </div>
            <div className={styles.animation}>
              <Rive
                src={animationFreeProxyDifference}
                autoPlay
                top={-85}
                right={-120}
                bottom={-85}
                left={-120}
              />
            </div>
            <picture>
              {/* <source srcSet={freeProxyInfoImage1} media="(min-width: 993px)" /> */}
              <source
                srcSet="img/free-proxy/free-proxy-info-image-mobile1.svg"
                media="(max-width: 992px)"
              />
              <img
                src="img/free-proxy/free-proxy-info-image-mobile1.svg"
                alt={t("freeProxy.differenceBetween.title")}
                className={styles.image}
              />
            </picture>
          </div>
        </Container>
        <div className={styles.table__container}>
          <div className={styles.table}>
            <ProxyDifferenceTable
              data={queryLang ? mockTableData[queryLang] : []}
            />
          </div>
        </div>
      </div>
      <div className={styles.section__white}>
        <Container className={styles.container__white}>
          <div className={styles.animation}>
            <Rive
              src={animationFreeProxyDownload}
              autoPlay
              top={-41}
              right={-69}
              bottom={-13}
              left={-69}
            />
          </div>
          <picture>
            {/* <source srcSet={freeProxyInfoImage2} media="(min-width: 993px)" /> */}
            <source
              srcSet="img/free-proxy/free-proxy-info-image-mobile2.svg"
              media="(max-width: 992px)"
            />
            <img
              src="img/free-proxy/free-proxy-info-image-mobile2.svg"
              alt={t("freeProxy.howDownloadProxy.title")}
              className={styles.image}
            />
          </picture>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("freeProxy.howDownloadProxy.title")}
            </Title>
            {queryLang &&
              mockStepsData[queryLang].map((step, index) => (
                <div key={step} className={styles.step__item}>
                  <p className={styles.step}>
                    {t("ui.step")} {index + 1}
                  </p>
                  <p className={styles.step__description}>{step}</p>
                </div>
              ))}
          </div>
        </Container>
      </div>
    </>
  );
};

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../hooks";
import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";

import "./Burger.scss";

const Burger = () => {
  // **Redux state
  const { isMobileMenuOpened } = useSelector(getAllSite);
  const { t } = useTranslation();

  // Dispatch
  const { setMobileMenu } = useDispatchedActions();

  const clickHandler = () => {
    setMobileMenu(!isMobileMenuOpened);
  };

  return (
    <button
      className={isMobileMenuOpened ? "burger burger--active" : "burger"}
      onClick={clickHandler}
      aria-label={
        isMobileMenuOpened ? t("header.ariaClose") : t("header.ariaOpen")
      }
      aria-expanded={isMobileMenuOpened ? "true" : "false"}
    >
      <span></span>
    </button>
  );
};

export default Burger;

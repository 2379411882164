import axios from "axios";
import { useTranslation } from "react-i18next";

import { AllActions } from "store/reducers/AllActions";

import { Breadcrumbs } from "../../../components/common";

import { Blog } from "./Blog/Blog";

export const BlogPage = () => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs items={t("blogPage.breadcrumbs", { returnObjects: true })} />
      <Blog />
    </>
  );
};

export default BlogPage;

BlogPage.getServerSideState = async (store, params, api) => {
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: blogContent } = await axios.get(`${api}/api/front/site/blog`, {
    params: {
      page: 0,
      size: 16,
      category: params.params.tab === "articles",
      languageCode: params.languageCode
    }
  });

  const { data: sidebar } = await axios.get(
    `${api}/api/front/site/blog/sidebar`,
    { params: { languageCode: params.languageCode } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(
    AllActions.setBlogContent({
      ...blogContent,
      params: {
        page: 0,
        size: 16,
        category: params.params.tab === "articles",
        languageCode: params.languageCode
      }
    })
  );
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.languageCode
    })
  );
};

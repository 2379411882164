import Moment from "react-moment";
import { Link } from "react-router-dom";

import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { getDomainTitle } from "../../../../../../utils/helpers/";

export const ReviewItem = ({ review, closeSidebar }) => {
  const [, hrefLang] = useLangUrlDefault();

  // **Dispatch
  const { setReviewInfo } = useDispatchedActions();

  const domainName = getDomainTitle(review.siteUrl);
  const reviewSiteUrl = domainName ? domainName : review.siteUrl;

  const routeHadler = async () => {
    // Get page by review
    const response = await ApiService.getReviewPageByReviewId(review.id);

    // Close sidebar
    closeSidebar(false);

    if (response?.data?.toString()) {
      setReviewInfo({
        page: response?.data,
        id: review.id
      });
    }
  };

  return (
    <li className="proxy-sidebar__review">
      <Link
        to={`${hrefLang}/proxy/${review.siteAlias}/#reviews`}
        onClick={routeHadler}
      >
        <div className="proxy-sidebar__review-name">{review.userName}</div>
        <div className="proxy-sidebar__review-site" title={reviewSiteUrl}>
          {reviewSiteUrl}
        </div>
        <div className="proxy-sidebar__review-date">
          <Moment format="DD.MM.YYYY">{new Date(review.date)}</Moment>
        </div>
      </Link>
    </li>
  );
};

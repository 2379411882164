import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Languages } from "../../";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { Container } from "../../../ui";
import AuthButton from "../../../ui/AuthButton";
import Burger from "../../../ui/Burger";
import Logo from "../../../ui/Logo";

import { Navigation } from "./Navigation/Navigation";

export const HeaderTop = () => {
  // **Props
  const { t } = useTranslation();

  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <div className="header__top">
      <Container isFluid={routeContent.isContainerFluid}>
        <div className="header__wrapper">
          <div className="lgMax">
            <Burger />
          </div>
          <Logo
            imgPath="/img/ui/logo.svg"
            imgAlt={t("header.logo.imgAlt")}
            linkTitle={t("header.logo.linkTitle")}
          />
          <>
            <div
              className="lgMin"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <Navigation />
            </div>
            <div className="smMin">
              <AuthButton type="solid" position="right" />
            </div>
          </>
          <Languages margin="left" />
        </div>
      </Container>
    </div>
  );
};

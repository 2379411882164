import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumbs from "components/common/New/Breadcrumbs/Breadcrumbs";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";

import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";
import { useBrowserFingerprint } from "hooks/useBrowserFingerprint";

import { BlogInner } from "./BlogInner/BlogInner";

import styles from "./BlogInnerPage.module.scss";

const STATUS_DRAFT = "DRAFT";

const BlogInnerPage = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { alias } = useParams();
  const navigate = useNavigate();

  // **Redux state
  const { content, sidebar } = useSelector(getAllBlogInner);
  const { t } = useTranslation();
  //blogInnerPage, notifications
  // **Local state
  const [isLoading, setIsLoading] = useState(alias !== content.data?.alias);
  const browserFingerprint = useBrowserFingerprint();

  // Dispatch
  const {
    getBlogContentByAlias,
    setErrorBlogInnerContent,
    getBlogSidebarContent
  } = useDispatchedActions();

  useEffect(() => {
    getBlogContentByAlias({ alias, languageCode: queryLang });
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, alias]);

  useEffect(
    () => {
      if (!sidebar?.isLoading) {
        getBlogSidebarContent({ languageCode: queryLang });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryLang]
  );

  useEffect(() => {
    if (content?.data?.id && browserFingerprint) {
      ApiService.createCountView(content?.data?.id, browserFingerprint);
    }
  }, [content?.data?.id, browserFingerprint]);

  // Handling error
  useEffect(() => {
    if (content.isError || content?.data?.status === STATUS_DRAFT) {
      toast.error(t("notifications.blogInner.notFoundBlogPost"));
      navigate(`${hrefLang}/new/blog/?tab=all`);
      setErrorBlogInnerContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isError, content?.data?.status]);

  return (
    <>
      <Breadcrumbs
        className={styles.breadcrumbs}
        items={[
          ...t("blogInnerPage.breadcrumbs", { returnObjects: true }),
          { title: content.data?.title }
        ]}
      />
      {!isLoading && <BlogInner />}
    </>
  );
};

export default BlogInnerPage;

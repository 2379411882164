import PT from "prop-types";
import { useSelector } from "react-redux";

import Container from "components/common/New/Container/Container";
import Pagination from "components/ui/New/Pagination/Pagination";

import { getAllBlog } from "store/reducers/BlogReducer/Blog.selectors";

import { BlogDarkBackground, BlogListWrapper } from "../Blog.styled";
import { BlogItem } from "../BlogItem/BlogItem";
import { BlogTabs } from "../BlogTabs/BlogTabs";
import styles from "../Blog.module.scss";

export const BlogList = ({ emptyText, pageParams, pageChangeHandler }) => {
  // **Redux state
  const { content } = useSelector(getAllBlog);

  return (
    <BlogDarkBackground>
      <BlogTabs pageChangeHandler={pageChangeHandler} />
      <Container className={styles.list}>
        <BlogListWrapper>
          {content.data?.content.map((item) => (
            <BlogItem key={item.id} data={item} />
          ))}
        </BlogListWrapper>
        {content.data.totalElements > 0 && (
          <Pagination
            currentPage={pageParams.page}
            rowsPerPage={pageParams.size}
            rowCount={content.data.totalElements}
            onChangePage={pageChangeHandler}
          />
        )}
        {!content.isLoading && content.data.totalElements === 0 && (
          <p className="blog__empty">{emptyText}</p>
        )}
      </Container>
    </BlogDarkBackground>
  );
};

BlogList.propTypes = {
  emptyText: PT.string.isRequired,
  category: PT.oneOfType([PT.bool, PT.string]).isRequired
};

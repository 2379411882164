import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Rating } from "../../../../../../components/common";
import { FeedbackCount } from "../../../../../../components/ui/FeedbackCount/FeedbackCount";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import { Typography } from "../../../../../../components/ui/Typography/Typography";
import { useLangUrlDefault } from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import {
  convertCountryCodeAlpha3ToAlpha2,
  getCountriesById,
  getSiteDescription,
  time
} from "../../../../../../utils/helpers";

export const Сharacteristics = ({ setTab }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const { site } = useSelector(getAllProxySite);

  const { countries, topSites } = useSelector(getAllContent);

  return !site.isLoading ? (
    <div className="proxy-site__characteristics">
      <ul className="proxy-site__characteristics-items">
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.market.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {time(
              "year",
              site.data?.age,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </div>
        </li>
        {site.data?.rating !== "NaN" && (
          <li className="proxy-site__characteristics-item">
            <div className="proxy-site__characteristics-item-left">
              {t("proxySitePage.content.chacracteristics.rating.title")}
            </div>
            <div className="proxy-site__characteristics-item-right">
              <Rating
                position="left"
                margin="no"
                isCaptionVisible={false}
                rating={site.data?.rating?.toFixed(1)}
              />
            </div>
          </li>
        )}

        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.countries.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            <ul className="proxy-site__characteristics-item-countries">
              {getCountriesById(site.data?.countries, countries.data).map(
                (country) => {
                  const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                    country?.code
                  ).toLowerCase();
                  const name = queryLang
                    ? country?.localization[queryLang]
                    : "";

                  return (
                    <li key={country?.id}>
                      {alpha2 !== "default" ? (
                        <span className={`fi fi-${alpha2}`}></span>
                      ) : (
                        <img
                          src="/img/flags/default.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                          alt={name}
                        />
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.freeTest.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.freeTest ? t("common.yes") : t("common.no")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.individual.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.individual ? t("common.yes") : t("common.no")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.change.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.replacementPossibility
              ? t("common.yes")
              : t("common.no")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.type.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.proxyType.join(", ")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.minCost.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.price}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.minRent.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {time(
              site.data?.minRentPeriod?.periodName,
              site.data?.minRentPeriod?.periodValue,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.reviews.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            <FeedbackCount
              Tag="button"
              onClick={() => setTab("reviews")}
              positive={site.data?.reviewRatingPositive}
              negative={site.data?.reviewRatingNegative}
            />
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.auth.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.clientLoginAuth &&
              `${t("proxySitePage.content.chacracteristics.auth.login")}, `}
            {site.data?.clientIPAuth &&
              `${t("proxySitePage.content.chacracteristics.auth.ip")}, `}
            {site.data?.clientNoAuth &&
              t("proxySitePage.content.chacracteristics.auth.noAuth")}{" "}
            {!site.data?.clientLoginAuth &&
              !site.data?.clientIPAuth &&
              !site.data?.clientNoAuth &&
              t("proxySitePage.content.chacracteristics.auth.noAuth")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.return.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.refunds ? t("common.yes") : t("common.no")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t("proxySitePage.content.chacracteristics.partnership.title")}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.affiliateProgram ? t("common.yes") : t("common.no")}
          </div>
        </li>
        <li className="proxy-site__characteristics-item">
          <div className="proxy-site__characteristics-item-left">
            {t(
              "proxySitePage.content.chacracteristics.partnershipPercent.title"
            )}
          </div>
          <div className="proxy-site__characteristics-item-right">
            {site.data?.affiliatePercent}
          </div>
        </li>
      </ul>
      {site.data?.description && (
        <div className="proxy-site__characteristics-description">
          <Typography
            Tag="h2"
            size="large"
            weight="semibold"
            padding="bottom-large"
          >
            {t("proxySitePage.content.chacracteristics.description.title")}
          </Typography>
          <p className="proxy-site__characteristics-description-body">
            {getSiteDescription(
              site.data?.description,
              queryLang,
              t(
                "proxySitePage.content.chacracteristics.description.noDescription"
              )
            )}
          </p>
        </div>
      )}
      {topSites.data.length > 0 && (
        <div className="proxy-site__characteristics-services">
          <Typography
            Tag="h2"
            size="large"
            weight="semibold"
            padding="bottom-very-large"
          >
            {t("proxySitePage.content.chacracteristics.services.title")}
          </Typography>
          <ul className="proxy-site__characteristics-sites">
            {topSites.data.map((topSite) => (
              <li key={topSite.id} className="proxy-site__characteristics-site">
                <div className="proxy-site__characteristics-site-image">
                  <div className="proxy-site__characteristics-site-image-inner">
                    <a href={`${hrefLang}/proxy/${topSite.alias}/`}>
                      <img
                        src={
                          topSite.image
                            ? topSite.image
                            : "/img/ui/blog-placeholder.svg"
                        }
                        width={214}
                        height={108}
                        alt={topSite.name}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
                <h3 className="proxy-site__characteristics-site-name">
                  <a href={`${hrefLang}/proxy/${topSite.alias}/`}>
                    {topSite.name}
                  </a>
                </h3>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

import PT from "prop-types";

import { mockSeoData } from "utils/constants/mockSeoData";

import Container from "../Container/Container";

import { StyledSeoSection, StyledSeoSectionContent } from "./SeoSection.styled";

const SeoSection = ({ content, noPaddingTop }) => (
    <StyledSeoSection noPaddingTop={noPaddingTop}>
      <Container>
        <StyledSeoSectionContent
          dangerouslySetInnerHTML={{
            __html: content || mockSeoData
          }}
        />
      </Container>
    </StyledSeoSection>
  );

SeoSection.propTypes = {
  content: PT.string,
  noPaddingTop: PT.bool
};

export default SeoSection;

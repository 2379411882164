import PT from "prop-types";

import { Label, StyledStatus } from "./Status.styled";

const Status = ({ label, className, color, bordered, size, style }) => (
  <StyledStatus
    color={color}
    size={size}
    bordered={bordered.toString()}
    className={className}
    style={style}
  >
    <Label size={size}>{label}</Label>
  </StyledStatus>
);

Status.propTypes = {
  className: PT.string,
  color: PT.oneOf(["success", "warning", "error", "default"]),
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  size: PT.oneOf(["sm", "md"]),
  bordered: PT.bool,
  style: PT.shape({})
};

Status.defaultProps = {
  className: "",
  color: "success",
  size: "md",
  bordered: true,
  style: {}
};

export default Status;

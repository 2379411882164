import { useTranslation } from "react-i18next";

import { Checkbox } from "components/forms/New";
import { FormDate } from "components/forms/New/FormDate/FormDate";

import FormBlock from "../../components/FormBlock/FormBlock";

import styles from "./BlockPeriod.module.scss";

export const BlockPeriod = ({ eternal }) => {
  const { t } = useTranslation();

  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.period.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.period.subtitle")}
    >
      <span className={styles.body_label}>
        {t("dashboard.promocodes.addEdit.blocks.period.title")}
      </span>
      <div className={styles.body_box}>
        <div className={styles.body_box_checkboxes}>
          <Checkbox
            name={"eternal"}
            type={"radio"}
            label={t("promocodes.eternal.label")}
            value={"eternal"}
          />
          <Checkbox
            type="radio"
            name={"eternal"}
            label={t("dashboard.promocodes.addEdit.blocks.period.date")}
            value={"date"}
          />
        </div>

        {eternal !== "eternal" && (
          <FormDate
            name={"dateRange"}
            showError
            defa
            className={styles.body_box_date}
          />
        )}
      </div>
    </FormBlock>
  );
};

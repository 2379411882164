import axios from "axios";

import { AllActions } from "../../../store/reducers/AllActions";

import { Feedback } from "./Feedback/Feedback";

import "./FeedbackPage.scss";

const FeedbackPage = () => (
  <>
    <Feedback />
  </>
);

export default FeedbackPage;

FeedbackPage.getServerSideState = async (store, params, api) => {
  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: sidebar } = await axios.get(
    `${api}/api/front/site/blog/sidebar`,
    { params: { languageCode: params.languageCode } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.languageCode
    })
  );
};

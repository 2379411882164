import { useMemo } from "react";

import { Rive } from "components/ui/New/Rive/Rive";

// import Rive from "@rive-app/react-canvas";
import { list, listWithNumbers } from "utils/constants/mockHowTobuyData";

import animationGuide from "assets/animations/proxy_purchasing_guide.riv";
import animationService from "assets/animations/proxy_service.riv";
import {
  // howToBuyIpv4ProxyImage,
  // howToBuyIpv4ProxyImage2x,
  howToBuyIpv4ProxyImageMobile,
  howToBuyIpv4ProxyImageMobile2x, // ipv4ProxyImage,
  // ipv4ProxyImage2x,
  ipv4ProxyImageMobile,
  ipv4ProxyImageMobile2x
} from "assets/img";

import Container from "../Container/Container";

import styles from "./HowToBuy.module.scss";

const HowToBuy = () => {
  const numberedList = useMemo(
    () => (
      <ul>
        {listWithNumbers.map((item, index) => (
          <li key={index} className={styles.item}>
            <p className={styles.title}>{`${index + 1}. ${item.title}`}</p>
            <span className={styles.description}>{item.description}</span>
          </li>
        ))}
      </ul>
    ),
    []
  );

  const dottedList = useMemo(
    () => (
      <ul className={styles.dotted__list}>
        {list.map((item, index) => (
          <li key={index} className={styles.dotted__list_item}>
            <div>
              <img src="img/icons/dot.svg" alt="Dot icon" />
            </div>
            {item}
          </li>
        ))}
      </ul>
    ),
    []
  );

  return (
    <>
      <div className={styles.section__darkBackground}>
        <Container className={styles.container}>
          <div className={styles.animation_guide}>
            <Rive
              src={animationGuide}
              autoPlay
              top={-82}
              right={-80}
              bottom={-81}
              left={-81}
            />
          </div>
          <div className={styles.content}>
            <h2 className={styles.heading}>{"How to buy {IPv4} Proxy"}</h2>
            <p className={styles.subheading}>
              How to buy a diverse range of proxy types: datacenter IPv4 & IPv6,
              ISP, residential, and 4G/5G rotating mobile:
            </p>
            {numberedList}
          </div>
          <picture className={styles.image__mobile_dark}>
            <source
              srcSet={`${howToBuyIpv4ProxyImageMobile} 1x, ${howToBuyIpv4ProxyImageMobile2x} 2x`}
            />
            <img src={howToBuyIpv4ProxyImageMobile2x} alt="How to buy IPv4 Proxy" loading="lazy" />
          </picture>
        </Container>
      </div>
      <div className={styles.section__lightBackground}>
        <Container className={styles.container}>
          <div className={styles.content}>
            <h2 className={`${styles.heading} ${styles.margin__bottom}`}>
              {"{IPv4} Proxy"}
            </h2>
            <p className={styles.subheading}>
              To buy high-quality IPv4 proxies located in a huge number of
              networks and subnets with a choice of suitable locations
            </p>
            <p className={styles.dotted__list_title}>
              Services are provided according to the characteristics in the
              catalog
            </p>
            {dottedList}
          </div>
          <div className={styles.animation_service}>
            <Rive
              src={animationService}
              autoPlay
              top={-30}
              right={-39}
              bottom={-62}
              left={-64}
            />
          </div>
          <picture className={styles.image__light}>
            {/* <source
              srcSet={`${ipv4ProxyImage} 1x, ${ipv4ProxyImage2x} 2x`}
              media="(min-width: 993px)"
            /> */}
            <source
              srcSet={`${ipv4ProxyImageMobile} 1x, ${ipv4ProxyImageMobile2x} 2x`}
              // media="(max-width: 992px)"
            />
            <img src={ipv4ProxyImageMobile} alt="IPv4 Proxy" loading="lazy" />
          </picture>
        </Container>
      </div>
    </>
  );
};

export default HowToBuy;

/* eslint-disable react/no-danger */
import { useMemo, useState } from "react";

// import Rive from "@rive-app/react-canvas";
import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { Rive } from "components/ui/New/Rive/Rive";

import { animationFaq, animationFaqTools } from "assets/animations";

import Container from "../Container/Container";

import FAQCard from "./FAQCard";

import styles from "./FAQ.module.scss";

const FAQ = ({
  heading,
  description,
  list,
  image,
  image2x,
  tools,
  className
}) => {
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState(0);

  const handleOpen = (index) => {
    setActiveElement(index);
  };
  const handleClose = () => {
    setActiveElement(null);
  };
  const collapsibleListOfQuestions = useMemo(
    () =>
      list.map((el, index) => (
        <FAQCard
          handleOpen={
            activeElement === index
              ? () => handleClose()
              : () => handleOpen(index)
          }
          isOpened={activeElement === index}
          key={index}
          title={el.question}
        >
          {typeof el?.answer === "string" ? (
            <div
              itemProp="text"
              dangerouslySetInnerHTML={{ __html: el.answer }}
            />
          ) : (
            el.answer.map((item) => (
              <div itemProp="text" key={item}>
                {item}
              </div>
            ))
          )}
        </FAQCard>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, activeElement]
  );

  return (
    <div className={`${styles.section} ${className}`}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.heading}>{heading || t("faq.heading")}</h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: description || t("faq.description")
            }}
          />
          <div className={tools ? styles.animation__tools : styles.animation}>
            <Rive
              src={tools ? animationFaqTools : animationFaq}
              autoPlay
              top={tools ? -55 : -67}
              right={tools ? -69 : -97}
              bottom={tools ? -83 : -67}
              left={tools ? -69 : -97}
              className={tools ? styles.animation__tools_anim : ""}
            />
          </div>
        </div>
        <div
          className={styles.questions__list}
          itemScope
          itemType="https://schema.org/FAQPage"
        >
          {collapsibleListOfQuestions}
        </div>
        <picture className={styles.image__mobile}>
          <source srcSet={`${image} 1x, ${image2x} 2x`} />
          <img src={image} alt="How we can help you?" width="100%" loading="lazy" />
        </picture>
      </Container>
    </div>
  );
};
FAQ.propTypes = {
  description: PT.string,
  heading: PT.string,
  image: PT.node,
  list: PT.arrayOf(
    PT.shape({
      answer: PT.oneOfType([PT.string, PT.arrayOf(PT.string)]),
      question: PT.string
    })
  ),
  tools: PT.bool
};
FAQ.defaultProps = {
  image: null,
  list: [],
  animation: null,
  tools: false
};

export default FAQ;

import PT from "prop-types";

import { StyledRive, StyledRiveWrapper } from "./Rive.styled";

export const Rive = ({
  src,
  className,
  width,
  height,
  top,
  right,
  bottom,
  left,
  autoPlay,
  stateMachines,
  breakpoint
}) => (
  <StyledRiveWrapper
    className={className}
    width={width}
    height={height}
    breakpoint={breakpoint}
  >
    <StyledRive
      src={src}
      autoPlay={autoPlay}
      stateMachines={stateMachines}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
    />
  </StyledRiveWrapper>
);

Rive.propTypes = {
  src: PT.string.isRequired,
  className: PT.string,
  width: PT.number,
  height: PT.number,
  top: PT.number,
  right: PT.number,
  bottom: PT.number,
  left: PT.number,
  autoPlay: PT.bool,
  stateMachines: PT.string,
  breakpoint: PT.number
};

Rive.defaultProps = {
  className: "",
  width: null,
  height: null,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  autoPlay: true,
  stateMachines: "State Machine 1",
  breakpoint: 993
};

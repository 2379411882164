import { Link } from "react-router-dom";

import Annotation from "components/ui/New/Annotation/Annotation";
import Card from "components/ui/New/Card/Card";
import Rating from "components/ui/New/Raiting/Rating";

import { useLangUrlDefault } from "hooks";

import { ProxyTypes } from "./ProxyTypes/ProxyTypes";
import { ShortInfo } from "./ShortInfo/ShortInfo";

import styles from "./ServiceItem.module.scss";

export const ServiceItem = ({ data }) => {
  const [, hrefLang] = useLangUrlDefault();
  return (
    <Card className={styles.card} radius={"small"} size={"small"}>
      <div className={styles.title_box}>
        <img
          className={styles.title_image}
          src={data?.image ? data.image : "/img/ui/placeholder.svg"}
          alt={data?.name}
          loading="lazy"
          aria-hidden="true"
        />
        <div className={styles.title_info}>
          {/* <div className={styles.title_name}>{data?.name}</div> */}
          <Link
            to={`${hrefLang}/new/proxy/${data.alias}/`}
            className={styles.title_name}
          >
            {data?.name}
          </Link>
          <Annotation
            className={styles.title_annotation}
            positive={data?.reviewRatingPositive}
            negative={data?.reviewRatingNegative}
            showIcon
          />
        </div>
        <Rating rating={data?.rating} size={"sm"} />
      </div>
      <ProxyTypes data={data?.proxyType || []} />
      <ShortInfo
        data={{
          freeTest: data?.freeTest || false,
          refund: data?.refunds || false,
          minPrice: data?.priceMin
        }}
      />
    </Card>
  );
};

import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import IconsSVG from "../../../../ui/New/Icon/IconsSVG";

import {
  StyledPromoImage,
  StyledPromoTitle,
  StyledPromoTitleBox,
  StyledPromoTitleInfoBox,
  StyledPromoTitleTime,
  StyledPromoTitleWrapper
} from "./PromoTitle.styled";

export const PromoTitle = ({ variant, title, image, time, eternal }) => {
  const { t } = useTranslation();
  const id = useId();
  const ref = useRef(null);
  const refBox = useRef(null);
  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth - 64);
    }
  }, [ref]);

  return (
    <StyledPromoTitleBox variant={variant}>
      {variant !== "dark" && (
        <StyledPromoImage
          variant={variant}
          src={image ? image : "/img/ui/placeholder.svg"}
          loading="lazy"
          alt=""
          aria-hidden="true"
        />
      )}
      <StyledPromoTitleInfoBox variant={variant}>
        <StyledPromoTitleWrapper ref={refBox}>
          <StyledPromoTitle ref={ref} data-tooltip-id={id} variant={variant}>
            {title}
          </StyledPromoTitle>
        </StyledPromoTitleWrapper>
        {needTooltip && (
          <StyledTooltip id={id} place={"top"}>
            {title}
          </StyledTooltip>
        )}
        <StyledPromoTitleTime variant={variant} suppressHydrationWarning>
          {eternal || time ? (
            <>
              {["dark", "gray"].includes(variant) ? (
                <IconsSVG name={"hourglass"} />
              ) : (
                `${t("promocodes.valid")} `
              )}
              {time && !eternal ? (
                <Moment
                  date={new Date(time).toISOString()}
                  format="DD/MM/YYYY"
                  suppressHydrationWarning
                />
              ) : null}
              {eternal ? t("promocodes.eternal.name") : null}
            </>
          ) : null}
        </StyledPromoTitleTime>
      </StyledPromoTitleInfoBox>
    </StyledPromoTitleBox>
  );
};

PromoTitle.propTypes = {
  title: PT.string.isRequired,
  image: PT.string,
  time: PT.string,
  eternal: PT.bool
};

PromoTitle.defaultProps = {
  image: "",
  time: "",
  eternal: false
};

export default PromoTitle;

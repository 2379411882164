import { useTranslation } from "react-i18next";

import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { faqToolPortScanner } from "utils/constants/questionsAndAnswers";

import animation from "assets/animations/fingerprint.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  fingerprintHeroImageMobile,
  fingerprintHeroImageMobile2x
} from "assets/img";

import { Fingerprint } from "./Fingerprint/Fingerprint";

import styles from "./FingerprintPage.module.scss";

const FingerprintPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Main
        title={t("fingerprint.main.title")}
        subtitle={t("fingerprint.main.subtitle")}
        buttonLabel={t("fingerprint.main.buttonLabel")}
        images={{
          mobile1x: fingerprintHeroImageMobile,
          mobile2x: fingerprintHeroImageMobile2x
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-71}
            right={-63}
            bottom={-71}
            left={-63}
          />
        }
      />
      <Fingerprint />
      <FAQ
        list={faqToolPortScanner}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        className={styles.faq}
        tools
      />
      <SeoSection />
    </>
  );
};

export default FingerprintPage;

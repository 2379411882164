// import "@szhsin/react-menu";
import PT from "prop-types";
import { Link } from "react-router-dom";

import { Menu, MenuButton, MenuItem } from "./Dropdown.styled";

export const Dropdown = ({
  className,
  menuClassName,
  align,
  direction,
  label,
  children,
  portal,
  position
}) => (
  <Menu
    transition
    align={align}
    direction={direction}
    className={menuClassName}
    position={position}
    portal={portal}
    menuButton={<MenuButton className={className}>{label}</MenuButton>}
  >
    {children}
  </Menu>
);

Dropdown.propTypes = {
  className: PT.string,
  menuClassName: PT.string,
  align: PT.oneOf(["start", "end", "center"]),
  direction: PT.oneOf(["top", "bottom", "left", "right"]),
  position: PT.oneOf(["auto", "anchor", "initial"]),
  portal: PT.bool,
  label: PT.node,
  children: PT.node
};
Dropdown.defaultProps = {
  className: "",
  menuClassName: "",
  align: "center",
  direction: "bottom",
  position: "auto",
  portal: false,
  label: null,
  children: null
};

export const DropdownItem = ({
  children,
  onClick,
  linkTo,
  target,
  disabled,
  className,
  active
}) => (
  <MenuItem
    onClick={onClick}
    className={className}
    disabled={disabled}
    active={active.toString()}
  >
    {linkTo ? (
      <Link to={linkTo} target={target}>
        {children}
      </Link>
    ) : (
      children
    )}
  </MenuItem>
);

DropdownItem.propTypes = {
  children: PT.node,
  className: PT.string,
  onClick: PT.func,
  linkTo: PT.string,
  target: PT.string,
  disabled: PT.bool,
  active: PT.bool
};
DropdownItem.defaultProps = {
  children: null,
  className: "",
  onClick: null,
  linkTo: null,
  target: null,
  disabled: false,
  active: false
};

Dropdown.Item = DropdownItem;

export default Dropdown;

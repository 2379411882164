import { Tab } from "@mui/base/Tab";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import PT from "prop-types";

import "./Tabs.scss";

export const Tabs = ({
  content,
  value,
  defaultValue,
  orientation,
  onChange,
  withoutPanel,
  className
}) => {
  const hendlerChange = (_, value) => {
    onChange?.(value);
  };
  return (
    <div className={className}>
      <BaseTabs
        defaultValue={
          defaultValue || content?.find((it) => !it?.disabled)?.value
        }
        value={value}
        onChange={hendlerChange}
        orientation={orientation}
      >
        <TabsList>
          <div className="tabs__list">
            {content?.map((it) => (
              <Tab
                key={`tab-${it?.value}`}
                value={it?.value}
                disabled={it?.disabled}
              >
                {it?.label}
              </Tab>
            ))}
          </div>
        </TabsList>
        {withoutPanel
          ? null
          : content
              ?.map((it) =>
                it?.component ? (
                  <TabPanel key={`panel-${it?.value}`} value={it?.value}>
                    {it?.component}
                  </TabPanel>
                ) : null
              )
              .filter((it) => it)}
      </BaseTabs>
    </div>
  );
};

Tabs.propTypes = {
  content: PT.arrayOf(
    PT.shape({
      label: PT.node,
      value: PT.string,
      component: PT.node,
      disabled: PT.bool
    })
  ),
  orientation: PT.oneOf(["horizontal", "vertical"]),
  defaultValue: PT.string,
  value: PT.string,
  onChange: PT.func,
  withoutPanel: PT.bool,
  className: PT.string
};

Tabs.defaultProps = {
  content: [],
  orientation: "horizontal",
  defaultValue: null,
  value: null,
  onChange: null,
  withoutPanel: false,
  className: ""
};

export default Tabs;

import PT from "prop-types";

import Container from "../Container/Container";

import { BreadcrumbsItem } from "./BreadcrumbsItem";

import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs = ({ items, className }) => (
  <Container className={className}>
    <ul className={styles.breadcrumbs__list}>
      {items.map((item) => (
        <BreadcrumbsItem key={item.title} item={item} />
      ))}
    </ul>
  </Container>
);

Breadcrumbs.propTypes = {
  className: PT.string,
  items: PT.arrayOf(PT.oneOfType([PT.string, PT.shape({})]))
};

Breadcrumbs.defaultProps = {
  className: "",
  items: []
};

export default Breadcrumbs;

import axios from "axios";

import { AllActions } from "../../../store/reducers/AllActions";

import { Comparison } from "./Comparison/Comparison";

const ComparisonPage = () => (
  <>
    <Comparison />
  </>
);

export default ComparisonPage;

ComparisonPage.getServerSideState = async (store, params, api, req) => {
  const [, identifier] = req._parsedUrl.pathname.split("/").reverse();

  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });
  const { data: comparison } = await axios.get(
    `${api}/api/front/site/proxy/comparison`,
    { params: { uuid: identifier } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setFullSites(comparison));
};

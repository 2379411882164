import { useEffect, useId, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import styles from "../Info.module.scss";

export const Contacts = ({ active, data }) => {
  const ref = useRef();
  const refBox = useRef();
  const id = useId();
  // **Redux state
  const { t } = useTranslation();
  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth);
    }
  }, [ref]);

  return (
    <div className={`${styles.info_block} ${active ? styles.info_active : ""}`}>
      <div className={styles.info_title}>
        {t("proxySitePage.info.contacts.title")}
      </div>
      <ul className={styles.social_contacts}>
        {data.map((item, i) => (
          <li
            key={`keycon${item?.code}${i}`}
            className={styles.social_contacts_item}
          >
            <Link
              to={item?.link}
              target="_blank"
              rel="noreferrer"
              ref={refBox}
              className={styles.social_link}
            >
              <img src={`/img/social/${item?.code}.svg`} alt={item?.code} />
              <span ref={ref} data-tooltip-id={`${id}${i}`}>
                {item?.link}
              </span>
            </Link>
            {needTooltip ? (
              <StyledTooltip id={`${id}${i}`} target={ref}>
                {item?.link}
              </StyledTooltip>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

import styled from "@emotion/styled";

export const StyledSpesialOfferCard = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -26px;
  margin-bottom: 64px;
  padding: 0;
  padding-top: 24px;
  border-radius: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.card};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -46px;
    margin-bottom: 96px;
    padding-top: 36px;
  }
`;

export const StyledSpesialOffersHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 36px;
  }
`;

export const StyledSpesialOffersTitle = styled.h2`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-width: 590px;
    max-width: 590px;
    font-size: ${(p) => p.theme.fontSizes.xll};
  }
`;

export const StyledSpesialOffersDescription = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex: 1;
    min-width: 400px;
  }
`;

export const StyledSpesialOffersListBox = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 24px 16px 42px 16px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 40px 36px 36px 36px !important;
  }

  & ul,
  & ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

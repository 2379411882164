import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Icon from "components/ui/New/Icon/Icon";
import { ReadMore } from "components/ui/New/ReadMore/ReadMore";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import styles from "./BlogNavItem.module.scss";

const BlogNavItem = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  // **Dispatch
  const { setBurgerMenu, setServiceMenu, getBlogSidebarContent } =
    useDispatchedActions();

  const { sidebar } = useSelector(getAllBlogInner);

  const closeMenu = () => {
    setBurgerMenu(false);
    setServiceMenu(false);
  };

  useEffect(() => {
    getBlogSidebarContent({ languageCode: queryLang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  const listMemo = useMemo(
    () => (
      <ul className={styles.list}>
        {sidebar.data?.latest?.slice(0, 2)?.map((el) => (
          <li key={el.title} className={styles.list__item}>
            <Link
              to={`${hrefLang}/new/blog/${el.alias}`}
              className={styles.list__link}
              onClick={closeMenu}
            >
              {el?.title}
              <Icon name="arrowAltRight" className={styles.icon} />
            </Link>
          </li>
        ))}
      </ul>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebar?.data?.latest]
  );

  return (
    <div className={styles.navigation__item}>
      <div className={styles.title}>{t("navigation.interestingRead")}</div>
      <div className={styles.preview}>
        <picture className={styles.image}>
          <source
            srcSet={`${
              sidebar.data?.popular[0]?.coverImageSmall ||
              "/img/ui/blog-placeholder.svg"
            } 1x, ${
              sidebar.data?.popular[0]?.coverImage ||
              "/img/ui/blog-placeholder.svg"
            } 2x`}
          />
          <img
            src={sidebar.data?.popular[0]?.coverImageSmall}
            alt={sidebar.data?.popular[0]?.title}
            loading="lazy"
          />
        </picture>
        <Link
          to={`${hrefLang}/new/blog/${sidebar.data?.popular[0]?.alias}`}
          onClick={closeMenu}
        >
          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <p className={styles.preview__title}>
                {sidebar.data?.popular[0]?.title}
              </p>
              <p className={styles.description}>
                {sidebar.data?.popular[0]?.description}
              </p>
            </div>
            <ReadMore variant="light" />
          </div>
        </Link>
      </div>
      {listMemo}
    </div>
  );
};

export default BlogNavItem;

// import Card from "components/ui/New/Card/Card";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  CountryNameCell,
  IndexCell,
  NewCell,
  TextCell
} from "components/common/New/TableColumns";
import TextButton from "components/ui/New/TextButton/TextButton";

import { useLangUrlDefault } from "hooks";

import {
  StyledUserChoiceSpase,
  StyledUserChoiceTable,
  StyledUserChoiceTitle
} from "./UserChoiceTable.styled";
import { choiceTableData } from "./mockData";

export const UserChoiceTable = () => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const [data] = useState(choiceTableData);

  const columns = [
    {
      name: "№",
      cell: (row, index) => {
        const currentNumber = index + 1;
        return (
          <IndexCell>
            {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
          </IndexCell>
        );
      },
      maxWidth: "50px",
      minWidth: "32px",
      center: true
    },
    {
      name: t("mainPage.userChoice.columns.name").toUpperCase(),
      cell: (row) => <CountryNameCell name={row?.name} code={row?.code} />,
      width: "175px"
    },
    {
      cell: (row) => <NewCell isNew={Boolean(row?.new)} />,
      minWidth: "75px"
    },
    {
      name: t("mainPage.userChoice.columns.price").toUpperCase(),
      cell: (row) => <TextCell>{`${row?.price} USD`}</TextCell>,
      minWidth: "95px"
    },
    {
      name: t("mainPage.userChoice.columns.offers").toUpperCase(),
      cell: (row) => <TextCell>{row?.offers}</TextCell>,
      minWidth: "90px"
    },
    {
      name: t("mainPage.userChoice.columns.site").toUpperCase(),
      cell: (row) => (
        <TextButton
          size={"sm"}
          color={"table"}
          iconRight={"arrowAltRight"}
          linkTo={`${hrefLang}/new/proxy/${row.alias}/`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      ),
      minWidth: "80px"
    }
  ];

  return (
    <StyledUserChoiceSpase>
      <StyledUserChoiceTitle tag={"h3"}>
        {t("mainPage.userChoice.title")}
      </StyledUserChoiceTitle>

      <StyledUserChoiceTable data={data} columns={columns} />
    </StyledUserChoiceSpase>
  );
};

import { useSelector } from "react-redux";

import { SectionHead } from "components/common/New/SectionHead/SectionHead";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

export const ProxyContentTop = () => {
  // **Redux state
  const { pageContent } = useSelector(getAllContent);

  return (
    <SectionHead
      title={pageContent?.current?.data?.h1 || ""}
      description={pageContent.current?.data?.description}
    />
  );
};

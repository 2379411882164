import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Input } from "components/forms/New";
import Button from "components/ui/New/Button/Button";

import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

import { useLangUrlDefault } from "hooks";

import { ProfileChangeEmailSchema } from "utils/validation";

import { FormBlock } from "../../components/FormBlock/FormBlock";
import { ModalConfirmCode } from "../ModalConfirmCode/ModalConfirmCode";

import { StyledButtonBox } from "./EmailBlock.styled";

// eslint-disable-next-line import/order
import styles from "../Profile.module.scss";

export const EmailBlock = () => {
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  // **Redux state
  const { user } = useSelector(getAllUser);

  // const methods = useForm({ defaultValues: { email: user.email } });
  const methods = useForm({
    defaultValues: { newEmail: user?.email },
    resolver: yupResolver(
      ProfileChangeEmailSchema(t("forms", { returnObjects: true }))
    )
  });
  const { newEmail } = methods.watch();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async (data) => {
    setLoading(true);
    try {
      const res = await ApiService.changeEmail({
        ...data,
        locale: queryLang
      });
      if (res.status === 200) {
        toast.success(t("dashboard.profile.confirmModal.successSend"));
        setModalOpen(true);
      }
      if (res?.response?.status === 400) {
        if (res.response?.data?.error === "PASSWORD_DOESNT_MATCHES") {
          methods.setError("password", {
            type: "manual",
            message: t("dashboard.profile.confirmModal.invalidPassword")
          });
        }
        if (res.response?.data?.error === "INVALID_EMAIL") {
          methods.setError("newEmail", {
            type: "manual",
            message: t("dashboard.profile.confirmModal.invalidEmail")
          });
        }
      }
      if (res?.response?.status === 409) {
        methods.setError("newEmail", {
          type: "manual",
          message: t("dashboard.profile.confirmModal.emailExist")
        });
      }
    } catch (error) {
      toast.error(t("notifications.serverError"));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    methods.reset({ newEmail: user.email });
    methods.clearErrors();
  };

  useEffect(() => {
    if (methods && Object.keys(methods.formState.errors).length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSave)}>
          <FormBlock
            title={t("dashboard.profile.blockEmail.title")}
            subtitle={t("dashboard.profile.blockEmail.subtitle")}
            className={styles.email_block}
          >
            <Input
              name="newEmail"
              label={t("forms.emailV2.label")}
              placeholder={t("forms.emailV2.placeholder")}
              defaultValue={user.email}
              type="email"
              showError
            />
            {newEmail !== user.email && (
              <>
                <Input
                  name="password"
                  label={t("forms.password.labelChangeEmail")}
                  placeholder={t("forms.password.placeholderCurrent")}
                  type="password"
                  showError
                />
                <StyledButtonBox>
                  <Button formSubmit loading={loading}>
                    {t("dashboard.profile.blockEmail.change")}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleCancel}
                    disabled={loading}
                  >
                    {t("forms.buttons.cancel")}
                  </Button>
                </StyledButtonBox>
              </>
            )}
          </FormBlock>
        </form>
      </FormProvider>
      <ModalConfirmCode open={modalOpen} close={setModalOpen} />
    </>
  );
};

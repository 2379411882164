import axios from "axios";

import { AllActions } from "../../../store/reducers/AllActions";

import { ProxySite } from "./ProxySite/ProxySite";

const ProxySitePage = () => (
  <>
    <ProxySite />
  </>
);

export default ProxySitePage;

ProxySitePage.getServerSideState = async (store, params, api, req) => {
  let identifier = "";

  if (params.tag === "proxy-site-promo") {
    [, , identifier] = req._parsedUrl.pathname.split("/").reverse();
  } else {
    [, identifier] = req._parsedUrl.pathname.split("/").reverse();
  }

  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    {
      ...params,
      params: {
        ...params.params,
        siteId: identifier
      }
    }
  );

  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );

  const { data: proxySiteInfo } = await axios.get(
    `${api}/api/front/site/proxy/description`,
    { params: { id: identifier } }
  );
  const { data: reviewsAmount } = await axios.get(
    `${api}/api/front/site/reviews/amount`,
    { params: { siteId: identifier } }
  );
  const { data: rating } = await axios.get(`${api}/api/front/site/rating`, {
    params: { siteId: identifier, step: "days" }
  });
  const { data: promocodes } = await axios.get(
    `${api}/api/admin/promocode/site`,
    { params: { siteId: identifier } }
  );
  const { data: review } = await axios.get(
    `${api}/api/front/site/review/sort`,
    {
      params: {
        sort: "creationDate",
        siteId: identifier,
        page: 0,
        size: 10
      }
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setProxySiteInfo(proxySiteInfo));
  store.dispatch(AllActions.setProxySiteReviewsAmount(reviewsAmount));
  store.dispatch(AllActions.setProxySiteRating(rating));
  store.dispatch(AllActions.setAllPromocodesBySite(promocodes));
  store.dispatch(AllActions.setProxySiteReviews(review));
};

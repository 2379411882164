import Rive from "@rive-app/react-canvas";
import PT from "prop-types";

import { Title } from "components/ui/New/Title/Title";

import styles from "./PortScannerSteps.module.scss";

export const PortScannerStep = ({
  title,
  image,
  animation,
  description,
  index
}) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <Title tag="h2" className={styles.title}>
        {title}
      </Title>
      <div className={styles.step}>{`Step ${index}`}</div>
      <div className={styles.description}>{description}</div>
    </div>
    {animation && (
      <div className={styles.animation__wrapper}>
        <Rive
          src={animation}
          autoPlay
          className={styles.animation}
          stateMachines="State Machine 1"
        />
      </div>
    )}
    <div className={animation ? styles.image__mobile : styles.image}>
      <img
        src={image}
        loading="lazy"
        alt={`${title} - ${index}`}
        aria-hidden="true"
      />
    </div>
  </div>
);

PortScannerStep.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};

PortScannerStep.defaultProps = {
  title: "",
  description: "",
  image: null
};

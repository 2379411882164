import PT from "prop-types";

import styles from "./HttpHeaderCheck.module.scss";

const HttpHeaderCheckItem = ({ title, image, value }) => (
  <div className={styles.item}>
    <div className={styles.image}>
      <img src={image} loading="lazy" alt={title} aria-hidden="true" />
    </div>
    <div className={styles.content}>
      <p className={styles.item__title}>
        {title}
      </p>
      <div className={styles.value}>{value}</div>
    </div>
  </div>
);

HttpHeaderCheckItem.propTypes = {
  title: PT.string,
  value: PT.string,
  image: PT.node
};

HttpHeaderCheckItem.defaultProps = {
  title: "",
  value: "",
  image: null
};

export default HttpHeaderCheckItem;

import { useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthInput } from "../../../components/forms/AuthInput/AuthInput";
import { Button } from "../../../components/ui/Button/Button";
import { Loader } from "../../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { PasswordRecoverySchema } from "../../../utils/validation";
import { Authorization } from "../components/Authorization/Authorization";

import "../Auth.scss";

export const PasswordRecoveryPage = () => {
  const captchaRef = useRef(null);

  // **Props
  const { token } = useParams();
  const [hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // **Redux state
  const {
    pageContent: { current },
    captchaConfig
  } = useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const [, setTokenCaptcha] = useState(null);
  const [email, setEmail] = useState();
  const { enable, clientKey, headerName } = captchaConfig.data;

  // Form
  const methods = useForm({
    resolver: yupResolver(
      PasswordRecoverySchema(t("forms", { returnObjects: true }))
    )
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      setIsLoading(true);

      const response = await ApiService.restorePassword(
        {
          email,
          token,
          password: data?.password
        },
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      methods.reset();
      toast.success(t("notifications.passwordRecovery"));

      navigate(`${hrefLang}/login/`, { replace: true });
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  const getUserEmailByToken = async () => {
    try {
      if (!token) {
        toast.error(t("notifications.apiError"));
        return;
      }

      const response = await ApiService.getEmailByToken(token);

      if (response && response.status !== 200) {
        throw response;
      }

      if (!response?.data) {
        throw response;
      }

      setEmail(response.data);
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    }
  };

  useEffect(() => {
    getUserEmailByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Authorization title={current.data?.h1}>
        <div className="auth__form-wrapper">
          <FormProvider {...methods}>
            <form
              className="auth__form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <AuthInput
                placeholder={t("forms.password.newPassword")}
                type="password"
                name="password"
                showError
              />
              {enable && clientKey && (
                <HCaptcha
                  ref={captchaRef}
                  sitekey={clientKey}
                  onVerify={setTokenCaptcha}
                  onExpire={() => setTokenCaptcha(null)}
                  onError={(err) => toast.error(err?.message)}
                  size="invisible"
                />
              )}
              <Button
                disabled={isLoading}
                size="auth"
                position="center"
                type="solid"
              >
                {!isLoading ? (
                  t("authPages.recovery.btn")
                ) : (
                  <Loader
                    type="absolute"
                    scale={0.6}
                    color="var(--clr-default-900)"
                  />
                )}
              </Button>
            </form>
          </FormProvider>
        </div>
      </Authorization>
    </>
  );
};

export default PasswordRecoveryPage;

PasswordRecoveryPage.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
};

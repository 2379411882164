import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledReadReview = styled(Link)`
  position: relative;
  padding: 3px 7px;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-weight: 400;
  line-height: 108%;
  text-align: left;
  background-color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
  white-space: nowrap;
  text-wrap: nowrap;
  color: ${(p) => p.theme.colors["neutral-400"]};
  border: 1px solid ${(p) => p.theme.colors["outline-color"]};
  transition: all 0.3s ease;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;

    border-bottom: ${(p) => (p.v1 === "true" ? "12px" : "6px")} solid
      ${(p) => p.theme.colors["outline-color"]};
    border-right: 6px solid transparent;
    border-top: ${(p) => (p.v1 === "true" ? "12px" : "6px")} solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    top: ${(p) => (p.v1 === "true" ? "calc(100% - 12px)" : "0")};
    left: ${(p) => (p.v1 === "true" ? "5px" : "0")};
    transform: ${(p) =>
      p.v1 === "true" ? "rotate(90deg)" : "translateX(-50%) rotate(45deg);"};
    transition: all 0.3s ease;
  }
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;

    border-bottom: ${(p) => (p.v1 === "true" ? "12px" : "6px")} solid
      ${(p) => p.theme.colors.white};
    border-right: 6px solid transparent;
    border-top: ${(p) => (p.v1 === "true" ? "12px" : "6px")} solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    top: ${(p) => (p.v1 === "true" ? "calc(100% - 13px)" : "1px")};
    left: ${(p) => (p.v1 === "true" ? "6px" : "2px")};
    transform: ${(p) =>
      p.v1 === "true" ? "rotate(90deg)" : "translateX(-50%) rotate(45deg)"};
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["neutral-800"]};
    border-color: ${(p) => p.theme.colors["main-500"]};
    background-color: ${(p) => p.theme.colors["main-500"]};
    transition: all 0.3s ease;

    &::before {
      border-bottom-color: ${(p) => p.theme.colors["main-500"]};
      transition: all 0.3s ease;
    }
    &::after {
      border-bottom-color: ${(p) => p.theme.colors["main-500"]};
      transition: all 0.3s ease;
    }
  }

  &:active {
    color: ${(p) => p.theme.colors["neutral-800"]};
    border-color: ${(p) => p.theme.colors["main-500"]};
    background: ${(p) => p.theme.colors["main-500"]};
    transition: all 0.3s ease;

    &::before {
      border-bottom-color: ${(p) => p.theme.colors["main-500"]};
      transition: all 0.3s ease;
    }
    &::after {
      border-bottom-color: ${(p) => p.theme.colors["main-500"]};
      transition: all 0.3s ease;
    }
  }
`;

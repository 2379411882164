import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getAllPromocodesBySite,
  getProxySiteCompaints,
  getProxySiteInfo,
  getProxySiteRating,
  getProxySiteReviews,
  getProxySiteReviewsAmount,
  getProxySites,
  setSiteViewStatistic
} from "./ProxySite.thunks";

const initialState = {
  site: {
    data: {
      id: "",
      name: "",
      affiliateProgram: false,
      clientIPAuth: false,
      clientLoginAuth: false,
      clientNoAuth: false,
      countries: [],
      freeTest: false,
      price: 0,
      individual: false,
      proxyType: [],
      minRentPeriod: 0,
      purposeUse: [],
      refunds: false,
      replacementPossibility: false
    },
    isLoading: false,
    isError: null
  },
  statistic: {
    isStatisticSites: [],
    isLoading: false,
    isError: null
  },
  reviewsAmount: {
    data: null,
    isLoading: false,
    isError: null
  },
  rating: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  reviews: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isError: null,
    isLoading: false,
    reviewPage: null,
    reviewId: null
  },
  complaints: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isError: null,
    isLoading: false
  },
  promocodes: {
    data: [],
    isLoading: false,
    isError: null
  },
  proxySites: {
    totalElements: 0,
    totalPages: 0,
    content: [],
    fetchParams: {},
    skip: 0,
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  proxySitesType: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};

const ProxySiteReducer = createSlice({
  name: "proxysite",
  initialState,
  reducers: {
    setReviewInfo(state, action) {
      state.reviews.reviewPage = action.payload.page;
      state.reviews.reviewId = action.payload.id;
    },
    setProxySiteInfo(state, action) {
      state.site.data = action.payload;
    },
    setProxySiteReviewsAmount(state, action) {
      state.reviewsAmount.data = action.payload;
    },
    setProxySiteRating(state, action) {
      state.rating.data = action.payload;
      state.rating.isDataLoaded = true;
    },
    setAllPromocodesBySite(state, action) {
      state.promocodes.data = action.payload;
    },
    setProxySiteReviews(state, action) {
      state.reviews.data = action.payload;
    },
    setProxySites(state, action) {
      state.proxySites.content = action.payload.content;
      state.proxySites.totalElements = action.payload.totalElements;
      state.proxySites.totalPages = action.payload.totalPages;
      state.proxySites.fetchParams = action.payload.fetchParams;
      state.proxySites.skip = 0;
      state.proxySites.isDataLoaded = true;
    },
    setProxySitesFetchParams(state, action) {
      state.proxySites.fetchParams = action.payload;
    },
    setProxySitesType(state, action) {
      state.proxySitesType.data = action.payload;
      state.proxySitesType.isDataLoaded = true;
    }
  },
  extraReducers: {
    [getProxySiteInfo.pending]: (state) => {
      state.site.isError = null;
      state.site.isLoading = true;
    },
    [getProxySiteInfo.fulfilled]: (state, action) => {
      state.site.isError = null;
      state.site.data = action.payload;
      state.site.isLoading = false;
    },
    [getProxySiteInfo.rejected]: (state, action) => {
      state.site.isError = action.payload;
      state.site.isLoading = false;
    },

    [setSiteViewStatistic.pending]: (state) => {
      state.statistic.isError = null;
      state.statistic.isLoading = true;
    },
    [setSiteViewStatistic.fulfilled]: (state, action) => {
      state.statistic.isError = null;
      state.statistic.isStatisticSites = [
        ...state.statistic.isStatisticSites,
        action.payload
      ];
      state.statistic.isLoading = false;
    },
    [setSiteViewStatistic.rejected]: (state, action) => {
      state.statistic.isError = action.payload;
      state.statistic.isLoading = false;
    },

    [getProxySiteReviewsAmount.pending]: (state) => {
      state.reviewsAmount.isError = null;
      state.reviewsAmount.isLoading = true;
    },
    [getProxySiteReviewsAmount.fulfilled]: (state, action) => {
      state.reviewsAmount.isError = null;
      state.reviewsAmount.data = action.payload;
      state.reviewsAmount.isLoading = false;
    },
    [getProxySiteReviewsAmount.rejected]: (state, action) => {
      state.reviewsAmount.isError = action.payload;
      state.reviewsAmount.isLoading = false;
    },

    [getProxySiteRating.pending]: (state) => {
      state.rating.isError = null;
      state.rating.isLoading = true;
    },
    [getProxySiteRating.fulfilled]: (state, action) => {
      state.rating.isError = null;
      state.rating.data = action.payload;
      state.rating.isDataLoaded = true;
      state.rating.isLoading = false;
    },
    [getProxySiteRating.rejected]: (state, action) => {
      state.rating.isError = action.payload;
      state.rating.isLoading = false;
    },

    [getProxySiteReviews.pending]: (state) => {
      state.reviews.isError = null;
      state.reviews.isLoading = true;
    },
    [getProxySiteReviews.fulfilled]: (state, action) => {
      state.reviews.isError = null;

      if (action.payload?.content) {
        state.reviews.data = action.payload;
      } else {
        state.reviews.data = {
          content: [],
          totalElements: 0,
          totalPages: 0
        };
      }

      state.reviews.isLoading = false;
    },
    [getProxySiteReviews.rejected]: (state, action) => {
      state.reviews.isError = action.payload;
      state.reviews.isLoading = false;
    },

    [getProxySiteCompaints.pending]: (state) => {
      state.complaints.isError = null;
      state.complaints.isLoading = true;
    },
    [getProxySiteCompaints.fulfilled]: (state, action) => {
      state.complaints.isError = null;
      state.complaints.data = action.payload;
      state.complaints.isLoading = false;
    },
    [getProxySiteCompaints.rejected]: (state, action) => {
      state.complaints.isError = action.payload;
      state.complaints.isLoading = false;
    },

    [getAllPromocodesBySite.pending]: (state) => {
      state.promocodes.isError = null;
      state.promocodes.isLoading = true;
    },
    [getAllPromocodesBySite.fulfilled]: (state, action) => {
      state.promocodes.isError = null;
      state.promocodes.data = action.payload;
      state.promocodes.isLoading = false;
    },
    [getAllPromocodesBySite.rejected]: (state, action) => {
      state.promocodes.isError = action.payload;
      state.promocodes.isLoading = false;
    },
    [getProxySites.pending]: (state) => {
      state.proxySites.isLoading = true;
      state.proxySites.isError = null;
    },
    [getProxySites.fulfilled]: (state, action) => {
      state.proxySites.isLoading = false;
      state.proxySites.content = action.payload.content;
      state.proxySites.totalElements = action.payload.totalElements;
      state.proxySites.totalPages = action.payload.totalPages;
      state.proxySites.skip = action.payload.skip;
      state.proxySites.fetchParams = action.payload.params;
      state.proxySites.isDataLoaded = true;
    },
    [getProxySites.rejected]: (state, action) => {
      state.proxySites.isLoading = false;
      state.proxySites.isError = action.payload;
    }
  }
});

export const ProxySiteReducerActions = {
  ...ProxySiteReducer.actions,
  getProxySiteInfo,
  setSiteViewStatistic,
  getProxySiteReviewsAmount,
  getProxySiteRating,
  getProxySiteReviews,
  getProxySiteCompaints,
  getAllPromocodesBySite,
  getProxySites
};
export default ProxySiteReducer.reducer;

import { useTranslation } from "react-i18next";

import YesNo from "components/common/New/YesNo/YesNo";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

import {
  StyledContentList,
  StyledContentText,
  StyledContentTitle
} from "../../ExpandebleComponent.styled";

export const Characteristics = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const auth = []
    .concat(
      data?.clientIPAuth
        ? [t("proxyPage.content.table.characteristics.autorization.ip")]
        : []
    )
    .concat(
      data?.clientLoginAuth
        ? [t("proxyPage.content.table.characteristics.autorization.login")]
        : []
    )
    .concat(
      data?.clientNoAuth
        ? [t("proxyPage.content.table.characteristics.autorization.noAuth")]
        : []
    )
    .filter(Boolean);
  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.characteristics.title")}
      </StyledContentTitle>
      <StyledContentList type="characteristics">
        <li>
          <StyledContentTitle sub>
            {t("proxyPage.content.table.characteristics.autorization.title")}
          </StyledContentTitle>
          <StyledContentText>{auth.join(", ")}</StyledContentText>
        </li>
        <li>
          <StyledContentTitle sub>
            {t("proxyPage.content.table.api.title")}
          </StyledContentTitle>
          <YesNo value={data?.api} />
        </li>
        <li>
          <StyledContentTitle sub>
            {t("proxyPage.content.table.name.domainYear")}
          </StyledContentTitle>
          <StyledContentText>
            {time(
              "year",
              data?.domainYear,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </StyledContentText>
        </li>
      </StyledContentList>
    </div>
  );
};

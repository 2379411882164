import { useState } from "react";

import { Button } from "@mui/base/Button";
import { Input as BaseInput } from "@mui/base/Input";
import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import IconsSVG from "../../../ui/New/Icon/IconsSVG";
import FormError from "../FormError/FormError";

// import IconsSVG from "../../Icon/IconsSVG";
import styles from "./Input.module.scss";

export const Input = ({
  label,
  name,
  rules,
  defaultValue,
  placeholder,
  type,
  readOnly,
  disabled,
  showError,
  className,
  showMaxLenght,
  showParentError,
  parentErrorPath,
  textError,
  rows
}) => {
  // ** Hooks
  const {
    control,
    formState: { errors }
  } = useFormContext();
  // **Redux state
  const { t } = useTranslation();

  const [showPass, setShowPass] = useState(false);

  const handleShowPass = () => setShowPass((prev) => !prev);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div className={`${styles.wrapper} ${className}`}>
          <label>
            {label && <p className={styles.label}>{label}</p>}
            <BaseInput
              className={`${styles.input} ${
                type === "textarea" ? styles.textarea : ""
              } ${
                showError &&
                showParentError &&
                errors[parentErrorPath] &&
                styles.input_error
              }`}
              id={name}
              error={!!error}
              placeholder={placeholder}
              multiline={type === "textarea"}
              readOnly={readOnly}
              type={
                (type === "password" && showPass) || type === "textarea"
                  ? "text"
                  : type
              }
              rows={rows}
              disabled={disabled}
              slotProps={{
                input: {
                  ...field,
                  onChange: (e) => {
                    if (e.target.value.startsWith(" ")) {
                      const test = e.target.value.trimStart();
                      field.onChange(test);
                    } else {
                      field.onChange(e.target.value);
                    }
                  },
                  onBlur: (e) => {
                    e.target.value = e.target.value.trim();
                    field.onChange(e);
                  },
                  step: "any"
                }
              }}
              endAdornment={
                type === "password" && (
                  <Button
                    className={styles.showPass}
                    aria-label={
                      showPass
                        ? t("forms.password.state.hide")
                        : t("forms.password.state.show")
                    }
                    onClick={handleShowPass}
                  >
                    <IconsSVG name={showPass ? "eye" : "eyeOff"} />
                  </Button>
                )
              }
            />
          </label>
          {showMaxLenght && (
            <div
              className={`${styles.maxLenght} ${
                field.value.length > showMaxLenght ? styles.maxLenght_error : ""
              } `}
            >
              {field.value.length}/{showMaxLenght}
            </div>
          )}
          {showError && !!error?.message && (
            <FormError message={textError || error.message} />
          )}
          {showError &&
            showParentError &&
            !error?.message &&
            !!errors[parentErrorPath]?.message && (
              <FormError message={errors[parentErrorPath]?.message} />
            )}
        </div>
      )}
    />
  );
};

Input.propTypes = {
  label: PT.string,
  name: PT.string.isRequired,
  rules: PT.object,
  defaultValue: PT.string,
  placeholder: PT.string,
  type: PT.string,
  readOnly: PT.bool,
  disabled: PT.bool,
  showError: PT.bool,
  className: PT.string,
  showMaxLenght: PT.number,
  converterOnChange: PT.func,
  rows: PT.number
};

Input.defaultProps = {
  label: "",
  rules: {},
  defaultValue: "",
  placeholder: "",
  type: "text",
  readOnly: false,
  disabled: false,
  showError: false,
  className: "",
  showMaxLenght: null
};

export default Input;

import PT from "prop-types";

import styles from "./Card.module.scss";

export const Card = ({
  className,
  shadow,
  size,
  bgColor,
  radius,
  style,
  children
}) => {
  const defineClasses = () => {
    const classes = [styles.card];

    switch (size) {
      case "small":
        classes.push(styles.card_small);
        break;
      case "medium":
        classes.push(styles.card_medium);
        break;
      case "large":
        classes.push(styles.card_large);
        break;
      default:
        break;
    }

    switch (radius) {
      case "small":
        classes.push(styles.card_radius_small);
        break;
      case "medium":
        classes.push(styles.card_radius_medium);
        break;
      case "large":
        classes.push(styles.card_radius_large);
        break;
      default:
        break;
    }

    switch (bgColor) {
      case "white":
        classes.push(styles.card_bgWhite);
        break;
      case "gray":
        classes.push(styles.card_bgGrey);
        break;
      case "black":
        classes.push(styles.card_bgBlack);
        break;
      default:
        break;
    }

    if (shadow) classes.push(styles.card_shadow);

    if (className) classes.push(className);

    return classes.join(" ");
  };

  return (
    <div className={defineClasses()} style={style}>
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PT.string,
  size: PT.oneOf(["small", "medium", "large"]),
  radius: PT.oneOf(["small", "medium", "large"]),
  bgColor: PT.oneOf(["white", "gray", "black"]),
  style: PT.shape({}),
  shadow: PT.bool,
  children: PT.node
};

Card.defaultProps = {
  className: "",
  size: "medium",
  radius: "medium",
  bgColor: "white",
  style: {},
  shadow: false,
  children: null
};

export default Card;

import { useState } from "react";

import PT from "prop-types";

import IconsSVG from "../Icon/IconsSVG";

import styles from "./Alert.module.scss";

export const Alert = ({
  title,
  showIcon,
  closable,
  onClose,
  type,
  className,
  children
}) => {
  const defineClassname = (close = false) => {
    const classes = [styles.alert];
    if (type) {
      classes.push(styles[`alert_${type}`]);
    }
    if (className) {
      classes.push(className);
    }

    if (close) {
      classes.push(styles.alert_close);
    }

    return classes.join(" ");
  };

  const [classes, setClasses] = useState(defineClassname());

  const handlerClose = () => {
    setClasses(defineClassname(true));
    onClose?.();
  };

  return (
    <div className={classes}>
      {showIcon && <IconsSVG name={type} className={styles.icon} />}
      <div className={styles.content}>
        {title && <strong>{title}</strong>}
        <div className={styles.text}>{children}</div>
      </div>
      {closable && (
        <button
          type="button"
          aria-label="Close"
          className={styles.close}
          onClick={handlerClose}
        >
          <IconsSVG name="x" />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  title: PT.string,
  showIcon: PT.bool,
  closable: PT.bool,
  onClose: PT.func,
  type: PT.oneOf(["info", "success", "warning", "error"]),
  className: PT.string,
  children: PT.node
};

Alert.defaultProps = {
  title: "",
  showIcon: true,
  closable: true,
  onClose: null,
  type: "info",
  className: "",
  children: null
};

import PT from "prop-types";

import { StyletTitle } from "./Title.styled";

// import styles from "./Title.module.scss";

export const Title = ({ tag, className, size, color, children }) => {
  const Tag = StyletTitle?.[tag] ? StyletTitle[tag] : StyletTitle.div;

  return (
    <Tag size={size} color={color} className={className}>
      {children || ""}
    </Tag>
  );
};

Title.propTypes = {
  title: PT.string,
  tag: PT.string,
  className: PT.string,
  size: PT.oneOf(["sm"]),
  color: PT.oneOf(["white"])
};

Title.defaultProps = {
  title: null,
  tag: "h2",
  className: "",
  size: null,
  color: null
};

import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "components/common/New/Progress/Progress";
import { Input } from "components/forms/New/Input/Input";
import Button from "components/ui/New/Button/Button";
import SelectNew from "components/ui/New/Select/SelectNew";
import { Title } from "components/ui/New/Title/Title";

import { useUserIp } from "hooks/useUserIp";

import { TraicingIpSchema } from "utils/validation/traicingIp.validation";

import styles from "./TraicingIp.module.scss";

const mockData = [
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed",
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed"
];

export const TraicingIp = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      TraicingIpSchema(t("tracingIp", { returnObjects: true }))
    )
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      methods.reset();

      setResult(mockData);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Title tag="h2" className={styles.title}>
        {t("tracingIp.main.title")}
      </Title>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <div className={styles.input}>
            <Input
              name="ipAddress"
              type="text"
              placeholder={t("tracingIp.form.enterIP")}
              label={t("tracingIp.form.inputLabel")}
              showError
            />
            <div
              className={styles.additional_text}
              onClick={() => {
                methods.setValue("ipAddress", userIp?.ipAddress);
                methods.clearErrors();
              }}
            >
              {t("portScanner.form.pasteIP")}
            </div>
          </div>
          <SelectNew
            name="type"
            id="type"
            placeholder={t("ui.yesNo.yes")}
            defaultValue="yes"
            label={t("tracingIp.form.selectLabel")}
            showError
            className={styles.select}
            options={[
              {
                label: t("ui.yesNo.yes"),
                value: "yes"
              },
              { label: t("ui.yesNo.no"), value: "no" }
            ]}
          />
          <Button
            className={styles.button}
            formSubmit
            fullWidth
            loading={isLoading}
            {...(isLoading && { iconLeft: "loading" })}
          >
            {isLoading
              ? t("tracingIp.form.loading")
              : t("tracingIp.form.traceIP")}
          </Button>
        </form>
      </FormProvider>
      <div className={styles.info_message}>
        <img src="img/icons/information.svg" alt="Info icon" />
        <span>{t("tracingIp.form.infoMessage")}</span>
      </div>
      <Title tag="h2" className={styles.title}>
        {t("portScanner.checkResults")}
      </Title>
      <div className={isLoading ? styles.results__loading : styles.results}>
        {isLoading ? (
          <Progress isLoading={isLoading} />
        ) : (
          <div className={styles.results__content}>
            {result
              ? result?.map((el, index) => (
                  <div key={index} className={styles.results__item}>
                    {el}
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

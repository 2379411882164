import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Pagination } from "../../../../../components/common";
import { SkeletonTable } from "../../../../../components/ui/Skeleton/SkeletonTable/SkeletonTable";
import { ApiService } from "../../../../../services";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getCountryId, getGoalId, isEqual } from "../../../../../utils/helpers";

import { ProxyContentTop } from "./ProxyContentTop/ProxyContentTop";
import { ProxyFilter } from "./ProxyFilter/ProxyFilter";
import { ProxySort } from "./ProxySort/ProxySort";
import { ProxyTable } from "./ProxyTable/ProxyTable";
import { ProxyTypeTables } from "./ProxyTypeTables/ProxyTypeTables";

import "./ProxyContent.scss";

export const ProxyContent = () => {
  // **Props
  const [searchParams] = useSearchParams();
  const searchParamsSize = [...new Set(searchParams.keys())].length;

  // **Redux state
  const { countries, goals } = useSelector(getAllContent);
  const { proxySites } = useSelector(getAllProxySite);
  const { t } = useTranslation();

  // **Local state
  const [isLoading, setIsLoading] = useState(!proxySites?.isDataLoaded);
  const [ssrLoaded, setSsrLoaded] = useState(proxySites?.isDataLoaded);

  const [sites, setSites] = useState({
    totatElements: 0,
    // totalPages: 0,
    totalPages: proxySites.totalPages,
    // content: []
    content: proxySites.content
  });
  const [filterParams, setFilterParams] = useState({
    country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
    sort: searchParams.get("s") || "",
    goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
    proxyType: searchParams.get("fpt") ? [searchParams.get("fpt")] : [],
    page: 0,
    size: 10
  });

  // const [tableDate, setTableDate] = useState(proxySites.content);
  // const [tablePages, setTablePages] = useState(proxySites.totalPages);

  // **Ref
  const scrollRef = useRef(null);

  const getTableData = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await ApiService.getProxySites(filterParams);

      if (response && response.status !== 200) {
        throw response;
      }

      setSites(response.data);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const pageChangeHandler = useCallback(
    (page) => {
      setFilterParams({ ...filterParams, page: page - 1 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [searchParams]);

  useEffect(() => {
    if (searchParamsSize !== 0 && !ssrLoaded) {
      getTableData();
    } else {
      setSsrLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);
  // useEffect(() => {
  //   setTableDate(sites.content);
  //   setTablePages(sites.totalPages);
  // }, [sites.content, sites.totalPages]);

  useEffect(() => {
    const prevFilterParams = {
      page: filterParams.page,
      country: filterParams.country,
      sort: filterParams.sort,
      goal: filterParams.goal,
      proxyType: filterParams.proxyType
    };
    const currentFilterParams = {
      page: 0,
      country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
      sort: searchParams.get("s") || "",
      goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
      proxyType: searchParams.getAll("fpt")
    };
    const isEqualFilter = isEqual(prevFilterParams, currentFilterParams);

    if (!isEqualFilter) {
      setFilterParams({
        ...filterParams,
        ...currentFilterParams
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, goals]);
  // console.log("ProxyContent.jsx", sites.content);
  return (
    <>
      {searchParamsSize === 0 && <ProxyTypeTables />}
      {searchParamsSize !== 0 && (
        <div className="proxy-content__item">
          <ProxyContentTop />
          <div className="proxy-content__settings">
            <ProxySort />
            <ProxyFilter />
          </div>
          <div ref={scrollRef} className="proxy-content__table">
            <ProxyTable
              // tableDate={tableDate}
              tableDate={sites.content}
              params={filterParams}
              isLoading={isLoading}
            />
            <Pagination
              // totalPages={tablePages}
              totalPages={sites.totalPages}
              onPageChange={pageChangeHandler}
              currentPage={filterParams.page + 1}
              hideOnSinglePage
              scrollRef={scrollRef}
            />
            {isLoading && <SkeletonTable cells={10} />}
          </div>
        </div>
      )}
    </>
  );
};

import FeedbackPage from "pages/New/AuthPages/FeedbackPage/FeedbackPage";
import FeedbackSuccess from "pages/New/AuthPages/FeedbackSuccess/FeedbackSuccess";
import LoginPage from "pages/New/AuthPages/LoginPage/LoginPage";
import RecoveryPassPage from "pages/New/AuthPages/RecoveryPassPage/RecoveryPassPage";
import RecoverySendPage from "pages/New/AuthPages/RecoverySendPage/RecoverySendPage";
import RegistrationPage from "pages/New/AuthPages/RegistrationPage/RegistrationPage";
import RegistrationSuccess from "pages/New/AuthPages/RegistrationSuccess/RegistrationSuccess";

export const AuthRoutes = [
  {
    path: "/:lang?/new/login",
    tag: "login",
    type: "auth",
    component: () => import("pages/New/AuthPages/LoginPage/LoginPage"),
    element: LoginPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/registration",
    tag: "registration",
    type: "auth",
    component: () =>
      import("pages/New/AuthPages/RegistrationPage/RegistrationPage"),
    element: RegistrationPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/registration/success",
    tag: "registration",
    type: "auth",
    component: () =>
      import("pages/New/AuthPages/RegistrationSuccess/RegistrationSuccess"),
    element: RegistrationSuccess,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/recovery/",
    tag: "recovery",
    type: "auth",
    component: () =>
      import("pages/New/AuthPages/RecoverySendPage/RecoverySendPage"),
    element: RecoverySendPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/recovery/:token",
    tag: "recovery",
    type: "auth",
    component: () =>
      import("pages/New/AuthPages/RecoveryPassPage/RecoveryPassPage"),
    element: RecoveryPassPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/feedback",
    tag: "feedback",
    type: "auth",
    component: () => import("pages/New/AuthPages/FeedbackPage/FeedbackPage"),
    element: FeedbackPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/feedback/success",
    tag: "feedback",
    type: "auth",
    component: () =>
      import("pages/New/AuthPages/FeedbackSuccess/FeedbackSuccess"),
    element: FeedbackSuccess,
    isAuthRequired: false,
    isSsr: false
  }
];

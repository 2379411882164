import { forwardRef } from "react";

import PT from "prop-types";
import ReactSelect, { components } from "react-select";

import Icon from "../Icon/Icon";

import styles from "./Select.module.scss";

export const SelectNew = forwardRef(
  (
    {
      options,
      id,
      name,
      value,
      defaultValue,
      onChange,
      disabled,
      className,
      wrapperClassName,
      menuClassName,
      placeholder,
      multiple,
      label,
      color,
      labelSize
    },
    ref
  ) => {
    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <Icon
          name="arrowDown"
          className={
            props.selectProps.menuIsOpen
              ? styles.indicator__open
              : styles.indicator
          }
          size="xlarge"
        />
      </components.DropdownIndicator>
    );

    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
          {children}
        </components.Control>
      );

    const currentValueProps = (value) => {
      if (!value) return multiple ? [] : value;
      const type = typeof value;
      if (multiple) {
        return type === "object" ? value?.[0] : value;
      }
      return type === "object" ? value : [value];
    };

    const getDefaultValue = (value) =>
      options.find((option) => option.value === value);

    const defineWrapperClassName = () => {
      const classes = [styles.wrapper];

      if (styles[color]) {
        classes.push(styles[color]);
      }

      if (wrapperClassName) {
        classes.push(wrapperClassName);
      }

      return classes.join(" ");
    };
    return (
      <div className={defineWrapperClassName()}>
        {label && (
          <label className={`${styles.label} ${styles[`label__${labelSize}`]}`}>
            {label}
          </label>
        )}
        <ReactSelect
          ref={ref}
          id={id}
          instanceId={id}
          name={name}
          value={currentValueProps(value)}
          defaultValue={getDefaultValue(defaultValue)}
          onChange={onChange}
          // menuIsOpen={true}
          classNames={{
            control: () =>
              className ? `${styles.select} ${className}` : styles.select,
            valueContainer: () => styles.value__container,
            input: () => `${styles[`input__${color}`]} ${styles.input}`,
            singleValue: () => `${styles[`input__value__${color}`]} ${styles.input__value}`,
            indicatorsContainer: () =>
              `${styles.icon__container} ${
                styles[`icon__container__${color}`]
              }`,
            menu: () => `${styles.menu} ${styles[`menu__${color}`]}`,
            menuList: () =>
              `${styles.menu__list} ${
                styles[`menu__list__${color}`]
              } ${menuClassName} ${color === "dark" ? "scrollbar__dark" : ""}`,
            option: (state) =>
              state.isSelected
                ? `${styles.option} ${styles[`option__${color}`]} ${
                    styles[`option__selected__${color}`]
                  }`
                : `${styles.option} ${styles[`option__${color}`]}`
          }}
          components={{ DropdownIndicator, IndicatorSeparator: () => null, Control }}
          isDisabled={disabled}
          placeholder={<div className={`${styles.placeholder} ${styles[`placeholder__${color}`]}`}>{placeholder}</div>}
          isMulti={multiple}
          options={options}
        ></ReactSelect>
      </div>
    );
  }
);

SelectNew.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.oneOfType([PT.string, PT.number]).isRequired,
      label: PT.node.isRequired
    })
  ).isRequired,
  onChange: PT.func.isRequired,
  className: PT.string,
  disabled: PT.bool,
  id: PT.string,
  name: PT.string,
  value: PT.oneOfType([PT.string, PT.array, PT.number, PT.shape({})]),
  defaultValue: PT.oneOfType([PT.string, PT.array, PT.number]),
  placeholder: PT.string,
  multiple: PT.bool,
  menuClassName: PT.string,
  wrapperClassName: PT.string,
  label: PT.string,
  color: PT.oneOf(["light", "dark"]),
  labelSize: PT.oneOf(["small", "large"])
};

SelectNew.defaultProps = {
  options: [],
  onChange: () => null,
  className: "",
  wrapperClassName: "",
  disabled: false,
  id: "",
  name: "",
  defaultValue: "",
  placeholder: "",
  multiple: false,
  menuClassName: "",
  label: null,
  color: "light",
  labelSize: "small"
};

export default SelectNew;

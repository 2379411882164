import { useState } from "react";

import { useTranslation } from "react-i18next";

import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { useUserIp } from "hooks/useUserIp";

import { faqMyAnonymity } from "utils/constants/questionsAndAnswers";

import animation from "assets/animations/my_anonymity.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  myAnonymityHeroImageMobile
} from "assets/img";

import { CheckAnonymity } from "./CheckAnonymity/CheckAnonymity";
import { IpDetails } from "./IpDetails/IpDetails";
import { MyAnonymityInfo } from "./MyAnonymityInfo/MyAnonymityInfo";

import styles from "./MyAnonymityPage.module.scss";

const MyAnonymityPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const { t } = useTranslation();

  const userIp = useUserIp();

  const handleClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setResult(userIp);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className={styles.section}>
      <Main
        title={t("myAnonymity.main.title")}
        subtitle={t("myAnonymity.main.subtitle")}
        button={false}
        images={{
          mobile1x: myAnonymityHeroImageMobile,
          mobile2x: myAnonymityHeroImageMobile
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        contentClassName={styles.hero__content}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-108}
            right={-82}
            bottom={-108}
            left={-82}
          />
        }
      />
      <CheckAnonymity
        userIp={userIp}
        isLoading={isLoading}
        result={result}
        handleClick={handleClick}
      />
      <IpDetails userIp={userIp} isLoading={isLoading} />
      <MyAnonymityInfo />
      <FAQ
        list={faqMyAnonymity}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        className={styles.faq}
        tools
      />
      <SeoSection />
    </div>
  );
};

export default MyAnonymityPage;

import { Navigate } from "react-router-dom";

import AddEditPromocode from "pages/New/Dashboard/AddEditPromocode/AddEditPromocode";
import AddEditService from "pages/New/Dashboard/AddEditService/AddEditService";
import Profile from "pages/New/Dashboard/Profile/Profile";
import Promocodes from "pages/New/Dashboard/Promocodes/Promocodes";
import Reviews from "pages/New/Dashboard/Reviews/Reviews";
import Services from "pages/New/Dashboard/Services/Services";

export const DashboardRoutes = [
  {
    path: "/:lang?/new/dashboard/profile",
    tag: "dashboard-profile",
    type: "dashboard",
    component: () => import("pages/New/Dashboard/Profile/Profile"),
    element: Profile,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/services",
    tag: "dashboard-services",
    type: "dashboard",
    component: () => import("pages/New/Dashboard/Services/Services"),
    element: Services,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/services/create",
    tag: "dashboard-services-create",
    type: "dashboard",
    component: () =>
      import("pages/New/Dashboard/AddEditService/AddEditService"),
    element: AddEditService,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/services/edit/:id",
    tag: "dashboard-services-edit",
    type: "dashboard",
    component: () =>
      import("pages/New/Dashboard/AddEditService/AddEditService"),
    element: AddEditService,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/reviews",
    tag: "dashboard-reviews",
    type: "dashboard",
    component: () => import("pages/New/Dashboard/Reviews/Reviews"),
    element: Reviews,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/promocodes",
    tag: "dashboard-promocodes",
    type: "dashboard",
    component: () => import("pages/New/Dashboard/Promocodes/Promocodes"),
    element: Promocodes,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/promocodes/create",
    tag: "dashboard-promocodes-create",
    type: "dashboard",
    component: () =>
      import("pages/New/Dashboard/AddEditPromocode/AddEditPromocode"),
    element: AddEditPromocode,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/promocodes/edit/:id",
    tag: "dashboard-promocodes-edit",
    type: "dashboard",
    component: () =>
      import("pages/New/Dashboard/AddEditPromocode/AddEditPromocode"),
    element: AddEditPromocode,
    isAuthRequired: true
  },
  {
    path: "/:lang?/new/dashboard/*",
    element: <Navigate to="/new/dashboard/profile" replace={true} />
  }
];

import { useTranslation } from "react-i18next";

import IconLogo from "../../../ui/Logo";

export const Logo = () => {
  const { t } = useTranslation();

  return (
    <div className="footer__item">
      <IconLogo
        imgPath="/img/ui/logo.svg"
        imgAlt={t("footer.logo.imgAlt")}
        linkTitle={t("footer.logo.linkTitle")}
      />
    </div>
  );
};

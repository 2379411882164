import { useEffect, useId, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Rating from "components/ui/New/Raiting/Rating";
import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

import {
  StyledBaseInfoWrapper,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemLabel,
  StyledListItemValue,
  StyledSiteName,
  StyledSiteNameBox,
  StyledSiteNameImage
} from "./BaseInfo.styled";

export const BaseInfo = () => {
  const [queryLang] = useLangUrlDefault();

  const id = useId();
  const ref = useRef(null);

  // **Redux state
  const { t } = useTranslation();
  const {
    site: { data }
  } = useSelector(getAllProxySite);

  const [needTooltip, setNeedTooltip] = useState(false);

  const currentCountWords = (count) => {
    let words = "";
    if (count === 1) {
      words = t("proxySitePage.info.base.reviewCountOne");
    } else if (count > 1 && count < 5) {
      words = t("proxySitePage.info.base.reviewCountTwo");
    } else {
      words = t("proxySitePage.info.base.reviewCount");
    }
    return `${count} ${words}`;
  };

  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const listData = [
    {
      key: "age",
      label: `${t("proxySitePage.info.base.domain")}:`,
      value: time(
        "year",
        data?.age,
        queryLang,
        t("times", { returnObjects: true })
      )
    },
    {
      key: "rating",
      label: `${t("proxySitePage.info.base.rating")}:`,
      value: <Rating size={"md_s"} rating={data?.rating} background={false} />
    },
    {
      key: "reviews",
      label: `${t("proxySitePage.info.base.reviews")}:`,
      value: currentCountWords(
        data?.reviewRatingNegative + data?.reviewRatingPositive
      )
    }
  ];

  return (
    <StyledBaseInfoWrapper>
      <StyledSiteNameBox>
        <StyledSiteNameImage
          src={data?.imageFile ? data.imageFile : "/img/ui/placeholder.svg"}
          loading="lazy"
          alt="logo"
          aria-hidden="true"
        />
        <StyledSiteName data-tooltip-id={id} ref={ref}>
          {data?.title}
        </StyledSiteName>
        <StyledLink to={data?.url} target="_blank">
          <IconsSVG name={"linkExternal"} />
        </StyledLink>
        {needTooltip ? (
          <StyledTooltip id={id}>{data?.title}</StyledTooltip>
        ) : null}
      </StyledSiteNameBox>
      <StyledList>
        {listData.map((item) => (
          <StyledListItem key={item.key}>
            <StyledListItemLabel>{item.label}</StyledListItemLabel>
            <StyledListItemValue>{item.value}</StyledListItemValue>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledBaseInfoWrapper>
  );
};

import styled from "@emotion/styled";

export const Item = styled.li`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
  }

  &:not(:last-child) {
    border-bottom: ${(p) => p.theme.borders.border};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: ${(p) => p.theme.lineHeight.normal};

  color: ${(p) => p.theme.colors["neutral-400"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const Icon = styled.img`
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;

export const Value = styled.span`
  display: flex;
  align-items: center;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: ${(p) => p.theme.lineHeight.normal};

  color: ${(p) => p.theme.colors["neutral-800"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    max-width: 50%;
    text-align: right;
  }
`;

import { useId } from "react";

import PT from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Container from "components/common/New/Container/Container";
import { Progress } from "components/common/New/Progress/Progress";
import Button from "components/ui/New/Button/Button";
import Icon from "components/ui/New/Icon/Icon";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import AnonymityStatus from "./AnonymityStatus/AnonymityStatus";
import { ResultsTable } from "./ResultTable/ResultTable";

import styles from "./CheckAnonymity.module.scss";

export const CheckAnonymity = ({ userIp, isLoading, result, handleClick }) => {
  const { t } = useTranslation();

  const customToastId = useId();
  const tooltipId = useId();

  const onCopyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <div className={styles.ip__wrapper}>
            {userIp?.ipAddress ? (
              <>
                <div className={styles.ip}>
                  <h3 className={styles.title}>
                    {t("myAnonymity.myIP")}
                  </h3>
                  <div className={styles.ip__address__wrapper}>
                    {userIp?.countryA2 && (
                      <span
                        className={`fl fl-${userIp?.countryA2?.toLowerCase()} ${
                          styles.flag
                        }`}
                      />
                    )}
                    {userIp?.ipAddress?.length > 24 ? (
                      <Tooltip
                        id={tooltipId}
                        body={userIp?.ipAddress}
                        place="top-start"
                      >
                        <span
                          className={`${styles.ip__address} ${styles.text_overflow}`}
                        >
                          {userIp?.ipAddress}
                        </span>
                      </Tooltip>
                    ) : (
                      <span className={styles.ip__address}>
                        {userIp?.ipAddress}
                      </span>
                    )}

                    <CopyToClipboard
                      text={userIp?.ipAddress}
                      onCopy={onCopyHandler}
                    >
                      <Icon
                        name="copyAlt"
                        className={styles.icon}
                        size="xlarge"
                      />
                    </CopyToClipboard>
                  </div>
                </div>
                {userIp?.country && (
                  <div className={styles.country}>
                    <Icon name="marker" className={styles.icon} size="xlarge" />
                    <span>
                      {userIp?.city ? `${userIp?.city},` : ""} {userIp?.country}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <Icon name="loading" loading={true} />
            )}
          </div>
          {result && !isLoading ? (
            <AnonymityStatus value={60} />
          ) : (
            <>
              <h3 className={styles.title}>
                {t("myAnonymity.title")}
              </h3>
              <p className={styles.subtitle}>{t("myAnonymity.subtitle")}</p>
              <Button
                className={styles.button}
                type="button"
                onClick={handleClick}
                fullWidth
              >
                {t("myAnonymity.buttonLabel")}
              </Button>
            </>
          )}
        </div>
        <div className={isLoading ? styles.results__loading : styles.results}>
          {isLoading ? (
            <div className={styles.progress__wrapper}>
              <Progress isLoading={isLoading} />
            </div>
          ) : (
            <div className={styles.results__container}>
              {result ? <ResultsTable data={userIp} /> : null}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

CheckAnonymity.propTypes = {
  userIp: PT.shape({}),
  isLoading: PT.bool,
  result: PT.shape({}),
  handleClick: PT.func.isRequired
};

CheckAnonymity.defaultProps = {
  userIp: {},
  isLoading: false,
  result: null
};

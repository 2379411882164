import { forwardRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { AuthNavigate } from "pages/New/AuthPages/components/AuthNavigate/AuthNavigate";

import { Input } from "components/forms/New";
import Button from "components/ui/New/Button/Button";
import Card from "components/ui/New/Card/Card";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

import { useLangUrlDefault } from "hooks";

import { CommentSchemaNew } from "utils/validation";

import styles from "./Response.module.scss";

export const Response = forwardRef(
  (
    {
      review,
      toggleAccordion,
      isOpen,
      changeHandler,
      captchaRef,
      setTokenCaptcha,
      nestedResponseValue,
      ...rest
    },
    ref
  ) => {
    const [queryLang] = useLangUrlDefault();

    // **Redux state
    const { content } = useSelector(getAllBlogInner);
    const { user, isUserAuthenticated } = useSelector(getAllUser);

    const { t } = useTranslation();
    // Form
    const methods = useForm({
      resolver: yupResolver(
        CommentSchemaNew(
          t("forms", { returnObjects: true }),
          isUserAuthenticated
        )
      )
    });

    const { captchaConfig } = useSelector(getAllContent);
    const { site } = useSelector(getAllProxySite);

    // **Local state
    const [isDisabled, setIsDisabled] = useState(false);

    const { enable, headerName } = captchaConfig.data;

    const leaveComment = async (data) => {
      let header = {};

      if (enable) {
        try {
          const { response: token } = await captchaRef.current.execute({
            async: true
          });
          header = { [headerName]: token };
        } catch (ignore) {
          return;
        }
        // const { response: token } = await captchaRef.current.execute({
        //   async: true
        // });
        // header = { [headerName]: token };
      }

      try {
        setIsDisabled(true);
        const values = {
          name: isUserAuthenticated ? user.name : data?.name,
          email: isUserAuthenticated ? user.email : data?.email,
          body: data?.body
        };

        const response = await ApiService.createComment(
          review.id,
          site.data?.id || content.data.id,
          values.name,
          values.email,
          values.body,
          queryLang,
          header
        );

        if (response && response.status !== 200) {
          throw response;
        }

        methods.reset({ name: "", email: "", body: "" });

        // Show success message
        toast.success(t("notifications.comment"));

        // Fetch comment
        if (changeHandler) {
          changeHandler();

          // Close textarea
          toggleAccordion();
        }
      } catch (err) {
        toast.error(t("notifications.apiError"));
      } finally {
        if (enable) {
          captchaRef.current.resetCaptcha();
          setTokenCaptcha(null);
        }

        setIsDisabled(false);
      }
    };

    const defineClassname = () => {
      let className = styles.response;

      if (isOpen) {
        className += ` ${styles.response_active}`;
      }

      return className;
    };

    return (
      <div ref={ref} className={defineClassname()} {...rest}>
        <Card
          className={`
        ${styles.card} 
        
        ${nestedResponseValue % 2 === 0 ? styles.card_white : styles.card_gray}
        `}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(leaveComment)}
              className={styles.form}
              noValidate
            >
              {!isUserAuthenticated && (
                <>
                  <Input
                    name="name"
                    label={t("modals.comment.form.name.placeholder")}
                    placeholder={t("modals.comment.form.name.placeholder")}
                    showError
                  />
                  <Input
                    name="email"
                    placeholder={t("forms.emailV2.label")}
                    label={t("forms.emailV2.label")}
                    showError
                  />
                  <AuthNavigate
                    to="login"
                    isStatic
                    noIcon
                    className={styles.auth_link}
                  />
                </>
              )}
              <Input
                name="body"
                label={t("forms.answer.label")}
                placeholder={t("forms.answer.label")}
                showError
                type="textarea"
              />
              <Button
                disabled={isDisabled}
                fullWidth
                formSubmit
                className={styles.card_btn}
              >
                {t("forms.addComment.btn")}
              </Button>
            </form>
          </FormProvider>
        </Card>
      </div>
    );
  }
);

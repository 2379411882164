import styled from "@emotion/styled";

export const StyledUserChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 64px;
  min-width: 320px;
  max-width: 1200px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    flex-direction: row;
    gap: 12px;
    padding: 0 15px;
    margin-bottom: 120px;
  }
`;

import { useTranslation } from "react-i18next";

import Card from "components/ui/New/Card/Card";

import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { EmailBlock } from "./EmailBlock/EmailBlock";
import { PasswordBlock } from "./PasswordBlock/PasswordBlock";

import styles from "./Profile.module.scss";

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleBlock title={t("dashboard.profile.title")} />
      <Card className={styles.card} radius={"small"}>
        <EmailBlock />
        <PasswordBlock />
      </Card>
    </>
  );
};

export default Profile;

import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Rive } from "components/ui/New/Rive/Rive";
import { Title } from "components/ui/New/Title/Title";

import { animationDnsLeakCheck, animationDnsLeakTest } from "assets/animations";

import styles from "./DnsLeakTestInfo.module.scss";

export const DnsLeakTestInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.section__dark}>
        <Container className={styles.container}>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("dnsLeak.whatIsDns.title")}
            </Title>
            <div className={styles.description}>
              {t("dnsLeak.whatIsDns.description")}
            </div>
          </div>
          <div className={styles.animation}>
            <Rive
              src={animationDnsLeakTest}
              autoPlay
              top={-59}
              right={-84}
              bottom={-50}
              left={-56}
            />
          </div>
          <picture>
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image.svg"
              media="(min-width: 993px)"
            />
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <img
              src="img/dns-leak-test/dns-leak-test-info-image.svg"
              alt={t("dnsLeak.whatIsDns.title")}
              className={styles.image}
            />
          </picture>
        </Container>
      </div>
      <div className={styles.section__light}>
        <Container className={styles.container__light}>
          <picture>
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image2.png"
              media="(min-width: 993px)"
            />
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image-mobile2.png"
              media="(max-width: 992px)"
            />
            <img
              src="img/dns-leak-test/dns-leak-test-info-image2.png"
              alt={t("dnsLeak.whatIsDns.title")}
              className={styles.image}
            />
          </picture>
          <div className={styles.animation}>
            <Rive
              src={animationDnsLeakCheck}
              autoPlay
              top={-55}
              right={-75}
              bottom={-55}
              left={-75}
            />
          </div>
          <div className={styles.content}>
            <Title tag="h2" className={styles.title}>
              {t("dnsLeak.whatDoesDns.title")}
            </Title>
            <div className={styles.description}>
              {t("dnsLeak.whatDoesDns.description")}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

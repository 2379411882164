import axios from "axios";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/ui/New/Button/Button";
import Subtitle from "../../../../components/ui/New/Subtitle/Subtitle";
import { Title } from "../../../../components/ui/New/Title/Title";
import { useLangUrlDefault } from "../../../../hooks";
import { AllActions } from "../../../../store/reducers/AllActions";

import styles from "./FeedbackSuccess.module.scss";

const FeedbackSuccess = () => {
  // **Redux state
  const { t } = useTranslation();

  const [, hrefLang] = useLangUrlDefault();

  return (
    <>
      <Title className={styles.title}>{t("feedbackPage.success.title")}</Title>
      <Subtitle className={styles.subtitle}>
        {t("feedbackPage.success.subtitle")}
      </Subtitle>
      <picture className={styles.picture}>
        <source
          // media="(min-width: 1200px)"
          srcSet="./img/auth/feedback-success-x1.png 1x, ./img/auth/feedback-success-x2.png 2x"
          type="image/png"
        />
        <img
          src="./img/auth/feedback-success-x1.png"
          alt="success reistration image"
        />
      </picture>
      <Button linkTo={`${hrefLang}/new/`} className={styles.btn}>
        {t("feedbackPage.success.btn")}
      </Button>
    </>
  );
};

export default FeedbackSuccess;

FeedbackSuccess.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

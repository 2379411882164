import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const LoginSchema = (trns) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(trns.email.rules.required)
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        trns.email.rules.email
      ),
    password: yup.string().required(trns.password.rules.required)
    // .min(4, `${trns.password.rules.min} 4`)
  });

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { RenderReviews } from "components/common/New/RenderReviews/RenderReviews";
import Select from "components/ui/New/Select/Select";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useDispatchedActions } from "hooks";

import { BaseScore } from "./BaseScore/BaseScore";

import styles from "./ListReview.module.scss";

export const ListReview = ({
  active,
  captchaRef,
  setTokenCaptcha,
  trigerfetch
}) => {
  const { t } = useTranslation();
  const { reviews, site } = useSelector(getAllProxySite);
  // **Props
  const { alias } = useParams();
  const options = t("proxySitePage.content.reviews.top.sortList", {
    returnObjects: true
  }).map((sortType) => ({
    label: sortType.label,
    value: sortType.param
  }));

  // **Dispatch
  const { getProxySiteReviews } = useDispatchedActions();

  // **Local state
  const [paginationDisabled, setPaginationDisabled] = useState(1);
  const [pageParams, setPageParams] = useState({
    sort: "creationDate",
    currentPage: reviews.reviewPage + 1 || 1,
    size: 1
  });

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      currentPage: page
    });
  };

  const incrementPageSizes = () => {
    setPageParams({ ...pageParams, size: pageParams.size + 4 });
  };

  const fetchProxyRevies = useCallback(() => {
    getProxySiteReviews({
      sort: pageParams.sort,
      siteId: alias,
      page: pageParams.currentPage - 1,
      size: pageParams.size
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, pageParams, trigerfetch]);

  // Handling content
  useEffect(() => {
    fetchProxyRevies();
  }, [fetchProxyRevies]);

  useEffect(() => {
    if (reviews.reviewPage?.toString()) {
      setPageParams({
        ...pageParams,
        currentPage: reviews.reviewPage + 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.reviewPage]);

  // Handling error
  useEffect(() => {
    if (reviews.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.isError]);

  useEffect(() => {
    const width = document.body.clientWidth;
    if (width > 992) {
      setPageParams({ ...pageParams, size: 4 });
      setPaginationDisabled(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.container} ${active ? "" : styles.hide}`}>
      <div className={styles.head}>
        <div className={styles.sort_group}>
          <span className={styles.sort_label}>
            {t("proxySitePage.content.reviews.top.title")}:
          </span>
          <Select
            wrapperClassName={styles.sort_select}
            value={pageParams.sort}
            onChange={changeSortType}
            options={options}
          />
        </div>
        <BaseScore
          score={site.data?.rating}
          total={reviews.data?.totalElements}
        />
      </div>
      <RenderReviews
        reviews={reviews.data?.content}
        totalElements={reviews.data?.totalElements}
        pageSize={pageParams.size}
        currentPage={pageParams.currentPage}
        changeHandler={fetchProxyRevies}
        onChangePage={pageChangeHandler}
        handlerShowMore={incrementPageSizes}
        paginationDisabled={paginationDisabled}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
      />
    </div>
  );
};

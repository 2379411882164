import { useId } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const Tooltip = ({ data = [], promocode = false, children }) => {
  const { t } = useTranslation();
  const customToastId = useId();
  const copyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <div className="proxy-table__name-tooltip-item">
      <div
        className={
          promocode && !data.length
            ? "proxy-table__name-tooltip-icon__not-active"
            : "proxy-table__name-tooltip-icon"
        }
      >
        {children}
      </div>
      {data.length > 0 && (
        <ul className="proxy-table__name-tooltip-dropdown">
          {promocode &&
            data.length !== 0 &&
            data.map((item) => (
              <li key={item.value.promocode}>
                <span>{item.key}:</span>
                <CopyToClipboard
                  onCopy={copyHandler}
                  text={item.value.promocode}
                >
                  <button>
                    <span className="copy">{item.value.promocode}</span>
                  </button>
                </CopyToClipboard>
              </li>
            ))}
          {!promocode &&
            data.length !== 0 &&
            data.map((item) => (
              <li key={item.value}>
                <span>{item.key}:</span>
                <span>{item.value}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

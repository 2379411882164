import { cloneElement } from "react";

import PT from "prop-types";

// export const iconsListKeys = Object.keys(iconsList);

export const IconsSVG = ({ name, ...rest }) => {
  // const icons = iconsList;
  // if (!icons[name]) return null;

  // const icon = icons[name];

  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg">
      <use href={`/img/svg-sprite.svg#${name}`}></use>
    </svg>
  );
  const cloneProps = { ...rest };
  if (Object.keys(cloneProps).length) {
    return cloneElement(icon, cloneProps);
  }
  return icon;
};

IconsSVG.propTypes = {
  name: PT.string.isRequired
};

IconsSVG.defaultProps = {};

export default IconsSVG;

export const makeWordHighlight = (title, highlightWord) => {
  if (title) {
    return title.replace(highlightWord, `<span>${highlightWord}</span>`);
  }
};

export const getHighlightedWord = (highlightedWord) =>
  highlightedWord
    ?.replace("проксі", "")
    .replace("прокси", "")
    .trim();

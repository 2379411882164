// import { useTranslation } from "react-i18next";
import { Select } from "components/ui/New/Select/Select";

import styles from "./SortBlock.module.scss";

export const SortBlock = ({
  // rowCount,
  params,
  label,
  options,
  changeSortType
}) => (
  // const { t } = useTranslation();

  // const currentPage = params.page + 1;
  // const rowsPerPage = params.size;
  // const from = currentPage * rowsPerPage - rowsPerPage + 1;
  // const to =
  //   currentPage * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage;
  <div className={styles.sort}>
    {/* <div className={styles.sort_rows}>
        {from} - {to} {t("pagination.table.from")} {rowCount}
      </div> */}
    <div className={styles.sort_group}>
      {label && <span className={styles.sort_label}>{label}</span>}
      <Select
        wrapperClassName={styles.sort_select}
        value={params.statusId}
        onChange={changeSortType}
        options={options}
      />
    </div>
  </div>
);

import { Suspense, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { Languages } from "../components/common";
import { Container } from "../components/ui";
import { Loader } from "../components/ui/Loader/Loader";
import Logo from "../components/ui/Logo";
import { useDispatchedActions, useLangUrlDefault } from "../hooks";
import { getAllUser } from "../store/reducers/UserReducer/User.selectors";

export const AuthLayout = () => {
  const [, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pathname = searchParams.get("pathname");

  // **Redux state
  const { isUserAuthenticated, isRedirectToCabinet } = useSelector(getAllUser);

  // **Dispatch
  const { setIsRedirectToCabinet } = useDispatchedActions();

  useEffect(() => {
    if (isUserAuthenticated && !isRedirectToCabinet) {
      setIsRedirectToCabinet(false);
      navigate(pathname || `${hrefLang}/`);
    } else if (isUserAuthenticated) {
      navigate(`${hrefLang}/cabinet/proxy/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, pathname, isRedirectToCabinet]);

  return (
    <section className="auth">
      <Container isAuth>
        <Languages margin="left" />
        <Logo
          imgPath="/img/ui/logo-large.svg"
          imgAlt={t("header.logo.imgAlt")}
          linkTitle={t("header.logo.linkTitle")}
          position="center"
          imgWidth={285}
          imgHeight={59}
        />
        <div className="auth__wrapper">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </Container>
    </section>
  );
};

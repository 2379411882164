import PT from "prop-types";

import { IconsSVG } from "./IconsSVG";

import styles from "./Icon.module.scss";

const Icon = ({ name, className, color, size, disabled, onClick, style, loading }) => {
  const defineClasses = () => {
    const classes = [styles.icon];

    switch (color) {
      case "primary":
        classes.push(styles.primary);
        break;
      case "secondary":
        classes.push(styles.secondary);
        break;
      default:
        break;
    }

    switch (size) {
      case "xlarge":
        classes.push(styles.xlarge);
        break;
      case "large":
        classes.push(styles.large);
        break;
      case "normal":
        classes.push(styles.normal);
        break;
      case "medium":
        classes.push(styles.medium);
        break;
      case "small":
        classes.push(styles.small);
        break;
      default:
        break;
    }

    if (disabled) classes.push(styles.disabled);

    if (loading) classes.push(styles.loading);

    if (className) classes.push(className);

    return classes.join(" ");
  };

  return (
    <IconsSVG
      name={name}
      className={defineClasses()}
      style={style}
      onClick={onClick}
    />
  );
};

// Icon.Keys = IconsSVG.Keys;

Icon.propTypes = {
  className: PT.string,
  color: PT.oneOf(["", "primary", "secondary"]),
  name: PT.string.isRequired,
  size: PT.oneOf(["large", "normal", "medium", "small", "xlarge"]),
  disabled: PT.bool,
  loading: PT.bool,
  style: PT.shape({}),
  onClick: PT.func
};

Icon.defaultProps = {
  className: "",
  color: "",
  size: "normal",
  loading: false,
  style: {}
};

export default Icon;

/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";
import { useUserIp } from "../../../../hooks/useUserIp";

import "./DetermineIp.scss";

export const DetermineIp = () => {
  // **Refs
  const determineIpBtnRef = useRef(null);
  const determineIpModalRef = useRef(null);
  const userIp = useUserIp();

  // **Props
  const [, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  // **Local state
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", (evt) => {
      const withinBoundariesBtn = evt
        .composedPath()
        .includes(determineIpBtnRef.current);
      const withinBoundariesModal = evt
        .composedPath()
        .includes(determineIpModalRef.current);

      if (!withinBoundariesBtn && !withinBoundariesModal) {
        setIsModalOpen(false);
      }
    });
  }, []);

  const getCountryShow = (userIpData) => {
    const { country, countryA2, countryA3, city } = userIpData || {};
    let locat = "";

    if (country) {
      locat = country;
    } else if (countryA2) {
      locat = countryA2;
    } else if (countryA3) {
      locat = countryA3;
    } else {
      locat = t("mainPage.determineIp.notCountry");
    }

    if (city) {
      locat += ` / ${city}`;
    }

    return locat;
  };

  const Locat = () => (
    <>
      <b>{t("mainPage.determineIp.yourIp")}:</b>{" "}
      {userIp?.ipAddress ? userIp?.ipAddress : "—"}
      &nbsp;
      <b>{getCountryShow(userIp)}</b>
    </>
  );

  return (
    <div className="container">
      <div className="determine-ip">
        <div className="determine-ip__your">{userIp && <Locat />}</div>
        {userIp && (
          <button
            ref={determineIpBtnRef}
            className="button-link determine-ip__button"
            type="button"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            {t("mainPage.determineIp.changeLocat")}
          </button>
        )}
        <div
          ref={determineIpModalRef}
          className={
            isModalOpen ? "determine-ip__modal" : "determine-ip__modal-hide"
          }
        >
          <h2 className="determine-ip__modal-title">
            {t("mainPage.determineIp.changeLocat")}
          </h2>
          <p className="determine-ip__modal-description">
            {userIp && <Locat />}
          </p>
          <div className="determine-ip__modal-changeip">
            <Link className="determine-ip__modal-btn" to={`${hrefLang}/proxy/`}>
              {t("mainPage.determineIp.changeBtn")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ConfirmModal, Pagination } from "../../../../../../components/common";
import { Button } from "../../../../../../components/ui/Button/Button";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { getAllCabinet } from "../../../../../../store/reducers/CabinetReducer/Cabinet.selectors";

import { PromocodeItem } from "./PromocodeItem/PromocodeItem";

import "./PromocodesList.scss";

export const PromocodesList = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const { promocodes } = useSelector(getAllCabinet);

  // **Local state
  const [deletedPromocodeId, setDeletedPromocodeId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [params, setParams] = useState(
    promocodes.prevParams || {
      page: 0,
      size: 10
    }
  );

  // **Dispatch
  const { getCabinetPromocodes, setCabinetPromocodesPrevParams } =
    useDispatchedActions();

  // **Ref
  const scrollRef = useRef();

  const fetchCabinetPromocodes = useCallback(() => {
    getCabinetPromocodes(params);
    setCabinetPromocodesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const openModalHandler = (id) => () => {
    setIsModalOpened(true);
    setDeletedPromocodeId(id);
  };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(promocodes.prevParams)) {
      fetchCabinetPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (promocodes.data.totalPages !== params.page) {
      return;
    }

    if (params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, promocodes]);

  return (
    <>
      <div ref={scrollRef} className="cabinet-promocode">
        <Button
          type="black-reverse"
          size="large"
          link
          href={`${hrefLang}/cabinet/promocodes/create/`}
        >
          {t("cabinetPages.promocodes.list.new")}
        </Button>
        <div className="cabinet-promocode__wrapper">
          {promocodes.data?.content?.length !== 0 && (
            <>
              <ul className="cabinet-promocode__list">
                {promocodes.data?.content?.map((promocode) => (
                  <PromocodeItem
                    key={promocode?.id}
                    promocode={promocode}
                    modalHandler={openModalHandler}
                  />
                ))}
              </ul>
              <Pagination
                totalPages={promocodes.data.totalPages}
                onPageChange={pageChangeHandler}
                currentPage={params.page + 1}
                hideOnSinglePage
                scrollRef={scrollRef}
              />
            </>
          )}
          {!promocodes.isLoading && promocodes.data?.content?.length === 0 && (
            <p className="cabinet-promocode__empty">
              {t("cabinetPages.promocodes.list.noData")}
            </p>
          )}
          {promocodes.isLoading && <Loader type="blur" />}
        </div>
      </div>
      <ConfirmModal
        visible={isModalOpened}
        cancelHandler={setIsModalOpened}
        title={t("cabinetPages.promocodes.list.modalTitle")}
        confirmBtnText={t("cabinetPages.promocodes.list.delete")}
        cancelBtnText={t("cabinetPages.promocodes.list.cancel")}
        successMessage={t("notifications.site.removed")}
        confirmService={() => ApiService.deletePromocode(deletedPromocodeId)}
        afterConfirmService={() => {
          setDeletedPromocodeId(null);
          fetchCabinetPromocodes();
        }}
      />
    </>
  );
};

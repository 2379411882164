import PT from "prop-types";

import { convertCountryCodeAlpha3ToAlpha2 } from "utils/helpers";

import styles from "./LabelCountry.module.scss";

// import "scss/libs/flag-icons.scss";

export const LabelCountry = ({ code, className, children }) => {
  let value = code?.toLowerCase();
  if (value?.length !== 2) {
    value = convertCountryCodeAlpha3ToAlpha2(code)?.toLowerCase();
  }

  return (
    <div className={`${styles.label} ${className}`}>
      <span className={`fl fl-${value} ${styles.label_flag}`} />
      {children}
    </div>
  );
};

LabelCountry.propTypes = {
  code: PT.string.isRequired,
  className: PT.string,
  children: PT.node.isRequired
};

LabelCountry.defaultProps = {
  className: "",
  classNameBody: ""
};

export default LabelCountry;

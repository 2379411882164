export const choiceTableData = [
  {
    id: 1,
    name: "American Samoa",
    code: "AS",
    new: true,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 2,
    name: "Bosnia and Herzegovina",
    code: "BA",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 3,
    name: "Italy",
    code: "IT",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 4,
    name: "American Samoa",
    code: "AS",
    new: true,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 5,
    name: "Bosnia and Herzegovina",
    code: "BA",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 6,
    name: "Italy",
    code: "IT",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 7,
    name: "American Samoa",
    code: "AS",
    new: true,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 8,
    name: "Bosnia and Herzegovina",
    code: "BA",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 9,
    name: "Italy",
    code: "IT",
    new: false,
    price: 11.07,
    offers: 24,
    go: "#"
  },
  {
    id: 10,
    name: "American Samoa",
    code: "AS",
    new: true,
    price: 11.07,
    offers: 24,
    go: "#"
  }
];

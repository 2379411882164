import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const ProxySchema = (trns, languages, t) => {
  const languagesValidation = {};

  languages.forEach((lang) => {
    languagesValidation[lang.value] = yup.string();
  });

  const regexpUrlWithoutHttps =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

  return yup.object().shape(
    {
      link: yup
        .string()
        .required(t(`${trns}.link.rules.required`))
        .matches(regexpUrlWithoutHttps, t(`${trns}.link.rules.url`)),
      proxyType: yup.array().min(1).required(),
      countries: yup.array().min(1).required(),
      minRentPeriod: yup.mixed().required(t(`${trns}.minRent.rules.required`)),
      priceMin: yup
        .string()
        .required(t(`${trns}.minPrice.rules.required`))
        .max(12, t(`${trns}.minPrice.rules.max`)),
      currencyId: yup.lazy((value) => {
        switch (typeof value) {
          case "object": {
            return yup.object().shape({
              id: yup.string().required(t(`${trns}.currency.rules.required`)),
              label: yup
                .string()
                .required(t(`${trns}.currency.rules.required`)),
              value: yup.string().required(t(`${trns}.currency.rules.required`))
            });
          }
          case "string": {
            return yup.string().required(t(`${trns}.currency.rules.required`));
          }
          default: {
            return yup.mixed();
          }
        }
      }),
      api: yup.mixed().required(t(`${trns}.common.rules.required`)),
      freeTest: yup.mixed().required(t(`${trns}.common.rules.required`)),
      individual: yup.mixed().required(t(`${trns}.common.rules.required`)),
      refunds: yup.mixed().required(t(`${trns}.common.rules.required`)),
      replacementPossibility: yup
        .mixed()
        .required(t(`${trns}.common.rules.required`)),
      affiliateProgram: yup
        .mixed()
        .required(t(`${trns}.common.rules.required`)),
      affiliatePercent: yup
        .string()
        .max(3, t(`${trns}.affiliatePercent.rules.max`)),
      description: yup
        .object()
        .shape(languagesValidation)
        .test(
          "description-test",
          t(`${trns}.description.rules.required`),
          (obj) => Object.values(obj).some(Boolean)
        ),
      socialNetworks: yup.object().shape({
        email: yup.array().of(
          yup
            .string()
            .required(t(`${trns}.email.rules.required`))
            .matches(
              process.env?.REACT_APP_MODE === "prod"
                ? EMAIL_REGEX
                : EMAIL_STAGE_REGEX,
              t(`${trns}.email.rules.email`)
            )
        )
      })
    },
    [
      // Add Cyclic deps here because when require itself
      ["referralLink", "referralLink"]
    ]
  );
};

import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";

import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import {
  StyledCountryLabel,
  StyledCountryLabelText,
  StyledCountryTextBox
} from "./CountryNameCell.styled";

export const CountryNameCell = ({ name, code }) => {
  const id = useId();
  const ref = useRef(null);
  const refBox = useRef(null);

  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth);
    }
  }, [ref]);
  return (
    <StyledCountryLabel code={code}>
      <StyledCountryTextBox ref={refBox}>
        {needTooltip ? (
          <StyledTooltip id={id} place="top">
            {name}
          </StyledTooltip>
        ) : null}
        <StyledCountryLabelText data-tooltip-id={id} ref={ref}>
          {name}
        </StyledCountryLabelText>
      </StyledCountryTextBox>
    </StyledCountryLabel>
  );
};

CountryNameCell.propTypes = {
  name: PT.string,
  code: PT.string
};

CountryNameCell.defaultProps = {
  name: "",
  code: ""
};

export const getDefaultDescLangHelper = (desc = {}, languages = []) => {
  const allLangDesc = Object.keys(desc);

  if (allLangDesc.length === 0) {
    return languages[0]?.value;
  }

  for (const key in languages) {
    const { value } = languages[key];

    if (allLangDesc.includes(value) && desc[value]) {
      return value;
    }
  }

  return Object.entries(desc)
    .find(([lang]) => Object.keys(desc).includes(lang))?.[0];
};

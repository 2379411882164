import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getAllPromocodes,
  getAlternatePromocodes,
  getPromocode
} from "./Promocodes.thunks";

const initialState = {
  promocodes: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isLoading: false,
    isError: null,
    prevParams: null
  },
  promocode: {
    data: {
      alternatePromocodes: [],
      description: null,
      endDate: null,
      eternal: false,
      id: "",
      imageUrl: "",
      isActive: true,
      promocode: "",
      siteId: "",
      siteName: "",
      startDate: ""
    },
    isLoading: false,
    isError: null
  },
  alternatePromocodes: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  }
};

const PromocodesReducer = createSlice({
  name: "promocodes",
  initialState,
  reducers: {
    setPromocodesPrevParams(state, action) {
      state.promocodes.prevParams = action.payload;
    },
    setAllPromocodes(state, action) {
      state.promocodes.data = action.payload;
    },
    setAlternatePromocodes(state, action) {
      state.alternatePromocodes.data = action.payload;
      state.alternatePromocodes.isDataLoaded = true;
    },
    setPromocode(state, action) {
      state.promocode.data = action.payload;
    }
  },
  extraReducers: {
    [getAllPromocodes.pending]: (state) => {
      state.promocodes.isError = null;
      state.promocodes.isLoading = true;
    },
    [getAllPromocodes.fulfilled]: (state, action) => {
      state.promocodes.isError = null;
      state.promocodes.data = action.payload;
      state.promocodes.isLoading = false;
    },
    [getAllPromocodes.rejected]: (state, action) => {
      state.promocodes.isError = action.payload;
      state.promocodes.isLoading = false;
    },

    [getPromocode.pending]: (state) => {
      state.promocode.isError = null;
      state.promocode.isLoading = true;
    },
    [getPromocode.fulfilled]: (state, action) => {
      state.promocode.isError = null;
      state.promocode.data = action.payload;
      state.promocode.isLoading = false;
    },
    [getPromocode.rejected]: (state, action) => {
      state.promocode.isError = action.payload;
      state.promocode.isLoading = false;
    },

    [getAlternatePromocodes.pending]: (state) => {
      state.alternatePromocodes.isError = null;
      state.alternatePromocodes.isLoading = true;
    },
    [getAlternatePromocodes.fulfilled]: (state, action) => {
      state.alternatePromocodes.isError = null;
      state.alternatePromocodes.data = action.payload;
      state.alternatePromocodes.isLoading = false;
    },
    [getAlternatePromocodes.rejected]: (state, action) => {
      state.alternatePromocodes.isError = action.payload;
      state.alternatePromocodes.isLoading = false;
    }
  }
});

export const PromocodesReducerActions = {
  ...PromocodesReducer.actions,
  getAllPromocodes,
  getPromocode,
  getAlternatePromocodes
};
export default PromocodesReducer.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    email: "",
    uid: "",
    picture: "",
    name: "",
    roles: []
  },
  isLogout: false,
  isUserAuthenticated: false,
  isRedirectToCabinet: false,
  isAuthfetched: false
};

const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setDefaultUser(state) {
      state.user = {
        email: "",
        uid: "",
        picture: "",
        name: "",
        roles: []
      };
    },
    setAuthentication(state, action) {
      state.isUserAuthenticated = action.payload;
      state.isLogout = action.payload ? false : state.isLogout;
      state.isAuthfetched = true;
    },
    setIsRedirectToCabinet(state, action) {
      state.isRedirectToCabinet = action.payload;
    },
    setLogout(state) {
      state.isLogout = true;
      state.user = {
        email: "",
        uid: "",
        picture: "",
        name: "",
        roles: []
      };
      state.isUserAuthenticated = false;
    }
  }
});

export const UserReducerActions = {
  ...UserReducer.actions
};
export default UserReducer.reducer;

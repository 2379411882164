import { useEffect } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { AllActions } from "store/reducers/AllActions";
import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { faqToolPortScanner } from "utils/constants/questionsAndAnswers";
import { getCountryId } from "utils/helpers";

import animation from "assets/animations/free_proxy.riv";
import animationFaq from "assets/animations/how_we_can_help_you.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  freeProxyHeroImageMobile,
  freeProxyHeroImageMobile2x
} from "assets/img";

import { FreeProxyInfo } from "./FreeProxyInfo/FreeProxyInfo";
import { FreeProxyList } from "./FreeProxyList/FreeProxyList";

import styles from "./FreeProxyPage.module.scss";

const FreeProxyPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const google = searchParams.get("google");

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  useEffect(() => {
    if (!google) {
      searchParams.set("google", "yes");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.section}>
      <Main
        title={t("freeProxy.main.title")}
        subtitleHtml={t("freeProxy.main.subtitle")}
        button={false}
        images={{
          mobile1x: freeProxyHeroImageMobile,
          mobile2x: freeProxyHeroImageMobile2x
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        contentClassName={styles.hero__content}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-45}
            right={-1}
            bottom={-12}
            left={-83}
          />
        }
      />
      <FreeProxyList />
      <FreeProxyInfo />
      <FAQ
        list={faqToolPortScanner}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        animation={animationFaq}
        className={styles.faq}
      />
      <SeoSection content={current?.data?.seo} />
    </div>
  );
};

export default FreeProxyPage;

FreeProxyPage.getServerSideState = async (store, params, api) => {
  const {
    content: { countries }
  } = store.getState();

  const countryId = getCountryId(countries.data, params?.params?.country)?.id;

  const fetchParams = {
    page: 0,
    pageSize: 10,
    countryIds: countryId ? [countryId] : [],
    type: params?.params?.type || "",
    city: "",
    google:
      params?.params?.google === "yes" || !params?.params?.google
        ? "true"
        : "false"
  };

  const { data: tableData } = await axios.post(
    `${api}/api/front/proxy/free`,
    fetchParams
  );
  const { data: allData } = await axios.post(
    `${api}/api/front/proxy/free/all`,
    {
      countryIds: fetchParams.countryIds,
      type: fetchParams.type,
      city: fetchParams.city,
      google: fetchParams.google
    }
  );

  if (tableData) {
    store.dispatch(AllActions.setFreeProxyContent(tableData));
  }
  if (allData) {
    store.dispatch(AllActions.setAllFreeProxyContent(allData));
  }
};

import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getAllCabinetSiteStatuses,
  getAllCabinetSites,
  getCabinetPromocodes,
  getCabinetSites
} from "./Cabinet.thunks";

const initialState = {
  proxies: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isLoading: false,
    isError: null,
    prevParams: null
  },
  allProxies: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  siteStatuses: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  promocodes: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isLoading: false,
    isError: null,
    prevParams: null
  }
};

const CabinetReducer = createSlice({
  name: "cabinet",
  initialState,
  reducers: {
    setCabinetProxiesPrevParams(state, action) {
      state.proxies.prevParams = action.payload;
    },
    setCabinetPromocodesPrevParams(state, action) {
      state.promocodes.prevParams = action.payload;
    },
    setCabinetSiteStatuses(state, action) {
      state.siteStatuses.data = action.payload;
      state.siteStatuses.isDataLoaded = true;
    },
    resetCabinet(state) {
      state.proxies = {
        data: {
          content: [],
          totalElements: 0,
          totalPages: 0
        },
        isLoading: false,
        isError: null,
        prevParams: null
      };

      state.allProxies = {
        data: [],
        isLoading: false,
        isError: null,
        isDataLoaded: false
      };

      state.promocodes = {
        data: [],
        isLoading: false,
        isError: null,
        isDataLoaded: false
      };
    }
  },
  extraReducers: {
    [getCabinetSites.pending]: (state) => {
      state.proxies.isError = null;
      state.proxies.isLoading = true;
    },
    [getCabinetSites.fulfilled]: (state, action) => {
      state.proxies.isError = null;
      state.proxies.data = action.payload;
      state.proxies.isLoading = false;
    },
    [getCabinetSites.rejected]: (state, action) => {
      state.proxies.isError = action.payload;
      state.proxies.isLoading = false;
    },

    [getAllCabinetSites.pending]: (state) => {
      state.allProxies.isError = null;
      state.allProxies.isLoading = true;
    },
    [getAllCabinetSites.fulfilled]: (state, action) => {
      state.allProxies.isError = null;
      state.allProxies.data = action.payload;
      state.allProxies.isDataLoaded = true;
      state.allProxies.isLoading = false;
    },
    [getAllCabinetSites.rejected]: (state, action) => {
      state.allProxies.isError = action.payload;
      state.allProxies.isLoading = false;
    },

    [getCabinetPromocodes.pending]: (state) => {
      state.promocodes.isError = null;
      state.promocodes.isLoading = true;
    },
    [getCabinetPromocodes.fulfilled]: (state, action) => {
      state.promocodes.isError = null;
      state.promocodes.data = action.payload;
      state.promocodes.isLoading = false;
    },
    [getCabinetPromocodes.rejected]: (state, action) => {
      state.promocodes.isError = action.payload;
      state.promocodes.isLoading = false;
    },
    [getAllCabinetSiteStatuses.pending]: (state) => {
      state.siteStatuses.isError = null;
      state.siteStatuses.isLoading = true;
    },
    [getAllCabinetSiteStatuses.fulfilled]: (state, action) => {
      state.siteStatuses.isError = null;
      state.siteStatuses.data = action.payload;
      state.siteStatuses.isLoading = false;
    },
    [getAllCabinetSiteStatuses.rejected]: (state, action) => {
      state.siteStatuses.isError = action.payload;
      state.siteStatuses.isLoading = false;
    }
  }
});

export const CabinetReducerActions = {
  ...CabinetReducer.actions,
  getCabinetSites,
  getAllCabinetSites,
  getCabinetPromocodes,
  getAllCabinetSiteStatuses
};
export default CabinetReducer.reducer;

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledNewCell } from "./NewCell.styled";

export const NewCell = ({ isNew }) => {
  const { t } = useTranslation();

  if (!isNew) {
    return null;
  }

  return (
    <StyledNewCell>
      {t("mainPage.userChoice.columns.new").toUpperCase()}
    </StyledNewCell>
  );
};

NewCell.propTypes = {
  isNew: PT.bool
};

NewCell.defaultProps = {
  isNew: false
};

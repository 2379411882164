import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { getAllUser } from "../../../store/reducers/UserReducer/User.selectors";
import { Button } from "../Button/Button";

const AuthButton = ({ type = "solid", position = "center" }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { isUserAuthenticated, user } = useSelector(getAllUser);

  // **Dispatch
  const { setMobileMenu } = useDispatchedActions();

  const closeMenu = () => {
    setMobileMenu(false);
  };

  if (isUserAuthenticated) {
    return (
      <Button
        classNames="button-auth"
        type={type}
        position={position}
        link
        href={`${hrefLang}/cabinet/proxy/`}
        onClick={closeMenu}
      >
        <span>{user.email}</span>
      </Button>
    );
  }

  return (
    <Button
      classNames="button-auth"
      type={type}
      position={position}
      link
      href={`${hrefLang}/login/`}
      onClick={closeMenu}
    >
      {t("header.cabinet.cabinet")}
    </Button>
  );
};

export default AuthButton;

import { useEffect, useState } from "react";

import { Checkbox } from "components/ui/New/Checkbox/Checkbox";
import Dropdown from "components/ui/New/Dropdown/Dropdown";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Search from "components/ui/New/Search/Search";

import styles from "./NameFilter.module.scss";

export const NameFilter = ({ data, filter, setFilter }) => {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    setFilteredData(
      data?.filter((item) =>
        item?.siteName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, data]);

  const handleCheckboxClick = (id) => {
    if (filter.includes(id)) {
      setFilter(filter?.filter((item) => item !== id));
    } else {
      setFilter([...filter, id]);
    }
  };

  return (
    <Dropdown
      className={styles.dropdown}
      label={<IconsSVG className={styles.icon} name="filterAlt" />}
      menuClassName={styles.dropdown__menu}
      align="center"
      direction="bottom"
      portal
    >
      <Search value={search} onChange={handleSearch} variant={"outlined"} />
      <div className={styles.container}>
        {filteredData?.length ? (
          <div className={styles.box}>
            <ul className={styles.list}>
              {filteredData.map((item) => {
                const checked = filter.includes(item.id);
                return (
                  <li
                    key={item?.id}
                    className={checked ? styles.item__checked : styles.item}
                  >
                    <Checkbox
                      checked={checked}
                      onClick={() => handleCheckboxClick(item.id)}
                      name={item.siteName}
                      label={
                        <span className={styles.name}>{item.siteName}</span>
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </Dropdown>
  );
};

import styled from "@emotion/styled";

export const StyledButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

import { useMemo } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Title } from "components/ui/New/Title/Title";

import styles from "./HowToFix.module.scss";

export const HowToFix = ({
  data,
  title,
  imageMobile,
  animation,
  className,
  animationClassName
}) => {
  const { t } = useTranslation();

  const memoSteps = useMemo(
    () =>
      data.map((item, index) => (
        <li key={item} className={styles.item}>
          <p className={styles.step}>{`${t("ui.step")} ${index + 1}`}</p>
          <p className={styles.description}>{item}</p>
        </li>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <Container className={`${styles.container} ${className}`}>
      <div className={styles.content}>
        <Title tag="h2" className={styles.title}>
          {title}
        </Title>
        <ul className={styles.list}>{memoSteps}</ul>
      </div>
      <div className={`${styles.animation} ${animationClassName}`}>
        {animation ? animation : null}
      </div>
      <picture>
        {/* <source srcSet={imageDesktop} media="(min-width: 993px)" /> */}
        <source srcSet={imageMobile} />
        <img src={imageMobile} alt={title} className={styles.image} />
      </picture>
    </Container>
  );
};

HowToFix.propTypes = {
  data: PT.arrayOf(PT.string).isRequired,
  imageDesktop: PT.node.isRequired,
  imageMobile: PT.node.isRequired,
  title: PT.string
};

HowToFix.defaultProps = {
  data: [],
  title: ""
};

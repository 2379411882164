import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";
import debounce from "lodash.debounce";

import { scrollToWithOffset } from "utils/helpers/scroll.helper";

import styles from "../Article.module.scss";

export const Body = () => {
  const { content } = useSelector(getAllBlogInner);
  const [activeSection, setActiveSection] = useState(null);

  const { t } = useTranslation();

  const sections = useRef([]);

  const handleScroll = () => {
    const { pageYOffset } = window;
    let newActiveSection = null;
    const firstSectionTop = sections.current[0]?.offsetTop;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset + 200 >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
        newActiveSection = section.id;
      } else if (pageYOffset < firstSectionTop) {
        newActiveSection = sections.current[0].id;
      }
    });

    setActiveSection(newActiveSection);
  };

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");
    window.addEventListener("scroll", debounce(handleScroll, 15));
    return () => {
      window.removeEventListener("scroll", debounce(handleScroll, 15));
    };
  }, []);

  useEffect(() => {
    if (content?.data?.anchors?.length) {
      setActiveSection(content.data.anchors[0]?.anchor);
    }
  }, [content]);

  const handleAnchorClick = (item) => {
    setActiveSection(item.anchor);
    scrollToWithOffset(item.anchor, -50);
  };

  return (
    <div className={styles.body__wrapper}>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: content.data.articleBody }}
      />
      {content?.data.anchors?.length ? (
        <div className={styles.summary}>
          <p className={styles.title}>
            {t("blogInnerPage.articleBody.inThisArticle")}
          </p>
          <ul>
            {content.data?.anchors?.map((item) => (
              <li
                key={item.id}
                id={item.title}
                className={
                  activeSection === item.anchor
                    ? styles.summary__item_active
                    : styles.summary__item
                }
                onClick={() => handleAnchorClick(item)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

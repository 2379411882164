import LoginPage from "pages/AuthPages/LoginPage/LoginPage";
import PasswordRecoveryPage from "pages/AuthPages/PasswordRecoveryPage/PasswordRecoveryPage";
import RecoveryPage from "pages/AuthPages/RecoveryPage/RecoveryPage";
import RegisterPage from "pages/AuthPages/RegistrationPage/RegisterPage";

export const AuthRoutesOLD = [
  {
    path: "/:lang?/login/",
    tag: "login",
    type: "auth",
    component: () => import("pages/AuthPages/LoginPage/LoginPage"),
    element: LoginPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/registration/",
    tag: "registration",
    type: "auth",
    component: () => import("pages/AuthPages/RegistrationPage/RegisterPage"),
    element: RegisterPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/",
    tag: "recovery",
    type: "auth",
    component: () => import("pages/AuthPages/RecoveryPage/RecoveryPage"),
    element: RecoveryPage,
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/:token",
    tag: "recovery-password",
    type: "auth",
    component: () =>
      import("pages/AuthPages/PasswordRecoveryPage/PasswordRecoveryPage"),
    element: PasswordRecoveryPage,
    isAuthRequired: false,
    isSsr: false
  }
];

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";

export const Navigation = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Dispatch
  const { setMobileMenu } = useDispatchedActions();

  const closeMenu = () => {
    setMobileMenu(false);
  };

  return (
    <nav className="mobile-nav__nav">
      <ul>
        <li>
          <Link onClick={closeMenu} to={`${hrefLang}/addsite/?tab=proxy`}>
            {t("navigation.addsite")}
          </Link>
        </li>
        <li>
          <Link onClick={closeMenu} to={`${hrefLang}/feedback/`}>
            {t("navigation.feedback")}
          </Link>
        </li>
        <li>
          <Link onClick={closeMenu} to={`${hrefLang}/blog/`}>
            {t("navigation.blog")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

import { useState } from "react";

import { matchPath, useLocation } from "react-router-dom";

import { getCurrentRoutes } from "utils/helpers";

import * as allRoutes from "../routes";

export const useMatchedRoute = () => {
  const { pathname } = useLocation();
  const [routes] = useState(
    getCurrentRoutes(allRoutes, process.env.REACT_APP_MODE === "dev").map(
      (route) => ({ ...route, component: null, element: null })
    )
  );

  const splittedPathname = pathname.split("/").filter(Boolean);
  if (
    splittedPathname.length > 0 &&
    !["ru", "ua"].includes(splittedPathname[0])
  ) {
    return routes.find((route) =>
      matchPath({ path: route?.path.replace("/:lang?", "") }, pathname)
    );
  }

  return routes.find((route) => matchPath({ path: route?.path }, pathname));
};

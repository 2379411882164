import { forwardRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useLangUrlDefault } from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { getAllBlogInner } from "../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../../../store/reducers/UserReducer/User.selectors";

export const Response = forwardRef(
  (
    {
      review,
      toggleAccordion,
      isOpen,
      changeHandler,
      captchaRef,
      setTokenCaptcha,
      ...rest
    },
    ref
  ) => {
    const [queryLang] = useLangUrlDefault();

    // **Redux state
    const { content } = useSelector(getAllBlogInner);
    const { user, isUserAuthenticated } = useSelector(getAllUser);
    // const {
    //   reviews: { reply },
    //   modals,
    //   notifications
    // }
    //
    const { t } = useTranslation();
    const { captchaConfig } = useSelector(getAllContent);
    const { site } = useSelector(getAllProxySite);

    // **Local state
    const [isDisabled, setIsDisabled] = useState(false);
    const [inputValues, setInputValues] = useState({
      commentName: isUserAuthenticated ? user.name : "",
      commentEmail: isUserAuthenticated ? user.email : "",
      commentBody: ""
    });
    const [isErrorsFields, setIsErrorsFields] = useState({
      commentName: false,
      commentEmail: false,
      commentBody: false
    });
    const { enable, headerName } = captchaConfig.data;

    const leaveComment = async () => {
      // Field Validation
      let counter = 0;
      let isErrorField = false;

      for (const key in inputValues) {
        counter++;

        if (
          inputValues[key].length === 0 ||
          (key === "commentEmail" &&
            inputValues[key].length > 0 &&
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValues[key]))
        ) {
          isErrorField = true;

          setIsErrorsFields((prevState) => ({
            ...prevState,
            [key]: true
          }));
        }

        if (counter === Object.keys(inputValues).length && isErrorField) {
          return;
        }
      }

      let header = {};

      if (enable) {
        try {
          const { response: token } = await captchaRef.current.execute({
            async: true
          });
          header = { [headerName]: token };
        } catch (ignore) {
          return;
        }
      }

      try {
        setIsDisabled(true);

        const response = await ApiService.createComment(
          review.id,
          site.data?.id || content.data.id,
          inputValues.commentName,
          inputValues.commentEmail,
          inputValues.commentBody,
          queryLang,
          header
        );

        if (response && response.status !== 200) {
          throw response;
        }

        // Clear input value
        setInputValues({
          commentName: "",
          commentEmail: "",
          commentBody: ""
        });

        // Show success message
        toast.success(t("notifications.comment"));

        // Fetch comment
        if (changeHandler) {
          changeHandler();

          // Close textarea
          toggleAccordion();
        }
      } catch (err) {
        toast.error(t("notifications.apiError"));
      } finally {
        if (enable) {
          captchaRef.current?.resetCaptcha();
          setTokenCaptcha(null);
        }

        setIsDisabled(false);
      }
    };

    const inputHandler = (e) => {
      const nameField = e.target.name;
      const valueField = e.target.value.replace(
        /[^a-zа-яёіїє0-9!,.?@()\s]|\b(https?:\/\/\S*\b)/gi,
        ""
      );

      setInputValues({
        ...inputValues,
        [nameField]: valueField
      });

      if (valueField.length > 0) {
        setIsErrorsFields({
          ...isErrorsFields,
          [nameField]: false
        });
      }
    };

    const defineClassname = () => {
      let className = "reviews__response";

      if (isOpen) {
        className += " reviews__response--active";
      }

      return className;
    };

    return (
      <div ref={ref} className={defineClassname()} {...rest}>
        {!isUserAuthenticated && (
          <div className="reviews__response-row">
            <div
              className={`reviews__response-input ${
                isErrorsFields.commentName && "reviews__response--error"
              }`}
            >
              <input
                type="text"
                value={inputValues.commentName}
                placeholder={t("modals.comment.form.name.placeholder")}
                name="commentName"
                onInput={inputHandler}
              />
            </div>
            <div
              className={`reviews__response-input ${
                isErrorsFields.commentEmail && "reviews__response--error"
              }`}
            >
              <input
                type="text"
                value={inputValues.commentEmail}
                placeholder={t("modals.comment.form.email.placeholder")}
                name="commentEmail"
                onInput={inputHandler}
              />
            </div>
          </div>
        )}
        <div
          className={`reviews__response-textarea ${
            isErrorsFields.commentBody && "reviews__response--error"
          }`}
        >
          <textarea
            value={inputValues.commentBody}
            placeholder={t("modals.comment.form.body.placeholder")}
            name="commentBody"
            onInput={inputHandler}
          ></textarea>
        </div>
        <div className="reviews__response-btns">
          <button
            className="reviews__response-btn reviews__response-btn--cancel"
            onClick={toggleAccordion}
          >
            {t("reviews.reply.cancel")}
          </button>
          <button
            disabled={isDisabled}
            className="reviews__response-btn reviews__response-btn--apply"
            onClick={leaveComment}
          >
            {t("reviews.reply.apply")}
          </button>
        </div>
      </div>
    );
  }
);

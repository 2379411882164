import styled from "@emotion/styled";

export const StyledTextCell = styled.span`
color: ${(p) => p.theme.colors["neutral-800"]}
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: 400;
  line-height: 171%;
  text-align: left;
`;

/* eslint-disable no-nested-ternary */
import PT from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./AnonymityStatus.module.scss";

const AnonymityStatus = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.status}>
      <img
        src={
          value > 50 ? "img/ui/disguise-green.svg" : value > 20 ? "img/ui/disguise-yellow.svg" : "img/ui/disguise-red.svg"
        }
        className={styles.icon}
      />
      <div className={styles.value}>
        {t("myAnonymity.yourDisguise")} <span>{value}%</span>
      </div>
      <p className={styles.description}>{t("myAnonymity.info")}</p>
    </div>
  );
};

AnonymityStatus.propTypes = {
  value: PT.number.isRequired
};

export default AnonymityStatus;

import { useCallback, useMemo, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import Icon from "components/ui/New/Icon/Icon";
import { Title } from "components/ui/New/Title/Title";

import { getIpDetailsData } from "utils/helpers/getIpDetailsData.helpers";

import IpDetailsItem from "./IpDetailsItem";

import styles from "./IpDetails.module.scss";

export const IpDetails = ({ userIp, isLoading }) => {
  const [openedItems, setOpenedItems] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  const handleOpen = useCallback(
    (id) => {
      if (openedItems.includes(id)) {
        setOpenedItems(openedItems?.filter((item) => item !== id));
      } else {
        setOpenedItems([...openedItems, id]);
      }
    },
    [openedItems]
  );

  const resultsList = useMemo(
    () =>
      (showMore
        ? getIpDetailsData(userIp, t, styles)
        : getIpDetailsData(userIp, t, styles)?.slice(0, 4)
      )?.map((item) => (
        <IpDetailsItem
          title={item.title}
          image={item.image}
          isLoading={isLoading}
          key={item.id}
          id={item.id}
          isOpened={openedItems.includes(item.id)}
          handleOpen={handleOpen}
          listData={item.listData}
        />
      )),
    [showMore, handleOpen, openedItems, isLoading, userIp, t]
  );

  return (
    <Container className={styles.container}>
      <Title tag="h2" className={styles.title}>
      {t("myAnonymity.details.title")}
      </Title>
      <div className={styles.details}>
        {resultsList}
        <div className={styles.show_more} onClick={() => setShowMore(!showMore)}>
          <span className={styles.text}>{showMore ? "Hide" : "Show more"}</span>
          <Icon
            name={showMore ? "expandUp" : "refresh"}
            className={showMore ? styles.expand_icon : styles.refresh_icon}
          />
        </div>
      </div>
    </Container>
  );
};

IpDetails.propTypes = {
  userIp: PT.shape({}),
  isLoading: PT.bool
};

IpDetails.defaultProps = {
  userIp: {},
  isLoading: false
};

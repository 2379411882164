import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AuthButton from "components/ui/New/AuthButton/AuthButton";
import Button from "components/ui/New/Button/Button";

import { getAllSite } from "store/reducers/SiteReducer/Site.selectors";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import { navigationHeader } from "utils/constants";

import Languages from "../Languages/Languages";

import { ServicesNavigation } from "./ServicesNavigation/ServicesNavigation";
import { Navigation } from "./Navigation/Navigation";
import BlogNavItem from "./BlogNavItem/BlogNavItem";
import { Overlay } from "./Overlay/Overlay";

import styles from "./BurgerMenu.module.scss";

export const BurgerMenu = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { isBurgerMenuOpened } = useSelector(getAllSite);
  const { setBurgerMenu } = useDispatchedActions();
  const { t } = useTranslation();

  return (
    <Overlay isOpened={isBurgerMenuOpened} setIsOpened={setBurgerMenu}>
      <div className={styles.nav__mobile}>
        <ServicesNavigation />
        <Navigation data={navigationHeader} mobile />
      </div>
      <div className={styles.nav__desktop}>
        <Navigation data={navigationHeader} />
        <BlogNavItem />
      </div>
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          size="nm"
          iconLeft="plus"
          className={styles.add_site__button}
          linkTo={`${hrefLang}/addsite`}
        >
          {t("navigation.addsite")}
        </Button>
        <AuthButton />
        <Languages className={styles.languages} />
      </div>
    </Overlay>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getCabinetSites = createAsyncThunk(
  "cabinet/getCabinetSites",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCabinetSites(
        params || {
          proxyType: [],
          country: "",
          sort: "",
          page: 0,
          size: 10
        }
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllCabinetSites = createAsyncThunk(
  "cabinet/getAllCabinetSites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllCabinetSites();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getCabinetPromocodes = createAsyncThunk(
  "cabinet/getCabinetPromocodes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCabinetPromocodes(
        params || { size: 10, page: 0 }
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllCabinetSiteStatuses = createAsyncThunk(
  "cabinet/getAllCabinetSiteStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllCabinetSiteStatuses();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

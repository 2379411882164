import PT from "prop-types";
import ReactPaginate from "react-paginate";

import IconsSVG from "../Icon/IconsSVG";

import styles from "./Pagination.module.scss";

export const Pagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  className,
  pageRangeDisplayed,
  marginPagesDisplayed,
  currentPage
}) => (
  <div className={`${styles.wrapper} ${className}`}>
    <ReactPaginate
      pageClassName={styles.page}
      previousClassName={styles.previous}
      nextClassName={styles.next}
      previousLabel={<IconsSVG name="arrowDown" />}
      nextLabel={<IconsSVG name="arrowDown" />}
      breakLabel={"..."}
      breakClassName={styles.break}
      forcePage={currentPage}
      pageCount={Math.ceil(rowCount / rowsPerPage)}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={(data) => {
        onChangePage(data.selected + 1);
      }}
      containerClassName={styles.pagination}
      subContainerClassName={`${styles.pages} pagination`}
      activeClassName={styles.active}
    />
  </div>
);

Pagination.propTypes = {
  rowsPerPage: PT.number.isRequired,
  rowCount: PT.number.isRequired,
  onChangePage: PT.func.isRequired,
  className: PT.string,
  pageRangeDisplayed: PT.number,
  marginPagesDisplayed: PT.number
};

Pagination.defaultProps = {
  className: "",
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 2
};

export default Pagination;

import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useDispatchedActions } from "hooks";

import { Reviews } from "../Reviews";

import { InfoPart } from "./InfoPart/InfoPart";
import { ReplyPart } from "./ReplyPart/ReplyPart";
import { UserPart } from "./UserPart/UserPart";
import { UserPartV2 } from "./UserPartV2/UserPartV2";

import styles from "./ReviewItem.module.scss";

export const ReviewItem = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler,
  complaint,
  isRootReview,
  nestedResponseValue,
  isDashboard
}) => {
  // **Redux state
  const { reviews } = useSelector(getAllProxySite);

  // **Local state
  const [isReviewsOpened, setIsReviewsOpened] = useState(false);

  // **Dispatch
  const { setReviewInfo } = useDispatchedActions();

  // **Ref
  const reviewInnerRef = useRef(null);
  const reviewRef = useRef(null);

  const toggleReview = (test) => {
    if (!reviewInnerRef.current) return;

    if (test === true) {
      reviewInnerRef.current.style.height = "auto";
      setIsReviewsOpened(true);
      return;
    }
    const height = reviewInnerRef.current.scrollHeight;

    if (isReviewsOpened) {
      requestAnimationFrame(() => {
        reviewInnerRef.current.style.height = `${height}px`;

        requestAnimationFrame(() => {
          reviewInnerRef.current.style.height = "0px";
        });
      });

      setIsReviewsOpened(false);
    } else {
      reviewInnerRef.current.style.height = `${height}px`;

      setIsReviewsOpened(true);
    }
  };

  // const transitionEndHandler = () => {
  //   if (!reviewInnerRef.current) return;

  //   if (isReviewsOpened) {
  //     reviewInnerRef.current.style.height = "auto";
  //   } else {
  //     reviewInnerRef.current.style.height = "0px";
  //   }
  // };

  useEffect(() => {
    if (!reviewInnerRef.current) return;

    if (!isReviewsOpened) {
      reviewInnerRef.current.style.height = "0px";
    } else {
      reviewInnerRef.current.style.height = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewInnerRef.current?.scrollHeight]);

  useEffect(() => {
    if (!reviewRef.current) return;
    if (reviews.reviewId && review.id === reviews.reviewId) {
      const coords = reviewRef.current.getBoundingClientRect();

      window.scrollTo({
        top: coords.top + window.scrollY,
        behavior: "smooth"
      });

      setReviewInfo({ page: null, id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewRef.current, reviews.reviewId]);

  return (
    <>
      <div
        ref={nestedResponseValue === 1 ? reviewRef : null}
        className={`${styles.card} ${
          isDashboard
            ? styles.card_dashboard
            : `${isRootReview ? styles.card_shadow : styles.card_not_main}`
        } ${
          nestedResponseValue % 2 === 0 ? styles.card_gray : styles.card_white
        }`}
      >
        <div className={styles.base}>
          {isDashboard ? (
            <UserPartV2 review={review} isRootReview={isRootReview} />
          ) : (
            <UserPart review={review} isRootReview={isRootReview} />
          )}
          <div>
            <InfoPart review={review} toggleReview={toggleReview} />
            <ReplyPart
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              review={review}
              changeHandler={changeHandler}
              complaint={complaint}
              toggleReview={toggleReview}
              isRootReview={isRootReview}
              isReviewsOpened={isReviewsOpened}
              nestedResponseValue={nestedResponseValue}
            />
          </div>
        </div>
        {review?.responses?.length !== 0 && (
          <div
            ref={isRootReview ? reviewInnerRef : null}
            className={
              nestedResponseValue > 3
                ? `${styles.inner} ${styles.inner_no_margin} ${
                    isRootReview && !isReviewsOpened ? styles.inner_closed : ""
                  }`
                : `${styles.inner} ${styles.inner_margin} ${
                    isRootReview && !isReviewsOpened ? styles.inner_closed : ""
                  }`
            }
            // onTransitionEnd={isRootReview ? transitionEndHandler : null}
            style={isRootReview ? { height: 0 } : {}}
          >
            <Reviews
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              reviews={review.responses}
              changeHandler={changeHandler}
              nestedResponseValue={nestedResponseValue + 1}
              toggleReview={toggleReview}
              isDashboard={isDashboard}
            />
          </div>
        )}
      </div>
    </>
  );
};

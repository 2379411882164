import { useEffect } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";

import { AllActions } from "store/reducers/AllActions";

import { Blog } from "./Blog/Blog";

const BlogPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, [pathname]);
  return <Blog />;
};

export default BlogPage;

BlogPage.getServerSideState = async (store, params, api) => {
  const { data } = await axios.get(`${api}/api/front/site/blog`, {
    params: {
      page: 0,
      size: 5,
      category:
        params.params.tab === "all" ? "null" : params.params.tab !== "news",
      languageCode: params.languageCode,
      ...(params.params.s && { search: params.params.s })
    }
  });

  if (data) {
    store.dispatch(AllActions.setBlogContent(data));
  }
};

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import { getParamsUrlForFilter } from "utils/helpers";

import {
  StyledTableLink,
  StyledTableTabText,
  StyledTableTabs
} from "./TableTabs.styled";

export const TableTabs = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("fpt") || "IPv4";
  const navigate = useNavigate();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const proxyTypesForLang = proxyTypes.data?.[queryLang];
  const isProxyTypesForLangNotEmpty =
    proxyTypesForLang && proxyTypesForLang.length !== 0;

  const routeHandler = (filter, evt = null) => {
    const sortArray = [filter];

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const s = searchParams.get("s");
    const urlForFilter = getParamsUrlForFilter(fc, fg, sortArray, s);

    if (evt === null) {
      return urlForFilter;
    }

    navigate(urlForFilter);
    setActiveFilters({ fpt: sortArray });
  };

  return (
    <StyledTableTabs>
      {isProxyTypesForLangNotEmpty &&
        proxyTypesForLang?.map(({ type, name }) => (
          <StyledTableLink
            key={type}
            variant={"secondary"}
            className={activeTab === type ? "activeTab" : ""}
            to={`${hrefLang}/new/proxy/${routeHandler(type)}`}
            onClick={(evt) => {
              evt.preventDefault();
              routeHandler(type, evt);
            }}
          >
            <StyledTableTabText id={name}>{name}</StyledTableTabText>
          </StyledTableLink>
        ))}
    </StyledTableTabs>
  );
};

import styled from "@emotion/styled";

export const StyledHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors["neutral-400"]};
  opacity: 1 !important;

  & [role="tooltip"] {
    opacity: 1 !important;
  }
`;

export const StyledHeaderNameCell = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: uppercase;
  white-space: nowrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
`;

import styled from "@emotion/styled";

import LabelCountry from "components/ui/New/LabelContry/LabelCountry";

export const StyledCountryLabel = styled(LabelCountry)`
  width: 100%;
  overflow: hidden;
  padding-right: 5px;
`;

export const StyledCountryLabelText = styled.span`
  color: ${(p) => p.theme.colors["dark-700"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-weight: 600;
  line-height: 125%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCountryTextBox = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
`;

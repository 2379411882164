import { useTranslation } from "react-i18next";

import Icon from "components/ui/New/Icon/Icon";

import {
  StyledResultTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText
} from "./ResultTable.styled";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  return (
    <StyledResultTable>
      <TableHeadRow>
        <TableHeadCol>{t("evercookieTest.table.name")}</TableHeadCol>
        <TableHeadCol>{t("evercookieTest.table.value")}</TableHeadCol>
      </TableHeadRow>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.name}>
            <TableCol>
              <TableText>{item?.name}</TableText>
            </TableCol>
            <TableCol>
              <TableText>
                {isLoading ? (
                  <Icon name="loading" loading={isLoading} />
                ) : (
                  item?.value
                )}
              </TableText>
            </TableCol>
          </TableRow>
        ))}
      </TableBody>
    </StyledResultTable>
  );
};

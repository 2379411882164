// import { useEffect } from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "hooks";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { Loader } from "../../../../ui/Loader/Loader";
import { Promocode } from "../../Promocodes/Promocode";

import {
  StyledPromosBox,
  StyledPromosContainer,
  StyledPromosList,
  StyledPromosTitle
} from "./Promocodes.styled";

export const Promocodes = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { sellers } = useSelector(getAllContent);

  const [checkedSellers, setCheckedSellers] = useState([]);

  useEffect(() => {
    if (sellers.data) {
      const checkedSellers = sellers.data.filter(
        (seller) => seller.promoCode && seller.promoCode.length > 0
      );
      setCheckedSellers(checkedSellers);
    }
  }, [sellers.data]);

  return (
    <StyledPromosContainer>
      {!sellers.isLoading ? (
        <>
          <StyledPromosTitle>
            {t("proxyPage.sidebar.promocodes.title")}
          </StyledPromosTitle>
          <StyledPromosBox>
            <StyledPromosList>
              {checkedSellers.map((seller) => (
                <li key={seller.id}>
                  <Promocode
                    variant="gray"
                    promocode={seller?.promoCode}
                    description={
                      seller.promoCodeDescription?.[queryLang]
                        ? seller.promoCodeDescription[queryLang]
                        : t("proxyPage.sidebar.right.sellers.promocode")
                    }
                    title={seller.name}
                    image={seller.image}
                    time={seller.endDate}
                    eternal={seller.eternal}
                  />
                </li>
              ))}
            </StyledPromosList>
          </StyledPromosBox>
        </>
      ) : (
        <Loader />
      )}
    </StyledPromosContainer>
  );
};

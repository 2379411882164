import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AllProxyCountriesModal } from "components/common/New/Modals/AllProxyCountriesModal/AllProxyCountriesModal";
import YesNo from "components/common/New/YesNo/YesNo";
import Card from "components/ui/New/Card/Card";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import TextButton from "components/ui/New/TextButton/TextButton";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { getCountriesById } from "utils/helpers";

import icon from "assets/img/proxyPage/info-icon.svg";

import { TabTitle } from "../../TabTitle/TabTitle";

import styles from "./Info.module.scss";

export const Info = ({ active }) => {
  const { t } = useTranslation();

  const {
    site: { data }
  } = useSelector(getAllProxySite);

  const [open, setOpen] = useState(false);

  const { countries } = useSelector(getAllContent);
  const countriesList = getCountriesById(data.countries, countries.data);

  const auth = []
    .concat(
      data?.clientIPAuth
        ? [t("proxyPage.content.table.characteristics.autorization.ip")]
        : []
    )
    .concat(
      data?.clientLoginAuth
        ? [t("proxyPage.content.table.characteristics.autorization.login")]
        : []
    )
    .concat(
      data?.clientNoAuth
        ? [t("proxyPage.content.table.characteristics.autorization.noAuth")]
        : []
    )
    .filter(Boolean);

  return (
    <Card className={`${styles.card} ${active ? "" : styles.hide}`}>
      <TabTitle title={t("proxySitePage.about.info.title")} icon={icon} />
      <ul>
        <li className={styles.item}>
          <div className={styles.item_box}>
            <span>{t("proxySitePage.about.info.countries")}</span>
            <div className={styles.item_values}>
              <span>
                {countriesList.length} {t("proxyPage.content.table.geo.title")}{" "}
              </span>
            </div>
          </div>
          <TextButton
            // color={"secondary"}
            size={"sm"}
            className={styles.item_button}
            onClick={() => setOpen(true)}
          >
            {t("proxyPage.content.table.geo.modal.title")}
          </TextButton>
        </li>
        <li className={styles.item}>
          <div className={styles.item_box}>
            <div className={styles.item_label}>
              {t("proxySitePage.about.info.speed")}
              <Tooltip
                body={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                }
                className={styles.item_tooltip}
              >
                <IconsSVG name={"info"} className={styles.item_icon} />
              </Tooltip>
            </div>
            <div className={styles.item_values}>
              <span>from 36 Mb/s</span>
              <span>to 180 Mb/s</span>
            </div>
          </div>
        </li>
        <li className={styles.item}>
          <div className={styles.item_box}>
            <div className={styles.item_label}>
              {t("proxySitePage.about.info.ping")}
              <Tooltip
                body={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                }
                className={styles.item_tooltip}
              >
                <IconsSVG name={"info"} className={styles.item_icon} />
              </Tooltip>
            </div>
            <div className={styles.item_values}>
              <span>from 24 ms</span>
              <span>to 180 ms</span>
            </div>
          </div>
        </li>
        <li className={styles.item}>
          <div className={styles.item_box}>
            <span>{t("proxySitePage.about.info.auth")}</span>
            <div className={styles.item_values}>
              {auth?.length
                ? auth.map((item, index) => (
                    <span key={`auth${index}`}>
                      {item}
                      {index + 1 < auth?.length ? "," : ""}
                    </span>
                  ))
                : null}
            </div>
          </div>
        </li>
        <li className={styles.item}>
          <div className={styles.item_box}>
            <span>{t("proxySitePage.about.info.api")}</span>
            <div className={styles.item_values}>
              <YesNo value={data?.api} />
            </div>
          </div>
        </li>
      </ul>
      <AllProxyCountriesModal
        data={countriesList}
        open={open}
        onClose={setOpen}
      />
    </Card>
  );
};

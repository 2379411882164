import { useSelector } from "react-redux";

import Card from "components/ui/New/Card/Card";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import styles from "./ProxyTypes.module.scss";

export const ProxyTypes = ({ data }) => {
  const [queryLang] = useLangUrlDefault();
  const { proxyTypes } = useSelector(getAllContent);

  const currentProxyTypes = proxyTypes?.data?.[queryLang];

  return (
    <Card className={styles.card} radius={"small"} size={"small"}>
      <ul className={styles.list}>
        {data.length
          ? data.map((item) => (
              <li key={`key-${item}`} className={styles.item}>
                <span className={styles.item_dot} />
                {currentProxyTypes?.find(({ type }) => type === item)?.name}
              </li>
            ))
          : null}
      </ul>
    </Card>
  );
};

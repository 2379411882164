import PromoButtons from "./PromoButtons/PromoButtons";
import PromoTitle from "./PromoTitle/PromoTitle";
import {
  StyledDescription,
  StyledInfoBox,
  StyledPromocode
} from "./Promocode.styled";

export const Promocode = ({
  description,
  promocode,
  title,
  image,
  time,
  eternal,
  variant
}) => (
  <StyledPromocode variant={variant}>
    <StyledInfoBox variant={variant}>
      <PromoTitle
        variant={variant}
        title={title}
        image={image}
        time={time}
        eternal={eternal}
      />
      <StyledDescription variant={variant}>{description}</StyledDescription>
    </StyledInfoBox>
    <PromoButtons variant={variant} promocode={promocode} />
  </StyledPromocode>
);

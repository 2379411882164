import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

export const ProxyGeo = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { countries } = useSelector(getAllContent);

  return (
    <li className="header__bottom-item">
      <Link to={`${hrefLang}/proxy/?fpt=IPv4`}>
        <img
          src="/img/proxyItems/proxy-geo.svg"
          loading="lazy"
          width="14"
          height="14"
          alt={t("header.bottom.geo.title")}
        />
        {t("header.bottom.geo.title")}
      </Link>
      <div className="header__dropdown">
        <ul className="list-reset header__dropdown-list header__dropdown-list--type-grid">
          {countries.data?.map((country) => (
            <li key={country.id}>
              <Link to={`${hrefLang}/proxy/?fc=${country.urlParam}`}>
                <span>{country.localization?.[queryLang]}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export const listWithNumbers = [
  {
    title: "Choose a Reputable Proxy Service",
    description:
      "Research and select a well-established proxy service with a good track record. Look for reviews and feedback from other users to ensure reliability."
  },
  {
    title: "Choose a Reputable Proxy Service",
    description:
      "Understand Costs. Proxy services often charge fees for their help. Be sure to understand the cost structure"
  },
  {
    title: "Check Legalities ",
    description:
      "Check Legalities. While proxy purchasing is often legal, it’s essential to verify it. It’s whether the practice allowed for specific items or within certain regions."
  },
  {
    title: "Provide Accurate Information",
    description:
      "When submitting your proxy request, ensure that all details are accurate. It’s to prevent any issues during the purchasing and shipping process."
  },
  {
    title: "Provide Accurate Information",
    description:
      "When submitting your proxy request, ensure that all details are accurate. It’s to prevent any issues during the purchasing and shipping process."
  }
];

export const list = [
    "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
    "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
    "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
    "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
    "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi."
];

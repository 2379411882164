import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import RecentReviews from "components/common/New/RecentReviews/RecentReviews";
import Card from "components/ui/New/Card/Card";
import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Select from "components/ui/New/Select/Select";
import Tabs from "components/ui/New/Tabs/Tabs";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import { HeadAbout } from "./HeadAbout/HeadAbout";
import { Info } from "./Informations/Info/Info";
import { Promocodes } from "./Promocodes/Promocodes";
import { Rating } from "./Rating/Rating";
import { AddReview } from "./Reviews/AddReview/AddReview";
import { ListReview } from "./Reviews/ListReview/ListReview";

import styles from "./About.module.scss";

export const About = ({ stepRating, clickStepRatingHandle }) => {
  const { alias } = useParams();

  const { pathname, hash } = useLocation();
  const [, lastUrlParam] = pathname.split("/").reverse();
  // **Ref
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const options = [
    {
      label: (
        <div className={styles.label}>
          <IconsSVG name={"info"} className={styles.label_icon} />
          <span
            className={styles.label__text}
            id={t("proxySitePage.about.tabs.info")}
          >
            {t("proxySitePage.about.tabs.info")}
          </span>
        </div>
      ),
      value: "info"
    },
    {
      label: (
        <div className={styles.label}>
          <IconsSVG name={"messegeText"} className={styles.label_icon} />
          <span
            className={styles.label__text}
            id={t("proxySitePage.about.tabs.reviews")}
          >
            {t("proxySitePage.about.tabs.reviews")}
          </span>
        </div>
      ),
      value: "reviews"
    },
    {
      label: (
        <div className={styles.label}>
          <IconsSVG name={"lineChart"} className={styles.label_icon} />
          <span
            className={styles.label__text}
            id={t("proxySitePage.about.tabs.rating")}
          >
            {t("proxySitePage.about.tabs.rating")}
          </span>
        </div>
      ),
      value: "rating"
    },
    {
      label: (
        <div className={styles.label}>
          <IconsSVG name={"ticket"} className={styles.label_icon} />
          <span
            className={styles.label__text}
            id={t("proxySitePage.about.tabs.promocodes")}
          >
            {t("proxySitePage.about.tabs.promocodes")}
          </span>
        </div>
      ),
      value: "promocodes"
    }
  ];

  const initTab = (urlParam, hash) => {
    if (urlParam === "promocodes") {
      return "promocodes";
    }

    if (hash) {
      const tab = options.find(
        (option) => option.value === hash.replace("#", "")
      );

      return tab ? tab.value : "info";
    }

    return "info";
  };

  // **Redux state
  const { captchaConfig, reviews } = useSelector(getAllContent);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(
    initTab(lastUrlParam, hash.replace("#", ""))
  );
  const [, setTokenCaptcha] = useState(null);

  const [trigerfetchProxyRevies, setTrigerfetchProxyRevies] = useState(false);

  const { enable, clientKey } = captchaConfig.data;
  const fetchProxyRevies = () =>
    setTrigerfetchProxyRevies(!trigerfetchProxyRevies);

  const setTab = (newTab = "") => {
    if (!newTab || !options.find((option) => option.value === newTab)) {
      setActiveTab("info");
      navigate(`${hrefLang}/new/proxy/${alias}/#info`, { replace: true });
      return;
    }

    setActiveTab(newTab);
    if (newTab === "promocodes") {
      navigate(`${hrefLang}/new/proxy/${alias}/promocodes/`, {
        replace: !newTab
      });
    } else {
      navigate(`${hrefLang}/new/proxy/${alias}/#${newTab}`, {
        replace: !newTab
      });
    }
  };

  return (
    <Card className={styles.about}>
      <Card className={styles.card} radius={"small"} size={"small"}>
        <HeadAbout onChange={setTab} />
        <Select
          wrapperClassName={styles.select}
          options={options}
          value={activeTab}
          onChange={setTab}
          renderValue={(selected) => {
            const option = options.find((it) => it.value === selected?.value);
            return option?.label || selected?.value;
          }}
        />
        <Tabs
          content={options}
          value={activeTab}
          withoutPanel
          onChange={setTab}
          className={styles.tabs}
        />
        <Info active={activeTab === "info"} />
        <AddReview
          active={activeTab === "reviews"}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
          getSiteReviews={fetchProxyRevies}
        />
        <Rating
          stepRating={stepRating}
          clickStepRatingHandle={clickStepRatingHandle}
          active={activeTab === "rating"}
        />
        <Promocodes active={activeTab === "promocodes"} />
      </Card>
      <RecentReviews
        reviews={reviews?.data}
        isHiden={activeTab !== "info"}
        onAll={() => setTab("reviews")}
        isSitePage
      />
      <ListReview
        trigerfetch={trigerfetchProxyRevies}
        active={activeTab === "reviews"}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
      />
      {enable && clientKey && (
        <div className={styles.captcha_box}>
          <HCaptcha
            ref={captchaRef}
            sitekey={clientKey}
            onVerify={setTokenCaptcha}
            onExpire={() => setTokenCaptcha(null)}
            onError={(err) => toast.error(err?.message)}
            size="invisible"
          />
        </div>
      )}
    </Card>
  );
};

export const serviceSelectOptions = [
  {
    value: "1",
    label: "Proxy"
  },
  {
    value: "2",
    label: "Mobile Proxy"
  }
];

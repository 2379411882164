import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import { Languages } from "../";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { Container } from "../../ui";
import { Loader } from "../../ui/Loader/Loader";
import { Logo } from "./Logo/Logo";
import { Navigation } from "./Navigation/Navigation";

import "./Footer.scss";

export const Footer = () => {
  const { t } = useTranslation();

  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <footer className="footer">
      <Container isFluid={routeContent.isContainerFluid}>
        <div className="footer__wrapper">
        <Suspense fallback={<Loader />}>
          <Logo />
          </Suspense>
          <Navigation />
          <div className="footer__item footer__item--form">
            <Suspense fallback={<Loader />}>
            <Languages title={t("footer.form.langTitle")} />
            </Suspense>
          </div>
        </div>
      </Container>
    </footer>
  );
};

import axios from "axios";
import { useTranslation } from "react-i18next";

import { Breadcrumbs } from "../../../components/common";
import { AllActions } from "../../../store/reducers/AllActions";

import { Addsite } from "./Addsite/Addsite";

const AddsitePage = () => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        items={t("addsitePage.breadcrumbs", {
          returnObjects: true
        })}
      />
      <Addsite />
    </>
  );
};

export default AddsitePage;

AddsitePage.getServerSideState = async (store, params, api) => {
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setGoals(goals));
};

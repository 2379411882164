import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import {
  StyledCirkle,
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle
} from "../../ExpandebleComponent.styled";

export const ProxyTypes = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);
  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.type.title")}
      </StyledContentTitle>
      {data.proxyType?.length > 0 ? (
        <StyledContentList>
          {data.proxyType
            .map((type) => {
              const { name } = proxyTypes.data?.[queryLang]?.find(
                (it) => it.type === type
              );

              if (!name) return null;

              return (
                <StyledContentListItem key={type}>
                  <StyledCirkle />
                  {name}
                </StyledContentListItem>
              );
            })
            .filter(Boolean)}
        </StyledContentList>
      ) : (
        <span>{t("proxyPage.content.table.type.noContent")}</span>
      )}
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import { getFreeProxyContent, getAllFreeProxyContent } from "./FreeProxy.thunks";

const initialState = {
  freeProxy: {
    data: {
      totalElements: 0,
      totalPages: 0,
      content: [],
      skip: 0
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  allFreeProxy: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};

const FreeProxyReducer = createSlice({
  name: "freeproxy",
  initialState,
  reducers: {
    setFreeProxyContent(state, action) {
      state.freeProxy.data = action.payload;
      state.freeProxy.isDataLoaded = true;
    },
    setAllFreeProxyContent(state, action) {
      state.allFreeProxy.data = action.payload;
      state.allFreeProxy.isDataLoaded = true;
    }
  },
  extraReducers: {
    [getFreeProxyContent.pending]: (state) => {
      state.freeProxy.isError = null;
      state.freeProxy.isLoading = true;
    },
    [getFreeProxyContent.fulfilled]: (state, action) => {
      state.freeProxy.isError = null;
      state.freeProxy.data = action.payload;
      state.freeProxy.isLoading = false;
      state.freeProxy.isDataLoaded = true;
    },
    [getFreeProxyContent.rejected]: (state, action) => {
      state.freeProxy.isError = action.payload;
      state.freeProxy.isLoading = false;
    },
    [getAllFreeProxyContent.pending]: (state) => {
      state.allFreeProxy.isError = null;
      state.allFreeProxy.isLoading = true;
    },
    [getAllFreeProxyContent.fulfilled]: (state, action) => {
      state.allFreeProxy.isError = null;
      state.allFreeProxy.data = action.payload;
      state.allFreeProxy.isLoading = false;
      state.allFreeProxy.isDataLoaded = true;
    },
    [getAllFreeProxyContent.rejected]: (state, action) => {
      state.allFreeProxy.isError = action.payload;
      state.allFreeProxy.isLoading = false;
    }
  }
});

export const FreeProxyReducerActions = {
  ...FreeProxyReducer.actions,
  getFreeProxyContent,
  getAllFreeProxyContent
};
export default FreeProxyReducer.reducer;

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "../Info.module.scss";

export const SocialMedia = ({ active, data }) => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <div className={`${styles.info_block} ${active ? styles.info_active : ""}`}>
      <div className={styles.info_title}>
        {t("proxySitePage.info.socialMedia.title")}
      </div>
      <ul className={styles.social_media}>
        {data.map((item) => (
          <li key={`keySM${item?.code}`} className={styles.social_media_item}>
            <Link
              to={item?.link}
              target="_blank"
              rel="noreferrer"
              className={`${styles.social_link} ${styles.social_link_social}`}
            >
              <img src={`/img/social/${item?.code}.svg`} alt={item?.code} />
              <span>{item?.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Breadcrumbs from "components/common/New/Breadcrumbs/Breadcrumbs";
import Card from "components/ui/New/Card/Card";
import { Title } from "components/ui/New/Title/Title";

import { AllActions } from "store/reducers/AllActions";

import { Form } from "./Form/Form";

import styles from "./AddEditService.module.scss";

const AddEditService = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        className={styles.breadcrumbs}
        items={[
          {
            title: t("dashboard.services.table.title"),
            route: "dashboard/services/"
          },
          {
            title: t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)
          }
        ]}
      />
      <Title size={"sm"} className={styles.title}>
        {t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)}
      </Title>
      <Card className={styles.card} size={"small"} radius={"small"}>
        <Title size={"sm"} className={styles.card_title}>
          {t(`dashboard.services.addEdit.title${id ? "Edit" : "Add"}`)}
        </Title>
        <Form />
      </Card>
    </>
  );
};

export default AddEditService;

AddEditService.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};

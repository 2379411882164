import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/New/Button/Button";

import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import Languages from "../../Languages/Languages";

import styles from "./Header.module.scss";

export const DashboardHeader = ({ onOpenMenu }) => {
  const navigate = useNavigate();
  const [, hrefLang] = useLangUrlDefault();
  // **Dispatch
  const { setDefaultUser, setAuthentication, setMobileMenu, resetCabinet } =
    useDispatchedActions();
  // **Redux state
  const { user } = useSelector(getAllUser);

  const letter = user.email.charAt(0).toUpperCase();

  const closeMenu = () => {
    setMobileMenu(false);
  };

  const logout = async () => {
    try {
      const response = await ApiService.logout();

      if (response && response.status !== 200) {
        throw response;
      }

      setDefaultUser();
      resetCabinet();
      setAuthentication(false);

      navigate(`${hrefLang}/new/`);
      closeMenu();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response || err?.response || err);
    }
  };

  const openMenu = () => {
    if (window.innerWidth < 992) {
      onOpenMenu("logout");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.user}>
        <button className={styles.user_avatar} onClick={openMenu}>
          {letter}
        </button>
        <span className={styles.user_name}>{user.email}</span>
      </div>
      <div className={styles.actions}>
        <Languages variant={"light"} />
        <Button
          variant="secondary"
          size="nm"
          type={"icon"}
          iconLeft="logout"
          onClick={logout}
          className={styles.actions_logout}
        />
      </div>
    </div>
  );
};

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledBaseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  padding: 16px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors["background-color"]};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 20px;
  }
`;

export const StyledSiteNameBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledSiteNameImage = styled.img`
  width: 32px;
  height: 32px;
  overflow: hidden;
  color: transparent;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    width: 36px;
    height: 36px;
  }
`;

export const StyledSiteName = styled.span`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 20px;
  font-style: ${(props) => props.theme.fontStyle.normal};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 140%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: calc(100% - 65px);
  margin-left: 4px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
`;

export const StyledLink = styled(Link)`
  align-self: flex-start;

  font-size: 0;
  text-decoration: none;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: ${(props) => props.theme.colors["neutral-400"]};
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors["neutral-800"]};
    transition: color 0.3s ease;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;

  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: ${(props) => props.theme.fontSizes.m};
  font-style: ${(props) => props.theme.fontStyle.normal};
  line-height: 130%;
`;

export const StyledListItemLabel = styled.span`
  color: ${(props) => props.theme.colors["neutral-500"]};
  font-weight: ${(props) => props.theme.fontWeight.normal};
`;

export const StyledListItemValue = styled.span`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-weight: 600;
  text-align: right;
`;

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FAQ from "components/common/New/FAQ/FAQ";
import HowToBuy from "components/common/New/HowToBuy/HowToBuy";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { AllActions } from "store/reducers/AllActions";
import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { questionsAndAnswers } from "utils/constants/questionsAndAnswers";
import { getCountryId, getGoalId } from "utils/helpers";
import { getHighlightedWord } from "utils/helpers/text.helper";

import animation from "assets/animations/ipv4.riv";
import {
  ipv4FaqImage,
  ipv4FaqImage2x,
  ipv4HeroImageMobile,
  ipv4HeroImageMobile2x
} from "assets/img";

import { Proxy } from "./Proxy/Proxy";
import { SpecialOffer } from "./SpecialOffer/SpecialOffer";

import styles from "./ProxyPage.module.scss";

const ProxyPage = () => {
  const { t } = useTranslation();

  const { pageContent } = useSelector(getAllContent);

  return (
    <>
      <Main
        highlightWord={getHighlightedWord(
          pageContent?.current?.data?.highlightedWord
        )}
        subtitle="More than 100 proxies of services that will provide stable work on social networks, SEO and marketing, rates and online games."
        buttonLabel={t("navigation.selectService")}
        className={styles.hero}
        images={{
          mobile1x: ipv4HeroImageMobile,
          mobile2x: ipv4HeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-21}
            right={-18}
            bottom={-115}
            left={-18}
          />
        }
        imageClassName={styles.image}
        containerClassName={styles.hero__container}
      />
      <SpecialOffer />
      <Proxy />
      <HowToBuy />
      <FAQ
        image={ipv4FaqImage}
        image2x={ipv4FaqImage2x}
        list={questionsAndAnswers}
      />
      <SeoSection />
    </>
  );
};

export default ProxyPage;

ProxyPage.getServerSideState = async (store, params, api) => {
  const fptParams = params.params.fpt || "IPv4";
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    {
      ...params,
      location: params.params.fpt
        ? params.location
        : `${params.location}?fpt=IPv4`,
      params: {
        ...params.params,
        fpt: Array.isArray(fptParams)
          ? fptParams[fptParams.length - 1]
          : fptParams || null
      }
    }
  );

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.s || "",
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    page: 0,
    size: 10
  };
  const { data: sites } = await axios.post(
    `${api}/api/front/site/proxy`,
    fetchParams
  );
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );

  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));
};

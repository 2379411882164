import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getAllCountries,
  getAllCurrencies,
  getAllGoalGroups,
  getAllGoals,
  getAllProxies,
  getAllRentPeriods,
  getAllSocials,
  getAllTopSellers,
  getCaptchaConfig,
  getLastSiteReviews,
  getMainBlockList,
  getPageContent,
  getSeoDataDefault,
  getStatistic,
  getTopSites,
  getUserIp
} from "./Content.thunks";

const initialState = {
  statistic: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  pageContent: {
    data: {},
    current: {
      tag: "",
      data: {}
    },
    isLoading: false,
    isDataLoaded: false,
    isError: null
  },
  routeContent: {
    path: "/:lang/",
    tag: "home",
    isContainerFluid: false,
    isHeaderFull: false
  },
  countries: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  goalsByGroups: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  sellers: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  reviews: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  goals: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  topSites: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  currencies: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  rentPeriods: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  proxyTypes: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  activeFilters: {
    fpt: ["IPv4"],
    s: "reviews",
    fc: "",
    fg: ""
  },
  socials: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  captchaConfig: {
    data: {
      headerName: "",
      clientKey: "",
      enable: false
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  mainBlockList: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  seoDataDefault: {
    data: {
      siteTitle: "",
      siteDescription: "",
      globalReviewApproval: false
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  userIp: {
    data: {},
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  activePageParams: {
    languageCode: "",
    location: "",
    tag: "",
    params: {},
    pathname: ""
  }
};

const ContentReducer = createSlice({
  name: "content",
  initialState,
  reducers: {
    setRouteContent(state, action) {
      state.routeContent = action.payload;
    },
    setStatistic(state, action) {
      state.statistic.data = action.payload;
      state.statistic.isDataLoaded = true;
    },
    setActiveFilters(state, action) {
      state.activeFilters = { ...state.activeFilters, ...action.payload };
    },
    setPageContent(state, action) {
      state.pageContent.data = {
        ...state.pageContent.data,
        [action.payload.innerTag]: action.payload.data
      };
      state.pageContent.current.data = action.payload.data;
      state.pageContent.current.tag = action.payload.innerTag;
      state.pageContent.isDataLoaded = true;
    },
    setCurrentPageContent(state, action) {
      state.pageContent.current.data = action.payload.data;
      state.pageContent.current.tag = action.payload.innerTag;
    },
    setClearPageContent(state) {
      state.pageContent = {
        data: {},
        current: {
          tag: "",
          data: {}
        },
        isLoading: false,
        isDataLoaded: false,
        isError: null
      };
    },
    setCountries(state, action) {
      state.countries.data = action.payload;
      state.countries.isDataLoaded = true;
    },
    setGoalsByGroups(state, action) {
      state.goalsByGroups.data = action.payload;
      state.goalsByGroups.isDataLoaded = true;
    },
    setSellers(state, action) {
      state.sellers.data = action.payload;
      state.sellers.isDataLoaded = true;
    },
    setReviews(state, action) {
      state.reviews.data = action.payload;
      state.reviews.isDataLoaded = true;
    },
    setGoals(state, action) {
      state.goals.data = action.payload;
      state.goals.isDataLoaded = true;
    },
    setTopSites(state, action) {
      state.topSites.data = action.payload;
      state.topSites.isDataLoaded = true;
    },
    setCurrencies(state, action) {
      state.currencies.data = action.payload;
      state.currencies.isDataLoaded = true;
    },
    setRentPeriods(state, action) {
      state.rentPeriods.data = action.payload;
      state.rentPeriods.isDataLoaded = true;
    },
    setProxyTypes(state, action) {
      state.proxyTypes.data = action.payload;
      state.proxyTypes.isDataLoaded = true;
    },
    setActivePageParams(state, action) {
      state.activePageParams = action.payload;
    },
    setCaptchaConfig(state, action) {
      state.captchaConfig.data = action.payload;
      state.captchaConfig.isDataLoaded = true;
    },
    setSocials(state, action) {
      state.socials.data = action.payload;
      state.socials.isDataLoaded = true;
    },
    setMainBlockList(state, action) {
      state.mainBlockList.data = action.payload;
      state.mainBlockList.isDataLoaded = true;
    },
    setSeoDataDefault(state, action) {
      state.seoDataDefault.data = action.payload;
      state.seoDataDefault.isDataLoaded = true;
    },
    setUserIp(state, action) {
      state.userIp.data = action.payload;
      state.userIp.isDataLoaded = true;
    }
  },
  extraReducers: {
    [getPageContent.pending]: (state) => {
      state.pageContent.isError = null;
      state.pageContent.isLoading = true;
    },
    [getPageContent.fulfilled]: (state, action) => {
      state.pageContent.isError = null;
      state.pageContent.data = {
        ...state.pageContent.data,
        [action.payload.innerTag]: action.payload.data
      };
      // state.pageContent.current.data = action.payload.data;
      // state.pageContent.current.tag = action.payload.innerTag;
      state.pageContent.isDataLoaded = true;
      state.pageContent.isLoading = false;
    },
    [getPageContent.rejected]: (state, action) => {
      state.pageContent.isError = action.payload;
      state.pageContent.isLoading = false;
    },

    [getAllCountries.pending]: (state) => {
      state.countries.isError = null;
      state.countries.isLoading = true;
    },
    [getAllCountries.fulfilled]: (state, action) => {
      state.countries.isError = null;
      state.countries.data = action.payload;
      state.countries.isDataLoaded = true;
      state.countries.isLoading = false;
    },
    [getAllCountries.rejected]: (state, action) => {
      state.countries.isError = action.payload;
      state.countries.isLoading = false;
    },

    [getAllGoalGroups.pending]: (state) => {
      state.goalsByGroups.isError = null;
      state.goalsByGroups.isLoading = true;
    },
    [getAllGoalGroups.fulfilled]: (state, action) => {
      state.goalsByGroups.isError = null;
      state.goalsByGroups.data = action.payload;
      state.goalsByGroups.isDataLoaded = true;
      state.goalsByGroups.isLoading = false;
    },
    [getAllGoalGroups.rejected]: (state, action) => {
      state.goalsByGroups.isError = action.payload;
      state.goalsByGroups.isLoading = false;
    },

    [getAllGoals.pending]: (state) => {
      state.goals.isError = null;
      state.goals.isLoading = true;
    },
    [getAllGoals.fulfilled]: (state, action) => {
      state.goals.isError = null;
      state.goals.data = action.payload;
      state.goals.isDataLoaded = true;
      state.goals.isLoading = false;
    },
    [getAllGoals.rejected]: (state, action) => {
      state.goals.isError = action.payload;
      state.goals.isLoading = false;
    },

    [getAllTopSellers.pending]: (state) => {
      state.sellers.isError = null;
      state.sellers.isLoading = true;
    },
    [getAllTopSellers.fulfilled]: (state, action) => {
      state.sellers.isError = null;
      state.sellers.data = action.payload;
      state.sellers.isDataLoaded = true;
      state.sellers.isLoading = false;
    },
    [getAllTopSellers.rejected]: (state, action) => {
      state.sellers.isError = action.payload;
      state.sellers.isLoading = false;
    },

    [getLastSiteReviews.pending]: (state) => {
      state.reviews.isError = null;
      state.reviews.isLoading = true;
    },
    [getLastSiteReviews.fulfilled]: (state, action) => {
      state.reviews.isError = null;
      state.reviews.data = action.payload;
      state.reviews.isDataLoaded = true;
      state.reviews.isLoading = false;
    },
    [getLastSiteReviews.rejected]: (state, action) => {
      state.reviews.isError = action.payload;
      state.reviews.isLoading = false;
    },

    [getTopSites.pending]: (state) => {
      state.topSites.isError = null;
      state.topSites.isLoading = true;
    },
    [getTopSites.fulfilled]: (state, action) => {
      state.topSites.isError = null;
      state.topSites.data = action.payload;
      state.topSites.isDataLoaded = true;
      state.topSites.isLoading = false;
    },
    [getTopSites.rejected]: (state, action) => {
      state.topSites.isError = action.payload;
      state.topSites.isLoading = false;
    },

    [getAllCurrencies.pending]: (state) => {
      state.currencies.isError = null;
      state.currencies.isLoading = true;
    },
    [getAllCurrencies.fulfilled]: (state, action) => {
      state.currencies.isError = null;
      state.currencies.data = action.payload;
      state.currencies.isDataLoaded = true;
      state.currencies.isLoading = false;
    },
    [getAllCurrencies.rejected]: (state, action) => {
      state.currencies.isError = action.payload;
      state.currencies.isLoading = false;
    },

    [getAllRentPeriods.pending]: (state) => {
      state.rentPeriods.isError = null;
      state.rentPeriods.isLoading = true;
    },
    [getAllRentPeriods.fulfilled]: (state, action) => {
      state.rentPeriods.isError = null;
      state.rentPeriods.data = action.payload;
      state.rentPeriods.isDataLoaded = true;
      state.rentPeriods.isLoading = false;
    },
    [getAllRentPeriods.rejected]: (state, action) => {
      state.rentPeriods.isError = action.payload;
      state.rentPeriods.isLoading = false;
    },

    [getAllProxies.pending]: (state) => {
      state.proxyTypes.isError = null;
      state.proxyTypes.isLoading = true;
    },
    [getAllProxies.fulfilled]: (state, action) => {
      state.proxyTypes.isError = null;
      state.proxyTypes.data = action.payload;
      state.proxyTypes.isDataLoaded = true;
      state.proxyTypes.isLoading = false;
    },
    [getAllProxies.rejected]: (state, action) => {
      state.proxyTypes.isError = action.payload;
      state.proxyTypes.isLoading = false;
    },

    [getStatistic.pending]: (state) => {
      state.statistic.isError = null;
      state.statistic.isLoading = true;
    },
    [getStatistic.fulfilled]: (state, action) => {
      state.statistic.isError = null;
      state.statistic.data = action.payload;
      state.statistic.isDataLoaded = true;
      state.statistic.isLoading = false;
    },
    [getStatistic.rejected]: (state, action) => {
      state.statistic.isError = action.payload;
      state.statistic.isLoading = false;
    },

    [getAllSocials.pending]: (state) => {
      state.socials.isError = null;
      state.socials.isLoading = true;
    },
    [getAllSocials.fulfilled]: (state, action) => {
      state.socials.isError = null;
      state.socials.data = action.payload;
      state.socials.isDataLoaded = true;
      state.socials.isLoading = false;
    },
    [getAllSocials.rejected]: (state, action) => {
      state.socials.isError = action.payload;
      state.socials.isLoading = false;
    },

    [getCaptchaConfig.pending]: (state) => {
      state.captchaConfig.isError = null;
      state.captchaConfig.isLoading = true;
    },
    [getCaptchaConfig.fulfilled]: (state, action) => {
      state.captchaConfig.isError = null;
      state.captchaConfig.data = action.payload;
      state.captchaConfig.isDataLoaded = true;
      state.captchaConfig.isLoading = false;
    },
    [getCaptchaConfig.rejected]: (state, action) => {
      state.captchaConfig.isError = action.payload;
      state.captchaConfig.isLoading = false;
    },

    [getMainBlockList.pending]: (state) => {
      state.mainBlockList.isError = null;
      state.mainBlockList.isLoading = true;
    },
    [getMainBlockList.fulfilled]: (state, action) => {
      state.mainBlockList.isError = null;
      state.mainBlockList.data = action.payload;
      state.mainBlockList.isDataLoaded = true;
      state.mainBlockList.isLoading = false;
    },
    [getMainBlockList.rejected]: (state, action) => {
      state.mainBlockList.isError = action.payload;
      state.mainBlockList.isLoading = false;
    },

    [getUserIp.pending]: (state) => {
      state.userIp.isError = null;
      state.userIp.isLoading = true;
    },
    [getUserIp.fulfilled]: (state, action) => {
      state.userIp.isError = null;
      state.userIp.data = action.payload;
      state.userIp.isDataLoaded = true;
      state.userIp.isLoading = false;
    },
    [getUserIp.rejected]: (state, action) => {
      state.userIp.isError = action.payload;
      state.userIp.isLoading = false;
    },

    [getSeoDataDefault.pending]: (state) => {
      state.seoDataDefault.isError = null;
      state.seoDataDefault.isLoading = true;
    },
    [getSeoDataDefault.fulfilled]: (state, action) => {
      state.seoDataDefault.isError = null;
      state.seoDataDefault.data = action.payload;
      state.seoDataDefault.isDataLoaded = true;
      state.seoDataDefault.isLoading = false;
    },
    [getSeoDataDefault.rejected]: (state, action) => {
      state.seoDataDefault.isError = action.payload;
      state.seoDataDefault.isLoading = false;
    }
  }
});

export const ContentReducerActions = {
  ...ContentReducer.actions,
  getPageContent,
  getAllCountries,
  getAllGoalGroups,
  getAllTopSellers,
  getLastSiteReviews,
  getAllGoals,
  getTopSites,
  getStatistic,
  getAllProxies,
  getAllCurrencies,
  getAllRentPeriods,
  getCaptchaConfig,
  getMainBlockList,
  getAllSocials,
  getSeoDataDefault,
  getUserIp
};
export default ContentReducer.reducer;

import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "components/ui/New/Button/Button";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

import styles from "./Feedback.module.scss";

export const Feedback = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler
}) => {
  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);
  // const {
  //   reviews: { feedback },
  //   notifications,
  //   common
  // }

  const { t } = useTranslation();

  // **Local state
  const [isDisableButtons, setIsDisableButtons] = useState({
    yes: false,
    no: false
  });
  const { enable, headerName } = captchaConfig.data;

  const changeUsefulness = async (useful) => {
    let header = {};

    if (enable && !isUserAuthenticated) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      const response = await ApiService.changeReviewUsefulness(
        review.id,
        useful,
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      changeHandler();

      setIsDisableButtons({
        ...isDisableButtons,
        yes: useful,
        no: !useful
      });
      toast.success(t("notifications.vote"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      if (enable && !isUserAuthenticated) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }
    }
  };

  return (
    <div className={styles.feedback}>
      <Button
        className={`${styles.feedback_btn} ${styles.feedback_btn_yes}`}
        type={"icon"}
        variant={"outlined"}
        onClick={() => changeUsefulness(true)}
        disabled={isDisableButtons.yes}
        iconLeft={"fingerUp"}
      />
      {review?.useful > 0 && (
        <span
          className={`${styles.feedback_count} ${styles.feedback_count_yes}`}
        >
          {review.useful}
        </span>
      )}
      <span className={styles.feedback_divider} />
      {review?.unuseful > 0 && (
        <span
          className={`${styles.feedback_count} ${styles.feedback_count_no}`}
        >
          {review.unuseful}
        </span>
      )}
      <Button
        className={`${styles.feedback_btn} ${styles.feedback_btn_no}`}
        type={"icon"}
        variant={"outlined"}
        onClick={() => changeUsefulness(false)}
        disabled={isDisableButtons.no}
        iconLeft={"fingerDown"}
      />
    </div>
  );
};

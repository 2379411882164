import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "components/ui/New/Button/Button";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllTranslation } from "store/reducers/TranslationReducer/Translation.selectors";

import { useLangUrlDefault } from "hooks";

import { ServiceSchema } from "utils/validation";

import FormBlock from "../../components/FormBlock/FormBlock";

import { BlockAuth } from "./BlockAuth/BlockAuth";
import { BlockContacts } from "./BlockContacts/BlockContacts";
import { BlockFeatures } from "./BlockFeatures/BlockFeatures";
import { BlockLogo } from "./BlockLogo/BlockLogo";
import { BlockPromocode } from "./BlockPromocode/BlockPromocode";
import { BlockType } from "./BlockType/BlockType";

// eslint-disable-next-line import/order
import styles from "../AddEditService.module.scss";

export const Form = ({ defaultValues = {} }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { languages } = useSelector(getAllTranslation);
  const [queryLang] = useLangUrlDefault();

  const { proxyTypes } = useSelector(getAllContent);

  // const proxyForLang = proxyTypes.data?.[queryLang];

  const [proxyForLang, setProxyForLang] = useState(
    proxyTypes.data?.[queryLang]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      ServiceSchema(
        t("forms", {
          returnObjects: true
        }),
        languages,
        proxyForLang
      )
    )
  });
  const formData = methods.watch();
  // **Local state
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([]);
  const [croppedFileObjects, setCroppedFileObjects] = useState([]);

  const handleSave = (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  const handleCancel = () => {
    // eslint-disable-next-line no-console
    console.log("cancel");
    setProxyForLang(proxyTypes.data?.[queryLang]);
    methods.reset(defaultValues);
    setFiles([]);
    setCroppedFileObjects([]);
  };
  useEffect(() => {
    // TODO: try to remove this useEffect ( refactoring shema validation)

    const temp = proxyTypes.data?.[queryLang].map((item) => ({
      ...item,
      checked: proxyForLang?.[item.type] || false
    }));

    setProxyForLang(temp);

    // NOTE REMOVE THIS
    if (methods && Object.keys(methods.formState.errors).length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, proxyTypes]);

  // TODO: try to remove this useEffect ( refactoring shema validation)
  useEffect(() => {
    const { proxyTypes } = formData;
    let check = false;
    const newProxyForLang = proxyForLang.map((item) => {
      if (proxyTypes?.[item.type] !== item.checked) {
        check = true;
        return {
          ...item,
          checked: proxyTypes[item.type]
        };
      }
      return item;
    });
    if (check) {
      setProxyForLang(newProxyForLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <BlockLogo
          files={files}
          setFiles={setFiles}
          croppedFileObjects={croppedFileObjects}
          setCroppedFileObjects={setCroppedFileObjects}
          defaultImage={defaultValues?.image}
        />
        <BlockType proxyForLang={proxyForLang} />
        <BlockAuth />
        <BlockFeatures />
        <BlockPromocode />
        <BlockContacts />

        <FormBlock bodyClassName={styles.form_actions}>
          <Button formSubmit className={styles.form_submit}>
            {t(`dashboard.services.addEdit.${id ? "edit" : "add"}`)}
          </Button>
          <Button
            variant={"secondary"}
            className={styles.form_cancel}
            onClick={handleCancel}
          >
            {t("forms.buttons.cancel")}
          </Button>
        </FormBlock>
      </form>
    </FormProvider>
  );
};

import PT from "prop-types";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import FormError from "../FormError/FormError";

import "./DateRange.scss";
import styles from "./FormDate.module.scss";

export const FormDate = ({ name, label, className, rules, showError }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[new Date(), null]}
      render={({ field, fieldState: { error } }) => (
        <div className={`${styles.datepicker} ${className}`}>
          <label>
            {label && <span className={styles.datepicker_label}>{label}</span>}
            <DatePicker
              className={`${styles.datepicker_input} ${
                error?.message ? styles.datepicker_input_error : ""
              }`}
              showIcon
              icon={
                <div>
                  <IconsSVG name="calendar" />
                </div>
              }
              calendarIconClassname={styles.datepicker_icon}
              wrapperClassName={styles.datepicker_wrapper}
              popperClassName={styles.datepicker_popper}
              selectsRange
              monthsShown={2}
              startDate={field.value[0]}
              endDate={field.value[1]}
              onChange={(dates) => field.onChange(dates)}
              popperPlacement="bottom-end"
            />
          </label>
          {showError && !!error?.message && (
            <FormError message={error.message} />
            // <div className={styles.error}>
            //   <IconsSVG name={"inputError"} />
            //   {error.message}
            // </div>
          )}
        </div>
      )}
    />
  );
};

FormDate.propTypes = {
  name: PT.string.isRequired,
  label: PT.string,
  className: PT.string,
  showError: PT.bool
};

FormDate.defaultProps = {
  label: "",
  className: "",
  showError: false
};

import { Suspense } from "react";

import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ProxySidebarRight } from "../components/common";
import { Container } from "../components/ui";
import { Loader } from "../components/ui/Loader/Loader";
import { getAllContent } from "../store/reducers/ContentReducer/Content.selectors";

export const SidebarLayout = () => {
  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <div className="layout__sidebar">
      <Container isFluid={routeContent.isContainerFluid}>
        {/*<ProxySidebarLeft />*/}
        <Suspense fallback={<Loader />}>
          <Outlet />
          </Suspense>
        <ProxySidebarRight />
      </Container>
    </div>
  );
};


import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import { getSiteDescription } from "utils/helpers";

import styles from "./HeadAbout.module.scss";

export const HeadAbout = ({ onChange }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const {
    site: { data }
  } = useSelector(getAllProxySite);

  const handleReadReview = () => onChange?.("reviews");

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Title className={styles.title} tag={"h3"}>
          {t("proxySitePage.about.title")} {data?.title}
        </Title>
        <div
          className={`${styles.buttons} ${
            queryLang !== "en" ? styles.buttons_long : ""
          }`}
        >
          <Button variant={"outlined"} size={"md"} onClick={handleReadReview}>
            {t("proxySitePage.about.readReview")}
          </Button>
          <Button size={"md"} linkTo={data?.url} target="_blank">
            {t("proxySitePage.about.visit")}
          </Button>
        </div>
      </div>
      <p className={styles.description}>
        {getSiteDescription(
          data?.description,
          queryLang,
          t("proxySitePage.content.chacracteristics.description.noDescription")
        )}
      </p>
    </div>
  );
};

import "moment/locale/ru";
import "moment/locale/uk";
import Moment from "react-moment";

import Stars from "components/ui/New/Stars/Stars";

import { useLangUrlDefault } from "hooks";

import {
  StyledRecentReviewCard,
  StyledRecentReviewHeader,
  StyledRecentReviewInfo,
  StyledRecentReviewName,
  StyledRecentReviewStarsBox,
  StyledRecentReviewText
} from "./RecentReview.styled";

export const Review = ({
  review,
  className = "",
  itemHovered,
  siteNameFirst
}) => {
  const [queryLang] = useLangUrlDefault();
  return (
    <StyledRecentReviewCard className={className} hovered={itemHovered}>
      <StyledRecentReviewHeader>
        <div>
          <StyledRecentReviewName>
            {review?.userName?.includes("@")
              ? review?.userName.split("@")[0]
              : review?.userName}
          </StyledRecentReviewName>
          <StyledRecentReviewInfo className={"recent_date"}>
            {siteNameFirst ? (
              review?.siteAlias
            ) : (
              <Moment
                format="MMM DD, YYYY"
                locale={queryLang}
                suppressHydrationWarning
              >
                {new Date(review?.date)}
              </Moment>
            )}
          </StyledRecentReviewInfo>
          <StyledRecentReviewInfo className="recent_site">
            {siteNameFirst ? (
              <Moment
                format="MMM DD, YYYY"
                locale={queryLang}
                suppressHydrationWarning
              >
                {new Date(review?.date)}
              </Moment>
            ) : (
              review?.siteAlias
            )}
          </StyledRecentReviewInfo>
        </div>
        <StyledRecentReviewStarsBox>
          <Stars value={review?.rating} />
        </StyledRecentReviewStarsBox>
      </StyledRecentReviewHeader>
      <StyledRecentReviewText>
        {review?.translation?.[queryLang]?.reviewBody || review?.reviewBody}
      </StyledRecentReviewText>
    </StyledRecentReviewCard>
  );
};

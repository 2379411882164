import { useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";
import { getParamsUrlForFilter } from "../../../../utils/helpers";

import { ProxyContent } from "./ProxyContent/ProxyContent";

export const Proxy = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!search) {
      navigate(`${hrefLang}/new/proxy/?fpt=IPv4`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const proxyTypes = searchParams.getAll("fpt");
    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const s = searchParams.get("s");
    const filter = searchParams.get("filter");

    if (proxyTypes.length > 1) {
      navigate(getParamsUrlForFilter(fc, fg, [proxyTypes[0]], s, filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <section>
      <ProxyContent />
    </section>
  );
};

/* eslint-disable no-undef */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

// import DataTable from "react-data-table-component";
import { StyledDataTable } from "components/common/New/Table/Table.styled";
import {
  HeaderNameCell,
  SiteNameCell,
  TextCell
} from "components/common/New/TableColumns";
import YesNo from "components/common/New/YesNo/YesNo";
import Rating from "components/ui/New/Raiting/Rating";
// import { SkeletonTable } from "components/ui/New/Skeleton/SkeletonTable/SkeletonTable";
import { TextButton } from "components/ui/New/TextButton/TextButton";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

export const ProxyTable = ({ isLoading, tableData, withOutPagination }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const [data, setData] = useState(tableData);
  const columns = [
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.name.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <SiteNameCell
          imgUrl={row?.imageFile}
          name={row?.name}
          rating={row?.rating}
          showAnnotation
          positive={row?.ratingPositive}
          negative={row?.ratingNegative}
          linkToReview={`${hrefLang}/new/proxy/${row.alias}/#reviews`}
        />
      ),
      minWidth: "290px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.rating.name")}
        </HeaderNameCell>
      ),

      cell: (row) => (
        <Rating rating={row?.rating ? row?.rating : 0} size={"md"} />
      ),
      sortable: true,
      selector: (row) => row?.rating,
      minWidth: "100px"
    },

    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.minPrice.title")}
        </HeaderNameCell>
      ),
      cell: (row) => <TextCell>{`$${row?.price}`}</TextCell>,
      sortable: true,
      selector: (row) => row.price,
      width: "100px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.minRent.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <TextCell>
          {time(
            row?.minRentPeriod?.periodName,
            row?.minRentPeriod?.periodValue,
            queryLang,
            t("times", { returnObjects: true })
          )}
        </TextCell>
      ),
      sortable: true,
      selector: (row) => row?.minRentPeriod?.periodMilliseconds,
      width: "110px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => <YesNo value={row?.freeTest} />,
      minWidth: "75px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <TextButton
          size={"sm"}
          color={"table"}
          iconRight={"arrowAltRight"}
          linkTo={`${hrefLang}/new/proxy/${row.alias}/`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      ),
      width: "105px"
    }
  ];
  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    const block = document.querySelector(".MainPageTable");
    let interval;
    if (block) {
      // TODO: mabe  do this better
      const smoothScrollToBottom = (element) => {
        // Прокрутить вниз только если прокрутка не производится уже
        if (element.scrollTop !== element.scrollHeight - element.clientHeight) {
          const target = element.scrollHeight - element.clientHeight;
          let currentScroll = element.scrollTop;
          const step = Math.abs(currentScroll - target) / 20; // Чем больше значение, тем быстрее скролл
          interval = setInterval(() => {
            if (currentScroll < target) {
              currentScroll += step;
              if (currentScroll >= target) {
                currentScroll = target;
                clearInterval(interval);
              }
            } else {
              currentScroll -= step;
              if (currentScroll <= target) {
                currentScroll = target;
                clearInterval(interval);
              }
            }
            element.scrollTop = currentScroll;
          }, 15); // Интервал в миллисекундах для плавности прокрутки
        }
      };

      smoothScrollToBottom(block);
    }
    return () => {
      clearInterval(interval);
    };
  }, [data]);

  return (
    <StyledDataTable
      className="MainPageTable"
      styleRules={{
        showedAll: withOutPagination
      }}
      columns={columns}
      fixedHeader
      fixedHeaderScrollHeight="724px"
      data={data}
      noDataComponent={
        !isLoading && (
          <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
            {t("proxyPage.content.table.noDataComponent")}
          </div>
        )
      }
      // progressPending={isLoading}
      // progressComponent={<SkeletonTable cells={10} border={true} />}
    />
  );
};

import { useEffect, useState } from "react";

import { everCookie } from "@octaniumsw/react-tools";
import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";

import { mockEverCookieData } from "../../../../../utils/constants";

import { ResultsTable } from "./ResultsTable/ResultsTable.jsx";

import styles from "./EvercookieTest.module.scss";

export const EvercookieTest = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(mockEverCookieData);
  const [uid, setUid] = useState("");

  const {
    setEvercookie,
    localUid,
    sessionUid,
    clear,
    indexedDB,
    globalUid,
    cookie
  } = everCookie.useEverCookie();

  useEffect(() => {
    if (!localUid || !sessionUid || !globalUid) {
      indexedDB.then((data) => {
        if (data) {
          setUid(data.toString());
          setEvercookie(data.toString());
        } else {
          clear();
        }
      });
    } else {
      setUid(localUid);
    }
  }, [clear, localUid, indexedDB, setEvercookie, sessionUid, globalUid]);

  const generateEverCookie = () => {
    setIsLoading(true);

    const uid = everCookie.generateEverCookie();

    setUid(uid.toString());

    setEvercookie(uid.toString());

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const rediscoverEverCookie = () => {
    setIsLoading(true);

    setTimeout(() => {
      indexedDB.then((data) => {
        const newResult = [
          { name: "userData:", value: "N/A" },
          { name: "cookieData:", value: cookie.uid || "N/A" },
          { name: "localData:", value: localUid || "N/A" },
          { name: "globalData:", value: globalUid || "N/A" },
          { name: "sessionData:", value: sessionUid || "N/A" },
          { name: "windowData:", value: window.windowUid || "N/A" },
          { name: "pngData:", value: cookie["everCookie_png"] || "N/A" },
          { name: "dbData:", value: data || "N/A" },
          { name: "etagData:", value: cookie["everCookie_etag"] || "N/A" },
          { name: "lsoData:", value: "N/A" },
          { name: "cacheData:", value: cookie["everCookie_cache"] || "N/A" },
          { name: "slData:", value: "N/A" }
        ];

        setResult(newResult);
        setIsLoading(false);
      });
    }, 1000);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.form__wrapper}>
          <div className={styles.form}>
            <div className={styles.image__wrapper}>
              <picture>
                <source
                  srcSet="img/evercookie-test/evercookie-test-image.svg"
                  media="(min-width: 993px)"
                />
                <source
                  srcSet="img/evercookie-test/evercookie-test-image-mobile.svg"
                  media="(max-width: 992px)"
                />
                <img
                  src="img/evercookie-test/evercookie-test-image.svg"
                  alt={t("evercookieTest.testResult")}
                  className={styles.image}
                />
              </picture>
            </div>
            <Title tag="h2" className={styles.title}>
              <span>
                {uid !== ""
                  ? `${t("evercookieTest.cookieFound")} = ${uid}`
                  : t("evercookieTest.uidNotSet")}{" "}
              </span>
            </Title>
            <div className={styles.info_message}>
              {result
                ? t("evercookieTest.infoRediscover")
                : t("evercookieTest.infoCreate")}
            </div>

            <Button
              className={styles.button}
              onClick={uid !== "" ? rediscoverEverCookie : generateEverCookie}
              loading={isLoading}
              {...(isLoading && { iconLeft: "loading" })}
              fullWidth
            >
              {uid !== ""
                ? t("evercookieTest.buttonRediscover")
                : t("evercookieTest.buttonCreate")}
            </Button>
          </div>
        </div>
        <div className={styles.results}>
          <Title tag="h2" className={styles.title}>
            {t("evercookieTest.testResult")}
          </Title>
          <div className={styles.table__wrapper}>
            <ResultsTable data={result} isLoading={isLoading} />
            <div className={styles.gradient} />
          </div>
        </div>
      </div>
    </Container>
  );
};

import { useEffect, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

import IconsSVG from "../Icon/IconsSVG";
import Select from "../Select/Select";

import styles from "./PaginationTable.module.scss";

const RowsInfo = ({ from, to, total, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.rows__info} ${className}`}>
      {from} - {to} {t("pagination.table.from")} {total}
    </div>
  );
};

const ItemsONPage = ({
  rowsPerPage,
  totalRows,
  onChangeRowsPerPage,
  className
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(rowsPerPage || 10);
  // если totalRows 27, то options = [10, 25, 50]
  const options = [10, 25, 50, 100].filter(
    (item) => totalRows / item > 0.5 || item === rowsPerPage
  );

  useEffect(() => {
    setValue(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <div className={`${styles.itemsOnPage} ${className}`}>
      {t("pagination.table.rowsCount")}:
      <Select
        wrapperClassName={styles.select__rows}
        className={styles.select__rows_select}
        menuClassName={styles.select__rows__menu}
        options={options.map((item) => ({
          value: item,
          label: item
        }))}
        value={value}
        onChange={onChangeRowsPerPage}
      />
    </div>
  );
};

export const PaginationTable = ({
  currentPage,
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  className,
  border,
  rowsInfoClassName,
  itemsOnPageClassName
}) => {
  const from = currentPage * rowsPerPage - rowsPerPage + 1;
  const to =
    currentPage * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage;
  const total = rowCount;
  return (
    <div
      className={`${
        border ? styles.wrapper__border : styles.wrapper
      } ${className}`}
    >
      <RowsInfo
        from={from}
        to={to}
        total={total}
        className={rowsInfoClassName}
      />
      <ReactPaginate
        pageClassName={styles.page}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        forcePage={rowCount === 0 ? -1 : currentPage - 1}
        previousLabel={<IconsSVG name="arrowDown" />}
        nextLabel={<IconsSVG name="arrowDown" />}
        breakLabel={"..."}
        breakClassName={styles.break}
        pageCount={Math.ceil(rowCount / rowsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={(data) => {
          onChangePage(data.selected + 1);
        }}
        containerClassName={styles.pagination}
        subContainerClassName={`${styles.pages} pagination`}
        activeClassName={styles.active}
      />
      <ItemsONPage
        rowsPerPage={rowsPerPage}
        totalRows={rowCount}
        onChangeRowsPerPage={onChangeRowsPerPage}
        className={itemsOnPageClassName}
      />
    </div>
  );
};

PaginationTable.propTypes = {
  currentPage: PT.number.isRequired,
  rowsPerPage: PT.number.isRequired,
  rowCount: PT.number.isRequired,
  onChangeRowsPerPage: PT.func.isRequired,
  onChangePage: PT.func.isRequired,
  rowsInfoClassName: PT.string,
  itemsOnPageClassName: PT.string,
  border: PT.bool,
  className: PT.string
};

PaginationTable.defaultProps = {
  rowsInfoClassName: "",
  itemsOnPageClassName: "",
  border: true,
  className: ""
};

export default PaginationTable;

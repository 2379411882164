import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import Modal from "components/ui/New/Modal/Modal";
import Search from "components/ui/New/Search/Search";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import {
  convertCountryCodeAlpha3ToAlpha2,
  getParamsUrlForFilter
} from "utils/helpers";

import styles from "./AllProxyCountriesModal.module.scss";

export const AllProxyCountriesModal = ({ data, open, onClose }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();
  const [queryLang, hrefLang] = useLangUrlDefault();

  const [search, setSearch] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const [currentFilteredData, setCurrentFilteredData] = useState([]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    const continents = t("continents", { returnObjects: true });
    const countryArray = data?.filter((country) => {
      const name = queryLang ? country?.localization[queryLang] : "";
      return name.toLowerCase().includes(search.toLowerCase());
    });
    if (countryArray?.length) {
      const current = countryArray.reduce((acc, country) => {
        if (!continents[country?.continent]) {
          acc.other = [...(acc?.other || []), country];
          return acc;
        }
        acc[country?.continent] = [...(acc[country?.continent] || []), country];
        return acc;
      }, {});
      const newData = Object.keys(continents).reduce((acc, continent) => {
        if (current[continent]) {
          acc.push({ continent, data: current[continent] });
        }
        return acc;
      }, []);
      if (current.other) {
        newData.push({ continent: "other", data: current.other });
      }
      setCurrentFilteredData(newData);
      // setFilteredData(
      //   data?.filter((country) => {
      //     const name = queryLang ? country?.localization[queryLang] : "";
      //     return name.toLowerCase().includes(search.toLowerCase());
      //   })
      // );
    } else {
      setCurrentFilteredData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data, queryLang]);

  return (
    <Modal
      className={styles.modal}
      open={open}
      setOpen={onClose}
      title={t("proxyPage.content.table.geo.modal.title")}
    >
      <Search value={search} onChange={handleSearch} variant={"outlined"} />
      <div className={styles.container}>
        {currentFilteredData?.length
          ? currentFilteredData.map(({ continent, data }) => (
              <div key={continent} className={styles.box}>
                <p className={styles.subtitle}>
                  {continent === "other"
                    ? t("proxyPage.content.table.geo.modal.subtitles.other")
                    : t(`continents.${continent}`)}
                </p>
                <ul className={styles.list}>
                  {data.map((country) => {
                    const newCountry = country?.urlParam;
                    const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                      country?.code
                    ).toLowerCase();
                    const name = queryLang
                      ? country?.localization[queryLang]
                      : "";
                    return (
                      <li key={country?.id} className={styles.item}>
                        <Link
                          className={styles.link}
                          to={`${hrefLang}/proxy/${getParamsUrlForFilter(
                            newCountry,
                            searchParams.get("fg"),
                            searchParams.get("fpt")
                              ? [searchParams.get("fpt")]
                              : [],
                            searchParams.get("s")
                          )}`}
                          onClick={() => setActiveFilters({ fc: newCountry })}
                          title={name}
                        >
                          {alpha2 !== "default" ? (
                            <span
                              className={`fi fi-${alpha2} ${styles.flag}`}
                            ></span>
                          ) : (
                            <img
                              className={styles.flag}
                              src="/img/flags/default.svg"
                              width={20}
                              height={20}
                              loading="lazy"
                              alt={name}
                            />
                          )}
                          <span className={styles.name}>{name}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))
          : null}
      </div>
    </Modal>
  );
};

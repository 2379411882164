import axios from "axios";

import { AllActions } from "../../../store/reducers/AllActions";

import { Proxy } from "./Proxy/Proxy";

const ProxyPage = () => <Proxy />;

export default ProxyPage;

ProxyPage.getServerSideState = async (store, params, api) => {
  const fptParams = params.params.fpt || "IPv4";

  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    {
      ...params,
      // location: params.params.fpt
      //   ? params.location
      //   : `${params.location}?fpt=IPv4`,
      location: params.location,
      params: {
        ...params.params,
        fpt: Array.isArray(fptParams)
          ? fptParams[fptParams.length - 1]
          : fptParams || null
      }
    }
  );
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );

  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });

  // TODO: need add ather params
  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: "",
    sort: "",
    goal: "",
    page: 0,
    size: 10
  };
  const { data: sites } = await axios.post(
    `${api}/api/front/site/proxy`,
    fetchParams
  );
  const { data: sidebar } = await axios.get(
    `${api}/api/front/site/blog/sidebar`,
    { params: { languageCode: params.languageCode } }
  );
  const { data: typeSites } = await axios.get(
    `${api}/api/front/site/proxy/all`,
    { params: { size: 5 } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );

  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.languageCode
    })
  );
  store.dispatch(AllActions.setProxySitesType(typeSites));
};

import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useLangUrlDefault, useDispatchedActions } from "hooks";
import { locationsList } from "utils/constants";

import { convertCountryCodeAlpha3ToAlpha2 } from "utils/helpers";

import styles from "./Countries.module.scss";

export const Countries = ({ countries, search, selectedLocation }) => {

  const [queryLang] = useLangUrlDefault();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setServiceMenu, setBurgerMenu } = useDispatchedActions();

  const [filteredData, setFilteredData] = useState(countries?.data);

  const handleClick = (country) => {
    searchParams.set("fc", country);
    setSearchParams(searchParams);
    setServiceMenu(false);
    setBurgerMenu(false);
  };

  useEffect(() => {
    setFilteredData(
      countries?.data?.filter((country) => {
        const name = queryLang ? country?.localization[queryLang] : "";
        return name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, countries, queryLang]);

  useEffect(() => {
    if (selectedLocation !== "topLocations" && !search) {
      const selectedContinent = locationsList.find(
        (location) => location.translation === selectedLocation
      );
      setFilteredData(
        countries?.data?.filter(
          (country) => country.continent === selectedContinent?.continent
        )
      );
    } else if (selectedLocation === "topLocations" && !search) {
      setFilteredData(
        countries?.data?.filter((country) => country.bestLocation)
      );
    }
  }, [selectedLocation, countries, queryLang, search]);

  return (
    <ul className={`${styles.list} scrollbar__dark`}>
    {filteredData.length > 0 ? filteredData.map((country) => {
      const alpha2 = convertCountryCodeAlpha3ToAlpha2(
        country?.code
      ).toLowerCase();
      const name = queryLang ? country?.localization[queryLang] : "";
      return (
        <li key={country?.id} className={styles.item} onClick={() => handleClick(country?.urlParam)}>
            {alpha2 !== "default" ? (
              <span className={`fl fl-${alpha2} ${styles.flag}`}></span>
            ) : (
              <img
                className={styles.flag}
                src="/img/flags/default.svg"
                width={20}
                height={20}
                loading="lazy"
                alt={name}
              />
            )}
            <span className={styles.name}>{name}</span>
        </li>
      );
    }) : null}
  </ul>
  );
};

import styled from "@emotion/styled";

export const StyledRecentReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 16px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows["recentReviewCard"]};
  background-color: ${(p) => p.theme.colors.white};
  conlor: ${(p) => p.theme.colors["neutral-800"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: normal;
  font-weight: 400;

  cursor: pointer;

  transition: box-shadow 0.3s ease;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 20px;
  }

  & .recent_site {
    display: none;
  }

  ${(p) =>
    p.hovered &&
    `
  &:hover {
    box-shadow: ${p.theme.shadows["recentReviewCardHover"]};
    transition: box-shadow 0.3s ease;

    & .recent_date {
      margin: 0;
      height: 0;
      display: none;
    }

    & .recent_site {
      display: inline-flex;
    }
  }
    `}
`;

export const StyledRecentReviewHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  line-height: normal;
  margin-bottom: 16px;

  width: 100%;
`;
export const StyledRecentReviewName = styled.div`
  margin-bottom: 4px;
  font-size: ${(p) => p.theme.fontSizes.m};
  line-height: 100%;
  font-weight: 700;
`;

export const StyledRecentReviewInfo = styled.div`
  display: inline-flex;
  font-size: ${(p) => p.theme.fontSizes.xxs};
  color: ${(p) => p.theme.colors["neutral-400"]};
  line-height: 117%;
`;

export const StyledRecentReviewStarsBox = styled.div`
  margin-left: auto;
`;

export const StyledRecentReviewText = styled.p`
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 136%;
  height: 37px;
  max-height: 37px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

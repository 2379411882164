import PT from "prop-types";

import Icon from "../Icon/Icon";

import { StyledRating } from "./Rating.styled";

export const Rating = ({ rating, className, size, background }) => (
  <StyledRating
    className={className}
    rating={rating}
    size={size}
    background={background}
  >
    <Icon name={"starFill"} />
    {rating.toFixed(1)}
    {/* {rating % 1 === 0 ? ".0" : ""} */}
  </StyledRating>
);
Rating.propTypes = {
  rating: PT.number,
  className: PT.string,
  size: PT.oneOf(["sm", "md_s", "md", "lg"]),
  background: PT.bool
};

Rating.defaultProps = {
  rating: 0,
  className: "",
  size: "md",
  background: true
};

export default Rating;

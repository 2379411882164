import "moment/locale/ru";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Stars from "components/ui/New/Stars/Stars";

import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { useLangUrlDefault } from "hooks";

import styles from "./UserPart.module.scss";

export const UserPart = ({ review, isRootReview }) => {
  const { t } = useTranslation();

  const [lang] = useLangUrlDefault();

  const { user, isUserAuthenticated } = useSelector(getAllUser);

  const maskEmail = (email) => {
    const [firstPart, secondPart] = email.split("@");

    return `${firstPart.slice(0, 1)}***${firstPart.slice(
      -3,
      firstPart.length
    )}@${secondPart}`;
  };

  return (
    <div className={`${styles.user} ${isRootReview ? "" : styles.nested}`}>
      <div className={styles.user_data}>
        <div className={styles.user_name}>
          {review?.userName
            ? `${
                review?.userName.includes("@")
                  ? review?.userName.split("@")[0]
                  : review?.userName
              } `
            : `${t("reviews.anonymous")} `}
          {isUserAuthenticated &&
            review?.userId === user?.uid &&
            t("reviews.you")}
        </div>
        {review?.userEmail && isRootReview && (
          <div>{maskEmail(review?.userEmail)}</div>
        )}
        {!isRootReview && review?.userName === "Admin" && (
          <div className={styles.user_official}>
            <IconsSVG name="checkCircle" />
            {review?.userName}
          </div>
        )}
      </div>

      <div className={styles.review}>
        {isRootReview && (
          <Stars
            size={20}
            value={review?.rating}
            className={styles.review_stars}
          />
        )}
        <div>
          <Moment format="MMM DD, YYYY" locale={lang}>
            {new Date(review.creationDate)}
          </Moment>
        </div>
      </div>
    </div>
  );
};

import PT from "prop-types";

import { StyledCard, StyledIcon, StyledText, StyledContent } from "./InfoMessage.styled";

const InfoMessage = ({ text, className }) => (
  <StyledCard className={className}>
    <StyledContent>
    <StyledIcon src="img/ui/info.svg" />
    <StyledText>{text}</StyledText>
    </StyledContent>
  </StyledCard>
);

InfoMessage.propTypes = {
  text: PT.string,
  className: PT.string
};

InfoMessage.defaultProps = {
  className: "",
  text: ""
};

export default InfoMessage;

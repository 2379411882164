import BrowserInformationPage from "pages/New/ToolsPages/BrowserInformationPage/BrowserInformationPage";
import DnsLeakTestPage from "pages/New/ToolsPages/DnsLeakTestPage/DnsLeakTestPage";
import EvercookieTestPage from "pages/New/ToolsPages/EvercookieTestPage/EvercookieTestPage";
import FingerprintPage from "pages/New/ToolsPages/FingerprintPage/FingerprintPage.jsx";
import FreeProxyPage from "pages/New/ToolsPages/FreeProxyPage/FreeProxyPage";
import HttpHeadersCheckerPage from "pages/New/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage";
import IpBlacklistCheckPage from "pages/New/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage";
import MyAnonymityPage from "pages/New/ToolsPages/MyAnonymityPage/MyAnonymityPage";
import PortScannerPage from "pages/New/ToolsPages/PortScannerPage/PortScannerPage";
import TracingIpPage from "pages/New/ToolsPages/TracingIpPage/TracingIpPage";
import WebRTCLeakTestPage from "pages/New/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage";

export const ToolsRoutes = [
  {
    path: "/:lang?/new/tools/fingerprint",
    tag: "fingerprint",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/FingerprintPage/FingerprintPage"),
    element: FingerprintPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/port-scanner",
    tag: "port-scanner",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/PortScannerPage/PortScannerPage"),
    element: PortScannerPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/tracing-ip",
    tag: "tracing-ip",
    type: "tool",
    component: () => import("pages/New/ToolsPages/TracingIpPage/TracingIpPage"),
    element: TracingIpPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/dns-leak-test",
    tag: "dns-leak-test",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/DnsLeakTestPage/DnsLeakTestPage"),
    element: DnsLeakTestPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/ip-blacklist-check",
    tag: "ip-blacklist-check",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage"),
    element: IpBlacklistCheckPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/free-proxy",
    tag: "free-proxy",
    type: "tool",
    component: () => import("pages/New/ToolsPages/FreeProxyPage/FreeProxyPage"),
    element: FreeProxyPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/my-anonymity",
    tag: "my-anonymity",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/MyAnonymityPage/MyAnonymityPage"),
    element: MyAnonymityPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/webrtc-leak-test",
    tag: "webrtc-leak-test",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage"),
    element: WebRTCLeakTestPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/http-headers-checker",
    tag: "http-headers-checker",
    type: "tool",
    component: () =>
      import(
        "pages/New/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage"
      ),
    element: HttpHeadersCheckerPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/evercookie-test",
    tag: "evercookie-test",
    type: "tool",
    component: () =>
      import("pages/New/ToolsPages/EvercookieTestPage/EvercookieTestPage"),
    element: EvercookieTestPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/tools/browser-information",
    tag: "browser-information",
    type: "tool",
    component: () =>
      import(
        "pages/New/ToolsPages/BrowserInformationPage/BrowserInformationPage"
      ),
    element: BrowserInformationPage,
    isAuthRequired: false,
    isSsr: true
  }
];

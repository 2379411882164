import { useEffect, useState } from "react";

import { Chart, registerables } from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "hooks";

import { Tab } from "../../../../../../components/common/Tabs/Tab/Tab";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

Chart.register(...registerables);

export const Rating = ({ stepRating, clickStepRatingHandle }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const currentMonth = new Date().getMonth() + 1;
  const monthes = t("proxySitePage.content.rating.labels", {
    returnObjects: true
  });
  const startArray = monthes?.slice(0, currentMonth);
  const endArray = monthes?.slice(currentMonth, 12);
  const getChartData = (data) => ({
    labels:
      stepRating === "days"
        ? [...Array(30)]
            .map((it, i) => {
              if (i === 0) {
                return moment().format("DD.MM");
              }

              return moment().add(-i, "day").format("DD.MM");
            })
            .reverse()
        : [...endArray, ...startArray],
    datasets: [
      {
        label: t("proxySitePage.content.rating.label"),
        data,
        fill: false,
        backgroundColor: "#ffdf08",
        borderColor: "rgba(255, 223, 8, 0.5)"
      }
    ]
  });

  // **Redux state
  const { rating } = useSelector(getAllProxySite);

  // **Local state
  const [chartData, setChartData] = useState(getChartData([]));

  useEffect(() => {
    setChartData(getChartData(rating.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating, queryLang]);

  return (
    <div className="proxy-site__rating">
      <div className="proxy-site__rating-filter">
        <Tab
          size="small"
          tab="days"
          currentTab={stepRating}
          onClick={() => clickStepRatingHandle("days")}
        >
          {t("proxySitePage.content.rating.filters.month")}
        </Tab>
        <Tab
          size="small"
          tab="months"
          currentTab={stepRating}
          onClick={() => clickStepRatingHandle("months")}
        >
          {t("proxySitePage.content.rating.filters.general")}
        </Tab>
      </div>
      {rating.isDataLoaded ? (
        <Line
          data={chartData}
          options={{
            scales: {
              y: {
                suggestedMin: 3,
                suggestedMax: 5
              }
            }
          }}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

import axios from "axios";

import { AllActions } from "../../../../../store/reducers/AllActions";

import { ProxyEdit } from "./ProxyEdit/ProxyEdit";

const ProxyEditPage = () => (
  <>
    <ProxyEdit />
  </>
);

export default ProxyEditPage;

ProxyEditPage.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
};

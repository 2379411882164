import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../hooks";
import { navigation } from "../../../../../utils/constants";

export const Navigation = () => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  return (
    <nav className="header__top-nav">
      <ul className="list-reset">
        {navigation
          .filter(({ isTopNavigation }) => isTopNavigation)
          .map(({ route, translation }) => (
            <li key={route}>
              <Link to={`${hrefLang}/${route}/`}>
                {t(`navigation.${translation}`)}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import YesNo from "components/common/New/YesNo/YesNo";
import SelectNew from "components/ui/New/Select/SelectNew";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

import styles from "../Info.module.scss";

export const Informations = ({ active }) => {
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const {
    site: { data }
  } = useSelector(getAllProxySite);
  const { proxyTypes } = useSelector(getAllContent);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const proxyTypesForLang = proxyTypes.data?.[queryLang];
    if (proxyTypesForLang) {
      const options = proxyTypesForLang
        .map(({ type, name }) => ({
          label: name,
          value: type
        }))
        .filter(({ value }) => data?.proxyType?.includes(value));
      if (options?.length) {
        setSelected(options[0]?.value);
        setOptions(options);
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, data]);

  return (
    <div className={`${styles.info_block} ${active ? styles.info_active : ""}`}>
      <div className={styles.info_title}>
        {t("proxySitePage.info.informations.title")}
      </div>
      {options?.length && selected ? (
        <>
          <SelectNew
            id="informations"
            options={options}
            className={styles.select}
            // value={selected}
            defaultValue={selected}
            onChange={(data) => setSelected(data?.value)}
          />
          <ul className={styles.list_info}>
            <li className={styles.list_info_item}>
              <span>{t("proxySitePage.info.informations.minRent")}:</span>
              <span className={styles.list_info_item_value}>
                {time(
                  data?.minRentPeriod?.periodName,
                  data?.minRentPeriod?.periodValue,
                  queryLang,
                  t("times", { returnObjects: true })
                )}
              </span>
            </li>
            <li className={styles.list_info_item}>
              <span>{t("proxySitePage.info.informations.minPrice")}:</span>
              <span className={styles.list_info_item_value}>
                ${data?.price}
              </span>
            </li>
            <li className={styles.list_info_item}>
              <span>{t("proxySitePage.info.informations.freeTest")}:</span>
              <YesNo
                value={data?.freeTest}
                className={styles.list_info_item_value}
              />
            </li>
            <li className={styles.list_info_item}>
              <span>{t("proxySitePage.info.informations.refund")}:</span>
              <span className={styles.list_info_item_value}>7 days</span>
            </li>
            <li className={styles.list_info_item}>
              <span>{t("proxySitePage.info.informations.affiliate")}:</span>
              <YesNo
                value={data?.affiliateProgram}
                className={styles.list_info_item_value}
              />
            </li>
          </ul>
        </>
      ) : null}
    </div>
  );
};

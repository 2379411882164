import { useEffect, useId, useRef, useState } from "react";

import { StyledTooltip } from "components/ui/New/Tooltip/Tooltip.styled";

import { useLangUrlDefault } from "hooks";

import {
  StyledHeaderCell,
  StyledHeaderNameCell
} from "./HeaderNameCell.styled";

export const HeaderNameCell = ({ children }) => {
  const [lang] = useLangUrlDefault();
  const id = useId();
  const ref = useRef(null);
  const refBox = useRef(null);

  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth);
    }
  }, [ref, lang]);
  return (
    <StyledHeaderCell ref={refBox}>
      <StyledHeaderNameCell data-tooltip-id={id} ref={ref}>
        {children}
      </StyledHeaderNameCell>
      {needTooltip ? (
        <StyledTooltip id={id} place="top">
          {children}
        </StyledTooltip>
      ) : null}
    </StyledHeaderCell>
  );
};

import styled from "@emotion/styled";

const BG_COLOR = {
  light: "white",
  gray: "background-color",
  table: "white",
  dark: "background-color-dark"
};

const BOX_PADDING = {
  mobile: {
    table: 24,
    light: 16,
    gray: 16,
    dark: 16
  },
  desktop: {
    table: 24,
    light: 16,
    gray: 16,
    dark: 24
  }
};

const BOX_SHADOW = {
  light: "promoCardLight",
  table: "promoCardTable"
};

export const StyledPromocode = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.variant === "table" ? "row" : "column")};
  gap: ${(p) => (p.variant === "dark" ? "20px" : "16px")};
  padding: ${(p) => BOX_PADDING.mobile[p.variant]}px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors[BG_COLOR[p.variant]]};
  box-shadow: ${(p) =>
    BOX_SHADOW?.[p.variant] ? p.theme.shadows[BOX_SHADOW[p.variant]] : "none"};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: ${(p) => BOX_PADDING.desktop[p.variant]}px;
    flex-direction: ${(p) =>
      ["table"].includes(p.variant) ? "row" : "column"};
  }
  ${(p) =>
    p.variant === "light"
      ? `
        @media (min-width: 1440px) {
        flex-direction: row;
        `
      : ""}

  ${(p) =>
    p.variant === "dark"
      ? `
      background-size: cover;
  background-position: left top;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
     & * {
    z-index: 2;
  }
  &::before {
    content: "";
    background-color: ${p.theme.colors["background-color-special-decor"]};
    width: 345px;
    height: 345px;
    border-radius: 50%;
    position: absolute;
    top: -38px;
    left: 130px;
    z-index: 0;
  }

  &::after {
    content: "";
    background-color: ${p.theme.colors["background-color-special-decor"]};
    width: 345px;
    height: 345px;
    border-radius: 50%;
    position: absolute;
    top: -32px;
    left: 165px;
    z-index: 0;
  }`
      : ""}
`;

export const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.variant === "table" ? "row" : "column")};
  gap: ${(p) => (["table", "dark"].includes(p.variant) ? "16px" : "12px")};
`;

export const StyledDescription = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  white-space: wrap;
  text-overflow: clip;
  word-break: break-word;
  color: ${(p) =>
    p.variant === "dark" ? "#c5cdd9" : p.theme.colors["neutral-800"]};
  margin-left: ${(p) => (p.variant === "light" ? "44px" : "0")};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-left: ${(p) => (p.variant === "light" ? "52px" : "0")};
  }
`;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobileMenuOpened: false,
  isBurgerMenuOpened: false,
  isServiceMenuOpened: false,
  previousLang: "en"
};

const SiteReducer = createSlice({
  name: "site",
  initialState,
  reducers: {
    setMobileMenu(state, action) {
      state.isMobileMenuOpened = action.payload;
    },
    setBurgerMenu(state, action) {
      state.isBurgerMenuOpened = action.payload;
    },
    setServiceMenu(state, action) {
      state.isServiceMenuOpened = action.payload;
    },
    setPreviousLang(state, action) {
      state.previousLang = action.payload;
    }
  }
});

export const SiteReducerActions = {
  ...SiteReducer.actions
};
export default SiteReducer.reducer;

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import TextButton from "../../../../../components/ui/New/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../hooks";

import styles from "./AuthNavigate.module.scss";

export const AuthNavigate = ({ to, isStatic = false, noIcon, className }) => {
  // **Redux state
  const { t } = useTranslation();
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  return (
    <div
      className={`${styles.navigate} ${
        isStatic ? styles.static : ""
      }  ${className}`}
    >
      <span className={styles.text}>
        {to === "login" && t("authPages.navigate.toLogin.text")}
        {to === "registration" && t("authPages.navigate.toRegister.text")}
      </span>
      <TextButton
        color={"primary"}
        size={"md"}
        iconRight={!noIcon ? "arrowAltRight" : null}
        linkTo={`${hrefLang}/new/${to}`}
      >
        {to === "login" && t("authPages.navigate.toLogin.btn")}
        {to === "registration" && t("authPages.navigate.toRegister.btn")}
      </TextButton>
    </div>
  );
};

AuthNavigate.propTypes = {
  to: PT.oneOf(["login", "registration"]).isRequired,
  isStatic: PT.bool,
  className: PT.string,
  noIcon: PT.bool
};

AuthNavigate.defaultProps = {
  isStatic: false,
  noIcon: false,
  className: ""
};

export default AuthNavigate;

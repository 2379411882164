import { useState } from "react";

import { useTranslation } from "react-i18next";

import { LeaveReviewModal } from "components/common/New/Modals/LeaveReviewModal/LeaveReviewModal";
import Button from "components/ui/New/Button/Button";
import Card from "components/ui/New/Card/Card";

import icon from "assets/img/proxyPage/review-icon.svg";

import { TabTitle } from "../../TabTitle/TabTitle";

import styles from "./AddReview.module.scss";

export const AddReview = ({
  active,
  captchaRef,
  setTokenCaptcha,
  getSiteReviews
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <div className={`${styles.addReview} ${active ? "" : styles.hide}`}>
        <div className={styles.addReview_bg} />
        <div className={styles.addReview_content}>
          <Card className={styles.card}>
            <TabTitle
              title={t("proxySitePage.about.reviews.title")}
              icon={icon}
              className={styles.card_title}
            />
            <Button
              className={styles.card_btn}
              size={"md"}
              onClick={handleOpen}
            >
              {t("proxySitePage.about.reviews.btn")}
            </Button>
          </Card>
        </div>
        <LeaveReviewModal
          visible={open}
          cancelHandler={() => setOpen(false)}
          afterSubmitHandler={getSiteReviews}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
        />
      </div>
    </>
  );
};

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import IconsSVG from "../Icon/IconsSVG";

export const StyledLink = styled(Link)`
  font-size: 0;

  color: ${(p) =>
    p.color === "white" ? p.theme.colors.white : p.theme.colors["neutral-800"]};
`;

export const Icon = styled(IconsSVG)`
  width: 143px;
  height: 26px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 165px;
    height: 28px;
  }
`;

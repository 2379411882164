/* eslint-disable react/destructuring-assignment */
// import { useEffect, useRef, useState } from "react";
import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Input } from "components/forms/New";
import Button from "components/ui/New/Button/Button";
// import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Modal from "components/ui/New/Modal/Modal";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { ApiService } from "services";

// import { Rating } from "../../../";
import { useDispatchedActions, useLangUrlDefault } from "hooks";

import {
  // ReviewSchema,
  ReviewSchemaNew, // ReviewSchemaWithAuth,
  ReviewSchemaWithAuthNew
} from "utils/validation";

// import { Input } from "../../../../forms/Input/Input";
// import { Textarea } from "../../../../forms/Textarea/Textarea";
// import { Button } from "../../../../ui/Button/Button";
// import { Loader } from "../../../../ui/Loader/Loader";
// import { Typography } from "../../../../ui/Typography/Typography";
import { PersonalInfo } from "./PersonalInfo/PersonalInfo";
import { Ratings } from "./Ratings/Ratings";
import { Success } from "./Success/Success";

// import DiscreteSlider from "./Test";
import styles from "./LeaveReviewModal.module.scss";

export const LeaveReviewModal = (props) => {
  // **Props
  const {
    visible,
    captchaRef,
    setTokenCaptcha,
    // isEdit,
    // reviewItem,
    cancelHandler
  } = props;
  // const { visible, isEdit, cancelHandler, captchaRef } = props;
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const { captchaConfig } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);
  const { site } = useSelector(getAllProxySite);

  // **Local state
  // const [rating, setRating] = useState(isEdit ? reviewItem?.rating : 0);
  // const [ratingError, setRatingError] = useState(false);

  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const { enable, headerName } = captchaConfig.data;

  // **Ref
  // const modalRef = useRef();

  // **Dispatch
  const { getLastSiteReviews } = useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(
      isUserAuthenticated
        ? ReviewSchemaNew("forms", t)
        : ReviewSchemaWithAuthNew("forms", t)
    )
    // defaultValues: reviewItem
  });

  // const titleReviewModal = isEdit
  //   ? t("modals.review.titleEdit")
  //   : t("modals.review.titleAdd");
  const titleReviewModal = t("modals.review.titleAdd");

  // useEffect(() => {
  //   if (!visible && isEdit) {
  //     // Reset form for edit
  //     methods.reset();
  //     setRating(reviewItem?.rating);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visible, isEdit, methods]);

  const hendlerCloseModal = () => {
    setSuccess(false);
    cancelHandler(false);
  };

  // const onSubmit = (data) => {
  //   toast.info("Todo new logic");
  //   setSuccess(true);
  //   // eslint-disable-next-line no-console
  //   console.log(data);
  // };

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      // Check if rating is choosen
      // if (!rating) {
      //   modalRef.current?.scrollTo({
      //     top: 0,
      //     behavior: "smooth"
      //   });

      //   setRatingError(true);
      //   return;
      // }

      setIsLoading(true);

      // eslint-disable-next-line no-shadow
      const { name, email, ...rest } = data;
      const user = !isUserAuthenticated
        ? {
            name,
            email,
            locale: queryLang
          }
        : null;

      const params = {
        siteId: site.data?.id,
        user,
        reviewBody: rest.reviewBody,
        advantages: rest.advantages,
        disadvantages: rest.disadvantages,
        priceRating: rest.priceRating,
        speedRating: rest.speedRating,
        reliabilityRating: rest.reliabilityRating,
        supportRating: rest.supportRating
      };

      // const response = !isEdit
      //   ? await ApiService.createSiteReview(formSummary, header)
      //   : await ApiService.updateSiteReview(formSummary);
      const response = await ApiService.createSiteReviewNew(params, header);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form (except rating)
      methods.reset();

      // Show success message
      // toast.success(
      //   !isEdit
      //     ? t("notifications.modals.review")
      //     : t("notifications.modals.reviewEdit")
      // );
      toast.success(t("notifications.modals.review"));

      // Close modal
      props.cancelHandler(false);

      // Get new last reviews
      getLastSiteReviews();

      if (props.afterSubmitHandler) {
        props.afterSubmitHandler();
      }
    } catch (err) {
      if (err.response.status === 540) {
        methods.reset();
        props.cancelHandler(false);
        toast.error(t("notifications.modals.reviewAlreadySend"));
      } else {
        toast.error(t("notifications.apiError"));
      }
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      // setRating(0);
      setIsLoading(false);
    }
  };

  // const getRating = (ratingValue) => {
  //   setRating(ratingValue);
  //   setRatingError(false);
  // };

  const onChangeDelUrlOrChar = (evt) => {
    methods.setValue(
      evt.target.name,
      evt.target.value.replace(
        /[^a-zа-яёіїє0-9!,.?()\s]|\b(https?:\/\/\S*\b)/gi,
        ""
      )
    );
  };

  return (
    <Modal
      open={visible}
      setOpen={hendlerCloseModal}
      title={!success ? titleReviewModal : null}
      minWidth={320}
      className={`${styles.modal} ${success ? styles.modal_success : ""}`}
      classNameBody={styles.modal_body}
    >
      {success ? (
        <Success />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={styles.form}
            noValidate
          >
            <Ratings />
            <div className={styles.form_row}>
              <Input
                label={t("modals.review.form.advantages")}
                name="advantages"
                type="textarea"
                showError
                placeholder={t("modals.review.form.placeholder")}
                className={styles.textarea}
                onChange={onChangeDelUrlOrChar}
              />
              <Input
                label={t("modals.review.form.disadvantages")}
                name="disadvantages"
                type="textarea"
                showError
                placeholder={t("modals.review.form.placeholder")}
                className={styles.textarea}
                onChange={onChangeDelUrlOrChar}
              />
            </div>
            <Input
              label={t("modals.review.form.review")}
              name="reviewBody"
              type="textarea"
              showError
              placeholder={t("modals.review.form.placeholder")}
              className={styles.review_text}
              showMaxLenght={100}
              onChange={onChangeDelUrlOrChar}
            />
            {!isUserAuthenticated && <PersonalInfo />}

            <Button
              disabled={isLoading}
              formSubmit
              isLoading={isLoading}
              fullWidth
            >
              {titleReviewModal}
            </Button>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

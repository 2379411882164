export const getParamsUrlForFilter = (fc, fg, fpts, s, filter) => {

  const fcParam = fc ? `?fc=${fc}` : "";
  const fgParam = fg ? `${fc ? "&" : "?"}fg=${fg}` : "";
  const fptParams = fpts.length > 0 ? fpts
    .map((item, i) => {
      if (i === 0 && !fc && !fg) {
        return `?fpt=${item}`;
      }

      return `&fpt=${item}`;
    })
    .join("") : "";
  const sParam = s ? `${!fc && !fg && fpts.length === 0 ? "?" : "&"}s=${s}` : "";
  const filterParam = filter ? `${!fc && !fg && fpts.length === 0 && !s ? "?" : "&"}filter=${filter}` : "";

  return `${fcParam}${fgParam}${fptParams}${sParam}${filterParam}`;
};

import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Input } from "components/forms/New";
import Button from "components/ui/New/Button/Button";
import Modal from "components/ui/New/Modal/Modal";

import { SubscribeValidation } from "utils/validation";

import { StyledDescription } from "./SubscribePromoModal.styled";

import styles from "./SubscribePromoModal.module.scss";

export const SubscribePromoModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(
      SubscribeValidation(t("forms", { returnObjects: true }))
    ),
    defaultValues: {
      name: "",
      email: ""
    }
  });

  const [loading, setLoading] = useState(false);

  const handlerClose = () => {
    onClose?.();
  };

  const onSubmit = (data) => {
    setLoading(true);
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log(data);
      toast.success(`MOCK MESSAGE  ${t("modals.subscribe.success")}`);
      setLoading(false);
      methods.reset({
        name: "",
        email: ""
      });
      handlerClose();
    }, 2000);
  };

  return (
    <Modal
      open={open}
      setOpen={handlerClose}
      title={t("modals.subscribe.title")}
      maxWidth={610}
      className={styles.modal}
      classNameBody={styles.modal_body}
    >
      <StyledDescription>{t("modals.subscribe.description")}</StyledDescription>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
          <Input
            label={t("modals.subscribe.form.name.title")}
            name="name"
            showError
            placeholder={t("modals.subscribe.form.name.placeholder")}
          />
          <Input
            label={t("modals.subscribe.form.email.title")}
            name="email"
            showError
            placeholder={t("modals.subscribe.form.email.placeholder")}
          />
          <Button
            formSubmit
            fullWidth
            className={styles.modal_btn}
            loading={loading}
          >
            {t("modals.subscribe.form.btn")}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

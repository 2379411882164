import { Button } from "@mui/base/Button";

import Card from "components/ui/New/Card/Card";

import IconsSVG from "../../../../../components/ui/New/Icon/IconsSVG";

import styles from "./CardInfo.module.scss";

export const CardInfo = ({
  title,
  className,
  classNameBody,
  children,
  showIcon,
  onClose
}) => (
  <Card
    className={`${styles.card} ${className}`}
    size={"small"}
    radius={"small"}
    bgColor={"gray"}
  >
    <div>
      {showIcon && (
        <Button className={styles.card_close} onClick={onClose}>
          <IconsSVG name="closeModal" />
        </Button>
      )}
      {title && <div className={styles.card_title}>{title}</div>}
      <div className={`${styles.card_body} ${classNameBody}`}>{children}</div>
    </div>
  </Card>
);

import styled from "@emotion/styled";

export const StyledTable = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  overflow-x: auto;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: table;
`;

export const TableBody = styled.div`
  overflow-y: auto;
  max-height: 1086px;
  width: 100%;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: #f6f7f9;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 60px;
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  color: var(--neutral-400);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 64px;
  cursor: pointer;
  border-bottom: 1px solid rgba(216, 218, 224, 0.5);

  &:hover {
    background: rgba(246, 247, 249, 0.6);
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  box-sizing: border-box;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: 12px 0;
  align-items: center;
`;

export const TableText = styled.span`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  width: 100%;
  gap: 8px;
  flex-shrink: 0;
  color: var(--neutral-800);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const InfoIcon = styled.img`
  margin-left: 8px;
`;

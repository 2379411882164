import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import TextButton from "components/ui/New/TextButton/TextButton";

import { StyledRenderText, StyledRenderTextWrapper } from "./RenderText.styled";

export const RenderText = ({ sub, toggleReview, children }) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [full, setFull] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const showFull = () => {
    setFull(true);
    toggleReview(true);
  };

  useEffect(() => {
    if (ref.current?.scrollHeight > ref.current?.clientHeight && !full) {
      setShowButton(true);
    }
    const handleResize = () => {
      if (ref.current?.scrollHeight < 200) {
        setShowButton(false);
      }
      if (ref.current?.scrollHeight > ref.current?.clientHeight && !full) {
        setShowButton(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <StyledRenderTextWrapper sub={sub}>
      <StyledRenderText ref={ref} full={full} sub={sub}>
        {children}
      </StyledRenderText>
      {showButton && !full ? (
        <TextButton onClick={showFull}>{t("reviews.showFull")}</TextButton>
      ) : null}
    </StyledRenderTextWrapper>
  );
};

import { useEffect, useState } from "react";

import { webrtcCheck } from "@octaniumsw/react-tools";
import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { Loader } from "components/ui";
import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import { useUserIp } from "hooks/useUserIp";

import { useWebRtcStatus } from "../../../../../hooks/useWebRtcStatus";

import { ResultsTable } from "./ResultRable/ResultTable";

import styles from "./WebRTCLeakTest.module.scss";

export const WebRTCLeakTest = () => {
  const [ips, setIps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const userIp = useUserIp();

  const webRtcStatus = useWebRtcStatus(ips, userIp?.ipAddress, setIps);

  useEffect(() => {
    if (userIp?.ipAddress) {
      webrtcCheck.check(setIps);
    }
    setIsLoading(false);
  }, [userIp?.ipAddress]);

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <div className={styles.image__wrapper}>
            <img
              src="img/webRTC-leak-test/webRTC-leak-test-image.svg"
              alt={t("dnsLeak.main.title")}
              className={styles.image}
            />
          </div>
          <Button
            className={styles.button}
            type="button"
            // eslint-disable-next-line no-empty-function
            onClick={() => {}}
            fullWidth
          >
            {t("webRTCLeakTest.webRTCTest.buttonLabel")}
          </Button>
        </div>
        <div className={styles.results}>
          {userIp && !isLoading ? (
            <>
              <div className={styles.title__wrapper}>
                <Title tag="h2" className={styles.title}>
                  {t("webRTCLeakTest.webRTCTest.title")}
                </Title>
                <div
                  className={`${styles.info_message} ${
                    webRtcStatus ? styles.leak : styles.no_leak
                  }`}
                >
                  {webRtcStatus
                    ? t("webRTCLeakTest.webRTCTest.leakMessage")
                    : t("webRTCLeakTest.webRTCTest.noLeakMessage")}
                </div>
              </div>
              <ResultsTable data={[userIp]} status={webRtcStatus} />
              <ul className={styles.list__mobile}>
                <li className={styles.item}>
                  <span className={styles.key}>
                    {t("webRTCLeakTest.table.ip")}
                  </span>
                  <span className={styles.value}>{userIp.ipAddress}</span>
                </li>
                <li className={styles.item}>
                  <span className={styles.key}>
                    {t("webRTCLeakTest.table.type")}
                  </span>
                  <span className={styles.value}>
                    {userIp.type || "Public IPv4"}
                  </span>
                </li>
                <li className={styles.item}>
                  <span className={styles.key}>
                    {t("webRTCLeakTest.table.status")}
                  </span>
                  <span className={styles.value}>
                    <p
                      className={
                        webRtcStatus
                          ? styles.status__error
                          : styles.status__success
                      }
                    >
                      {webRtcStatus
                        ? t("webRTCLeakTest.table.leak")
                        : t("webRTCLeakTest.table.noLeak")}
                    </p>

                    <Tooltip
                      body={
                        webRtcStatus
                          ? t("webRTCLeakTest.table.leakTooltip")
                          : t("webRTCLeakTest.table.noLeakTooltip")
                      }
                      className={styles.tooltip}
                      place="top"
                    >
                      <img
                        src="img/icons/information.svg"
                        alt=""
                        className={styles.icon}
                      />
                    </Tooltip>
                  </span>
                </li>
              </ul>
              <Button
                className={styles.button__mobile}
                type="button"
                // eslint-disable-next-line no-empty-function
                onClick={() => {}}
                disabled={isLoading}
                fullWidth
              >
                {t("webRTCLeakTest.webRTCTest.buttonLabel")}
              </Button>
            </>
          ) : (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { StyledDataTable } from "components/common/New/Table/Table.styled";
import {
  CustomExpandCell,
  HeaderNameCell,
  IndexCell,
  SiteNameCell,
  TextCell
} from "components/common/New/TableColumns";
import YesNo from "components/common/New/YesNo/YesNo";

// import { SkeletonTable } from "../../../../../../components/ui/New/Skeleton/SkeletonTable/SkeletonTable";
import { TextButton } from "../../../../../../components/ui/New/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../../hooks";
import { time } from "../../../../../../utils/helpers";

import { ExpandebleComponent } from "./ExpandebleComponent/ExpandebleComponent";

export const ProxyTable = ({ isLoading, tableData, skip }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const [data, setData] = useState(tableData);

  const handleExpand = (event) => {
    const id = event.currentTarget.id.replace("expand-", "");
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          expanded: !item.expanded
        };
      }
      return { ...item, expanded: false };
    });
    setData(newData);
  };

  const columns = [
    {
      name: "№",
      selector: (row, index) => {
        const currentNumber = index + 1 + skip;
        return (
          <IndexCell top={row?.topSite}>
            {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
          </IndexCell>
        );
      },
      maxWidth: "50px",
      minWidth: "50px",
      center: true
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.name.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <SiteNameCell
          imgUrl={row?.imageFile}
          name={row?.name}
          rating={row?.rating}
          showRating
          showAnnotation
          positive={row?.ratingPositive}
          negative={row?.ratingNegative}
          linkToReview={`${hrefLang}/new/proxy/${row.alias}/#reviews`}
        />
      ),
      minWidth: "290px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.minPrice.title")}
        </HeaderNameCell>
      ),
      cell: (row) => <TextCell>{`$${row?.price}`}</TextCell>,
      sortable: true,
      selector: (row) => row.price,
      width: "110px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.minRent.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <TextCell>
          {time(
            row?.minRentPeriod?.periodName,
            row?.minRentPeriod?.periodValue,
            queryLang,
            t("times", { returnObjects: true })
          )}
        </TextCell>
      ),
      sortable: true,
      selector: (row) => row?.minRentPeriod?.periodMilliseconds,
      width: "110px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => <YesNo value={row?.freeTest} />,
      minWidth: "75px",
      width: "90px"
    },
    {
      name: (
        <HeaderNameCell>
          {t("proxyPage.content.table.site.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <TextButton
          size={"sm"}
          color={"table"}
          iconRight={"arrowAltRight"}
          linkTo={`${hrefLang}/new/proxy/${row.alias}/`}
        >
          {t("proxyPage.content.table.site.btn")}
        </TextButton>
      ),
      width: "105px"
    },
    {
      cell: (row) => (
        <CustomExpandCell
          id={`expand-${row.id}`}
          open={row?.expanded}
          onClick={handleExpand}
        />
      ),
      width: "44px"
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row?.expanded,
      style: {
        backgroundColor: "#f6f7f9cc !important"
      }
    }
  ];

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  return (
    <StyledDataTable
      styleRules={{
        row: {
          noBorder: true
          // noPadding: true
        }
      }}
      columns={columns}
      data={data}
      noDataComponent={
        !isLoading && (
          <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
            {t("proxyPage.content.table.noDataComponent")}
          </div>
        )
      }
      conditionalRowStyles={conditionalRowStyles}
      // progressPending={isLoading}
      // progressComponent={<SkeletonTable cells={10} />}
      expandableRowExpanded={(row) => row?.expanded}
      expandableRowsComponent={ExpandebleComponent}
      expandableRows
      expandableRowsHideExpander
    />
  );
};
